import { Request, CallbackFunc, MatcherFunc, WaitingResponse, AuthResponse, Operations, WaitingResponses, EntityRequest, TimeslotResponse } from "../types";
import { promiseRejectTimeoutMs } from "../settings";
import { v1 as uuid } from "uuid";
import { timeslotStore } from "../store/stores";
import CryptoJS from "crypto-js";

export const createCache = <T>() => {
    const cache = new Map<string, { time: number; response: T }>();

    const get = (key: string) => {
        return cache.get(key);
    };

    const add = (key: string, response: T) => {
        const responseObj = {
            time: new Date().getTime(),
            response
        };

        cache.set(key, responseObj);
    };

    return {
        get,
        add
    };
};

export const createRequestSessionKey = (
    socket: WebSocket,
    waitingResponses: WaitingResponses<any>,
    sessionKey?: string | null | undefined
) => (): Promise<AuthResponse> => {
    // API demands request to be strings with strict ordering
    const msgStr = `{"op":"${Operations.auth}","data":{"api-key":"${process.env.REACT_APP_ASPA_API_KEY || "INVALID API KEY"}"${sessionKey ? `,"session-key":"${sessionKey}"` : ""}}}`;
    const msg = JSON.parse(msgStr);
    const matchResponseToCallback = (resp: AuthResponse) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op;
    };

    return new Promise((resolve, reject) => {
        const callback = (data: AuthResponse) => resolve(data);
        send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

export const send = async <T>(
    socket: WebSocket,
    callback: CallbackFunc<T>,
    matchResponseToCallback: MatcherFunc<T>,
    msg: Request,
    waitingResponses: WaitingResponses<T>,
    reject: (reason: any) => void,
    msgStr: string // Websocket sends data as a string and API requires strict ordering of objects
) => {
    if (process.env.NODE_ENV === "development") {
        console.log("Socket message was sent"); //eslint-disable-line
    }
    const reqObj = {
        matcher: matchResponseToCallback,
        callback,
        requestMsg: msg,
    } as any;

    const reqKey = uuid();
    const pendingRequest =
        Object.values(waitingResponses)
            .find(request => {
                const requestMsg = request.requestMsg as EntityRequest;
                const msg = reqObj.requestMsg as EntityRequest;
                return requestMsg.uuid && requestMsg.uuid === msg.uuid;
            });

    if (!pendingRequest) {
        reqObj.timeout = createTimeout(waitingResponses, matchResponseToCallback, reject);
        waitingResponses[reqKey] = reqObj;
        socket.send(msgStr);
    } else {
        pendingRequest.callback = Array.isArray(pendingRequest.callback) ?
            [...pendingRequest.callback, callback] :
            [pendingRequest.callback, callback];
    }
};

const handleTimeslotUpdateMessage = (msg: MessageEvent) => {
    const data = tryJSONParse(msg.data);
    if (data.op === Operations.appointment && typeof data.available === "boolean") {
        if (data.available === true) {
            data.data.forEach((item: TimeslotResponse) =>
                timeslotStore.addTimeslot(timeslotStore, data.available, item)
            );
        } else {
            data.data.forEach((uuid: string) => {
                timeslotStore.updateTimeslotValues(timeslotStore, data.available, uuid);
            });
        }
        return Promise.resolve();
    }

    if (process.env.NODE_ENV === "development") {
        console.log("Error: There was no WaitingResponse object for received message.", msg); //eslint-disable-line
    }

    return Promise.resolve();
};

export const tryJSONParse = (data: string) => {
    try {
        return JSON.parse(data);
    } catch (err) {
        throw Error("Couldn't parse response data.");
    }
};

export const createOnmessage = <T>(waitingResponses: WaitingResponses<T>) =>
    async (evt: MessageEvent): Promise<void> => {


        const saveAndRespond = (
            wrKey: string,
            waitingResponse: WaitingResponse<T>,
            response: T,
        ) => {
            if (Array.isArray(waitingResponse.callback)) {
                waitingResponse.callback.forEach(callback => callback(response));
            } else {
                waitingResponse.callback(response);
            }
            clearTimeout(waitingResponse.timeout);
            delete waitingResponses[wrKey];
        };
        const [key, waitingResponse] =
            Object.entries(waitingResponses)
                .find(([_key, current]) => current.matcher(evt.data as T)) ||
            [null, null];
        waitingResponse && key ?
            saveAndRespond(
                key,
                waitingResponse,
                tryJSONParse(evt.data) as T
            ) :
            handleTimeslotUpdateMessage(evt);
    };

export const createTimeout = (
    waitingResponses: WaitingResponses<any>,
    matchResponseToCallback: any,
    reject: (reason?: any) => void,
    timeoutMs: number = promiseRejectTimeoutMs
) => setTimeout(() => {
    const [key, waitingResponse] = Object.entries(waitingResponses)
        .find(([_key, current]) => current.matcher = matchResponseToCallback) ||
        [null, null];

    if (waitingResponse && key) {
        delete waitingResponses[key];
        reject("Timeout");
    }

}, timeoutMs);

export const initializeSocket = (
    callback: (socket: WebSocket) => void,
    onmessage: (evt: MessageEvent) => Promise<void>,
    URL: string,
    reject: (arg: any) => void,
    onClose?: () => void,
    resolve?: (arg: any) => void
) => {
    try {
        const socket = new WebSocket(URL);
        socket.onopen = () => {
            if (process.env.NODE_ENV === "development") {
                console.log(`{Socket to ${URL} connected`); //eslint-disable-line
            }
            if (resolve) {
                resolve(socket);
            } else
                callback(socket);
        };

        socket.onclose = (ev: CloseEvent) => {
            if (process.env.NODE_ENV === "development") {
                console.log(`{Socket to ${URL} closed`, ev); //eslint-disable-line
            }
            if (onClose) onClose();
        };

        socket.onmessage = onmessage;
        socket.onerror = (evt: Event) => {
            if (process.env.NODE_ENV === "development") {
                console.log(`{Socket to ${URL} error: `, evt); //eslint-disable-line
            }
            reject("Socket error");
        };
    } catch (err) {
        reject("Failed estabilishing socket connection");
    }
};

export const utoa = (argStr: string) => {
    try {
        const wordArr = CryptoJS.enc.Utf8.parse(argStr);
        const base64 = CryptoJS.enc.Base64.stringify(wordArr);
        return base64;
    } catch (err) {
        throw Error("Couldn't encode string.");
    }
};

export const btou = (argStr: string) => {
    try {
        const parsedWord = CryptoJS.enc.Base64.parse(argStr);
        const parsedStr = parsedWord.toString(CryptoJS.enc.Utf8);
        return parsedStr;
    } catch (err) {
        throw Error("Couldn't parse base64 string.");
    }
};

export const arraysAreEqual = (a: Array<string | number>, b: Array<string | number>) =>
    a.length === b.length &&
    !a.some(itemA => !b.find(itemB => itemB === itemA));

export const hasData = <T>(data: T) => new Promise((resolve, reject) =>
    Object.keys(data).length > 0 ? resolve(true) : reject());
