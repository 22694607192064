import { Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { createTimeframes } from "../utils";


const button = (theme: Theme) => ({
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: 130
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centered: {
            textAlign: "center",
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(2)
        },
        button: {
            ...button(theme),
        }
    }));

export interface ITimes {
    hr: number,
    minutes: number
}



export interface ITimeFrame {
    from: Date,
    to: Date
}

export interface TimeOfDayFilter {
    id: number,
    timeframe: ITimeFrame
}

export interface ITimeOfDayProps {
    date: Date,
    selectedTimeframe: TimeOfDayFilter,
    setSelectedTimeframe: (filters: TimeOfDayFilter) => void
}

const TimeOfDay: React.FC<ITimeOfDayProps> = ({
    date,
    selectedTimeframe,
    setSelectedTimeframe
}) => {
    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);
    const timeframes = useMemo(() => createTimeframes(date), [date]);

    const toggle = (val: TimeOfDayFilter) =>
        selectedTimeframe.id !== val.id ?
            setSelectedTimeframe(val) :
            setSelectedTimeframe(timeframes.all);

    const getVariant = (timefilter: TimeOfDayFilter) =>
        timefilter.id === selectedTimeframe.id ?
            "contained" :
            "outlined";

    return (
        <div className={classes.centered}>
            <Button
                variant={getVariant(timeframes.all)}
                color="primary"
                className={classes.button}
                onClick={() => toggle(timeframes.all)}
            >
                {translation.all}
            </Button>
            <Button
                variant={getVariant(timeframes.morning)}
                color="primary"
                className={classes.button}
                onClick={() => toggle(timeframes.morning)}
            >
                {translation.morning}
            </Button>
            <Button
                variant={getVariant(timeframes.midday)}
                color="primary"
                className={classes.button}
                onClick={() => toggle(timeframes.midday)}
            >
                {translation.dayTime}
            </Button>

            <Button
                variant={getVariant(timeframes.evening)}
                color="primary"
                className={classes.button}
                onClick={() => toggle(timeframes.evening)}
            >
                {translation.evening}
            </Button>
        </div>
    );
};

export default TimeOfDay;
