import { theme, colors } from "../../theme";
import React, { useContext } from "react";
import { DisclaimerButton } from "../CustomStyleComponents/Buttons";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selected: {
            color: theme.palette.primary.main,
            backgroundColor: colors.boxBackground
        }
    }));

export type SortOptions = "time" | "distance";

interface ISortSelectionView {
    selectedSorter: SortOptions,
    setSelectedSorter: (option: SortOptions) => void
}

export const SortSelectionView: React.FC<ISortSelectionView> = ({
    selectedSorter, setSelectedSorter
}) => {
    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    return (
        <div style={{ textAlign: "center" }}>
            <DisclaimerButton
                className={
                    selectedSorter === "distance" ?
                        classes.selected :
                        ""
                }
                onClick={() => setSelectedSorter("distance")}
            >
                {translation.orderByDistance}
            </DisclaimerButton>
            <DisclaimerButton
                style={{ marginLeft: theme.spacing(0.5) }}
                className={
                    selectedSorter === "time" ?
                        classes.selected :
                        ""
                }
                onClick={() => setSelectedSorter("time")}
            >
                {translation.orderByTime}
            </DisclaimerButton>
        </div>
    );
};
