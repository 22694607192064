import { Translation, TranslationKeys } from "../types";

const translation: Translation = {
    [TranslationKeys.selectLanguage]: "Select language",
    [TranslationKeys.selectVisitSubject]: "Select the subject of the service",
    [TranslationKeys.selectCustomerType]: "Select customer type",
    [TranslationKeys.selectService]: "Search for services",
    [TranslationKeys.selectSubService]: "Select service subtype",
    [TranslationKeys.selectDistrict]: "Select city",
    [TranslationKeys.selectPremise]: "Select location",
    [TranslationKeys.selectExpert]: "Expert",
    [TranslationKeys.summary]: "Summary",
    [TranslationKeys.selectTime]: "Select time",
    [TranslationKeys.service]: "Service",
    [TranslationKeys.time]: "Appointment",
    [TranslationKeys.duration]: "Duration",
    [TranslationKeys.city]: "City",
    [TranslationKeys.address]: "Address",
    [TranslationKeys.confirm]: "Confirm",
    [TranslationKeys.selfPaying]: "Self-paid",
    [TranslationKeys.serviceVoucher]: "Service voucher",
    [TranslationKeys.purchaseService]: "Purchasing service",
    [TranslationKeys.monday]: "Monday",
    [TranslationKeys.tuesday]: "Tuesday",
    [TranslationKeys.wednesday]: "Wednesday",
    [TranslationKeys.thursday]: "Thursday",
    [TranslationKeys.friday]: "Friday",
    [TranslationKeys.saturday]: "Saturday",
    [TranslationKeys.sunday]: "Sunday",
    [TranslationKeys.stepperReserveTimeslot]: "APPOINTMENT TO BE RESERVED",
    [TranslationKeys.stepperInformationForm]: "MY INFORMATION",
    [TranslationKeys.stepperConfirm]: "BOOKING CONFIRMATION",
    [TranslationKeys.clock]: "Time",
    [TranslationKeys.price]: "Price",
    [TranslationKeys.loginToMyCoronaria]: "Log in to My Coronaria",
    [TranslationKeys.personalIdentificationNumber]: "Enter your personal identity code",
    [TranslationKeys.firstName]: "Enter your first name",
    [TranslationKeys.lastName]: "Enter your last name",
    [TranslationKeys.postalCode]: "Enter your zip code",
    [TranslationKeys.district]: "Enter your post office",
    [TranslationKeys.phoneNumber]: "Enter your phone number",
    [TranslationKeys.email]: "Enter your e-mail address",
    [TranslationKeys.iDontHaveEmail]: "I do not have an e-mail address",
    [TranslationKeys.moreInformation]: "Additional information",
    [TranslationKeys.buttonGoBack]: "Go back",
    [TranslationKeys.buttonNext]: "Next",
    [TranslationKeys.buttonAbort]: "Close",
    [TranslationKeys.buttonConfirm]: "Confirm appointment",
    [TranslationKeys.howToRemind]: "How would you like to be reminded:",
    [TranslationKeys.withEmail]: "By e-mail",
    [TranslationKeys.withTextMessage]: "With a text message",
    [TranslationKeys.chooseReminderTime]: "Select reminder time",
    [TranslationKeys.oneDayBefore]: "Previous day",
    [TranslationKeys.twoHoursBefore]: "2 hours before appointment",
    [TranslationKeys.dataProtectionAndMarketing]: "Marketing consent",
    [TranslationKeys.checkboxDataProtectionAgree]: "I understand that my data will be stored in a customer register and processed in accordance with the data protection legislation.",
    [TranslationKeys.checkboxMarketingPermission]: "I give my consent for being sent current information about Coronaria’s customer benefits, offers and services.",
    [TranslationKeys.appointmentSuccessfull]: "Appointment booked successfully",
    [TranslationKeys.buttonSaveToCalendar]: "Save to calendar",
    [TranslationKeys.buttonPrint]: "Print",
    [TranslationKeys.buttonScheduleNewAppointment]: "Book a new appointment",
    [TranslationKeys.buttonClose]: "Close",
    [TranslationKeys.confirmationFormLongFooterHeader]: "Cancelling a booked appointment",
    [TranslationKeys.confirmationFormLongFooterText1]: "Only appointments booked online can be cancelled in the online appointment booking service. A booking confirmation, which includes a link to cancel the appointment, will be sent by e-mail. Cancellations must be made at least 24 hours before the appointment.",
    [TranslationKeys.confirmationFormLongFooterText2]: "The service provider has the right to charge a fee for cancellations made less than 24 hours before the appointment and uncancelled appointments according to their current price list.",
    [TranslationKeys.confirmationFormShortFooterText]: "We will send you an invoice for uncancelled appointments. Appointment cancellations must be made 24 h before the time of the appointment at the latest.",
    [TranslationKeys.confirmationFormShortFooterText2]: "Link to appointment cancellation",
    [TranslationKeys.specialty]: "Specialty",
    [TranslationKeys.education]: "Education",
    [TranslationKeys.languageSkills]: "Language skills",
    [TranslationKeys.expertsPremises]: "Expert’s locations",
    [TranslationKeys.priceList]: "Price list",
    [TranslationKeys.reserveTimeslot]: "Book an appointment",
    [TranslationKeys.closeOrMoveBack]: "Close or go back",
    [TranslationKeys.reservationService]: "Coronaria Appointment booking service",
    [TranslationKeys.location]: "Your location",
    [TranslationKeys.writeYourLocation]: "Enter your location",
    [TranslationKeys.locate]: "Services close to you",
    [TranslationKeys.gettingLocation]: "Retrieving location...",
    [TranslationKeys.preferCalling]: "Would you rather call",
    [TranslationKeys.youCanCallUs]: "If you are unable to find a suitable time for an appointment or a suitable expert, you can always book an appointment by calling us.",
    [TranslationKeys.morning]: "Morning",
    [TranslationKeys.dayTime]: "Daytime",
    [TranslationKeys.afternoon]: "Afternoon",
    [TranslationKeys.evening]: "Evening",
    [TranslationKeys.nextFreeTimeslots]: "Next available appointments",
    [TranslationKeys.noTimesAvailable]: "No available appointments",
    [TranslationKeys.searchExpert]: "Search for an expert",
    [TranslationKeys.searchService]: "Search for a service",
    [TranslationKeys.timeslotConfirmationFailureHeader]: "Appointment booking failed",
    [TranslationKeys.timeslotConfirmationFailureMiddle]: "Unfortunately, the appointment you selected is no longer available",
    [TranslationKeys.timeslotConfirmationFailureText]: 'Varaa uusi aika samoilla kriteereillä valitsemalla "Valitse uusi aika"',
    [TranslationKeys.closeAndSelectNewTimeslot]: "Select a new appointment",
    [TranslationKeys.ssnError]: "Invalid personal identity code",
    [TranslationKeys.ssnTooltip]: `The personal identification number is used to identify the customer's information. We treat the information confidentially.

If you do not have a Finnish social security number, you can book an appointment by phone. The appointment phone number can be found in the lower-left corner when you close the Confirm Reservation window. The clinic or city must be selected.`,
    [TranslationKeys.emailError]: "Invalid e-mail address",
    [TranslationKeys.phoneError]: "The phone number may only include numbers, spaces, hyphens and plus signs",
    [TranslationKeys.continueToNextPhase]: "Continue to next phase",
    [TranslationKeys.errorNotFoundHeader]: "404",
    [TranslationKeys.errorNotFoundText]: "The page you are looking for could not be found",
    [TranslationKeys.errorMaintenanceHeader]: "Service under maintenance",
    [TranslationKeys.errorAlternateHeader]: " - service is unavailable!",
    [TranslationKeys.errorMaintenanceText]: "Appointments can be booked by calling us during the maintenance",
    [TranslationKeys.errorSomethingWentWrongHeader]: "Sorry",
    [TranslationKeys.errorSomethingWentWrongFirstParagraph]: "We are experiencing some technical difficulties. Our services will be available soon.",
    [TranslationKeys.errorSomethingWentWrongSecondParagraph]: "Please",
    [TranslationKeys.errorContactLinkText]: "contact our customer service",
    [TranslationKeys.errorTryAgain]: "to make a reservation or try again shortly.",
    [TranslationKeys.loading]: "Loading page",
    [TranslationKeys.addressSpecifier]: "Address specifier",
    [TranslationKeys.selectStreet]: "Select street",
    [TranslationKeys.searchForStreetBy]: "Search for a street by entering its name",
    [TranslationKeys.selectPostalRegion]: "Select post office",
    [TranslationKeys.selectSpecificAddress]: "Select exact address",
    [TranslationKeys.ariaLabelSelectCustomerTypeRadio]: "Select a payment method",
    [TranslationKeys.thisDay]: "Today",
    [TranslationKeys.chosenDate]: "Selected day",
    [TranslationKeys.noFreeTimes]: "No available appointments",
    [TranslationKeys.timesFree]: "Available appointments",
    [TranslationKeys.showExtraFilters]: "View additional search criteria",
    [TranslationKeys.hideExtraFilters]: "Hide additional search criteria",
    [TranslationKeys.searchSpeciality]: "Search for a specialty",
    [TranslationKeys.speciality]: "Specialties of the expert",
    [TranslationKeys.expertLanguage]: "Languages spoken by the expert",
    [TranslationKeys.chooose]: "Select",
    [TranslationKeys.expertCustomers]: "Customers treated by the expert",
    [TranslationKeys.customers]: "All, children, adults",
    [TranslationKeys.reservingTimeslot]: "Booking appointment...",
    [TranslationKeys.stairAndOrUnitInformation]: "Staircase and/or apartment",
    [TranslationKeys.cancelReservationHeader]: "Cancelling an appointment",
    [TranslationKeys.pinCode]: "PIN code",
    [TranslationKeys.cancelReservationButton]: "Cancel appointment",
    [TranslationKeys.sendingCancellationRequest]: "Cancelling appointment",
    [TranslationKeys.cancellationError]: "Appointment cancellation failed",
    [TranslationKeys.cancellationErrorDefault]: "The appointment could not be cancelled right now.",
    [TranslationKeys.cancellationSuccess]: "Appointment cancellation succeeded",
    [TranslationKeys.services]: "Services",
    [TranslationKeys.others]: "Others",
    [TranslationKeys.premiseOrMunicipality]: "City or location",
    [TranslationKeys.searchForPremiseOrMunicipality]: "Search for a city or location",
    [TranslationKeys.invalidUUIDError]: "UUID validation failed",
    [TranslationKeys.reservationTooCloseError]: "Appointments made online must be cancelled 24 hours before the booked time. If you want to make changes to the appointment, please contact the customer service number indicated in the appointment confirmation email.",
    [TranslationKeys.showMore]: "More",
    [TranslationKeys.distance]: "Distance",
    [TranslationKeys.fromLocation]: "from your location",
    [TranslationKeys.toAddress]: "to address",
    [TranslationKeys.oclock]: "AT",
    [TranslationKeys.expertProfile]: "Expert profile",
    [TranslationKeys.paymentMethod]: "Select account",
    [TranslationKeys.selectDay]: "Select date",
    [TranslationKeys.day]: "Day",
    [TranslationKeys.all]: "All",
    [TranslationKeys.reserveTimeByPhone]: "Book an appointment with a phone",
    [TranslationKeys.openTimes]: "Mon-Fri 8 am-5 pm",
    [TranslationKeys.callPrice]: "Call price: ",
    [TranslationKeys.informationWillOnlyBeAvailableTo]: "This information will only be available to the experts involved in your treatment",
    [TranslationKeys.agreeToDataPrivacyHeader]: "Accept the privacy policy statement",
    [TranslationKeys.verifySelection]: "Confirm appointment",
    [TranslationKeys.arrival]: "Arrival",
    [TranslationKeys.showMoreArrivalTips]: "See more arrival instructions",
    [TranslationKeys.showLessArrivalTips]: "Show less arrival instructions",
    [TranslationKeys.summaryOfReservation]: "Summary of the appointment",
    [TranslationKeys.arrivalWithOwnCar]: "Arriving by car",
    [TranslationKeys.accessibility]: "Accessibility",
    [TranslationKeys.parking]: "Parking",
    [TranslationKeys.cancelReservationCancelWithPinHeader]: "Cancel the appointment by providing the PIN in the confirmation message",
    [TranslationKeys.cancelReservationCancellingReservedTimeHeader]: "Cancelling a booked appointment",
    [TranslationKeys.cancelReservationDisclaimerParagraph1]: "Only appointments booked online can be cancelled in the online appointment booking service. Cancellations must be made at least 24 hours before the appointment.",
    [TranslationKeys.cancelReservationDisclaimerParagraph2]: "The service provider has the right to charge a fee for cancellations made less than 24 hours before the appointment and uncancelled appointments according to their current price list.",
    [TranslationKeys.cancelReservationPinDisclaimer]: "The PIN code is included in the e-mail message sent to you",
    [TranslationKeys.cancelSuccessButton]: "Book a new appointment",
    [TranslationKeys.cancelGenericFailButton]: "Retry cancellation",
    [TranslationKeys.cancellationGenericError]: "If you are unable to cancel the appointment or want to make changes to your booking, please contact the customer service number indicated in the appointment confirmation email. A fee is charged for uncancelled appointments according to the price list.",
    [TranslationKeys.showPreviousMonth]: "Previous month",
    [TranslationKeys.showNextMonth]: "Next month",
    [TranslationKeys.showNextDay]: "Available appointments next day",
    [TranslationKeys.showPreviousDay]: "Available appointments previous day",
    [TranslationKeys.ariaLabelFilterMenu]: "Filter appointments",
    [TranslationKeys.loadingPremises]: "Loading locations...",
    [TranslationKeys.searchingLocationHelper]: "Enter your location here or mark it on the map.",
    [TranslationKeys.premisesNearYou]: "Locations near you",
    [TranslationKeys.cancelLocationSearch]: "Cancel location search",
    [TranslationKeys.orderByDistance]: "Sort by location",
    [TranslationKeys.orderByTime]: "Sort by appointments",
    [TranslationKeys.streetAddress]: "Enter your street address",
    [TranslationKeys.postalError]: "The zip code must contain exactly five numbers",
    [TranslationKeys.readOurPrivacyPolicyHere]: "Read more about our Privacy Policy",
    [TranslationKeys.sendSummaryOfReservation]: "We will send you a summary of the appointment to the email address you provided. The summary includes a link for cancelling the appointment and instructions on how to do so.",
    [TranslationKeys.summaryOfForm]: "Appointment summary",
    [TranslationKeys.iWantAReminderWithTextMessage]: "I would like to be reminded with a text message",
    [TranslationKeys.listOfPrices]: "Price list",
    [TranslationKeys.listOfPricesDisclaimer]: "Current prices of services can be found on our website",
    [TranslationKeys.listOfPricesLink]: "from the price list",
    [TranslationKeys.popular]: "Popular",
    [TranslationKeys.serviceVoucherNumber]: "Enter service voucher number",
    [TranslationKeys.discountCodeNumber]: "Enter promotional Code",
    [TranslationKeys.discountCodeNumberInfo]: "",
    [TranslationKeys.loadingExperts]: "Loading experts...",
    [TranslationKeys.paymentVoucher]: "Social assistance voucher",
    [TranslationKeys.reservationProblem]: "We have encountered an error in the reservation",
    [TranslationKeys.reservationConfirmationTimeout]: "Apologies - we are unable to verify your reservation",
    [TranslationKeys.reservationConfirmationTimeoutText]: "The details of your reservation have been sent, but it takes longer than usual to receive verification",
    [TranslationKeys.reservationConfirmationTimeoutPointOne]: "The reservation may still have succeeded and in that case you have received a confirmation to your email",
    [TranslationKeys.reservationConfirmationTimeoutPointTwo]: "If you haven't received a confirmation, please make a new reservation",
    [TranslationKeys.reservationConfirmationTimeoutEnd]: "We're sorry about the inconvenience",
    [TranslationKeys.infoAndPrice]: "Site info and prices",
    [TranslationKeys.previousDay]: "Previous day",
    [TranslationKeys.nextDay]: "Next day",
    [TranslationKeys.chooseCustomerType]: "",
    [TranslationKeys.ifPayingYourself]: "",
    [TranslationKeys.chooseAsCustomerType]: "",
    [TranslationKeys.weHaveKelasPayment]: "",
    [TranslationKeys.serviceVoucherCustomers]: "",
    [TranslationKeys.purchaseServiceCustomer]: "",
    [TranslationKeys.cityOfHelsinki]: "",
    [TranslationKeys.cityOfKerava]: "",
    [TranslationKeys.cityOfTampere]: "",
    [TranslationKeys.tampereInfo]: "",
    [TranslationKeys.byPhone]: "",
    [TranslationKeys.karviainens]: "",
    [TranslationKeys.cityOfEspoo]: "",
    [TranslationKeys.ythsCustomers]: "",
    [TranslationKeys.infoReminder]: "",
    [TranslationKeys.ythsInfo]: "",
    [TranslationKeys.howToChooseCustomerType]: "",
    [TranslationKeys.ifUsingServiceVoucher]: "",
    [TranslationKeys.serviceVoucherUsedFor]: "",
    [TranslationKeys.helsinkiAndUusimaa]: "",
    [TranslationKeys.hus]: "",
    [TranslationKeys.tampereArea]: "",
    [TranslationKeys.paidByInsurance]: "",
    [TranslationKeys.emptyTimeselectItem]: "There are no online times for an expert right now. You can book an appointment by phone.",
    [TranslationKeys.endoscopyCustomerTypeTooltip]: "",
    [TranslationKeys.remoteReception]: "",
    [TranslationKeys.koho]: "",
    [TranslationKeys.kohoClarification]: "",
    [TranslationKeys.reservationOnly]: "",
};

export default translation;
