import { Translation, TranslationKeys } from "../types";

const translation: Translation = {
    [TranslationKeys.selectLanguage]: "Valitse kieli",
    [TranslationKeys.selectVisitSubject]: "Valitse asioinnin aihe",
    [TranslationKeys.selectCustomerType]: "Valitse asiakastyyppi",
    [TranslationKeys.selectService]: "Etsi palvelu",
    [TranslationKeys.selectSubService]: "Valitse alipalvelu",
    [TranslationKeys.selectDistrict]: "Valitse paikkakunta",
    [TranslationKeys.selectPremise]: "Valitse toimipiste",
    [TranslationKeys.selectExpert]: "Asiantuntija",
    [TranslationKeys.summary]: "Yhteenveto",
    [TranslationKeys.selectTime]: "Valitse aika",
    [TranslationKeys.service]: "Palvelu",
    [TranslationKeys.time]: "Aika",
    [TranslationKeys.duration]: "Kesto",
    [TranslationKeys.city]: "Kaupunki",
    [TranslationKeys.address]: "Osoite",
    [TranslationKeys.confirm]: "Vahvista",
    [TranslationKeys.selfPaying]: "Itsemaksava",
    [TranslationKeys.serviceVoucher]: "Palveluseteli",
    [TranslationKeys.purchaseService]: "Ostopalvelu",
    [TranslationKeys.monday]: "Maanantai",
    [TranslationKeys.tuesday]: "Tiistai",
    [TranslationKeys.wednesday]: "Keskiviikko",
    [TranslationKeys.thursday]: "Torstai",
    [TranslationKeys.friday]: "Perjantai",
    [TranslationKeys.saturday]: "Lauantai",
    [TranslationKeys.sunday]: "Sunnuntai",
    [TranslationKeys.stepperReserveTimeslot]: "VARATTAVA AIKA",
    [TranslationKeys.stepperInformationForm]: "OMAT TIEDOT",
    [TranslationKeys.stepperConfirm]: "AJAN VAHVISTUS",
    [TranslationKeys.clock]: "Kello",
    [TranslationKeys.price]: "Hinta",
    [TranslationKeys.loginToMyCoronaria]: "Kirjaudu minun Coronariaan",
    [TranslationKeys.personalIdentificationNumber]: "Kirjoita henkilötunnuksesi",
    [TranslationKeys.firstName]: "Kirjoita etunimesi",
    [TranslationKeys.lastName]: "Kirjoita sukunimesi",
    [TranslationKeys.postalCode]: "Kirjoita postinumerosi",
    [TranslationKeys.district]: "Kirjoita postitoimipaikkasi",
    [TranslationKeys.phoneNumber]: "Kirjoita puhelinnumerosi",
    [TranslationKeys.email]: "Kirjoita sähköpostiosoitteesi",
    [TranslationKeys.iDontHaveEmail]: "Minulla ei ole sähköpostia",
    [TranslationKeys.moreInformation]: "Lisätiedot",
    [TranslationKeys.buttonGoBack]: "Palaa takaisin",
    [TranslationKeys.buttonNext]: "Seuraava",
    [TranslationKeys.buttonAbort]: "Sulje",
    [TranslationKeys.buttonConfirm]: "Vahvista varaus",
    [TranslationKeys.howToRemind]: "Miten haluat muistutuksen:",
    [TranslationKeys.withEmail]: "Sähköpostilla",
    [TranslationKeys.withTextMessage]: "Tekstiviestillä",
    [TranslationKeys.chooseReminderTime]: "Valitse muistutusaika",
    [TranslationKeys.oneDayBefore]: "Edellisenä päivänä",
    [TranslationKeys.twoHoursBefore]: "2h ennen vastaanottoa",
    [TranslationKeys.dataProtectionAndMarketing]: "Markkinointilupa",
    [TranslationKeys.checkboxDataProtectionAgree]: "Ymmärrän, että tietoni tallennetaan asiakasrekisteriin ja niitä käsitellään tietosuojalainsäädännön mukaisesti.",
    [TranslationKeys.checkboxMarketingPermission]: "Minulle saa lähettää tietoa Coronarian asiakaseduista, tarjouksista ja ajankohtaisista palveluista.",
    [TranslationKeys.appointmentSuccessfull]: "Ajanvaraus onnistui",
    [TranslationKeys.buttonSaveToCalendar]: "Tallenna kalenteriin",
    [TranslationKeys.buttonPrint]: "Tulosta",
    [TranslationKeys.buttonScheduleNewAppointment]: "Varaa uusi aika",
    [TranslationKeys.buttonClose]: "Sulje",
    [TranslationKeys.confirmationFormLongFooterHeader]: "Varatun ajan peruuttaminen",
    [TranslationKeys.confirmationFormLongFooterText1]: "Verkkoajanvarauksen kautta voi peruuttaa vain verkkoajanvarauksesta varatut ajat. Varatusta ajasta lähetetään vahvistusviesti sähköpostitse, ja ajan voi perua sähköpostiviestissä olevan linkin välityksellä. Peruutukset vastaanotolle tulee tehdä vähintään 24 tuntia ennen vastaanottoajan alkua.",
    [TranslationKeys.confirmationFormLongFooterText2]: "Myöhemmin tehdystä peruutuksesta tai peruuttamattomasta vastaanottoajasta palveluntarjoaja voi periä maksun oman voimassaolevan hinnastonsa mukaisesti.",
    [TranslationKeys.confirmationFormShortFooterText]: "Peruuttamattomasta ajasta lähetämme sinulle laskun. Peruutukset vastaanotolle tulee tehdä vähintään 24 tuntia ennen vastaanottoajan alkua.",
    [TranslationKeys.confirmationFormShortFooterText2]: "Linkki ajan peruutukseen",
    [TranslationKeys.specialty]: "Erityisosaaminen",
    [TranslationKeys.education]: "Koulutus",
    [TranslationKeys.languageSkills]: "Kielitaito",
    [TranslationKeys.expertsPremises]: "Asiantuntijan toimipaikat",
    [TranslationKeys.priceList]: "Hinnasto",
    [TranslationKeys.reserveTimeslot]: "Varaa aika",
    [TranslationKeys.closeOrMoveBack]: "Sulje tai siirry takaisin",
    [TranslationKeys.reservationService]: "Coronaria Ajanvaraus",
    [TranslationKeys.location]: "Sijaintisi",
    [TranslationKeys.locate]: "Palvelut lähellä sinua",
    [TranslationKeys.gettingLocation]: "Haetaan sijaintia...",
    [TranslationKeys.preferCalling]: "Haluatko mieluummin soittaa",
    [TranslationKeys.youCanCallUs]: "Jos et löydä sopivaa aikaa tai asiantuntijaa, voit varata meiltä ajan aina myös soittamalla.",
    [TranslationKeys.morning]: "Aamu",
    [TranslationKeys.dayTime]: "Päivä",
    [TranslationKeys.afternoon]: "Iltapäivä",
    [TranslationKeys.evening]: "Ilta",
    [TranslationKeys.nextFreeTimeslots]: "Seuraavat vapaat ajat",
    [TranslationKeys.noTimesAvailable]: "Ei vapaita aikoja",
    [TranslationKeys.searchExpert]: "Etsi asiantuntijaa",
    [TranslationKeys.searchService]: "Etsi palvelua",
    [TranslationKeys.timeslotConfirmationFailureHeader]: "Ajanvaraus epäonnistui",
    [TranslationKeys.timeslotConfirmationFailureMiddle]: "Valitettavasti valitsemasi aika ei ole enää saatavilla",
    [TranslationKeys.timeslotConfirmationFailureText]: 'Varaa uusi aika samoilla kriteereillä valitsemalla "Valitse uusi aika"',
    [TranslationKeys.closeAndSelectNewTimeslot]: "Valitse uusi aika",
    [TranslationKeys.ssnError]: "Virheellinen henkilötunnus",
    [TranslationKeys.ssnTooltip]: `Käytämme henkilötunnusta asiakkaidemme tietojen yksilöimiseen. Henkilötunnusta käsitellään luottamuksellisesti.

Jos sinulla ei ole suomalaista henkilötunnusta, voit varata ajan puhelimitse. Ajanvarauksen puhelinnumero löytyy vasemmasta alakulmasta, kun suljet vahvista varaus -ikkunan. Toimipiste tai paikkakunta tulee olla valittuna.`,
    [TranslationKeys.emailError]: "Virheellinen sähköpostiosoite",
    [TranslationKeys.phoneError]: "Puhelinnumero saa sisältää numeroita, välilyöntejä, väliviivoja ja plusmerkkejä",
    [TranslationKeys.continueToNextPhase]: "Jatka seuraavaan vaiheeseen",
    [TranslationKeys.errorNotFoundHeader]: "404",
    [TranslationKeys.errorNotFoundText]: "Hakemaasi sivua ei löytynyt",
    [TranslationKeys.errorMaintenanceHeader]: "Huollamme palveluamme",
    [TranslationKeys.errorMaintenanceText]: "Huollon aikana voit varata ajan vastaanotollemme soittamalla",
    [TranslationKeys.errorSomethingWentWrongHeader]: "Hupsis!",
    [TranslationKeys.errorAlternateHeader]: "Palvelu ei ole saatavilla",
    [TranslationKeys.errorSomethingWentWrongFirstParagraph]: "Meillä on juuri nyt teknisiä haasteita ja ajanvarauspalvelu ei ole käytettävissä. Korjaamme vian mahdollisimman pian",
    [TranslationKeys.errorSomethingWentWrongSecondParagraph]: "Voit",
    [TranslationKeys.errorContactLinkText]: "varata ajan puhelimitse",
    [TranslationKeys.errorTryAgain]: "tai kokeilla palvelua hetken päästä uudelleen",
    [TranslationKeys.loading]: "Sivua ladataan",
    [TranslationKeys.addressSpecifier]: "Osoitteen tarkenne",
    [TranslationKeys.selectStreet]: "Valitse katu",
    [TranslationKeys.searchForStreetBy]: "Etsi katua kirjoittamalla kadun nimi",
    [TranslationKeys.selectPostalRegion]: "Valitse postitoimipaikka",
    [TranslationKeys.selectSpecificAddress]: "Valitse tarkka osoite",
    [TranslationKeys.ariaLabelSelectCustomerTypeRadio]: "Valitse maksutapa",
    [TranslationKeys.thisDay]: "Tämä päivä",
    [TranslationKeys.chosenDate]: "Valittu päivä",
    [TranslationKeys.noFreeTimes]: "Ei vapaita aikoja",
    [TranslationKeys.timesFree]: "Vapaita aikoja",
    [TranslationKeys.showExtraFilters]: "Näytä lisähakuehdot",
    [TranslationKeys.hideExtraFilters]: "Piilota lisähakuehdot",
    [TranslationKeys.searchSpeciality]: "Etsi erityisosaamista",
    [TranslationKeys.speciality]: "Asiantuntijan erityisosaaminen",
    [TranslationKeys.expertLanguage]: "Asiantuntijan kieli",
    [TranslationKeys.chooose]: "Valitse",
    [TranslationKeys.expertCustomers]: "Asiantuntijan hoitamat asiakkaat",
    [TranslationKeys.customers]: "Kaikki, lapset, aikuiset",
    [TranslationKeys.reservingTimeslot]: "Aikaa varataan, odota hetki...",
    [TranslationKeys.stairAndOrUnitInformation]: "Rappu ja/tai asunto",
    [TranslationKeys.cancelReservationHeader]: "Ajanvarauksen peruutus",
    [TranslationKeys.pinCode]: "PIN-koodi",
    [TranslationKeys.cancelReservationButton]: "Peruuta aika",
    [TranslationKeys.sendingCancellationRequest]: "Aikaa perutaan",
    [TranslationKeys.cancellationError]: "Ajan peruutus ei onnistunut",
    [TranslationKeys.cancellationErrorDefault]: "Ajan peruutus ei juuri nyt onnistunut.",
    [TranslationKeys.cancellationSuccess]: "Ajan peruutus onnistui",
    [TranslationKeys.services]: "Palvelut",
    [TranslationKeys.others]: "Muut",
    [TranslationKeys.premiseOrMunicipality]: "Paikkakunta tai toimipaikka",
    [TranslationKeys.searchForPremiseOrMunicipality]: "Etsi paikkakunta tai toimipaikka",
    [TranslationKeys.invalidUUIDError]: "UUID validointi epäonnistui",
    [TranslationKeys.reservationTooCloseError]: "Verkossa tehty ajanvaraus tulee peruuttaa 24 tuntia ennen sovittua vastaanottoaikaa. Tehdäksesi muutoksia tekemääsi varaukseen ota yhteyttä ajanvarauksen vahvistussähköpostiviestissä ilmoitettuun asiakaspalvelunumeroon",
    [TranslationKeys.showMore]: "Näytä lisää",
    [TranslationKeys.distance]: "Etäisyys",
    [TranslationKeys.fromLocation]: "sijainnistasi",
    [TranslationKeys.toAddress]: "osoitteeseen",
    [TranslationKeys.oclock]: "KLO",
    [TranslationKeys.expertProfile]: "Asiantuntijan profiili",
    [TranslationKeys.paymentMethod]: "Valitse asiakkuus",
    [TranslationKeys.selectDay]: "Valitse päivä",
    [TranslationKeys.day]: "Päivä",
    [TranslationKeys.all]: "Kaikki",
    [TranslationKeys.reserveTimeByPhone]: "Varaa aika puhelimella",
    [TranslationKeys.openTimes]: "ma-pe klo 8-17",
    [TranslationKeys.callPrice]: "Puhelun hinta: ",
    [TranslationKeys.informationWillOnlyBeAvailableTo]: "Kirjoita tähän halutessasi käynnin syy, jotta vastaanottava ammattilainen voi varautua käyntiisi mahdollisimman hyvin.\nNämä tiedot välitetään ainoastaan sinua hoitaville asiantuntijoille",
    [TranslationKeys.agreeToDataPrivacyHeader]: "Tietosuojan hyväksyminen",
    [TranslationKeys.verifySelection]: "Vahvista aika",
    [TranslationKeys.arrival]: "Saapuminen",
    [TranslationKeys.showMoreArrivalTips]: "Näytä lisää saapumisohjeita",
    [TranslationKeys.showLessArrivalTips]: "Näytä vähemmän saapumisohjeita",
    [TranslationKeys.summaryOfReservation]: "Yhteenveto tehdystä ajanvarauksesta",
    [TranslationKeys.arrivalWithOwnCar]: "Saapuminen omalla autolla",
    [TranslationKeys.accessibility]: "Esteettömyys",
    [TranslationKeys.parking]: "Pysäköinti",
    [TranslationKeys.cancelReservationCancelWithPinHeader]: "Peruuta aika vahvistusviestissä olevalla PIN-koodilla",
    [TranslationKeys.cancelReservationCancellingReservedTimeHeader]: "Varatun ajan peruuttaminen",
    [TranslationKeys.cancelReservationDisclaimerParagraph1]: "Verkkoajanvarauksen kautta voi peruuttaa vain verkkoajanvarauksessa varatut ajat. Peruutukset vastaanotolle vähintään 24 tuntia ennen vastaanottoajan alkua.",
    [TranslationKeys.cancelReservationDisclaimerParagraph2]: "Myöhemmin tehdystä peruutuksesta ja peruuttamattomasta vastaanottoajasta palveluntarjoaja voi periä maksun oman voimassa olevan hinnastonsa mukaisesti.",
    [TranslationKeys.cancelReservationPinDisclaimer]: "PIN-koodi löytyy teille lähetetystä sähköpostiviestistä",
    [TranslationKeys.cancelSuccessButton]: "Varaa uusi aika",
    [TranslationKeys.cancelGenericFailButton]: "Yritä peruuttaa uudelleen",
    [TranslationKeys.cancellationGenericError]: "Jos peruuttaminen ei edelleenkään onnistu, tai haluat tehdä muita muutoksia tekemääsi varaukseen, ota yhteyttä ajanvarauksen vahvistussähköpostissa ilmoitettuun asiakaspalvelunumeroon. Peruuttamattomasta ajasta peritään hinnaston mukainen maksu",
    [TranslationKeys.showPreviousMonth]: "Näytä edellinen kuukausi",
    [TranslationKeys.showNextMonth]: "Näytä seuraava kuukausi",
    [TranslationKeys.showNextDay]: "Näytä seuraavan päivän vastaanottoajat",
    [TranslationKeys.showPreviousDay]: "Näytä edellisen päivän vastaanottoajat",
    [TranslationKeys.ariaLabelFilterMenu]: "Aikojen suodatus",
    [TranslationKeys.loadingPremises]: "Ladataan toimipaikkoja...",
    [TranslationKeys.loadingExperts]: "Ladataan asiantuntijoita...",
    [TranslationKeys.searchingLocationHelper]: "Kirjoita sijaintisi tai raahaa merkki kartalla oikeaan paikkaan.",
    [TranslationKeys.writeYourLocation]: "Kirjoita sijaintisi",
    [TranslationKeys.premisesNearYou]: "Toimipaikat lähellä sinua",
    [TranslationKeys.cancelLocationSearch]: "Peru sijaintihaku",
    [TranslationKeys.orderByDistance]: "Järjestä sijainnin mukaan",
    [TranslationKeys.orderByTime]: "Järjestä ajan mukaan",
    [TranslationKeys.streetAddress]: "Kirjoita katuosoitteesi",
    [TranslationKeys.postalError]: "Postinumeron tulee sisältää täsmälleen viisi numeroa",
    [TranslationKeys.readOurPrivacyPolicyHere]: "Lue lisää tietosuojakäytännöstämme",
    [TranslationKeys.sendSummaryOfReservation]: "Lähetämme yhteenvedon ajanvarauksesta antamaasi sähköpostiosoitteeseen. Yhteenveto sisältää linkin ja ohjeet ajan peruuttamiseen.",
    [TranslationKeys.summaryOfForm]: "Yhteenveto ajanvarauksesta",
    [TranslationKeys.iWantAReminderWithTextMessage]: "Haluan muistutuksen ajasta tekstiviestillä",
    [TranslationKeys.listOfPrices]: "Hinnasto",
    [TranslationKeys.listOfPricesDisclaimer]: "Ajantasaiset palveluhinnat löydät verkkosivuiltamme",
    [TranslationKeys.listOfPricesLink]: "hinnastosta",
    [TranslationKeys.popular]: "Suosittu",
    [TranslationKeys.serviceVoucherNumber]: "Kirjoita palvelusetelin numero",
    [TranslationKeys.paymentVoucher]: "Maksusitoumus",
    [TranslationKeys.discountCodeNumber]: "Kirjoita tarjouskoodi",
    [TranslationKeys.discountCodeNumberInfo]: "Tarjoushinta huomioidaan laskutusvaiheessa käynnin yhteydessä",
    [TranslationKeys.reservationProblem]: "Ajanvarauksen vahvistamisessa havaittiin ongelma",
    [TranslationKeys.reservationConfirmationTimeout]: "Pahoittelut - emme saa vahvistettua tekemääsi ajanvarausta",
    [TranslationKeys.reservationConfirmationTimeoutText]: "Ajanvarauksesi tiedot on lähetetty eteenpäin, mutta ajanvarausvahvistuksen saamisessa kestää tavallista pidempään",
    [TranslationKeys.reservationConfirmationTimeoutPointOne]: "Ajanvaraus on kuitenkin voinut onnistua ja olet tällöin saanut siitä sähköpostiisi varausvahvistuksen",
    [TranslationKeys.reservationConfirmationTimeoutPointTwo]: "Jos et ole saanut vahvistusviestiä sähköpostiisi, pyydämme varaamaan ajan uudelleen",
    [TranslationKeys.reservationConfirmationTimeoutEnd]: "Pahoittelemme ylimääräistä vaivaa",
    [TranslationKeys.infoAndPrice]: "Toimipaikan tiedot ja hinnasto",
    [TranslationKeys.previousDay]: "Edellinen päivä",
    [TranslationKeys.nextDay]: "Seuraava päivä",
    [TranslationKeys.chooseCustomerType]: "Valitse oikea asiakkuus",
    [TranslationKeys.ifPayingYourself]: "Jos maksat laskun itse ",
    [TranslationKeys.chooseAsCustomerType]: "valitse asiakkuudeksi ",
    [TranslationKeys.weHaveKelasPayment]: "Meillä on käytössä Kelan suorakorvaus.",
    [TranslationKeys.serviceVoucherCustomers]: "palveluseteliasiakkaat",
    [TranslationKeys.purchaseServiceCustomer]: "ostopalveluasiakkaat",
    [TranslationKeys.cityOfHelsinki]: "Helsingin kaupungin ",
    [TranslationKeys.cityOfKerava]: "Keravan kaupungin ",
    [TranslationKeys.karviainens]: "Karviaisen ",
    [TranslationKeys.cityOfEspoo]: "Espoon kaupungin ",
    [TranslationKeys.cityOfTampere]: "Tampereen kaupungin ",
    [TranslationKeys.ythsCustomers]: "YTHS asiakkaat ",
    [TranslationKeys.infoReminder]: "Asiakkuuden jälkeen muista valita vielä oikea palvelu.",
    [TranslationKeys.ythsInfo]: "Ajanvaraukseen tarvitset henkilökohtaisen koodin YTHS:ltä.",
    [TranslationKeys.byPhone]: "puhelimitse!",
    [TranslationKeys.tampereInfo]: "ostopalveluasiakkaana ajanvaraus vain ",
    [TranslationKeys.ifUsingServiceVoucher]: "Jos käytössäsi on palveluseteli, valitse ",
    [TranslationKeys.serviceVoucherUsedFor]: "Palvelusetelillä palvelemme seuraavia asiakkaita:",
    [TranslationKeys.howToChooseCustomerType]: "Mikä on minulle oikea asiakkuus?",
    [TranslationKeys.helsinkiAndUusimaa]: "Helsingin ja uudenmaan sairaanhoitopiiri",
    [TranslationKeys.hus]: "HUS",
    [TranslationKeys.tampereArea]: "Tampereen kehyskunnat",
    [TranslationKeys.paidByInsurance]: "Jos laskun maksaa vakuutusyhtiö tai yritys, valitse asiakkuudeksi itsemaksava ja muista tuoda maksusitoumus mukanasi.",
    [TranslationKeys.emptyTimeselectItem]: "Tälle asiantuntijalle ei ole juuri nyt aikoja verkossa. Voit varata ajan puhelimitse.",
    [TranslationKeys.endoscopyCustomerTypeTooltip]: `Aikaa varatessanne valitkaa ensin vaihtoehdoista "itsemaksava", ”palveluseteli” tai ”maksusitoumus”. 
    Tämän jälkeen pääsette valitsemaan toimipisteen, palvelun sekä halutessanne asiantuntijan. 
    Huomioikaa palvelua varatessanne, että valitsette oikean teille määrätyn tutkimuksen (gastroskopia = mahalaukuntähystys tai kolonoskopia = paksusuolentähystys).
    `,
    [TranslationKeys.remoteReception]: "etävastaanotto",
    [TranslationKeys.koho]: "KOHO",
    [TranslationKeys.kohoClarification]: "Helsingin kaupungin kokonaishoidon palveluseteli",
    [TranslationKeys.reservationOnly]: "ajanvaraus vain",
};

export default translation;
