import { makeStyles } from "@material-ui/core";
import React from "react";
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';

const useStyles = makeStyles(theme => ({
    errorIcon: {
        color: theme.palette.error.main
    },
    successIcon: {
        color: theme.palette.success.main
    },
}));

interface IFieldStatusIndicatorProps {
    value: string,
    isValid: (str: string) => boolean
}

export const FieldStatusIcon: React.FC<IFieldStatusIndicatorProps> = ({
    value, isValid
}) => {
    const classes = useStyles();
    return value === "" ?
        null :
        isValid(value) ?
            <CheckCircleIcon className={classes.successIcon} /> :
            <ErrorIcon className={classes.errorIcon} />;
};
