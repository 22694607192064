import React, { useEffect, useContext, useState, useMemo, Dispatch, SetStateAction, useLayoutEffect } from 'react';
import MultiFilter, { StyledChip } from "../MultiFilter";
import { Expert, EntityResponse, SelectedFilters, Experts, CampaignService } from "../../types";
import { ServiceContext } from "../ServiceContext";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { AutocompleteGetTagProps } from "@material-ui/lab";
import { BehaviorSubject } from "rxjs";
import { filter, debounceTime, distinctUntilChanged, concatMap } from "rxjs/operators";
import { expertsTofilterOut, uiSearchTimeoutMs } from "../../settings";
import LoadingIndicator from '../UtilComponents/LoadingIndicator';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { allSettled } from '../utils';
import StyledTooltip from '../CustomStyleComponents/Tooltip';

interface SearchExpertProps {
    selectedExperts: EntityResponse<Expert>[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    expertFilters: string[],
    loadingInitialState: boolean,
    services: CampaignService[]
}

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        loading: {
            width: "1.5em",
            height: "1.5em",
        }
    })
);

const searchSubject = new BehaviorSubject<string>("");

const SearchExpert: React.FC<SearchExpertProps> = props => {
    const {
        expertFilters,
        setSelectedFilters,
        selectedExperts,
        loadingInitialState,
        services
    } = props;
    const { translation } = useContext(LocalizationContext);
    const { dataService, getSearchService } = useContext(ServiceContext);
    const [experts, setExperts] = useState<{
        uuid: string,
        Nimi: string
    }[]>([]);
    const [searchStr, setSearchStr] = useState<string>("");
    const [expertUuids, setExpertUuids] = useState<Experts["työntekijä"] | null>(null);
    const [selection, setSelection] = useState<{ uuid: string; Nimi: string }[] | null>(null);
    const [searchExperts, setSearchExperts] = useState<{ uuid: string; Nimi: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const classes = useStyles();

    useEffect(() => {
        if (expertFilters.length !== 0 && dataService && !loadingInitialState) {
            const fetchExperts = async () => {
                const resp = await dataService.requestExperts(expertFilters);
                const filtered = resp.data.työntekijä
                    .filter(expert => !expertsTofilterOut.includes(expert.uuid));
                setExpertUuids(filtered);
                setExperts(filtered
                    .map(expert => ({
                        uuid: expert.uuid,
                        Nimi: expert.Nimi
                    })));
                setLoading(false);
            };
            fetchExperts();
        }
        if (expertFilters.length === 0) {
            setExpertUuids(null);
        }
    }, [dataService, expertFilters, loadingInitialState]);

    useEffect(() => setLoading(true), [expertFilters]);

    useEffect(() => {
        const hammastarkastus = services
            .find(service => service.uuid === "e98fec45-97f8-11e9-bd29-02004c4f4f50");
        const hammaskiven_poisto = services
            .find(service => service.uuid === "41b6d7b9-2ac2-11e9-9d67-0050569b2d10");
        if (dataService && selection) {
            const fetchSelectedExperts = async () => {
                const expertsToFetch = await allSettled(
                    selection.map(({ uuid }) => dataService.requestExpert(uuid))
                );
                const resolvedExperts = expertsToFetch.reduce((acc, cur) => {
                    return cur.status === "fulfilled" ? [...acc, cur.value] : acc;
                }, [] as EntityResponse<Expert>[]);

                const isBasic = selectedExperts.length === 0
                    && resolvedExperts.length > 0;

                const isDentist = isBasic
                    && resolvedExperts.every(expert =>
                        expert.data.palvelu?.find(service =>
                            service.uuid === hammastarkastus?.uuid
                        )
                    );

                const isHygienist = isBasic
                    && resolvedExperts.every(expert =>
                        expert.data.palvelu?.find(service =>
                            service.uuid === hammaskiven_poisto?.uuid
                        )
                        && !expert.data.palvelu?.find(service =>
                            service.uuid === hammastarkastus?.uuid
                        )
                    );



                setSelectedFilters((prevState: SelectedFilters) => {
                    const selected = prevState.selectedServices.filter(service =>
                        service.uuid !== hammaskiven_poisto?.uuid
                        && service.uuid !== hammastarkastus?.uuid);

                    if (selected.length > 0) {
                        return {
                            ...prevState,
                            selectedExperts: resolvedExperts,
                            selectedServices: selected
                        };
                    }

                    return {
                        ...prevState,
                        selectedExperts: resolvedExperts,
                        selectedServices: isDentist && hammastarkastus
                            ? [hammastarkastus]
                            : isHygienist && hammaskiven_poisto
                                ? [...selected, hammaskiven_poisto]
                                : prevState.selectedServices
                    };
                });
            };
            fetchSelectedExperts();
        }
    }, [dataService, selection]);

    useLayoutEffect(() => {
        if (getSearchService) {
            const searchResultsObs = searchSubject.pipe(
                filter(str => str.length > 1),
                debounceTime(uiSearchTimeoutMs),
                distinctUntilChanged(),
                concatMap(searchString =>
                    getSearchService().then(async search => {
                        const experts = await search.searchExpert(searchString);
                        return experts;
                    })
                )
            );
            const subscription = searchResultsObs?.subscribe(results => {
                const experts = results.data.map((result: any) =>
                    ({ uuid: result.uuid, Nimi: result.name, probability: result.probability })
                );
                setSearchExperts(experts);
            }, () => true, () => getSearchService().then(search => search.socket.close()));

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [getSearchService]);

    const expertIsInFilterList = (expert: { uuid: string; Nimi: string }) =>
        expert && expertUuids?.map(expert => expert.uuid)?.includes(expert.uuid) && expert.Nimi;

    const nameMatchesSearchString = (expert: { uuid: string; Nimi: string }) =>
        expert && expert.Nimi &&
        expert.Nimi.toLowerCase().includes(searchStr.toLowerCase());

    const filteredExperts = experts
        .filter(expert =>
            expertUuids && expertUuids.length > 0 ?
                expertIsInFilterList(expert) :
                nameMatchesSearchString(expert)
        );

    const onSelectionChange = (e: any, value: { uuid: string; Nimi: string }[]) => {
        setSelection(value);
        setSearchStr("");
    };

    const renderTags = (
        value: { uuid: string; Nimi: string }[],
        getTagProps: AutocompleteGetTagProps
    ) =>
        value.map((option, index) => <StyledTooltip
            key={`chip-tooltip-${option.uuid}`}
            title={option.Nimi}
        >
            <StyledChip
                key={`chip-${option.uuid}`}
                id={`chip-${option.uuid}`}
                label={option.Nimi} {...getTagProps({ index })}
            />
        </StyledTooltip>
        );

    const filterOptions = (entities: { uuid: string; Nimi: string; probability?: number }[]) =>
        entities.filter(entity =>
            entity.Nimi.toLowerCase().includes(searchStr.toLowerCase())
            || (entity.probability && entity.probability > 0.6)
        );

    const onInputChange = (e: any) => {
        e.preventDefault();
        const newValue = e.target.value;
        searchSubject.next(newValue);
        setSearchStr(newValue);
    };

    const filteredSearchExperts = useMemo(() => searchExperts.filter(({ uuid }) =>
        expertUuids?.map(expert => expert.uuid)?.includes(uuid)
    ), [searchExperts]);

    const selectedValues = useMemo(() => selectedExperts.map(expert => ({
        uuid: expert.uuid,
        Nimi: expert.data.Nimi
    })), [selectedExperts]);

    const loadingEl = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {translation.loadingExperts}
            <LoadingIndicator className={classes.loading} />
        </div>
    );

    return (
        <MultiFilter<{ uuid: string; Nimi: string; probability?: number }>
            id={"searchExpert"}
            options={searchStr.length > 0 ? filteredSearchExperts : filteredExperts}
            label={translation.selectExpert}
            getOptionLabel={option => option.Nimi}
            renderTags={renderTags}
            placeholder={translation.searchExpert}
            onChange={onSelectionChange}
            value={selectedValues}
            filterOptions={filterOptions}
            onInputChange={onInputChange}
            loading={loading}
            loadingText={loadingEl}
            inputStr={searchStr}
        />
    );
};

export default SearchExpert;
