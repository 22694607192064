import { createStyles, Grid, Link, ListItemAvatar, makeStyles, Theme, Typography } from '@material-ui/core';
import { DoubleArrowRounded } from '@material-ui/icons';
import React from 'react';
import { Building, EntityResponse, Expert, Premise } from '../../types';
import { ExpertPicture } from '../ExpertPicture';
import { getAddress, getExpertTitles, getKunta, roundToOneDecimal } from '../utils';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import { Translation } from '../LanguageContext/types';
import LocationOnIcon from '@material-ui/icons/LocationOnRounded';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        distance: {
            color: theme.palette.primary.main,
            textTransform: "none"
        },
        grid: {
            paddingBottom: theme.spacing(1),
        },
        iconContainer: {
            display: "flex"
        },
        icon: {
            color: "#B3B3B3"
        },
        iconDist: {
            color: theme.palette.primary.main
        },
        container: {
            borderTop: "1px solid #e3e3e3",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        linkButton: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: "1em",
            background: "none!important",
            border: "none",
            padding: "0!important",
            color: theme.palette.primary.main,
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                fontSize: "0.9em",
                marginTop: theme.typography.pxToRem(3)
            }
        },
        span: {
            height: "max-content",
            backgroundColor: "#DAEDF7",
            borderRadius: "4px",
            maxWidth: "230px",
            display: "inline",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
        name: {
            paddingTop: theme.typography.pxToRem(2),
            textDecoration: "none",
            textTransform: "none"
        },
        cardMedia: {
            width: "60px",
            minHeight: "60px",
        },
        avatar: {
            padding: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        phone: {
            display: "flex",
            flexDirection: "row"
        },
        phoneIcon: {
            paddingRight: theme.spacing(1)
        }
    }), { name: "NoTime" }
);

export interface ListItemNoTimesProps {
    translation: Translation,
    openExpertModal: () => void,
    expert: EntityResponse<Expert>,
    premise?: EntityResponse<Premise>,
    building?: EntityResponse<Building>,
    distance?: number
}

const ListItemNoTimesMobile: React.FC<ListItemNoTimesProps> = props => {
    const {
        expert,
        premise,
        building,
        openExpertModal,
        distance,
        translation
    } = props;
    const classes = useStyles();
    return (
        <Grid container item xs={12} className={classes.container}>
            <Grid item xs={3}>
                <Link
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => openExpertModal()}
                >
                    <ListItemAvatar
                        className={classes.avatar}>
                        <ExpertPicture
                            uuid={expert.uuid}
                            className={classes.cardMedia}
                        />
                    </ListItemAvatar>
                </Link>
            </Grid>
            <Grid item container direction="column" xs={9}>
                <Grid item>
                    <button
                        className={classes.linkButton}
                        onClick={() => openExpertModal()}
                    >
                        <Typography
                            variant="button"
                            color="primary"
                            className={classes.name}
                        >
                            {expert.data.Nimi}
                        </Typography>
                        <DoubleArrowRounded />
                    </button>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        {getExpertTitles(expert.data)}
                    </Typography>
                </Grid>
                <Grid item>
                    {distance && <div className={classes.iconContainer}>
                        <LocationOnIcon
                            className={classes.iconDist}
                        />
                        <Typography className={classes.distance} variant="button" component="span">
                            {`${translation.distance} ${roundToOneDecimal(distance)} km ${translation.fromLocation}`}
                        </Typography>
                    </div>
                    }
                    <Typography>
                        {
                            building?.data.katuosoite ?
                                getAddress(building.data.katuosoite) :
                                ""
                        }
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        {
                            building?.data.katuosoite ?
                                getKunta(building.data.katuosoite) :
                                ""
                        }
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body2"
                    >
                        {translation.emptyTimeselectItem}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography component="div" color="primary" className={classes.phone}>
                        <PhoneRoundedIcon className={classes.phoneIcon} />
                        {premise?.data.Ajanvaraus_Puhelin || ""}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListItemNoTimesMobile;
