import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, createStyles } from "@material-ui/core"; import React from "react";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecordRounded';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: 0
        },
        listIcon: {
            minWidth: "24px",
            fontSize: "8px",
            color: theme.palette.primary.light
        },
    }));

interface IItemList {
    items: {
        uuid: string,
        name: string | undefined
    }[]
}

export const ItemList: React.FC<IItemList> = ({ items }) => {
    const classes = useStyles();

    return <List>
        {
            items.map(item =>
                !item.name ?
                    undefined :
                    <ListItem
                        className={classes.listItem}
                        key={item.uuid}

                    >
                        <ListItemIcon
                            className={classes.listIcon}

                        >
                            <FiberManualRecordIcon style={{ fontSize: "8px" }} />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
            )
        }
    </List>;
};
