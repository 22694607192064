import { makeStyles, Link } from "@material-ui/core";
import React, { useContext } from "react";
import { coronariPrivacyPolicyUrl } from "../settings";
import { LocalizationContext } from "./LanguageContext/LocalizationContext";

const useStyles = makeStyles(theme => ({
    link: {
        fontFamily: "Gotham Narrow Medium",
        size: theme.typography.pxToRem(16),
        color: theme.palette.primary.main
    }
}));

interface IStyledLink {
    text: string,
    link: string
}

export const StyledLink: React.FC<IStyledLink> = ({ text, link }) => {
    const classes = useStyles();

    return <Link
        className={classes.link}
        href={link}
        target={"_blank"}
    >
        {text}
    </Link>;
};

export const PrivacyPolicyLink: React.FC = () => {
    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    return <Link
        className={classes.link}
        href={coronariPrivacyPolicyUrl}
        target={"_blank"}
    >
        {` ${translation.readOurPrivacyPolicyHere}.`}
    </Link>;
};
