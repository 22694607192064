import { Translation, TranslationKeys } from "../types";

const translation: Translation = {
    [TranslationKeys.selectLanguage]: "Välj språk",
    [TranslationKeys.selectVisitSubject]: "Välj tjänsten",
    [TranslationKeys.selectCustomerType]: "Välj kundtyp",
    [TranslationKeys.selectService]: "Hitta tjänst",
    [TranslationKeys.selectSubService]: "Välj underordnad tjänst",
    [TranslationKeys.selectDistrict]: "Välj ort",
    [TranslationKeys.selectPremise]: "Välj servicepunkt",
    [TranslationKeys.selectExpert]: "Specialist",
    [TranslationKeys.summary]: "Sammanfattning",
    [TranslationKeys.selectTime]: "Välj tid",
    [TranslationKeys.service]: "Tjänst",
    [TranslationKeys.time]: "Tid",
    [TranslationKeys.duration]: "Varaktighet",
    [TranslationKeys.city]: "Stad",
    [TranslationKeys.address]: "Adress: ",
    [TranslationKeys.confirm]: "Bekräfta",
    [TranslationKeys.selfPaying]: "Självbetalande",
    [TranslationKeys.serviceVoucher]: "Servicesedel",
    [TranslationKeys.purchaseService]: "Inköpstjänst",
    [TranslationKeys.monday]: "Måndag",
    [TranslationKeys.tuesday]: "Tisdag",
    [TranslationKeys.wednesday]: "Onsdag",
    [TranslationKeys.thursday]: "Torsdag",
    [TranslationKeys.friday]: "Fredag",
    [TranslationKeys.saturday]: "Lördag",
    [TranslationKeys.sunday]: "Söndag",
    [TranslationKeys.stepperReserveTimeslot]: "RESERVERAD TID",
    [TranslationKeys.stepperInformationForm]: "MINA UPPGIFTER",
    [TranslationKeys.stepperConfirm]: "TIDSBEKRÄFTELSE",
    [TranslationKeys.clock]: "Klockan",
    [TranslationKeys.price]: "Pris",
    [TranslationKeys.loginToMyCoronaria]: "Logga in på Min Coronaria",
    [TranslationKeys.personalIdentificationNumber]: "Ange ditt personnummer",
    [TranslationKeys.firstName]: "Ange ditt förnamn",
    [TranslationKeys.lastName]: "Ange ditt efternamn",
    [TranslationKeys.postalCode]: "Ange ditt postnummer",
    [TranslationKeys.district]: "Ange din postort",
    [TranslationKeys.phoneNumber]: "Ange ditt telefonnummer",
    [TranslationKeys.email]: "Ange din e-postadress",
    [TranslationKeys.iDontHaveEmail]: "Jag har ingen e-post",
    [TranslationKeys.moreInformation]: "Ytterligare information",
    [TranslationKeys.buttonGoBack]: "Gå tillbaka",
    [TranslationKeys.buttonNext]: "Nästa",
    [TranslationKeys.buttonAbort]: "Stäng",
    [TranslationKeys.buttonConfirm]: "Bekräfta bokningen",
    [TranslationKeys.howToRemind]: "Hur vill du ha en påminnelse:",
    [TranslationKeys.withEmail]: "E-post",
    [TranslationKeys.withTextMessage]: "Sms",
    [TranslationKeys.chooseReminderTime]: "Välj påminnelsetid",
    [TranslationKeys.oneDayBefore]: "Dagen innan",
    [TranslationKeys.twoHoursBefore]: "2 timmar före mottagningen",
    [TranslationKeys.dataProtectionAndMarketing]: "Marknadsföringstillstånd",
    [TranslationKeys.checkboxDataProtectionAgree]: "Jag förstår att mina uppgifter lagras i kundregistret och behandlas i enlighet med dataskyddslagstiftningen.",
    [TranslationKeys.checkboxMarketingPermission]: "Jag vill ta emot information om Coronarias kundförmåner, erbjudanden och aktuella tjänster.",
    [TranslationKeys.appointmentSuccessfull]: "Tidsbokningen har slutförts",
    [TranslationKeys.buttonSaveToCalendar]: "Spara i kalender",
    [TranslationKeys.buttonPrint]: "Skriv ut",
    [TranslationKeys.buttonScheduleNewAppointment]: "Boka en ny tid",
    [TranslationKeys.buttonClose]: "Stäng",
    [TranslationKeys.confirmationFormLongFooterHeader]: "Avbokning av en bokad tid",
    [TranslationKeys.confirmationFormLongFooterText1]: "Genom onlinebokningen kan du endast avboka tider som är bokade genom webben. Ett bekräftelsemeddelande med den bokade tiden skickas via e-post och du kan avboka tiden via länken i e-postmeddelandet. Avbokningar till mottagningen måste göras minst 24 timmar före det planerade besöket.",
    [TranslationKeys.confirmationFormLongFooterText2]: "Avbokningar som är gjorda senare eller ifall det planerade besöket uteblir, har tjänsteleverantören rätt att ta ut en avgift enligt den aktuella prislistan.",
    [TranslationKeys.confirmationFormShortFooterText]: "Vi skickar en faktura med avgiften för den ej avbokade tiden. Avbokning måste göras minst 24 timmar före mottagningstiden.",
    [TranslationKeys.confirmationFormShortFooterText2]: "Länk till tidsavbokning",
    [TranslationKeys.specialty]: "Specialkompetens",
    [TranslationKeys.education]: "Utbildning",
    [TranslationKeys.languageSkills]: "Språkkunskap",
    [TranslationKeys.expertsPremises]: "Specialistens mottagningar",
    [TranslationKeys.priceList]: "Priser",
    [TranslationKeys.reserveTimeslot]: "Boka tid",
    [TranslationKeys.closeOrMoveBack]: "Stäng eller gå tillbaka",
    [TranslationKeys.reservationService]: "Coronarias Tidsbokning",
    [TranslationKeys.location]: "Din plats",
    [TranslationKeys.writeYourLocation]: "Ange din plats ",
    [TranslationKeys.locate]: "Tjänster nära dig",
    [TranslationKeys.gettingLocation]: "Hämtar platsinformation...",
    [TranslationKeys.preferCalling]: "Vill du hellre ringa",
    [TranslationKeys.youCanCallUs]: "Om du inte kan hitta en lämplig tid eller specialist, kan du alltid boka en tid hos oss genom att ringa.",
    [TranslationKeys.morning]: "Morgon",
    [TranslationKeys.dayTime]: "Dag",
    [TranslationKeys.afternoon]: "Eftermiddag",
    [TranslationKeys.evening]: "Kväll",
    [TranslationKeys.nextFreeTimeslots]: "Nästa tillgängliga tider",
    [TranslationKeys.noTimesAvailable]: "Inga tillgängliga tider",
    [TranslationKeys.searchExpert]: "Hitta en specialist",
    [TranslationKeys.searchService]: "Hitta en tjänst",
    [TranslationKeys.timeslotConfirmationFailureHeader]: "Tidsbokning misslyckades",
    [TranslationKeys.timeslotConfirmationFailureMiddle]: "Tyvärr är den tid du har valt inte längre tillgänglig",
    [TranslationKeys.timeslotConfirmationFailureText]: 'Varaa uusi aika samoilla kriteereillä valitsemalla "Valitse uusi aika"',
    [TranslationKeys.closeAndSelectNewTimeslot]: "Välj en ny tid",
    [TranslationKeys.ssnError]: "Ogiltigt personnummer",
    [TranslationKeys.ssnTooltip]: `Personligt identifieringsnummer används för att identifiera kundens information. Vi behandlar informationen konfidentiellt.

Om du inte har en finländsk personbeteckning kan du boka tid per telefon. Telefonnumret till tidsbokningen finns i det nedre vänstra hörnet när du stänger fönstret bekräfta bokning. Verksamhetsställe eller ort ska väljas.`,
    [TranslationKeys.emailError]: "Ogiltig e-postadress",
    [TranslationKeys.phoneError]: "Telefonnumret kan innehålla siffror, mellanslag, streck och plustecken",
    [TranslationKeys.continueToNextPhase]: "Fortsätt till nästa steg",
    [TranslationKeys.errorNotFoundHeader]: "404",
    [TranslationKeys.errorNotFoundText]: "Sidan du sökte gick inte att hitta",
    [TranslationKeys.errorMaintenanceHeader]: "Underhållsarbete av servicen pågår",
    [TranslationKeys.errorAlternateHeader]: " - tjänsten är inte tillgänglig!",
    [TranslationKeys.errorMaintenanceText]: "Under underhållsarbetet kan du boka tid genom att ringa till vår reception",
    [TranslationKeys.errorSomethingWentWrongHeader]: "Tyvärr",
    [TranslationKeys.errorSomethingWentWrongFirstParagraph]: "Vi har för närvarande tekniska problem.",
    [TranslationKeys.errorSomethingWentWrongSecondParagraph]: "",
    [TranslationKeys.errorContactLinkText]: "Kontakta vår kundtjänst",
    [TranslationKeys.errorTryAgain]: "för att göra en bokning eller försök igen snart.",
    [TranslationKeys.loading]: "Läser in sidan",
    [TranslationKeys.addressSpecifier]: "Kontrollera adressen",
    [TranslationKeys.selectStreet]: "Välj gata",
    [TranslationKeys.searchForStreetBy]: "Sök efter en gata genom att skriva gatunamnet",
    [TranslationKeys.selectPostalRegion]: "Välj postort",
    [TranslationKeys.selectSpecificAddress]: "Välj exakt adress",
    [TranslationKeys.ariaLabelSelectCustomerTypeRadio]: "Välj betalningsmetod",
    [TranslationKeys.thisDay]: "Idag",
    [TranslationKeys.chosenDate]: "Vald dag",
    [TranslationKeys.noFreeTimes]: "Inga tillgängliga tider",
    [TranslationKeys.timesFree]: "Tillgängliga tider",
    [TranslationKeys.showExtraFilters]: "Visa avancerade sökvillkor",
    [TranslationKeys.hideExtraFilters]: "Dölj avancerade sökvillkor",
    [TranslationKeys.searchSpeciality]: "Hitta specialområden",
    [TranslationKeys.speciality]: "Specialistens specialkompetens",
    [TranslationKeys.expertLanguage]: "Specialistens språk",
    [TranslationKeys.chooose]: "Välj",
    [TranslationKeys.expertCustomers]: "Specialistens kunder",
    [TranslationKeys.customers]: "Alla, barn, vuxna",
    [TranslationKeys.reservingTimeslot]: "Tidsbokning sker...",
    [TranslationKeys.stairAndOrUnitInformation]: "Trappa och/eller lägenhet",
    [TranslationKeys.cancelReservationHeader]: "Avbokning av tid",
    [TranslationKeys.pinCode]: "PIN-kod",
    [TranslationKeys.cancelReservationButton]: "Avboka tid",
    [TranslationKeys.sendingCancellationRequest]: "Tidsavbokningen sker",
    [TranslationKeys.cancellationError]: "Tidsavbokningen misslyckades",
    [TranslationKeys.cancellationErrorDefault]: "Avbokningen av tiden lyckades inte just nu.",
    [TranslationKeys.cancellationSuccess]: "Tidsavbokningen är klar",
    [TranslationKeys.services]: "Tjänster",
    [TranslationKeys.others]: "Annat",
    [TranslationKeys.premiseOrMunicipality]: "Ort eller verksamhetsställe",
    [TranslationKeys.searchForPremiseOrMunicipality]: "Hitta ort eller verksamhetsställe",
    [TranslationKeys.invalidUUIDError]: "UUID-godkännandet misslyckades",
    [TranslationKeys.reservationTooCloseError]: "Tidsbokning som gjorts på nätet måste avbokas 24 timmar före mottagningstiden. För att göra ändringar i din bokning var vänlig kontakta kundtjänstnumret som anges i e-postmeddelandet med bokningsbekräftelsen.",
    [TranslationKeys.showMore]: "Visa mer",
    [TranslationKeys.distance]: "Avstånd",
    [TranslationKeys.fromLocation]: "från din plats",
    [TranslationKeys.toAddress]: "till adressen",
    [TranslationKeys.oclock]: "Kl",
    [TranslationKeys.expertProfile]: "Specialistens profil",
    [TranslationKeys.paymentMethod]: "Välj klientskap",
    [TranslationKeys.selectDay]: "Välj dag",
    [TranslationKeys.day]: "Dag",
    [TranslationKeys.all]: "Allt",
    [TranslationKeys.reserveTimeByPhone]: "Boka tid via telefon",
    [TranslationKeys.openTimes]: "Mån - Fre kl 8-17",
    [TranslationKeys.callPrice]: "Samtalspris: ",
    [TranslationKeys.informationWillOnlyBeAvailableTo]: "Den här informationen kommer endast förmedlas till de specialister som vårdar dig ",
    [TranslationKeys.agreeToDataPrivacyHeader]: "Godkännande av dataskydd",
    [TranslationKeys.verifySelection]: "Bekräfta tiden",
    [TranslationKeys.arrival]: "Ankomst",
    [TranslationKeys.showMoreArrivalTips]: "Se fler ankomstinstruktioner",
    [TranslationKeys.showLessArrivalTips]: "Visa mindre ankomstinstruktioner",
    [TranslationKeys.summaryOfReservation]: "Sammanfattning av tidsbokningen",
    [TranslationKeys.arrivalWithOwnCar]: "Om du kommer med egen bil",
    [TranslationKeys.accessibility]: "Tillgänglighet",
    [TranslationKeys.parking]: "Parkering",
    [TranslationKeys.cancelReservationCancelWithPinHeader]: "Avboka tiden med PIN-koden i bekräftelsemeddelandet",
    [TranslationKeys.cancelReservationCancellingReservedTimeHeader]: "Avbokning av en bokad tid",
    [TranslationKeys.cancelReservationDisclaimerParagraph1]: "Genom onlinebokningen kan du endast avboka tider som är bokade genom webben. Avbokningar till mottagningen måste göras minst 24 timmar före det planerade besöket.",
    [TranslationKeys.cancelReservationDisclaimerParagraph2]: "Avbokningar som är gjorda senare eller om det planerade besöket uteblir har tjänsteleverantören rätt att ta ut en avgift enligt den aktuella prislistan.",
    [TranslationKeys.cancelReservationPinDisclaimer]: "PIN-koden hittar du i e-postmeddelandet som har skickats till dig",
    [TranslationKeys.cancelSuccessButton]: "Boka en ny tid",
    [TranslationKeys.cancelGenericFailButton]: "Försök avboka igen.",
    [TranslationKeys.cancellationGenericError]: "Om avbokningen inte lyckas eller om du vill göra andra ändringar i din bokning, takontakt med kundtjänstnumret som anges i e-postmeddelandet med bokningsbekräftelsen. För oavbokad tid debiteras en avgift enligt prislistan.",
    [TranslationKeys.showPreviousMonth]: "Visa föregående månad",
    [TranslationKeys.showNextMonth]: "Visa nästa månad",
    [TranslationKeys.showNextDay]: "Visa mottagningstider till nästa dag",
    [TranslationKeys.showPreviousDay]: "Visa mottagningstider från dagen före",
    [TranslationKeys.ariaLabelFilterMenu]: "Tidsfiltrering",
    [TranslationKeys.loadingPremises]: "Laddar verksamhetsställen...",
    [TranslationKeys.searchingLocationHelper]: "Ange din plats eller dra markeringen på kartan till rätt ställe.",
    [TranslationKeys.premisesNearYou]: "Verksamhetsställen nära dig",
    [TranslationKeys.cancelLocationSearch]: "Avsluta platssökning",
    [TranslationKeys.orderByDistance]: "Sortera efter plats",
    [TranslationKeys.orderByTime]: "Sortera efter tid",
    [TranslationKeys.streetAddress]: "Ange din gatuadress",
    [TranslationKeys.postalError]: "Postnumret måste innehålla exakt fem siffror",
    [TranslationKeys.readOurPrivacyPolicyHere]: "Läs mer om vår integritetspolicy",
    [TranslationKeys.sendSummaryOfReservation]: "Vi skickar en sammanfattning av den bokade tiden till den e-postadress du har angivit. Sammanfattningen innehåller en länk och instruktioner för hur man kan avboka tiden.",
    [TranslationKeys.summaryOfForm]: "Sammanfattning av tidsbokningen",
    [TranslationKeys.iWantAReminderWithTextMessage]: "Jag vill få påminnelse om tiden via sms",
    [TranslationKeys.listOfPrices]: "Prislista",
    [TranslationKeys.listOfPricesDisclaimer]: "Aktuella priser för tjänster hittar du på vår hemsida",
    [TranslationKeys.listOfPricesLink]: "under prislistan",
    [TranslationKeys.popular]: "Populärt",
    [TranslationKeys.serviceVoucherNumber]: "Ange servicesedelns nummer",
    [TranslationKeys.discountCodeNumber]: "Ange rabattkod",
    [TranslationKeys.discountCodeNumberInfo]: "",
    [TranslationKeys.loadingExperts]: "Hämtar specialister...",
    [TranslationKeys.paymentVoucher]: "Betalningsförbindelse",
    [TranslationKeys.reservationProblem]: "Ett problem har uppstått i tidsreserveringen",
    [TranslationKeys.reservationConfirmationTimeout]: "Vi beklagar - vi kan inte verifiera bokandet",
    [TranslationKeys.reservationConfirmationTimeoutText]: "Informationen av tidsbokandet har skickats vidare, men det tar ovanligt länge att få verifikation av bokandet",
    [TranslationKeys.reservationConfirmationTimeoutPointOne]: "Tidsbokningen kan ändå ha lyckats och då har du fått bekräftelsen av det i din e-post",
    [TranslationKeys.reservationConfirmationTimeoutPointTwo]: "Ifall du inte har fått bekräftelse så ber vi er att boka tiden på nytt",
    [TranslationKeys.reservationConfirmationTimeoutEnd]: "Vi beklagar för extra besväret",
    [TranslationKeys.infoAndPrice]: "Platsinformation och prislista",
    [TranslationKeys.previousDay]: "Föregoende dag",
    [TranslationKeys.nextDay]: "Nästä dag",
    [TranslationKeys.chooseCustomerType]: "",
    [TranslationKeys.ifPayingYourself]: "",
    [TranslationKeys.chooseAsCustomerType]: "",
    [TranslationKeys.weHaveKelasPayment]: "",
    [TranslationKeys.serviceVoucherCustomers]: "",
    [TranslationKeys.purchaseServiceCustomer]: "",
    [TranslationKeys.cityOfHelsinki]: "",
    [TranslationKeys.cityOfKerava]: "",
    [TranslationKeys.cityOfTampere]: "",
    [TranslationKeys.karviainens]: "",
    [TranslationKeys.cityOfEspoo]: "",
    [TranslationKeys.ythsCustomers]: "",
    [TranslationKeys.infoReminder]: "",
    [TranslationKeys.ythsInfo]: "",
    [TranslationKeys.tampereInfo]: "",
    [TranslationKeys.byPhone]: "",
    [TranslationKeys.howToChooseCustomerType]: "",
    [TranslationKeys.ifUsingServiceVoucher]: "",
    [TranslationKeys.serviceVoucherUsedFor]: "",
    [TranslationKeys.helsinkiAndUusimaa]: "",
    [TranslationKeys.hus]: "",
    [TranslationKeys.tampereArea]: "",
    [TranslationKeys.paidByInsurance]: "",
    [TranslationKeys.emptyTimeselectItem]: "Just nu finns det inga tider på nätet för en expert. Du kan boka en tid via telefon.",
    [TranslationKeys.endoscopyCustomerTypeTooltip]: "",
    [TranslationKeys.remoteReception]: "",
    [TranslationKeys.koho]: "",
    [TranslationKeys.kohoClarification]: "",
    [TranslationKeys.reservationOnly]: "",
};

export default translation;
