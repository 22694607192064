import React, { useContext, useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import { PopulatedTimeslot, EntityResponse, Building, RequestReservationResponseSuccess, Premise, CampaignService } from "../../types";
import { makeStyles, createStyles, Theme, Avatar, Typography, Button, useTheme, useMediaQuery, Collapse } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckRounded';
import { InitialInformation } from "./InitialInformation";
import { LongCancellationDisclaimer } from './Footers';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { createICS, createPdf, getLengthOfAppointment } from '../utils';
import { coronariaRootUrl, noReplyMail } from "../../settings";
import { DefaultButton, SuccessButton } from '../CustomStyleComponents/Buttons';
import EventIcon from '@material-ui/icons/EventRounded';
import PrintIcon from '@material-ui/icons/PrintRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import { Translation } from '../LanguageContext/types';
import { gaEventSuccessfulReservation } from '../../gaEvents/events';
import { colors } from '../../theme';

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: "green"
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        container: {
            "& >.MuiGrid-item:not(:last-child)": {
                paddingBottom: theme.spacing(2),
            },
            "& >.MuiGrid-item:first-child": {
                paddingRight: mobile ? 0 : theme.spacing(2)
            }
        },
        initialInfoBox: {
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: colors.borderColor,
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2)
        },
        reserveNewButton: {
            paddingRight: theme.spacing(3)
        },
        closeButton: {
            paddingLeft: theme.spacing(3)
        },
        blue: {
            color: theme.palette.text.secondary
        },
        collapse: {
            width: "100%",
        },
        buttons: {
            "& >:first-child": {
                paddingRight: mobile ? theme.spacing(0) : theme.spacing(4),
                paddingBottom: mobile ? theme.spacing(2) : theme.spacing(0)
            }
        },
        button2: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.main,
            paddingLeft: 0,
        },
        showMore: {
            marginBottom: theme.spacing(2)
        },
        text: {
            paddingBottom: theme.spacing(2)
        }
    }));

const createIcsObjectURL = (
    timeslot: PopulatedTimeslot,
    translation: Translation,
    building: EntityResponse<Building>,
    premise: EntityResponse<Premise>,
) => {
    const url = premise?.data?.Verkkosivu ?
        premise.data.Verkkosivu :
        coronariaRootUrl;
    const [coronaria, title] = translation.reservationService.split(" ");

    const location = `${premise.data.Nimi.toimipaikka} ${building.data.katuosoite}`;
    const description = `${timeslot.palvelunNimi} ${getLengthOfAppointment(timeslot)}min ${timeslot.työntekijä.data.Nimi}
    ${location}
    ${premise.data.Pysäköintiohjeet} ${premise.data.Esteettömyystiedot} ${premise.data.Ajo_ohjeet}`;

    return createICS(
        new Date(timeslot.start),
        new Date(timeslot.end),
        title,
        description,
        location,
        url,
        {
            name: coronaria,
            email: noReplyMail
        }
    );
};

interface InitialInformationProps {
    timeslot: PopulatedTimeslot,
    serviceName: string,
    building: EntityResponse<Building> | null,
    premise: EntityResponse<Premise> | null,
    service: CampaignService | null,
    handleClose: () => void,
    discountCode: string,
    reservationObj: RequestReservationResponseSuccess,
    category?: string,
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}

const Confirmed: React.FC<InitialInformationProps> = ({
    timeslot,
    serviceName,
    building,
    premise,
    service,
    discountCode,
    handleClose,
    category,
    accessToken,
    setAccessToken
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles(!windowIsMdSize)();
    const { translation } = useContext(LocalizationContext);

    const ics = building && premise ?
        createIcsObjectURL(timeslot, translation, building, premise) :
        null;
    const icsObjectUrl = ics ?
        `data:text/calendar;charset=utf-8,${encodeURIComponent(ics)}` :
        undefined;

    const [
        showMoreArrivalInfo,
        setShowMoreArrivalInfo
    ] = useState<boolean>(false);

    useEffect(() => {
        gaEventSuccessfulReservation(
            timeslot,
            discountCode !== "" ? discountCode : null,
            category
        );
    }, []);

    const reserveNewButton = useMemo(() =>
        <Grid
            item xs={12}
            md={6}
        >
            <DefaultButton
                onClick={handleClose}
                fullWidth
            >
                {translation.buttonScheduleNewAppointment}
            </DefaultButton>
        </Grid>, [translation]
    );
    const closeButton = useMemo(() =>
        <Grid
            item
            xs={12}
            md={6}
        >
            <SuccessButton
                onClick={() => window.location.replace("/")}
                fullWidth
            >
                {translation.buttonClose}
            </SuccessButton>
        </Grid>
        , []);

    const initialInformation = useMemo(() =>
        <InitialInformation
            timeslot={timeslot}
            serviceName={serviceName}
            building={building}
            premise={premise}
            service={service}
            accessToken={accessToken}
            setAccessToken={setAccessToken}
        />
        , [premise, building, service]);

    interface IArrivalInfoElements {
        header: string,
        text: string
    }

    const ArrivalInfoElmenets: React.FC<IArrivalInfoElements> = ({ header, text }) =>
        <React.Fragment>
            <Typography
                variant="subtitle1"
                paragraph={true}
            >
                {header}
            </Typography>
            {
                text.split("\n")
                    .map((textItem, index) =>
                        <Typography
                            variant="subtitle2"
                            key={textItem + index}
                            className={classes.text}
                        >
                            {textItem}
                        </Typography>
                    )
            }
        </React.Fragment>;


    const arrivalInformation = useMemo(() => [
        ...(
            premise &&
                premise.data.Ajo_ohjeet &&
                premise.data.Ajo_ohjeet.length > 0 ?
                [<ArrivalInfoElmenets
                    key={"arrivalWithCar"}
                    header={translation.arrivalWithOwnCar}
                    text={premise.data.Ajo_ohjeet}
                />] :
                []
        ),
        ...(
            premise &&
                premise.data.Pysäköintiohjeet &&
                premise.data.Pysäköintiohjeet.length > 0 ?
                [<ArrivalInfoElmenets
                    key={"parkingInformation"}
                    header={translation.parking}
                    text={premise.data.Pysäköintiohjeet}
                />] :
                []
        ),
        ...(
            premise &&
                premise.data.Esteettömyystiedot &&
                premise.data.Esteettömyystiedot.length > 0 ?
                [<ArrivalInfoElmenets
                    key={"accessibilityInformation"}
                    header={translation.accessibility}
                    text={premise.data.Esteettömyystiedot}
                />] :
                []
        )
    ], [premise]);

    return (
        <Grid
            container
            item
            className={classes.container}
        >
            <Grid
                item xs={12}
            >
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <CheckIcon />
                    </Avatar>
                </div>
            </Grid>
            <Grid
                container
                item xs={12}
                className={classes.buttons}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                >
                    {
                        // MUI typings have hard time with download attribute as
                        // all attributes that are not needed by actual button
                        // are forwarded downwards to native dom elements,
                        // which makes it hard to type.
                        // @ts-ignore-start
                        //eslint-disable
                        <DefaultButton
                            type="submit"
                            fullWidth
                            href={icsObjectUrl}
                            startIcon={<EventIcon />}
                            //@ts-ignore
                            download={"booking.ics"}
                        >
                            {translation.buttonSaveToCalendar}
                        </DefaultButton>
                        //eslint-enable
                        // @ts-ignore-end
                    }
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                >
                    <DefaultButton
                        type="submit"
                        fullWidth
                        startIcon={<PrintIcon />}
                        onClick={
                            building !== null && premise ?
                                createPdf(
                                    translation,
                                    timeslot,
                                    serviceName,
                                    building.data,
                                    premise
                                ) : undefined}
                    >
                        {translation.buttonPrint}
                    </DefaultButton>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant={"h6"}
                    className={classes.blue}
                >
                    {translation.summaryOfReservation}
                </Typography>
            </Grid>
            <Grid
                container
                item
                className={classes.initialInfoBox}
                xs={12}
            >
                {initialInformation}
            </Grid>
            {
                arrivalInformation.length > 0 &&
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        variant={"h6"}
                        className={classes.blue}
                        paragraph={true}
                    >
                        {translation.arrival}
                    </Typography>
                    {arrivalInformation[0]}
                    {
                        arrivalInformation.length > 1 &&
                        <React.Fragment>
                            <div className={classes.showMore}>
                                <Button
                                    variant="text"
                                    onClick={() => setShowMoreArrivalInfo(!showMoreArrivalInfo)}
                                    endIcon={
                                        showMoreArrivalInfo ?
                                            <ExpandLessIcon /> :
                                            <ExpandMoreIcon />
                                    }
                                    className={classes.button2}
                                >
                                    {
                                        !showMoreArrivalInfo ?
                                            translation.showMoreArrivalTips :
                                            translation.showLessArrivalTips
                                    }
                                </Button>
                            </div>
                            <Collapse
                                className={classes.collapse}
                                in={showMoreArrivalInfo}
                                timeout="auto"
                            >
                                {arrivalInformation.slice(1)}
                            </Collapse>
                        </React.Fragment>
                    }
                </Grid>

            }
            <Grid
                item
                container
                xs={12}
            >
                <Grid item xs={12}>
                    <LongCancellationDisclaimer />
                </Grid>

            </Grid>
            <Grid item container className={classes.buttons}>
                {
                    windowIsMdSize ?
                        reserveNewButton :
                        closeButton
                }

                {
                    windowIsMdSize ?
                        closeButton :
                        reserveNewButton
                }
            </Grid>
        </Grid>
    );
};

export default Confirmed;
