import React, { FC, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
    createStyles,
    makeStyles,
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import campaignsJSON from "./../services.json";
import { StyledTextField } from './CustomStyleComponents/TextFields';
import { useHistory } from 'react-router-dom';

type OptionType = {
    label: string,
    uuid: string,
    id: string
};

const services = [
    'beb7bc57-7393-495f-8eaa-ef7b8916f8fd',
    '61041c0f-235e-4029-b5c1-7ae1092a9e2e',
    '74bb30af-4937-4e85-ae4d-be7db0193a7e',
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textInput: {
            '& .MuiOutlinedInput-root': {
                paddingRight: "9px !important",
            },
            "& .MuiAutocomplete-popupIndicatorOpen": {
                transform: "rotate(0)",
            },
        },
        listItemText: {
            paddingLeft: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        noDecoration: {
            textDecoration: "inherit",
            color: "inherit",
            width: "100%",
            display: "inline-block",
        },
        popper: {
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            width: "100%",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: "24px"
        },
        labelText: {
            color: "#595959",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(16),
            letterSpacing: theme.typography.pxToRem(0.4),
            backgroundColor: "#FFFFFF",
            paddingRight: theme.typography.pxToRem(4),
            "&::placeholder": {
                fontFamily: "Gotham Narrow Book",
                fontSize: theme.typography.pxToRem(16),
                letterSpacing: theme.typography.pxToRem(0.13),
                lineHeight: theme.typography.pxToRem(24),
                color: "rgba(33,33,33)",
                opacity: 0.7
            },
        },
    }), { name: "MuiItem" }
);

const options = campaignsJSON.map((service: {
    id: string,
    name: string,
    mainCategoryUuid: string,
    subServices: { id: string }[]
}) => ({
    id: service.id,
    label: service.name,
    uuid: service.subServices.map(service => service.id).join(";")
}));

const filteredOptions = options.filter((option) => (
    services.includes(option.id)
));

const resolveDefaultValue = (urlBase?: string[]) => {
    const option = filteredOptions.find((option) => (
        urlBase && option.uuid.includes(urlBase[0])
    ));

    return option ?? filteredOptions[0];
};

interface FilterServiceMenuProps {
    urlBase?: string[]
}

export const FilterServiceMenu: FC<FilterServiceMenuProps> = (props) => {
    const { urlBase } = props;

    const classes = useStyles();
    const [value, setValue] = useState<OptionType | null>(resolveDefaultValue(urlBase));
    const [inputValue, setInputValue] = useState<string>('');
    const history = useHistory();

    const getOptionLabel = (option: OptionType) => option.label;

    const handleChange = (e: any, value: OptionType | null) => {
        if (value) {
            history.push({ search: `?base=${value?.uuid}` });
            setValue(value);
        }
    };

    return (
        <Autocomplete
            autoHighlight
            value={value}
            className={classes.textInput}
            onChange={(e, value) => handleChange(e, value)}
            renderOption={(option: OptionType) => (
                <div key={option.uuid} className={classes.noDecoration}>
                    <Typography variant="body1" component="span" className={classes.listItemText}>
                        {getOptionLabel(option)}
                    </Typography>
                </div>
            )}
            inputValue={inputValue}
            getOptionLabel={getOptionLabel}
            onInputChange={(_e: any, value: string) => {
                setInputValue(value);
            }}
            options={filteredOptions}
            PaperComponent={(params) =>
                <Paper className={classes.popper}>
                    {params.children}
                </Paper>
            }
            renderInput={(params) =>
                <StyledTextField
                    {...params}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        disableAnimation: true,
                        shrink: true,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password"
                    }}
                    InputProps={{
                        ...params.InputProps,
                        classes: { input: classes.labelText },
                        endAdornment: null,
                    }}
                    variant="outlined"
                    fullWidth
                />
            }
            noOptionsText={""}
        />
    );
};
