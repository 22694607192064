import { createCache, initializeSocket, send, createOnmessage, createRequestSessionKey, utoa, btou, tryJSONParse } from "./socketHelpers";
import { Operations, Contexes } from "./constants";
import { Search, WaitingResponses, ExpertResult, LocationData } from "../types";
import { websocketClURL } from "../settings";

const waitingResponse: WaitingResponses<any> = {};

const searchCache = createCache();
const createFuzzySearch = (waitingResponse: WaitingResponses<any>) =>
    (socket: WebSocket) =>
        <T>(argumentStr: string): Promise<Search<T>> => {
            // API demands request to be strings with strict ordering
            const msgStr = `{"op":"${Operations.search}","ctx":"${Contexes.topaasi}","query":"${utoa(argumentStr)}"}`;
            const msg = JSON.parse(msgStr);

            const matchResponseToCallback = (resp: Search<T>) => {
                const respObj = tryJSONParse(resp as unknown as string);
                return respObj.ctx === msg.ctx &&
                    respObj.query.toLowerCase() === argumentStr.toLowerCase();
            };

            return new Promise((resolve, reject) => {
                const callback = (data: Search<T>) => {
                    const msgString = JSON.stringify(msg);
                    searchCache.add(msgString, data);
                    resolve(data);
                };
                const msgString = JSON.stringify(msg);
                const cached = searchCache.get(msgString);

                cached ?
                    callback(cached.response as Search<T>) :
                    send(
                        socket,
                        callback,
                        matchResponseToCallback,
                        msg,
                        waitingResponse,
                        reject,
                        msgStr
                    );
            });
        };

const createExpertSearch = (waitingResponse: WaitingResponses<any>) =>
    (socket: WebSocket) =>
        <ExpertResult>(argumentStr: string): Promise<Search<ExpertResult>> => {
            // API demands request to be strings with strict ordering
            const msgStr = `{"op":"${Operations.search}","ctx":"${Contexes.topaasiAsiantuntija}","query":"${utoa(argumentStr)}"}`;
            const msg = JSON.parse(msgStr);
            const matchResponseToCallback = (resp: Search<ExpertResult>) => {
                const respObj = tryJSONParse(resp as unknown as string);
                return respObj.ctx === msg.ctx &&
                    respObj.query.toLowerCase() === argumentStr.toLowerCase();
            };

            return new Promise((resolve, reject) => {
                const callback = (data: Search<ExpertResult>) => {
                    const msgString = JSON.stringify(msg);
                    searchCache.add(msgString, data);
                    resolve(data);
                };
                const msgString = JSON.stringify(msg);
                const cached = searchCache.get(msgString);

                cached ?
                    callback(cached.response as Search<ExpertResult>) :
                    send(
                        socket,
                        callback,
                        matchResponseToCallback,
                        msg,
                        waitingResponse,
                        reject,
                        msgStr
                    );
            });
        };

const locationFuzzySearch = (waitingResponse: WaitingResponses<any>) =>
    (socket: WebSocket) =>
        <T>(argumentStr: string): Promise<Search<T>> => {
            // API demands request to be strings with strict ordering
            const msgStr = `{"op":"${Operations.search}","ctx":"${Contexes.osoitteet}","query":"${utoa(argumentStr)}"}`;
            const msg = JSON.parse(msgStr);

            const matchResponseToCallback = (resp: Search<T>) => {
                const respObj = tryJSONParse(resp as unknown as string);
                return respObj.ctx === msg.ctx && respObj.query === btou(msg.query).toLowerCase();
            };

            return new Promise((resolve, reject) => {
                const callback = (data: Search<T>) => {
                    const msgString = JSON.stringify(msg);
                    searchCache.add(msgString, data);
                    resolve(data);
                };
                const msgString = JSON.stringify(msg);
                const cached = searchCache.get(msgString);

                cached ?
                    callback(cached.response as Search<T>) :
                    send(
                        socket,
                        callback,
                        matchResponseToCallback,
                        msg,
                        waitingResponse,
                        reject,
                        msgStr
                    );
            });
        };

type search<T> = (argumentStr: string) => Promise<Search<T>>

export interface ISearchService {
    socket: WebSocket,
    search: search<any>,
    searchExpert: search<ExpertResult[]>,
    locationSearch: search<LocationData[]>
}

export const initializeSearch = (sessionKey: string): Promise<ISearchService> =>
    new Promise((resolve, reject) => {
        const callback = async (socket: WebSocket) => {
            const sessionKeyResp =
                await createRequestSessionKey(socket, waitingResponse, sessionKey)();

            sessionKeyResp.success ?
                resolve({
                    socket,
                    search: createFuzzySearch(waitingResponse)(socket),
                    searchExpert: createExpertSearch(waitingResponse)(socket),
                    locationSearch: locationFuzzySearch(waitingResponse)(socket)
                }) :
                reject(new Error("Could not register search session"));
        };

        initializeSocket(
            callback,
            createOnmessage(waitingResponse),
            websocketClURL,
            reject
        );
    });

export const forTests = {
    createFuzzySearch,
    createExpertSearch,
    locationFuzzySearch
};
