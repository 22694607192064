import React, { useContext, useState, useMemo, useRef, useLayoutEffect, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { makeStyles, createStyles, Theme, List, useTheme, useMediaQuery, Grid } from "@material-ui/core";
import TimeSelectListItem from "./TimeSelectListItem";
import { ServiceContext } from '../ServiceContext';
import { PopulatedTimeslot, TimeslotStoreItems, TimeslotResponse, PremiseLocation, EntityResponse, Expert, Premise, Building, CampaignService } from '../../types';
import TimeOfDay, { TimeOfDayFilter } from '../Filters/TimeOfDay';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import DateChanger from '../DateChanger';
import EmptyTimeSelectListItem from "../EmptyTimeSelectListItem";
import { filter, concatMap, mergeMap } from "rxjs/operators";
import { TimeSpan, IDataService } from "../../services/DataService";
import { timeslotStore } from "../../store/stores";
import CustomDialog, { DialogRef } from '../CustomDialog';
import ConfirmReservation from '../ConfirmReservation/ConfirmReservation';
import { ExpertProfile } from '../ExpertProfile/ExpertProfile';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownRounded';
import { arraysAreEqual } from '../../services/socketHelpers';
import { datesAreEqual, getUuid, createDistanceSorter, createTimeSorter, allSettled, scrollToTop, onlyDate } from '../utils';
import { Header } from '../../Layout';
import TimeOfDayMobile from '../Filters/TimeOfDayMobile';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SortOptions, SortSelectionView } from './SortSelectionView';
import LoadingIndicator from '../UtilComponents/LoadingIndicator';
import { expertsTofilterOut, BUILD_ENV } from '../../settings';
import moment from "moment";
import { Observable, timer } from 'rxjs';
import ChangeDayButtons from './ChangeDayButtons';
import ListItemNoTimesMobile from './ListItemNoTimesMobile';
import ListItemNoTimesDesktop from './ListItemNoTimesDesktop';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2),
            height: "100%",
        },
        listWrapper: {
            display: "block",
            overflow: "auto",
            scroll: "auto",
            minHeight: 250
        },
        list: {
            width: "100%",
            maxHeight: "100%",
            backgroundColor: theme.palette.background.paper,
            paddingBottom: "0px",
            "& div[class*='NoTime-container']:last-child": {
                marginBottom: theme.spacing(4),
                borderBottom: "1px solid #e3e3e3"
            }
        },
        header: {
            textAlign: "center",
            padding: "10px",
            fontSize: theme.typography.pxToRem(26),
            lineHeight: theme.typography.pxToRem(31),
            letterSpacing: theme.typography.pxToRem(-0.37),
            opacity: "70%",
            paddingBottom: theme.spacing(2),
            fontFamily: "Gotham narrow bold",
            color: "#000000"
        },
        timeslotWrapper: {
            display: "flex",
            overflowY: "auto",
            justifyContent: "center"
        },
        dropdown: {
            color: "#085B9B",
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2)
        },
        container: {
            width: "100%",
            borderRadius: "1px",
            boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)"
        },
        loading: {
            height: "100px",
            width: "100px",
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(3)
        }
    }));

interface TimeSelectListProps {
    position?: GeolocationPosition | null,
    selectedDate: Date | null,
    setSelectedDate: (date: Date | null) => void,
    filters: string[],
    locationUuids: PremiseLocation[] | null,
    selectedExperts: EntityResponse<Expert>[],
    selectedPremises: EntityResponse<Premise>[],
    selectedServices: CampaignService[],
    handleMonthChange: (renderedDay: MaterialUiPickersDate) => Promise<void>,
    services: CampaignService[],
    locationEnabled: boolean,
    customerType: string,
    setLoadingInitialState: (status: boolean) => void,
    allServices: CampaignService[],
    selectedSorter: SortOptions,
    setSelectedSorter: (option: SortOptions) => void,
    selectedTimeframe: TimeOfDayFilter,
    setSelectedTimeframe: (filters: TimeOfDayFilter) => void,
    slots: string[],
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}


type StoreObservable = Observable<{
    populatedTimeslots: PopulatedTimeslot[],
    filteredTimeslots: TimeslotStoreItems
}>;

export const TimeSelectList: React.FC<TimeSelectListProps> = props => {
    const {
        selectedDate,
        setSelectedDate,
        filters,
        position,
        locationUuids,
        selectedExperts,
        selectedPremises,
        selectedServices,
        handleMonthChange,
        services,
        locationEnabled,
        customerType,
        setLoadingInitialState,
        allServices,
        selectedSorter,
        setSelectedSorter,
        selectedTimeframe,
        setSelectedTimeframe,
        slots,
        accessToken,
        setAccessToken
    } = props;
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const { dataService } = useContext(ServiceContext);
    const { translation } = useContext(LocalizationContext);
    const currentDate = useMemo(() => new Date(), []);
    const date: Date = useMemo(() =>
        onlyDate(selectedDate ? selectedDate : currentDate),
        [selectedDate]
    );
    const headerDivRef = useRef<HTMLDivElement>(null);
    const scrollToHeader = useCallback(() => {
        headerDivRef?.current?.scrollIntoView({
            behavior: "smooth",
            inline: "start",
            block: "start"
        });
    }, [headerDivRef]);
    const [filteredTimeslots, setFilteredTimeslots] = useState<TimeslotStoreItems | null>(null);
    const [populatedTimeslots, setPopulatedTimeslots] = useState<PopulatedTimeslot[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalValue, setModalValue] = useState<ITimeslotOrExpertModal | null>(null);
    const [expertPremises, setExpertPremises] = useState<EntityResponse<Premise>[]>([]);
    const [expertBuildings, setExpertBuildings] = useState<EntityResponse<Building>[]>([]);
    const [expertModalValues, setExpertModalValues] = useState<{
        expert: EntityResponse<Expert> | null,
        open: boolean,
        services: CampaignService[]
    }>({ expert: null, open: false, services: [] });
    const [dateChanged, setDateChanged] = useState(false);

    const [amountOfTimeslots, setAmountOfTimeslots] = useState<number>(8);
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [{ amountOfPopulated, amountToPopulate }, setAmounts] = useState<{
        amountOfPopulated: number,
        amountToPopulate: number
    }>({
        amountOfPopulated: 0,
        amountToPopulate: 8
    });

    const [timeslotObs, setTimeslotObs] = useState<null | StoreObservable>(null);
    const [timerObs, setTimerObs] = useState<null | StoreObservable>(null);
    interface ITimeslotOrExpertModal {
        type: "timeslot" | "expert",
        timeslot: PopulatedTimeslot,
        services?: CampaignService[]
    }

    const [reservationModalHeader, setResevationModalHeader] =
        useState<string>(translation.buttonConfirm);

    const dialogRef = useRef<DialogRef>(null);

    const scrollToDialogTop = () => {
        if (dialogRef.current) {
            dialogRef.current.scrollToDialogTop();
        }
    };

    const timeslotOrExpertModal = useMemo(() =>
        modalValue !== null &&
        <CustomDialog
            header={
                modalValue.type === "timeslot" ?
                    reservationModalHeader :
                    translation.expertProfile
            }
            open={modalValue !== null}
            handleClose={() => setModalValue(null)}
            maxWidth={"md"}
            fullScreen={!windowIsMdSize}
            ref={dialogRef}
        >
            {
                modalValue.type === "timeslot" ?
                    <ConfirmReservation
                        timeslot={modalValue.timeslot}
                        serviceName={modalValue.timeslot.palvelunNimi}
                        buildingUuid={modalValue.timeslot.toimitilaUuid}
                        setHeader={setResevationModalHeader}
                        customerType={customerType}
                        closeDialog={() => {
                            setModalValue(null);
                            setResevationModalHeader(translation.buttonConfirm);
                            scrollToTop();
                        }}
                        services={services}
                        scrollToDialogTop={scrollToDialogTop}
                        allServices={allServices}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    /> :
                    <ExpertProfile
                        expert={modalValue.timeslot.työntekijä}
                        timeslot={modalValue.timeslot}
                        services={services}
                        onClose={() => setModalValue(null)}
                        openReservation={() => {
                            setModalValue({
                                type: "timeslot",
                                timeslot: modalValue.timeslot,
                                services
                            });
                        }}
                    />
            }
        </CustomDialog>

        , [modalValue, reservationModalHeader, accessToken]);

    const expertModal = useMemo(() =>
        expertModalValues.expert ? <CustomDialog
            header={
                translation.expertProfile
            }
            open={expertModalValues.open}
            handleClose={() => setExpertModalValues({ expert: null, open: false, services: [] })}
            maxWidth={"md"}
            fullScreen={!windowIsMdSize}
            ref={dialogRef}
        >
            <ExpertProfile
                expert={expertModalValues.expert}
                services={expertModalValues.services}
                onClose={() => setExpertModalValues({ expert: null, open: false, services: [] })}
            />
        </CustomDialog> : null, [expertModalValues]);
    const padZeroes = (num: Number) => num < 10 ? "0" + num : num;
    const dateToTimeString = (date: Date) =>
        padZeroes(date.getHours()) + ":" + padZeroes(date.getMinutes());
    const fromTime = useMemo(() =>
        dateToTimeString(selectedTimeframe.timeframe.from) === "00:00" ?
            dateToTimeString(selectedTimeframe.timeframe.from) :
            moment.utc(selectedTimeframe.timeframe.from).format("HH:mm"),
        [selectedTimeframe.timeframe.from]
    );
    const toTime = useMemo(() =>
        dateToTimeString(selectedTimeframe.timeframe.to) === "23:59" ?
            dateToTimeString(selectedTimeframe.timeframe.to) :
            moment.utc(selectedTimeframe.timeframe.to).format("HH:mm"),
        [selectedTimeframe.timeframe.to]
    );
    const timeSpan: TimeSpan = useMemo(() => ({
        from: fromTime,
        to: toTime
    }), [fromTime, toTime]);

    const fetchTimeslots = async (nextFromNumber: number) => {
        if (filters.length !== 0 && dataService && services.length > 0) {
            await dataService.requestTimesInHourRange(
                date,
                timeSpan,
                filters,
                nextFromNumber,
                selectedExperts.map(getUuid),
                selectedPremises.map(getUuid),
                (
                    //We should limit updates based on the campaignServices
                    //if user has not selected any other services
                    selectedServices.length !== 0 ?
                        selectedServices.map(getUuid) :
                        services.map(getUuid)
                )
            );
        }
    };

    const filterTimeslots = (timeslot: TimeslotStoreItems) =>
        arraysAreEqual(timeslot.filter, filters)
        && selectedDate !== null &&
        timeslot.date !== null &&
        datesAreEqual(timeslot.date, selectedDate);

    const populateTimeslot = async (
        timeslot: TimeslotResponse,
        dataService: IDataService,
        location?: PremiseLocation,
    ): Promise<PopulatedTimeslot> => {
        const [
            expert,
            service
        ] = await Promise.all([
            dataService.requestExpert(
                timeslot.työntekijä ?
                    timeslot.työntekijä :
                    timeslot.tyontekija
            ),
            services.find(service => service.uuid === timeslot.palvelu)
        ]);
        const toimipaikka =
            location ? location : await dataService.requestPremise(timeslot.toimipaikka);
        const toimitila = location ?
            location.toimitila :
            await dataService.requestBuilding(
                (toimipaikka as EntityResponse<Premise>).data.toimitila
            );

        const dist = location ? location.toimitila.dist : null;

        return ({
            ...timeslot,
            dist,
            toimipaikka: (toimipaikka as EntityResponse<Premise>).data ?
                (toimipaikka as EntityResponse<Premise>).data as Premise :
                toimipaikka as PremiseLocation,
            toimitila: (toimitila as EntityResponse<Building>).data ?
                (toimitila as EntityResponse<Building>).data as Building :
                toimitila,
            työntekijä: expert as EntityResponse<Expert>,
            palvelunNimi: service?.name ?
                service.name :
                "" as string,
            toimipaikkaUuid: timeslot.toimipaikka,
            toimitilaUuid: !location ?
                toimitila.uuid :
                location.toimitila.uuid
        });
    };

    const createPopulatedTimeslots = async (
        resp: TimeslotStoreItems,
        dataService: IDataService,
    ) => {
        const availableSlots = resp.data.filter(timeslot =>
            (typeof timeslot.available !== "boolean" || timeslot.available === true) &&
            new Date(timeslot.start).getTime() > new Date().getTime()
        );

        const productionSlots = availableSlots.filter(timeslot =>
            !expertsTofilterOut.includes(timeslot.työntekijä || timeslot.tyontekija));
        const populatedPromises = await allSettled((
            BUILD_ENV !== "production" ?
                availableSlots : productionSlots).slice(0, amountToPopulate).map(timeslot => {
                    const location = locationUuids?.find(premise =>
                        premise.uuid === timeslot.toimipaikka
                    );
                    return populateTimeslot(timeslot, dataService, location);
                })
        );
        const populatedTimeslots = populatedPromises.reduce((acc, cur) =>
            cur.status === "fulfilled" ? [...acc, cur.value] : acc,
            [] as PopulatedTimeslot[]
        );
        return { populatedTimeslots, filteredTimeslots: resp };
    };

    useEffect(() => {
        if (dataService) {
            const timeslotStoreObs = timeslotStore.subject.pipe(
                filter(filterTimeslots),
                concatMap(async resp =>
                    createPopulatedTimeslots(resp, dataService)
                )
            );
            setTimeslotObs(timeslotStoreObs);

            const timeslotStoreSub = timeslotStoreObs.subscribe(resp => {
                setPopulatedTimeslots(resp.populatedTimeslots);
                setFilteredTimeslots(resp.filteredTimeslots);
                setLoading(false);
                setLoadingInitialState(false);
            });

            return () => {
                timeslotStoreSub.unsubscribe();
            };
        }
    }, [dataService, filters, selectedDate, locationUuids, amountToPopulate, amountOfPopulated]);

    useEffect(() => {
        if (populatedTimeslots && populatedTimeslots?.length && timeslotObs) {
            const timerValue = new Date(populatedTimeslots[0].start);
            const timerObs = timer(timerValue)
                .pipe(
                    mergeMap(() => timeslotObs)
                );
            setTimerObs(timerObs);
        }
    }, [populatedTimeslots, timeslotObs]);

    useEffect(() => {
        if (timerObs) {
            const timerSub = timerObs.subscribe((resp) => {
                setFilteredTimeslots(resp.filteredTimeslots);
                setPopulatedTimeslots(resp.populatedTimeslots);
            });
            return () => {
                timerSub.unsubscribe();
            };
        }
    }, [timerObs]);

    useLayoutEffect(() => {
        setAmountOfTimeslots(8);
        setAmounts({ amountToPopulate: 8, amountOfPopulated: 0 });
        setLoading(true);
        fetchTimeslots(0);
        if (filters.length === 0 && populatedTimeslots) {
            setLoading(false);
            setPopulatedTimeslots([]);
            setFilteredTimeslots(filteredTimeslots ?
                { ...filteredTimeslots, data: [] } :
                null);
        }
    }, [filters, date, timeSpan]);

    useLayoutEffect(() => {
        const nextFromNumber = filteredTimeslots ?
            filteredTimeslots.from + filteredTimeslots.limit :
            32;
        if (amountOfTimeslots > nextFromNumber) {
            fetchTimeslots(nextFromNumber);
        }
    }, [amountOfTimeslots, amountToPopulate]);

    useEffect(() => {
        if (dateChanged && populatedTimeslots && populatedTimeslots.length > 0) {
            scrollToHeader();
            setDateChanged(false);
        }
    }, [populatedTimeslots, dateChanged]);

    useEffect(() => {
        if (dataService && selectedExperts.length > 0 && slots?.length === 0) {
            const fetchPremises = async () => {
                const expertPremises = selectedExperts.flatMap(expert => expert.data.toimipaikka)
                    .map(toimipaikka => toimipaikka.uuid);
                const premisePromises = await allSettled(expertPremises
                    .map(uuid => dataService.requestPremise(uuid)));
                const premises = premisePromises
                    .filter(promise => promise.status === "fulfilled")
                    .map(promise => promise.value);

                const buildingPromises = await allSettled(premises.map(premise =>
                    dataService.requestBuilding(premise.data.toimitila)));
                const buildings = buildingPromises.filter(promise => promise.status === "fulfilled")
                    .map(promise => promise.value);
                setExpertPremises(premises);
                setExpertBuildings(buildings);
            };
            fetchPremises();
        }
    }, [selectedExperts, dataService, slots]);

    const createEmptyExpertItem = (expert: EntityResponse<Expert>, index: number) => {
        const premise = selectedPremises.length === 0 ?
            expertPremises.find(premise => premise?.uuid === expert.data.toimipaikka[0]?.uuid)
            : selectedPremises.find(selected =>
                expert.data.toimipaikka.find(expertPremise =>
                    expertPremise?.uuid === selected?.uuid))
            || expertPremises.find(premise =>
                expert.data.toimipaikka.find(toimipaikka =>
                    toimipaikka?.uuid === premise?.uuid));
        const building = expertBuildings.find(building =>
            building.uuid === premise?.data.toimitila);
        const distance = locationUuids?.find(premiseLocation =>
            premiseLocation.uuid === premise?.uuid)?.toimitila?.dist;

        return mobile ? <ListItemNoTimesMobile
            key={`item-${index}`}
            expert={expert}
            premise={premise}
            building={building}
            openExpertModal={() => setExpertModalValues({
                expert,
                open: true,
                services
            })}
            distance={distance}
            translation={translation}
        /> : <ListItemNoTimesDesktop
            key={`item-${index}`}
            expert={expert}
            premise={premise}
            building={building}
            openExpertModal={() => setExpertModalValues({
                expert,
                open: true,
                services
            })}
            distance={distance}
            translation={translation}
        />;
    };

    const listItems = useMemo(() =>
        populatedTimeslots
            && populatedTimeslots.length > 0
            && locationUuids?.length
            && slots.length > 0 ?
            populatedTimeslots
                .slice(0, amountOfTimeslots)
                .sort((a: PopulatedTimeslot, b: PopulatedTimeslot) =>
                    selectedSorter === "time" ?
                        createTimeSorter(a, b) :
                        createDistanceSorter(a, b)
                )
                .map((timeslot, index) =>
                    <TimeSelectListItem
                        key={`${timeslot.uuid}`}
                        id={`timeslot-${index}`}
                        position={position}
                        timeslot={timeslot}
                        openReservationModal={
                            (timeslot: PopulatedTimeslot) =>
                                setModalValue({ type: "timeslot", timeslot })
                        }
                        openExpertModal={
                            (timeslot: PopulatedTimeslot) =>
                                setModalValue({ type: "expert", timeslot, services })
                        }
                        locationUuids={locationUuids}
                        services={services}
                    />) : selectedExperts.length > 0 && slots.length === 0
                ? selectedExperts.map(createEmptyExpertItem)
                : <EmptyTimeSelectListItem translation={translation} />,
        [
            populatedTimeslots,
            amountOfTimeslots,
            selectedSorter,
            slots,
            mobile,
            selectedPremises,
            locationUuids,
            expertBuildings,
            expertPremises
        ]);

    return (
        <Grid
            container
            item
            xs={12}
            className={classes.container}
            ref={headerDivRef}
        >
            <Grid item xs={12}>
                <Header text={translation.nextFreeTimeslots.toUpperCase()} />
            </Grid>
            <Grid item xs={12}>
                {mobile ? <TimeOfDayMobile
                    date={date}
                    selectedTimeframe={selectedTimeframe}
                    setSelectedTimeframe={setSelectedTimeframe}
                /> : <TimeOfDay
                    date={date}
                    selectedTimeframe={selectedTimeframe}
                    setSelectedTimeframe={setSelectedTimeframe}
                />}
                {
                    (position !== null && locationUuids !== null && locationEnabled) &&
                    <SortSelectionView
                        selectedSorter={selectedSorter}
                        setSelectedSorter={setSelectedSorter}
                    />
                }
                <DateChanger
                    date={selectedDate}
                    setDate={setSelectedDate}
                    handleMonthChange={handleMonthChange}
                />
            </Grid>
            <Grid item xs={12}>
                <div
                    style={{
                        minHeight:
                            !mobile && (!filteredTimeslots || filteredTimeslots.data.length === 0) ?
                                "590px" : mobile ? "100%" : "590px"
                    }}
                    className={
                        classes.timeslotWrapper
                    }
                >
                    {
                        filteredTimeslots && !loading ?

                            <List component="div" className={classes.list}>
                                {listItems}
                            </List>
                            :
                            <LoadingIndicator className={classes.loading} />
                    }</div>
            </Grid>

            {filteredTimeslots
                && slots.length > 0
                && filteredTimeslots.data.length > 0
                && filteredTimeslots.total > amountOfTimeslots ?
                <Grid item xs={12}>
                    <Button
                        variant={"text"}
                        className={classes.dropdown}
                        endIcon={<ArrowDropDownIcon />}
                        fullWidth
                        onClick={() => {
                            setAmountOfTimeslots(amount => amount + 8);
                            setAmounts(({ amountOfPopulated, amountToPopulate }: any) => ({
                                amountOfPopulated: amountOfPopulated + 8,
                                amountToPopulate: amountToPopulate + 8
                            }));
                        }}
                    >
                        {translation.showMore}
                    </Button>
                </Grid> : slots.length > 0 ?
                    <Grid item xs={12}>
                        <ChangeDayButtons
                            date={selectedDate}
                            setDate={setSelectedDate}
                            handleMonthChange={handleMonthChange}
                            setDateChanged={setDateChanged}
                        />
                    </Grid> : null}

            {timeslotOrExpertModal}
            {expertModal}
        </Grid>
    );
};
