import React, { useContext, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme, Avatar, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { SuccessButton } from '../CustomStyleComponents/Buttons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: "#B00020"
        },
        paper: {
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        desktopButton: {
            minWdith: "360px"
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.typography.pxToRem(90),
            marginBottom: theme.typography.pxToRem(40)
        },
        blue: {
            color: theme.palette.text.secondary
        },
        mobileButtons: {
            paddingBottom: theme.spacing(2)
        },
        buttonContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        }
    }));

interface IFailureProps {
    handleClose: () => void
}

const Failure: React.FC<IFailureProps> = ({
    handleClose
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    const successButton = useMemo(() =>
        <SuccessButton
            fullWidth={!windowIsMdSize}
            onClick={handleClose}
            className={windowIsMdSize ? classes.desktopButton : undefined}
        >
            {translation.closeAndSelectNewTimeslot}
        </SuccessButton>
        , [translation, windowIsMdSize]);

    const desktopButton = useMemo(() =>
        <Grid
            item
            xs={12}
            md={12}
            className={classes.buttonWrapper}
        >
            {successButton}
        </Grid>
        , [successButton]);

    const mobileButton = useMemo(() =>
        <div className={classes.buttonContainer}>
            <div style={{ flex: 1 }} />
            <div className={classes.mobileButtons}>
                {successButton}
            </div>
        </div>
        , [translation]);

    return (
        <React.Fragment>
            <Grid item container>
                <Grid
                    item
                    xs={12}
                    className={classes.paper}
                >
                    <Avatar className={classes.avatar}>
                        <CloseIcon />
                    </Avatar>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.paper}
                >
                    <Typography
                        variant={"h6"}
                        className={classes.blue}
                        style={{ paddingBottom: "24px" }}
                        align={"center"}
                    >
                        {translation.timeslotConfirmationFailureMiddle}
                    </Typography>
                    <Typography
                        variant={"subtitle1"}
                        className={classes.blue}
                        align={"center"}
                    >
                        {`${translation.timeslotConfirmationFailureText}.`}
                    </Typography>
                </Grid>

            </Grid>
            {windowIsMdSize ? desktopButton : mobileButton}
        </React.Fragment>
    );
};

export default Failure;
