import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles, createStyles, Theme, Grid, useMediaQuery } from '@material-ui/core';
import SpecialityFilter from './SpecialityFilter';
import LanguageFilter from './LanguageFilter';
import { EntityResponse, Language, SelectedFilters, Speciality } from '../../../types';
import { theme } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(4)
        },
        header: {
            paddingBottom: theme.spacing(2),
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: theme.typography.pxToRem(0.15),
            fontFamily: "Gotham Narrow Book",
            textTransform: "uppercase",
            color: "#085b9b"
        },
        itemMobile: {
            paddingBottom: theme.spacing(2),
            "&:first-child": {
                paddingBottom: theme.spacing(4)
            }
        },
        itemDesktop: {
            paddingRight: theme.spacing(2),
        },
    })
);

interface ExtraFilterProps {
    languageFilters: string[],
    specialityFilters: string[],
    selectedLanguages: EntityResponse<Language>[],
    selectedSpecialities: EntityResponse<Speciality>[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    locationEnabled?: boolean,
    loadingInitialState: boolean
}

const ExtraFilters: React.FC<ExtraFilterProps> = props => {
    const {
        languageFilters,
        specialityFilters,
        selectedSpecialities,
        selectedLanguages,
        setSelectedFilters,
        locationEnabled,
        loadingInitialState
    } = props;
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container item xs={12} className={classes.root}>
            <Grid
                item
                md={locationEnabled ? 6 : 4}
                xs={12}
                className={mobile ? classes.itemMobile : classes.itemDesktop}
            >
                <SpecialityFilter
                    specialityFilters={specialityFilters}
                    selectedSpecialities={selectedSpecialities}
                    setSelectedFilters={setSelectedFilters}
                    loadingInitialState={loadingInitialState}
                />
            </Grid>
            <Grid
                item
                md={locationEnabled ? 6 : 4}
                xs={12}
                className={!mobile ? classes.itemDesktop : ""}
            >
                <LanguageFilter
                    languageFilters={languageFilters}
                    setSelectedFilters={setSelectedFilters}
                    selectedLanguages={selectedLanguages}
                    loadingInitialState={loadingInitialState}
                />
            </Grid>
        </Grid>
    );
};

export default ExtraFilters;
