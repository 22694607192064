import React, { useContext } from "react";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { Typography } from "@material-ui/core";
import { theme } from "../../theme";

export const LongCancellationDisclaimer: React.FC = () => {
    const { translation } = useContext(LocalizationContext);

    return (
        <div>
            <Typography
                variant={"h6"}
                style={{ color: theme.palette.text.secondary }}
                paragraph={true}
            >
                {translation.confirmationFormLongFooterHeader}
            </Typography>
            <Typography
                paragraph={true}
            >
                {translation.confirmationFormLongFooterText1}
            </Typography>
            <Typography
                paragraph={true}
            >
                {translation.confirmationFormLongFooterText2}
            </Typography>
        </div>
    );
};

export const ShortCancellationDisclaimer: React.FC = () => {
    const { translation } = useContext(LocalizationContext);

    return (
        <Typography paragraph={true}>
            {translation.confirmationFormShortFooterText}
        </Typography>
    );
};
