import React, { useEffect, useState, useContext, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Grid, Theme, makeStyles, createStyles, Paper, Typography } from '@material-ui/core';
import { uiSearchTimeoutMs } from '../../settings';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOnRounded';
import { colors } from '../../theme';
import { getCurrentPosition, getCoordinatesFromAddress, getAddressAndSetLocation } from '../utils';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime, mergeMap, map } from 'rxjs/operators';
import { UserLocation, SelectedFilters } from '../../types';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { LabelOutSideTextField } from '../CustomStyleComponents/TextFields';

interface UserLocationSearchProps {
    position?: GeolocationPosition | null,
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    value: UserLocation | null,
    accessToken: string | null
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2)
        },
        popper: {
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            width: "100%",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: "24pt"
        },
        autocomplete: {
            backgroundColor: colors.boxBackground,
            "& .MuiFormControl-root": {
                backgroundColor: colors.boxBackground,
            },
            "& .MuiInputBase-root": {
                backgroundColor: theme.palette.common.white
            }
        },
        textField: {
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            color: "#595959",

            "& .MuiInputLabel-shrink": {
                color: theme.palette.primary.main,
                fontFamily: "Gotham Narrow Medium"
            },
            '& .MuiOutlinedInput-root': {
                color: "#595959",
            }
        },
        labelText: {
            "&::placeholder": {
                fontFamily: "Gotham Narrow Book",
                fontSize: theme.typography.pxToRem(16),
                letterSpacing: theme.typography.pxToRem(0.13),
                lineHeight: theme.typography.pxToRem(24),
                color: "rgba(33,33,33)",
                opacity: 0.7
            }

        }
    })
);

const searchSubject = new BehaviorSubject<string>("");

const UserLocationSearch: React.FC<UserLocationSearchProps> = props => {
    const {
        position,
        value,
        setSelectedFilters,
        accessToken
    } = props;
    const { translation } = useContext(LocalizationContext);
    const [input, setInput] = useState<string>();
    const [options, setOptions] = useState<UserLocation[]>([]);

    const classes = useStyles();

    useEffect(() => {
        if (!position)
            getCurrentPosition().then(position => {
                setSelectedFilters(prevState => ({
                    ...prevState,
                    position
                }));
            });
    }, [position]);

    useEffect(() => {
        if (
            position &&
            value &&
            value.address === translation.gettingLocation &&
            accessToken &&
            setSelectedFilters
        ) {
            getAddressAndSetLocation(
                position.coords.longitude,
                position.coords.latitude,
                setSelectedFilters,
                accessToken
            );
        }
    }, [position, value, accessToken]);

    const getLocationOptionParams = (place: any) => // address missing from mapbox typings using any.
    ({
        address: place.place_name.replace(", Finland", ""),
        coordinates: {
            lat: place.geometry.coordinates[1],
            lon: place.geometry.coordinates[0]
        },
    });
    useEffect(() => {
        if (accessToken) {
            const sub = searchSubject.pipe(
                distinctUntilChanged(),
                filter(input => input.length > 2),
                debounceTime(uiSearchTimeoutMs),
                mergeMap(searchStr => getCoordinatesFromAddress(searchStr, accessToken)),
                map(({ features }: GeoJSON.FeatureCollection) =>
                    features.map(getLocationOptionParams))
            ).subscribe(places => setOptions(places));
            return () => sub.unsubscribe();
        }
    }, [input, accessToken]);

    const handleInputChange = (event: any) => {
        const newInputValue = event.target.value ? event.target.value : "";
        setInput(newInputValue);
        searchSubject.next(newInputValue);
    };

    const renderOption = (option: UserLocation) =>
        <Grid container direction="row" alignItems="center">
            <Grid item>
                <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
                <Typography variant="body1">
                    {option.address}
                </Typography>
            </Grid>
        </Grid>;

    const renderInput = (props: AutocompleteRenderInputParams) => <LabelOutSideTextField
        {...props}
        className={classes.textField}
        label={translation.location.toUpperCase()}
        fullWidth
        placeholder={input !== "" ? translation.writeYourLocation : undefined}
        variant="outlined"
        style={{
            paddingBottom: "16px",
        }}
        InputLabelProps={{
            ...props.InputLabelProps,
            disableAnimation: true,
            shrink: true,
        }}
        InputProps={{
            ...props.InputProps,
            classes: {
                input: classes.labelText
            }
        }}
        helperText={translation.searchingLocationHelper}
        onChange={handleInputChange}
    />;

    const handleOnChange = (_event: ChangeEvent<{}>, newValue: string | UserLocation | null) => {
        const value = typeof newValue === "string" ? null : newValue;
        setSelectedFilters(prevState => ({
            ...prevState,
            userLocation: value as UserLocation
        }));
        position && value !== null && setSelectedFilters(prevState => ({
            ...prevState,
            position: {
                ...prevState.position,
                coords: {
                    ...position.coords,
                    latitude: value.coordinates.lat,
                    longitude: value.coordinates.lon,
                },
                timestamp: new Date().getMilliseconds()
            }
        }));
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={option => option === undefined ? "" : option.address}
            autoComplete
            className={classes.autocomplete}
            includeInputInList
            getOptionSelected={(option, value) => option.address.includes(value.address)}
            filterSelectedOptions
            freeSolo
            placeholder={translation.writeYourLocation}
            value={value}
            PaperComponent={props =>
                < Paper className={classes.popper} > {props.children}</Paper >
            }
            onChange={handleOnChange}
            renderOption={renderOption}
            renderInput={renderInput}
        />

    );
};

export default UserLocationSearch;
