import React from "react";
import { Translation, Language } from "../LanguageContext/types";
import { CampaignService, PopulatedTimeslot } from "../../types";
import { makeStyles, createStyles, Grid, Theme, Typography, Button } from "@material-ui/core";
import { getLengthOfAppointment, getExpertTitles, roundToOneDecimal, getKunta, getAddress, parsePrice } from "../utils";
import LocationOnIcon from '@material-ui/icons/LocationOnRounded';
import { DoubleArrowRounded } from "@material-ui/icons";

interface ItemProps {
    translation: Translation,
    populatedSlot: PopulatedTimeslot,
    expertPicture: JSX.Element | null,
    numberOfTitles: number,
    openReservationModal: (timeslot: PopulatedTimeslot) => void,
    openExpertModal: (timeslot: PopulatedTimeslot, services?: CampaignService[]) => void,
    startTime: string,
    language: Language,
    id: string,
    services?: CampaignService[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            paddingBottom: theme.spacing(1)
        },
        button: {
            backgroundColor: theme.palette.success.main,
            "&:hover": {
                backgroundColor: theme.palette.success.dark
            },
            color: theme.palette.common.white
        },
        iconContainer: {
            display: "flex"
        },
        distance: {
            color: theme.palette.primary.main,
            textTransform: "none"
        },
        iconDist: {
            color: theme.palette.primary.main
        },
        icon: {
            color: "#B3B3B3"
        },
        linkButton: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: "1em",
            background: "none!important",
            border: "none",
            padding: "0!important",
            color: theme.palette.primary.main,
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                fontSize: "0.9em",
                marginTop: theme.typography.pxToRem(3)
            }
        },
        span: {
            height: "max-content",
            backgroundColor: "#DAEDF7",
            borderRadius: "4px",
            maxWidth: "230px",
            display: "inline",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
        name: {
            paddingTop: theme.typography.pxToRem(2),
            textDecoration: "none",
            textTransform: "none"
        },
        price: {
            paddingBottom: theme.spacing(2)
        }

    })
);

const ListItemMobile: React.FC<ItemProps> = props => {
    const classes = useStyles();
    const {
        populatedSlot,
        translation,
        expertPicture,
        numberOfTitles,
        openExpertModal,
        openReservationModal,
        startTime,
        id,
        services
    } = props;
    return (
        <Grid container item xs={12}>
            <Grid item container xs={12} className={classes.grid}>
                <Grid item xs={3}>
                    {expertPicture}
                </Grid>
                <Grid item xs={9}>
                    <div>
                        <Typography
                            display="inline"
                            variant="button"
                            component="h3"
                        >{startTime}
                        </Typography>
                        <Typography
                            display="inline"
                            variant="body1"
                        >
                            ({getLengthOfAppointment(populatedSlot)}min)
                        </Typography>
                    </div>
                    {populatedSlot.työntekijä.data.Nimi &&
                        <button
                            className={classes.linkButton}
                            onClick={() => openExpertModal(populatedSlot, services)}
                        >
                            <Typography
                                variant="button"
                                component="p"
                                color="primary"
                                className={classes.name}
                            >
                                {populatedSlot.työntekijä.data.Nimi}
                            </Typography>
                            <DoubleArrowRounded />
                        </button>
                    }
                    <div>
                        <Typography variant="body1">
                            {getExpertTitles(
                                populatedSlot.työntekijä.data,
                                numberOfTitles
                            )}
                        </Typography>
                    </div>
                    <div className={classes.span}>
                        <Typography display="inline" variant="body1" component="span">
                            {populatedSlot.palvelunNimi}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.grid}>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9}>
                    {populatedSlot.dist && <div className={classes.iconContainer}>
                        <LocationOnIcon
                            className={classes.iconDist}
                        />
                        <Typography className={classes.distance} variant="button" component="span">
                            {`${translation.distance} ${roundToOneDecimal(populatedSlot.dist)} km ${translation.fromLocation}`}
                        </Typography>
                    </div>
                    }
                    <Typography variant="body1">
                        {
                            populatedSlot.toimitila.katuosoite ?
                                getAddress(populatedSlot.toimitila.katuosoite) :
                                ""
                        }
                    </Typography>
                    <Typography variant="body1">
                        {
                            getKunta(populatedSlot.toimitila.katuosoite ?
                                populatedSlot.toimitila.katuosoite :
                                ""
                            )
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                    {populatedSlot.hinta &&
                        <div className={classes.price}>
                            <Typography
                                variant="h6"
                                component="span"
                                color="primary"
                            >
                                {translation.price} {parsePrice(populatedSlot.hinta)}€
                        </Typography>
                        </div>}
                    <Button
                        id={`button-${id}`}
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        onClick={() => openReservationModal(populatedSlot)}
                    >
                        {translation.reserveTimeslot}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListItemMobile;
