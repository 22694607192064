import { EntityResponse, Expert } from "../../types";
import { makeStyles, Theme, createStyles, Typography, CardMedia } from "@material-ui/core";
import React from "react";
import { getExpertTitles } from "../utils";
import { pictureURL, PictureSizes } from "../../settings";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pictureGridComponent: {
            display: "flex"
        },
        expertNameContainer: {
            paddingLeft: theme.spacing(3)
        },
        cardMediaLarge: {
            minHeight: theme.typography.pxToRem(103),
            minWidth: theme.typography.pxToRem(103),
            borderRadius: "1px",
        },
        cardMediaSmall: {
            minHeight: theme.typography.pxToRem(70),
            minWidth: theme.typography.pxToRem(70),
            borderRadius: "1px",
        },
        blue: {
            color: theme.palette.text.secondary
        }
    }));

interface IExpertPictureNameAndTitle {
    expert: EntityResponse<Expert>,
    size?: "small" | "medium"
}

export const ExpertPictureNameAndTitle: React.FC<IExpertPictureNameAndTitle> = (props) => {
    const { expert, size } = props;

    const classes = useStyles();

    return (
        <div className={classes.pictureGridComponent}>

            <CardMedia
                image={`${pictureURL}/${expert.uuid}.${PictureSizes["250x250"]}`}
                className={
                    size !== "small" ?
                        classes.cardMediaLarge :
                        classes.cardMediaSmall
                }
                title={"Expert picture"}
            />
            <div className={classes.expertNameContainer}>
                <Typography
                    variant={
                        size !== "small" ?
                            "h6" :
                            "subtitle1"
                    }
                    className={
                        size !== "small" ?
                            undefined :
                            classes.blue
                    }
                    id={"expertNameHeader"}
                >
                    {
                        size !== "small" ?
                            expert.data.Nimi?.toUpperCase() :
                            expert.data.Nimi
                    }
                </Typography>
                <Typography>
                    {getExpertTitles(expert.data)}
                </Typography>
            </div>
        </div>
    );
};
