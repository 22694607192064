import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Theme, createStyles, makeStyles, Typography, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { Expert, EntityResponse, Language, PopulatedTimeslot, Speciality, Premise, CampaignService } from '../../types';
import { ServiceContext } from '../ServiceContext';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { getUuid, getReservationTimeButtonText, allSettled } from '../utils';
import { ItemList } from './ItemList';
import { PremiseView } from './PremiseView';
import { DefaultButton, SuccessButton } from '../CustomStyleComponents/Buttons';
import NavigateNextIcon from '@material-ui/icons/NavigateNextRounded';
import { from } from 'rxjs';
import { distinctUntilChanged, concatMap } from 'rxjs/operators';
import { languageStore, specialityStore } from '../../store/stores';
import { ExpertPictureNameAndTitle } from './ExpertPictureNameAndTitle';
import { StyledLink } from '../Links';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        headerColour: {
            color: theme.palette.text.secondary
        },
        textPadding: {
            paddingBottom: "10px"
        }
    }));

interface ExpertProfileProps {
    expert: EntityResponse<Expert>,
    timeslot?: PopulatedTimeslot,
    services?: CampaignService[],
    onClose: () => void,
    openReservation?: () => void
}

export const ExpertProfile: React.FC<ExpertProfileProps> = ({
    expert, onClose, openReservation, timeslot, services
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const { translation } = useContext(LocalizationContext);
    const { dataService } = useContext(ServiceContext);

    const [storeLanguages, setStoreLanguages] = useState<EntityResponse<Language>[]>([]);
    const [storeSpecialities, setStoreSpecialities] = useState<EntityResponse<Speciality>[]>([]);
    const [premises, setPremises] = useState<EntityResponse<Premise>[]>([]);
    const showSingleLink = useMemo(() =>
        premises.length >= 1 && premises[0].data.Verkkosivu !== undefined &&
        premises.every(premise => premise.data.Verkkosivu === premises[0].data.Verkkosivu),
        [premises]
    );

    const url = useMemo(() =>
        (showSingleLink ||
            (premises.length === 1 && premises[0].data.Verkkosivu !== undefined)
        )
        && premises[0].data.Verkkosivu
        , [showSingleLink, premises]);

    const expertSpecialityUuids = useMemo(() => {
        return expert.data.palvelu?.reduce((acc, item) => {
            return item.erityisosaaminen ?
                acc.concat(item.erityisosaaminen) :
                acc;
        }, [] as string[]);
    }, [expert]);

    const languages = useMemo(() =>
        storeLanguages.filter(lang => expert.data.kieli?.includes(lang.uuid))
        , [expert.data.kieli, storeLanguages]);

    const expertServices = useMemo(() =>
        services?.filter(service =>
            expert.data.palvelu?.map(getUuid)
                .includes(service.uuid))
        , [expert.data.palvelu, services]);

    const specialities = useMemo(() =>
        storeSpecialities
            .filter(speciality => expertSpecialityUuids?.includes(speciality.uuid))
        , [expert.data.palvelu, storeSpecialities]);

    useEffect(() => {
        if (dataService && expert.data.kieli) {
            const langSub = from(expert.data.kieli).pipe(
                distinctUntilChanged(),
                concatMap(uuid => dataService.requestLanguage(uuid)
                    .catch(_err => { })
                )
            ).subscribe();

            const specialitySub = from(expertSpecialityUuids).pipe(
                distinctUntilChanged(),
                concatMap(specialityUuid => dataService.requestSpeciality(specialityUuid)
                    .catch(_err => { })
                )
            ).subscribe();

            return () => {
                langSub?.unsubscribe();
                specialitySub.unsubscribe();
            };
        }
    }, [dataService, expert.data.kieli]);

    useEffect(() => {
        if (dataService) {
            const fetchPremises = async () => {
                const premisePromises = await allSettled(
                    expert.data.toimipaikka ? expert.data.toimipaikka.map(premise =>
                        dataService?.requestPremise(premise.uuid)
                    ) : [Promise.reject()]
                );
                const premises = premisePromises
                    .filter(promise => promise.status === "fulfilled")
                    .map(promise => promise.value);
                setPremises(premises);
            };
            fetchPremises();
        }
    }, [dataService]);

    useEffect(() => {
        const languageSub = languageStore.subject.subscribe(setStoreLanguages);
        const specialitySub = specialityStore.subject.subscribe(setStoreSpecialities);

        return () => {
            languageSub.unsubscribe();
            specialitySub.unsubscribe();
        };
    }, []);

    const closeButton = (
        <Grid item md={6} xs={12}>
            <Grid container>
                <Grid item md={10} xs={12}>
                    <DefaultButton
                        id={"expertProfileCloseButton"}
                        fullWidth
                        onClick={onClose}
                    >
                        {translation.closeOrMoveBack}
                    </DefaultButton>
                </Grid>
            </Grid>
        </Grid>
    );

    const openReservationButton = (
        openReservation &&
        <Grid item md={6} xs={12}>
            <Grid
                id={"expertProfileOpenReservationForm"}
                container
                style={{ display: "flex", flexDirection: "row-reverse" }}
            >
                <Grid item md={10} xs={12}>
                    <SuccessButton
                        fullWidth
                        endIcon={<NavigateNextIcon />}
                        onClick={() => {
                            onClose();
                            openReservation();
                        }}
                    >
                        {getReservationTimeButtonText(timeslot?.start, translation)}
                    </SuccessButton>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid
            container
            spacing={3}
            className={classes.paper}
        >
            <Grid
                item
                xs={12}
                md={12}
            >
                <ExpertPictureNameAndTitle
                    expert={expert}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography>
                    {expert.data.Kuvaus}
                </Typography>
            </Grid>
            {
                expertServices && expertServices.length > 0 &&
                <Grid item xs={12} md={4}>
                    <Typography
                        variant={"h6"}
                        className={classes.headerColour}
                    >
                        {translation.services}
                    </Typography>
                    <ItemList
                        items={
                            expertServices.map(service => ({
                                uuid: service.uuid,
                                name: service.name
                            }))
                        }
                    />
                </Grid>
            }
            {
                specialities.length > 0 &&
                <Grid item xs={12} md={4}>
                    <Typography
                        variant={"h6"}
                        className={classes.headerColour}
                    >
                        {translation.speciality}
                    </Typography>
                    <ItemList
                        items={
                            specialities.map(speciality => ({
                                uuid: speciality.uuid,
                                name: speciality.data.erityisosaaminen
                            }))
                        }
                    />
                </Grid>
            }
            {
                languages.length > 0 &&
                <Grid item xs={12} md={4}>
                    <Typography
                        variant={"h6"}
                        className={classes.headerColour}
                    >
                        {translation.languageSkills}
                    </Typography>
                    <ItemList
                        items={
                            languages.map(language => ({
                                uuid: language.uuid,
                                name: language.data.kieli
                            }))
                        }
                    />
                </Grid>
            }
            {
                expert.data.Koulutus &&
                expert.data.Koulutus?.length > 0 &&
                <Grid item xs={12} md={12}>
                    <Typography
                        variant={"h6"}
                        className={classes.headerColour}
                    >
                        {translation.education}
                    </Typography>
                    {expert.data.Koulutus.split("\n")
                        .filter(koulutus => koulutus.trim() !== "")
                        .map((koulutus, index) =>
                            <Typography
                                key={koulutus + index}
                            >
                                {koulutus}
                            </Typography>

                        )
                    }
                </Grid>
            }
            {
                expert.data.toimipaikka?.length > 0 && premises.length > 0 &&
                <Grid item xs={12} md={12}>
                    <Typography
                        variant={"h6"}
                        className={classes.headerColour}
                    >
                        {translation.expertsPremises}
                    </Typography>
                    <Grid container>
                        {premises.map(premise => (
                            <Grid
                                item
                                xs={12}
                                key={premise.uuid}
                                className={classes.textPadding}
                            >
                                <PremiseView
                                    premise={premise}
                                    renderUrl={!showSingleLink}
                                />
                            </Grid>
                        ))}
                        {url &&
                            <Grid
                                item
                                xs={12}
                                className={classes.textPadding}
                            >
                                <Typography
                                    variant="h6"
                                    className={classes.headerColour}
                                >
                                    {translation.listOfPrices}
                                </Typography>
                                <Typography>
                                    {`${translation.listOfPricesDisclaimer} `}
                                    <StyledLink
                                        text={translation.listOfPricesLink}
                                        link={url.startsWith("https") ?
                                            url :
                                            "https://" + url}
                                    />
                                .</Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }

            {
                windowIsMdSize ?  //buttons should be in different order in differently sized windows
                    closeButton :
                    openReservationButton
            }

            {
                windowIsMdSize ?
                    openReservationButton :
                    closeButton
            }

        </Grid >
    );
};
