import { PopulatedTimeslot } from "../types";
import TagManager from "react-gtm-module";
import { getAddress, getKunta } from "../components/utils";


enum EventName {
    successfulReservation = "toteutunut-ajanvaraus",
    cancelledReservation = "peruutettu-ajanvaraus",
    discountCode = "tarjouskampanja"
}

export const gaEventSuccessfulReservation = (
    timeslot: PopulatedTimeslot,
    discountCode: string | null,
    category?: string
) => {
    TagManager.dataLayer(
        {
            dataLayer: {
                event: EventName.successfulReservation,
                kategoria: category ? category?.charAt(0).toUpperCase() + category?.slice(1) : "",
                asiantuntija: timeslot.työntekijä.data.Nimi,
                palvelu: timeslot.palvelunNimi,
                katuosoite: getAddress(timeslot.toimitila.katuosoite ?
                    timeslot.toimitila.katuosoite :
                    ""
                ),
                paikkakunta: getKunta(timeslot.toimitila.katuosoite ?
                    timeslot.toimitila.katuosoite :
                    ""
                ),
                tarjouskoodi: discountCode
            }
        }
    );
};

export const gaEventCancelledReservation = (success: boolean) => {
    TagManager.dataLayer({
        dataLayer: {
            event: EventName.cancelledReservation,
            cancellationSuccess: success
        }
    });
};
