import React, { useContext, useEffect, useState, useMemo, Dispatch, SetStateAction } from 'react';
import MultiFilter, { StyledChip } from '../../MultiFilter';
import { LocalizationContext } from '../../LanguageContext/LocalizationContext';
import { BehaviorSubject, from } from 'rxjs';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';
import { ServiceContext } from '../../ServiceContext';
import { languageStore } from '../../../store/stores';
import { EntityResponse, Language, SelectedFilters } from '../../../types';
import { AutocompleteGetTagProps } from '@material-ui/lab';

interface LanguageFilterProps {
    languageFilters: string[],
    selectedLanguages: EntityResponse<Language>[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    loadingInitialState: boolean
}

const filterSubject = new BehaviorSubject<string[]>([]);

const LanguageFilter: React.FC<LanguageFilterProps> = props => {
    const {
        languageFilters,
        setSelectedFilters,
        selectedLanguages,
        loadingInitialState
    } = props;
    const [languageUuids, setLanguageUuids] = useState<string[]>([]);
    const [languages, setLanguages] = useState<EntityResponse<Language>[]>([]);
    const { translation } = useContext(LocalizationContext);
    const { dataService } = useContext(ServiceContext);

    const onSelect = (_e: any, value: EntityResponse<Language>[]) => {
        setSelectedFilters((prevState: SelectedFilters) => ({
            ...prevState,
            selectedLanguages: value
        }));
    };

    useEffect(() => {
        if (dataService && languageFilters.length !== 0 && !loadingInitialState) {
            filterSubject.next(languageFilters);
            const sub = filterSubject.pipe(
                distinctUntilChanged(),
                concatMap(filters => dataService.requestLanguages(filters)),
                map(resp => resp.data.kieli)
            ).subscribe(setLanguageUuids);
            return () => sub.unsubscribe();
        }
    }, [dataService, languageFilters, loadingInitialState]);

    useEffect(() => {
        if (dataService) {
            const sub = from(languageUuids).pipe(
                distinctUntilChanged(),
                concatMap(uuid => dataService.requestLanguage(uuid)
                    .catch(_err => { })
                )
            ).subscribe();
            return () => sub.unsubscribe();
        }
    }, [dataService, languageUuids]);

    useEffect(() => {
        const sub = languageStore.subject.subscribe(setLanguages);
        return () => sub.unsubscribe();
    }, []);

    const renderTags = (
        value: EntityResponse<Language>[],
        getTagProps: AutocompleteGetTagProps
    ) => {
        return value.map((option: any, index: number) => (
            <StyledChip
                label={option.data?.kieli} {...getTagProps({ index })}
            />
        ));
    };

    const filteredLanguages = useMemo(() =>
        languages.filter(language => languageUuids.includes(language.uuid)),
        [languages, languageUuids]
    );

    return (
        <MultiFilter
            id={"languageFilter"}
            options={filteredLanguages}
            getOptionLabel={language => language.data.kieli}
            getOptionSelected={(option, value) => option.uuid === value.uuid}
            renderTags={renderTags}
            label={translation.expertLanguage}
            placeholder={translation.choose}
            onChange={onSelect}
            value={selectedLanguages}
        />
    );
};

export default LanguageFilter;
