import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import TagManager from "react-gtm-module";
import AspaApp from './AspaApp';

const hasGTMScript = document.head.innerHTML.includes("Google Tag Manager");

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID ?
        process.env.REACT_APP_GTM_ID :
        "GTM-MD8D7S7"
};

if (!hasGTMScript)
    TagManager.initialize(tagManagerArgs);

ReactDOM.render(<AspaApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
