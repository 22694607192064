import * as ics from "ics";
import { Translation, Language } from "./LanguageContext/types";
import { PopulatedTimeslot, Building, Expert, TranslationObj, EntityResponse, CampaignService, Premise, SelectedFilters } from "../types";
import moment, { Moment } from "moment";
import { defaultLanguage, mapBoxAPI } from "../settings";
import { Dispatch, SetStateAction } from "react";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import toothclinicListOrder from "../toothclinicListOrder";
import { ITimes, TimeOfDayFilter } from "./Filters/TimeOfDay";

// eslint-disable-next-line max-len
const regex = /^\s*([\w\s-.åäöáàéèíìóòúùüšÅÄÖÁÀÉÈÍÌÓÒÚÙÜŠ(),\d]*?)[\s,]+(?:FI-)?(\d{4}0)[\s,]+([\w-åäöáàéèíìóòúùüšÅÄÖÁÀÉÈÍÌÓÒÚÙÜŠ\s]+?)\s*$/;

export const createFunctionCallerWithTimeout = (waitMs: number) => {
    let timestamp = Date.now();

    const triggerAfterCountdownIfNotAborted = (functionToCall: () => void): void => {
        const timestampWhenCalled = timestamp;
        setTimeout(() => {
            if (timestamp === timestampWhenCalled)
                functionToCall();
        }, waitMs);
    };

    const abortEarlierFunctions = (): void => {
        timestamp = Date.now();
    };

    return {
        triggerAfterCountdownIfNotAborted,
        abortEarlierFunctions
    };
};

export const getExpertTitles = (expert: Expert, numberOfTitles?: number | null) => {
    const expertTitles = expert.yritys?.map(yritys => yritys.työtehtävä);
    const filteredExpertTitles = expertTitles?.filter(
        (title, index) => expertTitles.indexOf(title) === index && title !== ""
    );
    const sliced = filteredExpertTitles?.slice(0,
        numberOfTitles !== null && numberOfTitles !== undefined ?
            numberOfTitles :
            filteredExpertTitles.length
    ).join(", ");

    return sliced;
};

export const getKunta = (markkinointinimi: string) => {
    //Markkinointinimet seuraavat muutamaa poikkeusta lukuunottamatta seuraavaa kaavaa:
    //Kelantie 4, 93600, Kuusamo
    const str = markkinointinimi?.trim();
    return str.split(regex)[3];
};

export const getAddress = (katuosoite: string) => {
    return katuosoite.trim().split(regex)[1];
};

export const getPostalCode = (katuosoite: string) => {
    return katuosoite.trim().split(regex)[2];
};

const getICSDateArray = (date: Date): ics.DateArray => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; //getMonth indexes months from 0
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return [year, month, day, hours, minutes];
};

export const createICS = (
    start: Date,
    end: Date,
    title: string,
    description: string,
    location: string,
    url: string,
    organizer: {
        name: string,
        email: string
    }
) => {
    const event: ics.EventAttributes = {
        start: getICSDateArray(start),
        end: getICSDateArray(end),
        title: title,
        description: description,
        location: location,
        url: url,
        status: 'CONFIRMED',
        organizer: organizer
    };

    return ics.createEvent(event).value;
};

export const getWeekdayTranslation = (date: Moment, translation: Translation) => {
    const translations = [
        translation.sunday,
        translation.monday,
        translation.tuesday,
        translation.wednesday,
        translation.thursday,
        translation.friday,
        translation.saturday
    ];

    const index = date.day();
    return translations[index];
};

export const isBetweenDates = (currentDate: string, from?: string, to?: string) =>
    (!from || moment(currentDate).isSameOrAfter(from)) &&
    (!to || moment(currentDate).isSameOrBefore(to));

export const sortStr = (a?: string, b?: string) => {
    if (!a || !b) {
        return !a && !b ?
            0 :
            !b ?
                -1 : 1;
    }

    const aLabel = a.toLowerCase();
    const bLabel = b.toLowerCase();

    return aLabel < bLabel ?
        -1 : bLabel < aLabel ?
            1 : 0;
};

export const sortByDateOrderAndName = (a: CampaignService, b: CampaignService) => {
    const aIndex = toothclinicListOrder.findIndex(el => el.uuid === a.uuid) !== -1
        ? toothclinicListOrder.findIndex(el => el.uuid === a.uuid)
        : toothclinicListOrder.length;
    const bIndex = toothclinicListOrder.findIndex(el => el.uuid === b.uuid) !== -1
        ? toothclinicListOrder.findIndex(el => el.uuid === b.uuid)
        : toothclinicListOrder.length;
    const aIsPopular = a.popular ? 1 : 0;
    const bIsPopular = b.popular ? 1 : 0;
    const compareDate = (a?: string, b?: string) => a && b ? compare(a, b) : !a && b ? 1 : -1;
    return compare(
        [
            compareDate(a.päättymispäivä, b.päättymispäivä),
            -compare(aIsPopular, bIsPopular),
            compare(aIndex, bIndex),
            compare(a.name, b.name)
        ],
        [
            compareDate(b.päättymispäivä, a.päättymispäivä),
            -compare(bIsPopular, aIsPopular),
            compare(bIndex, aIndex),
            compare(b.name, a.name)
        ]
    );
};

const compare = (a: string | number | number[], b: string | number | number[]) => {
    return a > b ? 1 : a < b ? -1 : 0;
};

export const getPrettyDate = (date: Moment, translation: Translation) => {
    const dayOfMonth = date.date();
    const month = date.month() + 1;
    const year = date.year();

    return `${getWeekdayTranslation(date, translation)} ${dayOfMonth}.${month}.${year}`;
};

export const getCurrentPosition = (): Promise<GeolocationPosition> =>
    new Promise((resolve, reject) => {
        const options = { enableHighAccuracy: true };
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

export const getTime = (date: string) => (
    new Date(date).toLocaleTimeString("en-US",
        {
            timeZone: "Europe/Helsinki",
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        })
);

const getFirstNonEmptyOrEmpty = (
    obj: TranslationObj,
): string => {
    const values = Object.values(obj);
    const result = values.find((str) => str && str.length !== 0);
    return result ?
        result :
        "";
};

const getDefaultOrFirstAvailableTranslation = (
    obj: TranslationObj
): string => {
    const defaultResult = obj[defaultLanguage.apiKey];
    return defaultResult && defaultResult.length !== 0 ?
        defaultResult :
        getFirstNonEmptyOrEmpty(obj);
};

export const getTranslation = (
    obj: TranslationObj | undefined | null,
    translation: Language
): string => {
    if (obj === undefined || obj === null) return "";

    const options = {
        [Language.fi]: obj.suomeksi,
        [Language.se]: obj.ruotsiksi,
        [Language.en]: undefined
    };

    const translationStr = options[translation];

    return translationStr !== undefined && translationStr.length !== 0 ?
        translationStr :
        getDefaultOrFirstAvailableTranslation(obj);
};

export const getStrOrEmpty = (str: string | undefined | null): string =>
    str !== undefined && str !== null ?
        str :
        "";

export const isEmptyOrNull = (str: string | undefined | null) =>
    str === undefined || str === null || str.length === 0;

export const getMapRadius = (latitude: number) => {
    const oulu = { lat: 65.012301, lon: 25.465044 };
    const tampere = { lat: 61.497042, lon: 23.762511 };
    const helsinki = { lat: 60.185062, lon: 24.936267 };
    return latitude < oulu.lat && latitude >= tampere.lat ?
        100 :
        latitude <= helsinki.lat ||
            (latitude < tampere.lat && latitude > helsinki.lat) ?
            50 : 200;
};

export const createPdf = (
    translation: Translation,
    timeslot: PopulatedTimeslot,
    serviceName: string,
    building: Building,
    premise: EntityResponse<Premise>,
) => async (_e: any) => {
    const pdfMake = await import("pdfmake/build/pdfmake");
    const pdfFonts = await import("pdfmake/build/vfs_fonts");
    pdfMake.default.vfs = pdfFonts.default.pdfMake.vfs;
    const pdfContent = {
        content: [
            {
                text: `${translation.reservationService}`,
                style: "header",
            },
            {
                columns: [
                    [
                        {
                            text: `${getPrettyDate(moment.utc(timeslot.start).local(), translation)}
                            ${translation.clock} ${getTime(timeslot.start)}`
                        },
                        {
                            text: `${serviceName} ${getLengthOfAppointment(timeslot)} min`
                        },
                        {
                            text: `${timeslot.toimipaikka.Nimi.liiketoiminta} ${timeslot.toimipaikka.Nimi.toimitila}`
                        },
                        {
                            text: getAddress(building.katuosoite ? building.katuosoite : "")
                        },
                        {
                            text: getStrOrEmpty(building.Postinumero)
                        },
                        {
                            text: premise.data.Ajanvaraus_Puhelin
                        }
                    ],
                    [
                        { text: timeslot.työntekijä.data.Nimi },
                        { text: timeslot.työntekijä.data.Koulutus }
                    ]
                ]
            },

        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                lineHeight: 1.6,
                alignment: "center"
            },
        },
        defaultStyle: {
            columnGap: 20,
            alignment: "left"
        }
    } as TDocumentDefinitions;
    pdfMake.default.createPdf(
        pdfContent
    ).open();
};

export const isValidPhoneNr = (phoneNr: string) =>
    RegExp(/^\+?[0-9- ]+$/).test(phoneNr);

export const isNullOrUndefined = (value: any) =>
    value === undefined || value === null;

export const datesAreEqual = (date1: Date | string | null, date2: Date | string | null) =>
    (date1 === null || date2 === null) ?
        date1 === date2 :
        moment(date1).format("YYYY-MM-DD") === moment(date2).format("YYYY-MM-DD");

export const getLengthOfAppointment = (timeslot: PopulatedTimeslot): number => {
    const start = moment(timeslot.start).valueOf();
    const end = moment(timeslot.end).valueOf();
    const milliseconds = end - start;
    const minutes = milliseconds / 1000 / 60;

    return minutes;
};

export const getUuid = (item: { uuid: string }) => item.uuid;

export const roundToOneDecimal = (value: number) => Math.round(value * 10) / 10;

export const getReservationTimeButtonText = (
    date: string | undefined,
    translation: Translation
): string => {
    const parsedDate = moment.utc(date).local();

    return parsedDate.isValid() && date !== undefined ? //If date is undefined, moment will use current date, which should not be used
        parsedDate.format(`[${translation.reserveTimeslot}] D.M.YYYY [${translation.oclock}] `) + getTime(date) :
        translation.reserveTimeslot;
};
export const getAddressAndSetLocation = async (
    lon: number,
    lat: number,
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    accessToken: string,
) =>
    fetch(`${mapBoxAPI}${lon},${lat}.json?types=address&access_token=${accessToken}&country=FI`)
        .then(resp => resp.json())
        .then(({ features }: GeoJSON.FeatureCollection) =>
            setSelectedFilters(prevState => ({
                ...prevState,
                userLocation: features.length !== 0 ?
                    {
                        address: (features[0] as any).place_name.replace(", Finland", ""),
                        coordinates: {
                            lat: (features[0].geometry as any).coordinates[1],
                            lon: (features[0].geometry as any).coordinates[0]
                        },
                    } :
                    {
                        address: "",
                        coordinates: {
                            lat,
                            lon
                        }
                    }
            }))
        );

export const getCoordinatesFromAddress = async (address: string, accessToken: string) =>
    fetch(`${mapBoxAPI}${address}.json?types=address&access_token=${accessToken}&limit=5&country=FI`)
        .then(resp => resp.json());

export const removeDuplicateLocations = (locations: EntityResponse<Building>[]) =>
    locations?.reduce((acc, cur) => {
        const location = acc.find(loc => cur.data.location && loc.data.location &&
            loc.data.location.lon === cur.data.location.lon &&
            loc.data.location.lat === cur.data.location.lat);
        return !location && cur.data.location ?
            [...acc, cur] :
            acc;
    }, [] as EntityResponse<Building>[]);
export const createTimeSorter =
    (a: PopulatedTimeslot, b: PopulatedTimeslot): -1 | 0 | 1 | number =>
        a.start === b.start ?
            createDistanceSorter(a, b) :
            a.start.localeCompare(b.start);

export const createDistanceSorter =
    (a: PopulatedTimeslot, b: PopulatedTimeslot) => {
        const aDist = a.dist;

        const bDist = b.dist;

        const distanceEqualHandler = (a: PopulatedTimeslot, b: PopulatedTimeslot) =>
            a.start !== b.start ? //cancel endless loop if both distance and time are equal
                createTimeSorter(a, b) :
                0;

        const calculatedUndefinedResponse = (
            aDist: number | undefined | null,
            bDist: number | undefined | null
        ) =>
            aDist === bDist ?
                distanceEqualHandler(a, b) :
                isNullOrUndefined(aDist) ?
                    1 :
                    -1;

        const calculateOrder = (aDist: number, bDist: number) => {
            return aDist === bDist ?
                distanceEqualHandler(a, b) :
                aDist < bDist ?
                    -1 : 1;
        };

        return aDist === null || aDist === undefined || bDist === null || bDist === undefined ?
            calculatedUndefinedResponse(aDist, bDist) :
            calculateOrder(aDist, bDist);
    };

export const needsComma = (predicate: boolean) =>
    predicate ? "," : "";


export const flattenService = (service: CampaignService): CampaignService[] => {
    const children = service.children;
    const {
        uuid,
        name,
        Kuvaus,
        päättymispäivä,
        alkamispäivä,
        maksutapa
    } = service;
    return children ?
        ([{
            uuid,
            name,
            Kuvaus,
            alkamispäivä,
            päättymispäivä,
            maksutapa
        }] as CampaignService[]).concat(flattenServices(children)) :
        [service];
};

export const flattenServices = (services: CampaignService[]): CampaignService[] =>
    services.map(flattenService).flat();


export const allSettled = <T>(promises: Promise<T>[]) =>
    Promise.all(
        promises.map((promise) =>
            promise
                .then(value => ({
                    status: "fulfilled",
                    value,
                }))
                .catch(reason => ({
                    status: "rejected",
                    value: reason,
                }))
        )
    );

export const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

export const getNextDay = (date: Date) =>
    new Date(date.getTime() + oneDayInMilliseconds);

export const getPrevDay = (date: Date) =>
    new Date(date.getTime() - oneDayInMilliseconds);

export const isFutureDate = (date: Date): boolean => {
    const tomorrow = getNextDay(new Date(Date.now()));
    const tomorrowMidnight = new Date(tomorrow.setHours(0, 0, 0, 0));
    const tomorrowMidnightMillis = tomorrowMidnight.getTime();
    return tomorrowMidnightMillis <= date.getTime();
};

export const scrollToTop = () =>
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });

export const onlyDate = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const parseTime = (
    year: number,
    month: number,
    day: number,
    hour: number,
    minutes: number
) => moment(
    `${year}-${month}-${day}-${hour}-${minutes}`,
    "YYYY-MM-DD-hh-mm"
);

export const createTimeframe = (
    id: number,
    date: Date,
    start: ITimes,
    end: ITimes
): TimeOfDayFilter => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const startDate = parseTime(year, month, day, start.hr, start.minutes);
    const endDate = parseTime(year, month, day, end.hr, end.minutes);

    return {
        id,
        timeframe: {
            from: startDate.toDate(),
            to: endDate.toDate()
        }
    };
};

export const createDefaultTimeFrame = (date: Date) => {
    return createTimeframe(0, date, { hr: 0, minutes: 0 }, { hr: 23, minutes: 59 });
};
export const createTimeframes = (date: Date): { [index: string]: TimeOfDayFilter } => {
    const all = createDefaultTimeFrame(date);
    const morning = createTimeframe(1, date, { hr: 6, minutes: 0 }, { hr: 12, minutes: 0 });
    const midday = createTimeframe(2, date, { hr: 11, minutes: 0 }, { hr: 18, minutes: 0 });
    const evening = createTimeframe(3, date, { hr: 17, minutes: 0 }, { hr: 23, minutes: 59 });

    return {
        all,
        morning,
        midday,
        evening
    };
};

export const createTimeframesMobile = (date: Date) => {
    const all = createDefaultTimeFrame(date);
    const morning = createTimeframe(1, date, { hr: 6, minutes: 0 }, { hr: 12, minutes: 0 });
    const midday = createTimeframe(2, date, { hr: 11, minutes: 0 }, { hr: 18, minutes: 0 });
    const evening = createTimeframe(3, date, { hr: 17, minutes: 0 }, { hr: 23, minutes: 59 });

    return [
        all,
        morning,
        midday,
        evening
    ];
};

export const recursiveFind = (
    array: any,
    key: string,
    valueToFind: any,
    nestedObjectKey: string
): any => {
    let itemFound;
    for (let i = 0; i < array.length; i++) {
        if (!itemFound) {
            const item = array[i];
            itemFound = item[key] === valueToFind
                ? item
                : item[nestedObjectKey]
                && recursiveFind(item[nestedObjectKey], key, valueToFind, nestedObjectKey);
        }
        else
            break;
    }
    return itemFound;
};

export const parsePrice = (price: string | number) =>
    typeof price === "number"
        ? price.toFixed(2).replace(".", ",")
        : parseFloat(price).toFixed(2).replace(".", ",");
