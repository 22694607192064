import React from 'react';
import ServiceMenuIcon from "./ServiceMenuButton";
import { Grid, makeStyles, createStyles, Theme } from "@material-ui/core";
import Rehab from "../icons/Rehab";
import campaignsJSON from "./../services.json";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: "1600px",
            margin: "auto",
            alignItems: "flex-start",
            alignContent: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            padding: theme.spacing(1),
            paddingBottom: 0,
            backgroundColor: theme.palette.background.paper
        },
        icon: {
            width: "24px",
            height: "24px"
        },
        iconGrid: {
            height: 80,
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-around"
        }
    })
);

interface IServiceMenuProps {
    selectedUuid: string | null
}

const ServiceMenu: React.FC<IServiceMenuProps> = (props) => {
    const { selectedUuid } = props;
    const classes = useStyles();

    const createIcon = (service: {
        id: string,
        name: string,
        mainCategoryUuid: string,
        subServices: { id: string }[]
    }) => ({
        icon:
            <Rehab
                color="primary"
                className={classes.icon}
            />,
        label: service.name.toUpperCase(),
        uuid: service.subServices.map(service => service.id).join(";")
    });

    const icons = campaignsJSON.map(createIcon);

    const menuIcons = icons.map(icon =>
        <Grid item md xs={4} key={icon.label}>
            <ServiceMenuIcon
                icon={icon.icon}
                label={icon.label}
                active={icon.uuid === selectedUuid}
                key={icon.label}
                uuid={icon.uuid}
            />
        </Grid>
    );

    return (
        <Grid container className={classes.root}>
            {menuIcons}
        </Grid>
    );
};

export default ServiceMenu;
