import React, { FC, Fragment } from "react";
import { Typography } from "@material-ui/core";
import { ContentProps } from "./InfoDialog";

const Content: FC<ContentProps> = (props) => {
    const { translation } = props;

    return (
        <Fragment>
            <Typography
                component="span"
                paragraph
                display="block"
            >
                {translation.endoscopyCustomerTypeTooltip}
            </Typography>
        </Fragment>
    );
};

export default Content;
