import InformationView from "./InformationView";
import { useContext } from "react";
import { LocalizationContext } from "./LanguageContext/LocalizationContext";
import React from "react";
import LoadingIndicator from "./UtilComponents/LoadingIndicator";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.light,
            height: "150px"
        },
        header: {
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(24),
            lineHeight: theme.typography.pxToRem(29),
            letterSpacing: theme.typography.pxToRem(0.3),
            fontWeight: "bold"
        },
    })
);

const Loading: React.FC = (_props) => {
    const { translation } = useContext(LocalizationContext);
    const classes = useStyles();
    const header = <Typography className={classes.header}>
        {translation.loading.toUpperCase()}
    </Typography>;

    return (
        <InformationView
            header={header}
            icon={<LoadingIndicator className={classes.icon} />}
        />
    );
};

export default Loading;
