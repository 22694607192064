import { BehaviorSubject } from "rxjs";

export interface Timeslot {
    uuid: string,
    start: string,
    end: string,
    palvelu: string,
    available?: boolean,
    hinta?: number
}

export interface TimeslotRange {
    from: string,
    to: string
}

// interface Yritys {
//     // uuid: string,
//     // Nimi: string,
//     // role: boolean,
//     // työtehtävä: string
// }

interface Palvelu {
    uuid: string,
    erityisosaaminen?: string[]
}

export interface Expert {
    kieli: string[],
    palvelu: Palvelu[],
    // palvelualue: string[],
    yritys: { työtehtävä: string }[],
    toimipaikka: { uuid: string; role: boolean }[],
    Nimi: string,
    Kuvaus: string,
    // Puhelin: string,
    Koulutus: string,
    // näkyy_sisäisessä_verkossa: "0" | "1",
    // näkyy_julkisessa_verkossa: "0" | "1",
    erityisosaaminen?: string[]
}

export enum ApiTranslationKeys {
    suomeksi = "suomeksi",
    ruotsiksi = "ruotsiksi"
}

export interface TranslationObj {
    [ApiTranslationKeys.suomeksi]?: string,
    [ApiTranslationKeys.ruotsiksi]?: string
}

export interface Building {
    // Y_tunnus: string,
    Nimi: string,
    Markkinointinimi: string,
    Sijaintikiinteisto: string,
    // Alkamispaiva: string,
    // Päättymispäivä: string,
    katuosoite?: string,
    kadunnimi?: TranslationObj,
    Postinumero?: string,
    location?: LocationCoords,
    dist?: number
}

export interface Premise {
    // nearMe?: boolean,
    // yritys: string,
    toimitila: string,
    // kieli: string[],
    palvelu: { uuid: string }[],
    palvelualue: string[],
    // Nimi_sisäinen: string,
    Nimi: {
        liiketoiminta: string,
        yritys: string,
        toimipaikka: string,
        toimitila: string
    },
    Verkkosivu: string,
    // Kuvaus: string,
    Ajo_ohjeet: string,
    Pysäköintiohjeet: string,
    Esteettömyystiedot: string,
    Ajanvaraus_Kuvaus: string,
    Ajanvaraus_Puhelin: string,
    // Ajanvaraus_Sähköposti: string,
    // Ajanvaraus_Verkkosivu: string,
    // Asiakaspalvelu_Kuvaus: string,
    // Asiakaspalvelu_Puhelin: string,
    // Asiakaspalvelu_Sähköposti: string,
    // Asiakaspalvelu_Verkkosivu: string,
    // Faksi_Kuvaus: string,
    // Faksi_Numero: string,
    // Kaupunki: string,
    combineToOffice: string,
    alkamispäivä?: string,
    päättymispäivä?: string
}

export interface TimeslotResponse extends Timeslot {
    työntekijä: string,
    tyontekija: string,
    toimipaikka: string
}

export interface PopulatedTimeslot extends Timeslot {
    dist?: number | null,
    työntekijä: EntityResponse<Expert>,
    toimipaikka: PremiseLocation | Premise,
    toimitila: PremiseLocation["toimitila"] | Building,
    palvelunNimi: string,
    toimipaikkaUuid: string,
    toimitilaUuid: string
}


export interface Service {
    Palvelu_Nimi?: string,
    Kuvaus: string,
    maksutapa?: string,
    alkamispäivä?: string,
    päättymispäivä?: string,
    popular?: boolean
}

export interface CampaignService {
    uuid: string,
    name: string,
    Kuvaus?: string,
    popular?: boolean,
    children?: CampaignService[],
    alkamispäivä?: string,
    päättymispäivä?: string,
    maksutapa: string
}

export interface Request {
    op: string
}

export interface DataRequest extends Request {
    ctx?: string,
    filter: string[]
}

export interface EntityRequest extends Request {
    uuid: string
}

export interface SearchRequest extends Request {
    query: string
}

export interface LocationCoords {
    lat: number,
    lon: number
}

export interface PremiseLocation {
    uuid: string,
    Nimi: Premise["Nimi"],
    alkamispäivä?: string,
    päättymispäivä?: string,
    toimitila: { uuid: string } & Partial<Building>,
    dist?: number
}

export interface ExpertAndLocation {
    työntekijä: string[],
    toimipaikka: PremiseLocation[]
}

export interface Locations {
    toimipaikka: PremiseLocation[]
}

export interface Experts {
    työntekijä: { uuid: string; Nimi: string }[]
}

export interface ServiceAndLocation {
    palvelu: string[],
    toimipaikka: PremiseLocation[]
}

export interface ExpertService {
    Palvelu_Nimi: string
}

export interface Language {
    kieli: string
}

export interface BasicResponse {
    op: Operations
}

export interface AuthResponse extends BasicResponse {
    success: boolean,
    "session-key": string,
    socket: WebSocket,
    cn?: string,
    dn?: string,
    memberOf?: string[]
}

export interface EntityResponse<T> extends BasicResponse {
    uuid: string,
    ctx?: string,
    model: string,
    data: T,
    from?: string,
    limit?: number,
    total?: number
}

export interface DataResponse<T> extends BasicResponse {
    filter: string[],
    model: string,
    data: T,
    date?: string,
    from?: number,
    limit?: number,
    total?: number,
    range?: TimeslotRange
}

export interface RequestReservationResponse extends BasicResponse {
    uuid: string,
    success: boolean,
    timeout: boolean
}

export interface MapBoxTokenResponse {
    op: Operations.mapbox,
    ctx: Contexes.temporaryToken,
    success: boolean,
    data: {
        expires: string,
        token: string
    }
}

export interface RequestReservationResponseFailure extends RequestReservationResponse {
    success: false,
    data: {
        appointment?: "Not available",
        emailAddress?: "Invalid value",
        phoneNumber?: "Invalid value",
        streetAddress?: "Invalid value"

    }
}

export interface RequestReservationResponseSuccess extends RequestReservationResponse {
    success: true,
    data: {
        "cancellation_token": string
    }
}

export interface RequestReservationResponseTimeout extends RequestReservationResponse {
    success: false,
    timeout: true,
    data: {}
}

export interface CancelReservationResponse {
    op: Operations.appointment,
    uuid: string,
    success: boolean
}

export interface CancelReservationSuccessResponse extends CancelReservationResponse {
    success: true
}

export interface CancelReservationFailureResponse extends CancelReservationResponse {
    success: false,
    data: {
        error: string
    }
}

export interface ServiceResponse {
    uuid: string,
    ctx: string,
    name: string
}

export interface Search<T> {
    auth: {
        serial: string
    },
    version: string,
    ctx: string,
    query: string,
    execution: {
        probability: number,
        sort: number,
        unit: string
    },
    data: T
}

export interface TopaasiData {
    uuid: string,
    ctx: string,
    name: string,
    date: {
        from: Date,
        to: Date
    },
    probability: number
}

export type MatcherFunc<T> = (resp: T) => boolean;

export interface WaitingResponses<T> {
    [key: string]: WaitingResponse<T>
}
export interface WaitingResponse<T> {
    matcher: MatcherFunc<T>,
    callback: CallbackFunc<T> | CallbackFunc<T>[],
    requestMsg: Request,
    timeout: number
}

export interface StreetNumber {
    uuid: string,
    name: string,
    lon: number,
    lat: number
}

export interface Address {
    // nearMe?: boolean,
    address?: string,
    streetNumber: StreetNumber[],
    postalCode: string,
    locality?: string
}

export interface SpecificAddress {
    uuid: string,
    streetName: string,
    streetNumber: string,
    cityName: string,
    postalCode: string
}

export interface LocationData {
    uuid: string,
    kadunnimi: TranslationObj,
    probability: number
}

export type CallbackFunc<T> = (resp: T) => void;

export enum Operations {
    auth = "auth",
    data = "data",
    search = "search",
    appointment = "appointment",
    suomiOsoitteet = "Suomi osoitteet",
    mapbox = "mapbox",
    customer = "customer"
}

export enum Contexes {
    topaasi = "topaasi",
    toimipaikka = "toimipaikka",
    työntekijä = "työntekijä",
    toimitila = "toimitila",
    palvelu = "palvelu",
    kieli = "kieli",
    erityisosaaminen = "erityisosaaminen",
    temporaryToken = "temporary token"
}

export enum Models {
    palvelu = "palvelu",
    toimipaikka = "toimipaikka",
    työntekijä = "työntekijä",
    toimitila = "toimitila",
    kieli = "kieli",
    erityisosaaminen = "erityisosaaminen"
}

export interface DataStore<T> {
    subject: BehaviorSubject<EntityResponse<T>[]>,
    updateState: (
        subject: BehaviorSubject<EntityResponse<T>[]>,
        expert: EntityResponse<T>
    ) => Promise<void>,
    getValueByUuid: (uuid: string) => Promise<EntityResponse<T> | undefined>
}

export interface TimeslotStoreItems {
    from: number,
    limit: number,
    total: number,
    range: TimeslotRange | null,
    date: string | null,
    filter: string[],
    data: TimeslotResponse[],

    selectedExpertsUuids: string[],
    selectedPremisesUuids: string[],
    selectedServicesUuids: string[]
}

export interface TimeslotStore {
    subject: BehaviorSubject<TimeslotStoreItems>,
    updateState: (
        subject: BehaviorSubject<TimeslotStoreItems>,
        timeslots: DataResponse<TimeslotResponse[]>,
        selectedExpertsUuids: string[],
        selectedPremisesUuids: string[],
        selectedServicesUuids: string[],
        date: Date | null
    ) => Promise<void>,
    unsubscribe: () => void,
    getValueByUuid: (uuid: string) => Promise<TimeslotResponse | undefined>,
    updateTimeslotValues: (store: TimeslotStore, available: boolean, uuid: string) => void,
    addTimeslot: (store: TimeslotStore, available: boolean, item: TimeslotResponse) => void
}

export interface ExpertResult {
    ctx: string,
    date?: {
        from: string,
        to: string
    },
    name: string,
    probability: number,
    uuid: string
}

export interface Languages {
    kieli: string[]
}

export interface Speciality {
    erityisosaaminen: string
}

export interface Specialities {
    erityisosaaminen: string[]
}

export interface Services {
    palvelu: { uuid: string }[]
}

export interface UserLocation {
    address: string,
    coordinates: LocationCoords
}

export interface LocationContainer {
    uuids: string[],
    lon: number,
    lat: number,
    container: HTMLDivElement,
    building?: EntityResponse<Building> | null,
    premise?: EntityResponse<Premise> | null
}

export type BuildEnv = "production" | "staging" | "development" | "test"

export interface Campaign {
    id: string,
    name: string,
    customerTypes: string[],
    mainCategoryUuid: string,
    subServices: SubCampaign[],
    path?: {
        finnish: string,
        swedish: string,
        english: string
    }
}

export interface SubCampaign {
    id: string,
    name: string,
    customerType: string
}

export interface SelectedFilters {
    selectedServices: CampaignService[],
    selectedPremises: EntityResponse<Premise>[],
    selectedExperts: EntityResponse<Expert>[],
    selectedSpecialities: EntityResponse<Speciality>[],
    selectedLanguages: EntityResponse<Language>[],
    customerType: string,
    mapLocationUuids: PremiseLocation[] | null,
    locationUuids: PremiseLocation[] | null,
    position: GeolocationPosition | null,
    userLocation: UserLocation | null
}

export interface RoutePath<T> {
    path: string,
    component: React.FC<{} & T>,
    private: boolean
}
export interface SSNResponse {
    op: Operations.customer,
    "api-key": string,
    "session-key": string,
    data: CustomerFound | CustomerNotFound
}
export interface CustomerFound {
    socialSecurityNumber: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailAddress: string,
    streetAddress: string,
    postalCode: string,
    localityName: string
}

export interface CustomerNotFound {
    customer: "not available"
}
export enum QueryKeys {
    base = "base",
    filters = "filters",
    invoicing_type = "invoicing_type",
    use_geolocation = "use_geolocation",
    date = "date",
    time_of_day = "time_of_day"
}

export interface QueryParams {
    base?: string[],
    filters?: string[],
    invoicing_type?: string,
    date?: string,
    use_geolocation?: string,
    time_of_day?: string
}
