import { Language } from "./components/LanguageContext/types";
import { ApiTranslationKeys, BuildEnv } from "./types";


export const defaultLanguage = {
    languageKey: Language.fi,
    apiKey: ApiTranslationKeys.suomeksi
};

export enum LocalStorageKeys {
    languageSelection = "languageSelection",
    campaignSelection = "campaignSelection"
}

export const uiSearchTimeoutMs = 100;
export const promiseRejectTimeoutMs = 60000;
export const fireEventsAfterLength = 1;
export const coronariaRootUrl = "https://coronaria.fi";
export const coronariPrivacyPolicyUrl = "/tietosuojaseloste";
export const sheduleAppointmentPhoneNumbersURL = "/";
export const sheduleAppointmentPhoneNumber = "123 456 7890";
export const fiPhonePrefix = "+358";
export const sessionStorageSessionKeyName = "sessionKey";
export const retryConnectionTimes = 3;
export const retryTimeoutMs = 10000;
export const maxCalendarDays = new Date().getDate() + 50;

const mockServer = process.env.REACT_APP_MOCK_SERVER;
const clMockServer = process.env.REACT_APP_CLMOCK_SERVER;
export const websocketURL = mockServer ? mockServer : "wss://ws1.dev.cor.fi/websocket";
export const websocketClURL = clMockServer ? clMockServer : "wss://ws1.dev.cor.fi/websocket_cl";
export const pictureURL = "https://coronaria.fi/wp-content/uploads/topaasi";
export const listOfPricesLink = "/hinnasto";
export const mapBoxStyles = "mapbox://styles/mapbox/streets-v11";
export const mapBoxAPI = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
export const noReplyMail = "noreply@coronaria.fi";

export const BUILD_ENV: BuildEnv = process.env.REACT_APP_BUILD_ENV ?
    process.env.REACT_APP_BUILD_ENV as BuildEnv :
    process.env.NODE_ENV;

export enum PictureSizes {
    "250x250" = "250x250.jpg"
}


// Experts that shouldn't be shown to end-user.
export const expertsTofilterOut = [
    "20158ef4-2ac5-11e9-9d67-0050569b2d10",
    "c6f8acef-ce4e-11e9-b334-02004c4f4f50",
    "1e9d85f4-2ac5-11e9-9d67-0050569b2d10"
];

const toothClinicServicesToFilterOut = [
    "41b6d737-2ac2-11e9-9d67-0050569b2d10",
    "41b6d8e9-2ac2-11e9-9d67-0050569b2d10",
    "41b6d994-2ac2-11e9-9d67-0050569b2d10",
    "41b6d9d0-2ac2-11e9-9d67-0050569b2d10",
    "41b6da4a-2ac2-11e9-9d67-0050569b2d10",
    "41b6da84-2ac2-11e9-9d67-0050569b2d10",
    "41b6dba8-2ac2-11e9-9d67-0050569b2d10",
    "41b6dbe6-2ac2-11e9-9d67-0050569b2d10",
    "d2306560-5455-11e9-a3da-0050569b2d10",
    "2498dd83-98a8-11e9-bd29-02004c4f4f50",
    "2ef8229b-95d0-11ea-9528-02004c4f4f50",
    "3e9cb58a-b524-11ea-8413-787b0294e2af",
    "db3d20ae-95d1-11ea-9528-02004c4f4f50"
];

const sleepClinicServicesToFilterOut = [
    "0c7fd187-545e-11e9-a3da-0050569b2d10",
    "15ea0098-545e-11e9-a3da-0050569b2d10",
    "1e97d50d-545e-11e9-a3da-0050569b2d10",
    "26deeb8c-545e-11e9-a3da-0050569b2d10",
    "2d7f7706-545e-11e9-a3da-0050569b2d10",
    "761f2aff-545e-11e9-a3da-0050569b2d10",
    "7c0546ba-545e-11e9-a3da-0050569b2d10",
    "872a778c-545e-11e9-a3da-0050569b2d10",
    "983cd3a2-545e-11e9-a3da-0050569b2d10",
    "a0c4e3bc-545e-11e9-a3da-0050569b2d10",
    "acd8a4f0-545e-11e9-a3da-0050569b2d10",
    "b578d329-545e-11e9-a3da-0050569b2d10",
    "0586d80e-545f-11e9-a3da-0050569b2d10",
    "0e5205c0-545f-11e9-a3da-0050569b2d10",
    "39f75458-545f-11e9-a3da-0050569b2d10",
    "50423e7b-545f-11e9-a3da-0050569b2d10",
    "5eb76109-545f-11e9-a3da-0050569b2d10",
    "65ddc64b-545f-11e9-a3da-0050569b2d10",
    "6c9a4a0b-545f-11e9-a3da-0050569b2d10",
    "bbd053c4-dc76-11ea-b295-8f7bb92fbd2a",
    "00459de2-dc77-11ea-b295-8f7bb92fbd2a",
    "2701c439-dc77-11ea-b295-8f7bb92fbd2a",
    "3004fee9-dc77-11ea-b295-8f7bb92fbd2a",
];

export const servicesToFilterOut = [
    "759d7806-5462-11e9-a3da-0050569b2d10",
    "41b588d2-2ac2-11e9-9d67-0050569b2d10",
    "d778970a-dc77-11ea-b295-8f7bb92fbd2a",
    "4ffef709-0236-11eb-9110-e6fa3002795a",
    "58570c04-0237-11eb-9110-e6fa3002795a", // Physiotherapy services to filter out
    "42e9e30f-5460-11e9-a3da-0050569b2d10",
    "4a32b57c-5460-11e9-a3da-0050569b2d10",
    "689fd053-5460-11e9-a3da-0050569b2d10",
    "742fd424-5460-11e9-a3da-0050569b2d10",
    "79d940af-5460-11e9-a3da-0050569b2d10",
    "89842945-5460-11e9-a3da-0050569b2d10",
    "a4213158-5460-11e9-a3da-0050569b2d10",
    "a9e4c5ae-5460-11e9-a3da-0050569b2d10",
    "b6947bc5-5460-11e9-a3da-0050569b2d10",
    "85e39127-67f3-11e9-b409-0050569b2d10",
    "62ab2b17-90ff-11e9-a1b2-02004c4f4f50",
    "f164bd0d-5460-11e9-a3da-0050569b2d10",
    "503ef59e-5460-11e9-a3da-0050569b2d10", // End of Physiotherapy services
]
    .concat(toothClinicServicesToFilterOut)
    .concat(sleepClinicServicesToFilterOut);

export const customerTypes: { [index: string]: string } = {
    "selfPaying": "itsemaksava",
    "serviceVoucher": "palveluseteli",
    "purchaseService": "ostopalveluvaltuutus",
    "paymentVoucher": "maksusitoumus"
};

export const defaultCustomer = "itsemaksava";
export const optionalDefaultCustomer = "palveluseteli";
export const dentistUuid = "beae4d25-0af8-4dd5-b082-d522e803819b";

export const mobileServiceTooltipCloseTimeoutMs = 5000;
