import React from 'react';
import { ListItem, Typography, makeStyles, createStyles, Theme } from "@material-ui/core";
import { Translation } from "./LanguageContext/types";

interface EmptyTimeSelectListItemProps {
    translation: Translation
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: "center",
            alignItems: "center"
        },
        text: {
            fontSize: theme.typography.pxToRem(25),
            lineHeight: theme.typography.pxToRem(30),
            letterSpacing: theme.typography.pxToRem(0.44),
        }
    })
);

const EmptyTimeSelectListItem: React.FC<EmptyTimeSelectListItemProps> = props => {
    const { translation } = props;
    const classes = useStyles();
    return (
        <ListItem component="div" className={classes.root}>
            <Typography color="primary" className={classes.text}>
                {translation.noTimesAvailable}
            </Typography>
        </ListItem>
    );
};

export default EmptyTimeSelectListItem;
