import React, { useContext } from 'react';
import { Theme, WithStyles, withStyles, IconButton, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from "clsx";
import moment from 'moment';
import { format } from 'date-fns';
import CalendarInfo from './CalendarInfo';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizedUtils } from './LocalizedUtils';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import { LocalizationContext } from './LanguageContext/LocalizationContext';
import { Header } from "../Layout";
import { containerShadow } from '../theme';

interface CalendarFilterProps extends WithStyles<typeof styles> {
    date: MaterialUiPickersDate,
    localization: Locale,
    utils: typeof DateFnsUtils | typeof LocalizedUtils,
    onDateChange: (date: MaterialUiPickersDate) => void,
    filters: string[] | null,
    slots: string[],
    setSlots: (slots: string[]) => void,
    handleMonthChange: (dayRendered: MaterialUiPickersDate) => Promise<void>
}

const dayElement = (date: Date, divClass: string, id?: string) =>
    <IconButton className={divClass} id={id}>
        <span>{format(date, "d")}</span>
    </IconButton>;

const CalendarFilter: React.FC<CalendarFilterProps> = (props) => {
    const {
        date = new Date(),
        localization,
        onDateChange,
        classes,
        utils,
        slots,
        handleMonthChange
    } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { translation } = useContext(LocalizationContext);

    const hasAvailableTimes = (dateRendered: Date, isToday: boolean) => {
        const hasTimes = slots.includes(moment(dateRendered).format("YYYY-MM-DD"));
        const wrapperHasTimesClass = clsx(classes.day, {
            [classes.hasTimes]: hasTimes,
            [classes.today]: isToday
        });
        const wrapperNoTimesClass = clsx(classes.day, {
            [classes.noTimes]: !hasTimes,
            [classes.today]: isToday
        });

        return hasTimes ?
            dayElement(dateRendered, wrapperHasTimesClass) :
            dayElement(dateRendered, wrapperNoTimesClass);
    };

    const isSelectedDay = (dateRendered: Date, isSelected: boolean | null) => {
        const today = new Date().getDate();
        const monthNow = new Date().getMonth();
        const isToday = dateRendered.getDate() === today &&
            dateRendered.getMonth() === monthNow;
        const selectedDayWrapper = clsx(classes.day, {
            [classes.highlight]: isSelected,
            [classes.today]: isToday && !isSelected
        });

        return isSelected ?
            dayElement(dateRendered, selectedDayWrapper, "selectedDay") :
            hasAvailableTimes(dateRendered, isToday && !isSelected);
    };

    const isPastDay = (
        dateRendered: Date,
        dayComponent: JSX.Element,
        isSelected: boolean | null
    ) => {
        const today = new Date().getDate();
        const monthNow = new Date().getMonth();
        const yearNow = new Date().getFullYear();
        return dateRendered.getDate() < today &&
            dateRendered.getMonth() === monthNow &&
            dateRendered.getFullYear() === yearNow ?
            dayComponent :
            isSelectedDay(dateRendered, isSelected);
    };

    const isInCurrentMonth = (
        dateRendered: Date,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element,
        isSelected: boolean | null,
    ) => dayInCurrentMonth ?
            isPastDay(dateRendered, dayComponent, isSelected) :
            dayComponent;

    const renderDay = (
        day: MaterialUiPickersDate,
        _selectedDate: MaterialUiPickersDate,
        dayInCurrentMonth: boolean,
        dayComponent: JSX.Element
    ) => {
        const dateRendered = day ? day : new Date();
        const isSelected = date &&
            dateRendered.getMonth() === date.getMonth() &&
            dateRendered.getDate() === date.getDate() &&
            dateRendered.getFullYear() === date.getFullYear();
        return isInCurrentMonth(dateRendered, dayInCurrentMonth, dayComponent, isSelected);
    };

    const arrowButton = {
        width: 70,
        height: 70,
        padding: 2,
    };

    return (
        <Grid container item xs={12} className={classes.container}>
            <Header text={translation.selectDay} />
            <Grid id="calendar-wrapper" item xs={12} className={classes.datePicker}>
                <MuiPickersUtilsProvider
                    utils={utils}
                    locale={localization}
                >
                    <div id="calendar" className={classes.calendarWrapper}>
                        <Calendar
                            allowKeyboardControl={false}
                            date={date}
                            onMonthChange={handleMonthChange}
                            onChange={onDateChange}
                            disablePast
                            renderDay={renderDay}
                            leftArrowIcon={
                                <ArrowLeftRoundedIcon
                                    id="calendar-left-arrow"
                                    color="primary"
                                    className={classes.arrowIcon}
                                    titleAccess={translation.showPreviousMonth}
                                />
                            }
                            leftArrowButtonProps={{
                                title: translation.showPreviousMonth,
                                style: arrowButton
                            }}
                            rightArrowIcon={
                                <ArrowRightRoundedIcon
                                    id="calendar-right-arrow"
                                    color="primary"
                                    className={classes.arrowIcon}
                                    titleAccess={translation.showNextMonth}
                                />}
                            rightArrowButtonProps={{
                                title: translation.showNextMonth,
                                style: arrowButton
                            }}
                        />
                    </div>
                </MuiPickersUtilsProvider>
            </Grid>
            {!mobile && <Grid item xs={12}>
                <CalendarInfo />
            </Grid>}
        </Grid>
    );
};

const styles = ((theme: Theme) =>
    ({
        day: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 40,
            height: 40,
            margin: 2,
            color: "inherit",
            fontSize: theme.typography.pxToRem(20),
            lineHeight: theme.typography.pxToRem(24),
            letterSpacing: theme.typography.pxToRem(0.92),
            padding: 1,
        },
        highlight: {
            backgroundColor: theme.palette.primary.main + " !important",
            borderRadius: "50%",
            color: "#FFFFFF",
            fontFamily: "Gotham Narrow Book"
        },
        hasTimes: {
            color: "#006600",
            fontFamily: "Gotham Narrow Bold"
        },
        noTimes: {
            color: "rgba(0,0,0,0.4)",
            fontFamily: "Gotham Narrow Book"
        },
        today: {
            borderRadius: "50%",
            border: "1px solid #006600",
            fontFamily: "inherit"
        },
        datePicker: {
            display: "flex",
            flexFlow: "column nowrap",
            overflow: "hidden",
            width: "100%",
            height: "400px",
            paddingBottom: theme.spacing(2),
            justifyContent: "center",
            "& .MuiPickersCalendarHeader-dayLabel": {
                fontSize: theme.typography.pxToRem(20),
                lineHeight: theme.typography.pxToRem(24),
            }
        },
        calendarWrapper: {
            minWidth: "320px",
            maxWidth: "420px",
            paddingBottom: theme.spacing(2),
        },
        container: {
            width: "100%",
            paddingBottom: theme.spacing(2),
            borderRadius: "1px",
            boxShadow: containerShadow
        },
        arrowIcon: {
            fontSize: 70,
            width: 70
        }
    }));

export default withStyles(styles)(CalendarFilter);
