import React from "react";
import { createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ContentProps } from "./InfoDialog";

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        header: {
            color: theme.palette.primary.main
        },
        bold: {
            fontWeight: "bold",
            textTransform: "none"
        },
        title: {
            padding: 0
        },
        content: {
            padding: mobile ? theme.spacing(2) : theme.spacing(4),
            "& .MuiTypography": {
                fontFamily: "Gotham Narrow"
            }
        }
    })
);

const Content: React.FC<ContentProps> = props => {
    const { translation } = props;
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(fullscreen)();
    return (
        <>
            <Typography
                paragraph
                className={classes.bold}
            >
                {translation.chooseCustomerType}:
                </Typography>
            <Typography
                paragraph
            >
                <Typography
                    component="span"
                    display="inline"
                >
                    {`${translation.ifPayingYourself}`}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                >
                    {translation.chooseAsCustomerType}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                    className={classes.bold}
                    style={{ textTransform: "lowercase" }}
                >
                    {`${translation.selfPaying}. `}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                >
                    {translation.weHaveKelasPayment}
                </Typography>
            </Typography>
            <Typography
                component="span"
                display="block"
                paragraph
            >
                {translation.ifUsingServiceVoucher}
                <Typography
                    component="span"
                    display="inline"
                    className={classes.bold}
                    style={{ textTransform: "lowercase" }}
                >
                    {`${translation.serviceVoucher}. `}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                >
                    {translation.serviceVoucherUsedFor}
                </Typography>
            </Typography>
            <Typography
                paragraph
            >
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.helsinkiAndUusimaa}`}
                    <Typography
                        component="span"
                    >
                        {` (${translation.hus})`}
                    </Typography>
                </Typography>
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.tampereArea}`}
                </Typography>
            </Typography>
            <Typography
                display="block"
            >
                {translation.paidByInsurance}
            </Typography>
        </>
    );
};

export default Content;
