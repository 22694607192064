import React, { useState, useEffect, useContext, useMemo, useLayoutEffect } from 'react';
import { Grid, useMediaQuery, Theme, makeStyles, createStyles, Typography, Fab } from "@material-ui/core";
import { theme } from "./theme";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FilterMenu } from "./components/FilterMenu";
import { TimeSelectList } from "./components/TimeSelectList/TimeSelectList";
import { Campaign, CampaignService, DataResponse, EntityResponse, Expert, Models, Premise, QueryParams, SelectedFilters, Speciality, Language as LanguageEntity } from './types';
import { ServiceContext } from './components/ServiceContext';
import ServiceMenu from "./components/ServiceMenu";
import servicesJSON from "./services.json";
import PreferCalling from './components/PreferCalling';
import { isNullOrUndefined } from 'util';
import { getUuid, flattenServices, allSettled, onlyDate, createTimeframes, createDefaultTimeFrame, recursiveFind } from './components/utils';
import { from } from 'rxjs';
import CalendarFilter from './components/CalendarFilter';
import { LocalizationContext } from './components/LanguageContext/LocalizationContext';
import { Language } from './components/LanguageContext/types';
import fiLocale from "date-fns/locale/fi";
import enLocale from "date-fns/locale/en-US";
import seLocale from "date-fns/locale/sv";
import DateFnsUtils from '@date-io/date-fns';
import { LocalizedUtils } from './components/LocalizedUtils';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ScrollToTop from './components/ScrollToTop';
import { LocationFilterMenu } from './components/LocationFilterMenu';
import { maxCalendarDays, BUILD_ENV, servicesToFilterOut, defaultCustomer, optionalDefaultCustomer, customerTypes } from './settings';
import popularServices from "./popularServices.json";
import moment from 'moment';
import { SortOptions } from './components/TimeSelectList/SortSelectionView';
import { TimeOfDayFilter } from './components/Filters/TimeOfDay';

const rootBase = (theme: Theme) => ({
    margin: "auto",
    width: "100%",
    maxWidth: "1280px",
    fontSize: "100%",
    backgroundColor: theme.palette.background.paper,
    alignItems: "flex-start"
});

const useStyles = (mobile?: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...rootBase(theme),
            padding: "0 1px",
            paddingBottom: !mobile ? theme.spacing(3) : 0
        },
        rootMobile: {
            ...rootBase(theme),
            padding: 0
        },
        contentItem: {
            width: "100%",
        },
        contentItemLeft: {
            width: "100%",
            paddingLeft: 0
        },
        content: {
            margin: "auto",
            width: "100%",
            paddingTop: theme.spacing(3)
        },
        contentLeft: {
            backgroundColor: theme.palette.background.paper,
        },
        contentRight: {
            backgroundColor: theme.palette.background.paper,
        },
        menu: {
            backgroundColor: theme.palette.background.paper,
            marginBottom: theme.spacing(0.2),
        },
        container: {
            overflow: "hidden",
            maxWidth: "1280px",
            margin: "auto"
        },
        header: {
            display: "flex",
            height: "56px",
            color: theme.palette.common.white,
            backgroundColor: theme.palette.text.secondary,
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
        },
        calendarContainer: {
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        listContainer: {
            height: "813px",
            paddingBottom: theme.spacing(1),
            borderRadius: "1px",
            overflow: "hidden"
        }
    })
);

interface LayoutProps {
    campaignUuid: string | null,
    queryParams: Partial<QueryParams>
}

const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const Layout: React.FC<LayoutProps> = props => {
    const { campaignUuid, queryParams } = props;
    const { translation, getLanguage } = useContext(LocalizationContext);
    const [date, setSelectedDate] = useState<MaterialUiPickersDate>(currentDate);
    const [populatingFilters, setPopulatingFilters] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
        selectedExperts: [],
        selectedPremises: [],
        selectedServices: [],
        selectedLanguages: [],
        selectedSpecialities: [],
        customerType: defaultCustomer,
        mapLocationUuids: null,
        locationUuids: [],
        position: null,
        userLocation: {
            address: translation.gettingLocation,
            coordinates: {
                lat: 0,
                lon: 0
            }
        },
    });

    const {
        selectedExperts,
        selectedLanguages,
        selectedPremises,
        selectedServices,
        selectedSpecialities,
        mapLocationUuids,
        locationUuids,
        position,
        userLocation,
        customerType
    } = selectedFilters;
    const [slots, setSlots] = useState<string[]>([]);
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const { dataService } = useContext(ServiceContext);
    const selectedLanguage = getLanguage();
    const calendarLocalization =
        selectedLanguage === Language.fi ?
            fiLocale :
            selectedLanguage === Language.en ?
                enLocale :
                seLocale;
    const utils = selectedLanguage === Language.fi ? LocalizedUtils : DateFnsUtils;
    const [calendarModalOpen, setCalendarModalOpen] = useState(false);
    const [locationEnabled, setLocationEnabled] = useState(false);
    const [currentCalendarDate, setCurrentCalendarDate] = useState(new Date());
    const [availableCustomerTypes, setAvailableCustomerTypes] = useState<string[]>([]);
    const [allServices, setAllServices] = useState<DataResponse<CampaignService[]> | null>(null);
    const [loadingInitialState, setLoadingInitialState] = useState<boolean>(true);
    const [selectedSorter, setSelectedSorter] = useState<SortOptions>("time");
    const [selectedTimeframe, setSelectedTimeframe] = useState<TimeOfDayFilter>(
        createDefaultTimeFrame(onlyDate(date ? new Date(date) : new Date()))
    );
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [specialityServices, setSpecialityServices] = useState<string[]>([]);

    const timeframes = useMemo(() => createTimeframes(date ? date : new Date()), [date]);

    const openCalendarModal = () => {
        setCalendarModalOpen(true);
    };

    const closeCalendarModal = () => {
        setCalendarModalOpen(false);
    };

    const onDateChange = (newDate: MaterialUiPickersDate) => {
        if (newDate?.toLocaleString() !== date?.toLocaleString())
            setSelectedDate(newDate);
        closeCalendarModal();
    };

    useEffect(() => {
        if (dataService) {
            dataService.requestAllServices()
                .then(setAllServices)
                .catch();
        }
    }, [dataService]);

    const uniquePhoneNr: string | null = useMemo(() => {
        const phoneNrs = selectedPremises
            .map(premise => premise.data.Ajanvaraus_Puhelin);
        const firstNumber = phoneNrs.length > 0 ?
            phoneNrs[0] :
            null;

        const allEqual: boolean =
            !isNullOrUndefined(firstNumber) &&
            phoneNrs.every(nr => nr === phoneNrs[0]);

        return (
            allEqual &&
            firstNumber !== "" &&
            !isNullOrUndefined(firstNumber)
        ) ?
            firstNumber :
            null;
    }, [selectedPremises]);

    const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(campaignUuid);

    useEffect(() => {
        setSelectedCampaignId(campaignUuid);
    }, [campaignUuid]);

    const campaign: Campaign | undefined = useMemo(() =>
        servicesJSON.find(val => val.id === selectedCampaignId), [selectedCampaignId]);

    useEffect(() => {
        if (queryParams.invoicing_type)
            return;
        if (campaign && !campaign.customerTypes.includes(defaultCustomer)) {
            setSelectedFilters(prevState => ({
                ...prevState,
                customerType: optionalDefaultCustomer
            }));
        } else if (customerType !== defaultCustomer) {
            setSelectedFilters(prevState => ({
                ...prevState,
                customerType: defaultCustomer
            }));
        }
    }, [campaign, queryParams.invoicing_type]);

    const campaignServices = useMemo(() => {
        const subCampaign = campaign?.subServices.find(service =>
            service.customerType === customerType
        ) || null;
        const mainService = campaign && allServices ?
            allServices.data.find(service => service.uuid === campaign.mainCategoryUuid) :
            null;

        const service = subCampaign && mainService && mainService.children ?
            mainService.children.find(service => service.uuid === subCampaign.id) :
            null;

        const services = service && service.children ?
            flattenServices([service]) :
            service ?
                [service] :
                [];

        const filteredServices = services
            .filter(service =>
                !servicesToFilterOut.includes(service.uuid)
                && service.maksutapa === customerType
            );
        return campaign ? filteredServices : allServices ? flattenServices(allServices.data) : [];
    }, [campaign, allServices, customerType]);


    const services = useMemo(() => {
        const isPopular = (service: CampaignService) => !!popularServices.find(popularService =>
            popularService === service.uuid
        );
        const servicesByPremises = (services: CampaignService[]) =>
            selectedPremises.length > 0
                ? services
                    .filter(service =>
                        selectedPremises.find(premise =>
                            premise.data.palvelu.find(palvelu => palvelu.uuid === service.uuid)
                        )
                    )
                : services;

        const servicesByExperts = (services: CampaignService[]) =>
            selectedExperts.length > 0
                ? services.filter(service =>
                    selectedExperts.find(expert =>
                        expert.data?.palvelu?.find(serv => serv.uuid === service.uuid)
                    )
                )
                : services;

        const servicesBySpeciality = (services: CampaignService[]) =>
            specialityServices.length > 0 ?
                services.filter(service => specialityServices.includes(service.uuid)) :
                services;

        const filteredServicesBySelections = (services: CampaignService[]) =>
            servicesByPremises(services)
                .filter(service => servicesByExperts(services)
                    .find(serv => serv.uuid === service.uuid)
                )
                .filter(service => servicesBySpeciality(services)
                    .find(serv => serv.uuid === service.uuid))
                .filter(service => service.maksutapa === customerType)
                .filter(service => !servicesToFilterOut.includes(service.uuid))
                .map(service => ({
                    ...service,
                    popular: isPopular(service)
                }));

        if (!queryParams.base && !queryParams.filters && campaignServices && !populatingFilters) {
            return filteredServicesBySelections(campaignServices);
        } else if (
            queryParams.filters
            && queryParams.base
            && !populatingFilters
            && campaignServices
        ) {

            const baseServices = queryParams.base.map(base => recursiveFind(
                allServices?.data || [],
                "uuid",
                base,
                "children"
            )).flat();
            const services = baseServices.length > 0
                ? flattenServices(baseServices)
                    .filter(service => !servicesToFilterOut.includes(service.uuid))
                    .map(service => ({
                        ...service,
                        popular: isPopular(service)
                    }))
                : null;

            return services
                ? services
                : filteredServicesBySelections(campaignServices);
        } else if (queryParams.filters
            && !queryParams.base
            && !populatingFilters
        ) {
            return campaignServices.length > 0 ? filteredServicesBySelections(campaignServices)
                : filteredServicesBySelections(flattenServices(allServices?.data || []));
        } else if (queryParams.base && !queryParams.filters && !populatingFilters) {
            const baseServices = queryParams.base.map(base => recursiveFind(
                allServices?.data || [],
                "uuid",
                base,
                "children"
            ));

            const services = baseServices?.length > 0
                ? flattenServices(baseServices)
                    .filter(service => !servicesToFilterOut.includes(service.uuid))
                    .filter(service => service.maksutapa === customerType)
                    .map(service => ({
                        ...service,
                        popular: isPopular(service)
                    }))
                : null;

            return (services
                ? services
                : filteredServicesBySelections(campaignServices));
        } else {
            return [];
        }
    },
        [
            campaignServices,
            selectedPremises,
            selectedExperts,
            selectedServices,
            selectedLanguages,
            selectedSpecialities,
            populatingFilters,
            specialityServices,
            customerType
        ]
    );

    useEffect(() => {
        campaignServices.length > 0 && services.length === 0 && setPopulatingFilters(false);
    }, [customerType]);

    const filtersFromService = useMemo(() =>
        locationEnabled && !accessToken ? []
            : selectedServices.length !== 0 ?
                selectedServices.map(getUuid) :
                services.map(getUuid)
        , [selectedServices, services, locationEnabled, accessToken]);

    const filtersFromExperts = useMemo(() =>
        filtersFromService.length > 0 ? selectedExperts.map(getUuid) : [],
        [selectedExperts, filtersFromService,]
    );
    const filtersFromPremise = useMemo(() =>
        filtersFromService.length === 0 ? []
            : mapLocationUuids !== null && selectedPremises.length === 0 ?
                mapLocationUuids.map(getUuid) :
                selectedPremises.map(getUuid),
        [selectedPremises, mapLocationUuids, filtersFromService]);

    const filtersFromLanguages = useMemo(() =>
        selectedLanguages.map(getUuid),
        [selectedLanguages]
    );
    const filtersFromSpecialities = useMemo(() =>
        selectedSpecialities.map(getUuid),
        [selectedSpecialities]
    );

    const serviceFilterList = useMemo(() =>
        filtersFromLanguages.length > 0 || filtersFromSpecialities.length > 0
            ? filtersFromLanguages
                .concat(filtersFromSpecialities)
                .concat(filtersFromExperts)
                .concat(filtersFromPremise)
            : [],
        [filtersFromLanguages, filtersFromSpecialities]
    );

    const timeselectListFilters = useMemo(() =>
        !mapLocationUuids && locationEnabled
            ? []
            : !populatingFilters && filtersFromService.length > 0
                ? filtersFromService
                    .concat(filtersFromExperts)
                    .concat(filtersFromPremise)
                    .concat(filtersFromLanguages)
                    .concat(filtersFromSpecialities)
                : [],
        [
            filtersFromService,
            filtersFromExperts,
            filtersFromPremise,
            filtersFromLanguages,
            filtersFromSpecialities,
            populatingFilters,
            mapLocationUuids,
            locationEnabled
        ]
    );
    const year = currentCalendarDate ?
        currentCalendarDate.getFullYear() :
        new Date().getFullYear();
    const month = currentCalendarDate ?
        currentCalendarDate.getMonth() :
        new Date().getMonth();
    const firstDay = new Date();
    const lastDay = new Date(year, month, maxCalendarDays);

    useEffect(() => {
        if (date &&
            slots.length > 0 &&
            !queryParams.date &&
            !slots.includes(moment(date).format("YYYY-MM-DD"))
        ) {
            setSelectedDate(new Date(slots[0]));
        }
    }, [slots, queryParams]);

    useLayoutEffect(() => {
        if (timeselectListFilters.length === 0)
            setSlots([]);
        else if (dataService && timeselectListFilters.length !== 0 && !populatingFilters) {
            const fromRangeSub = from(dataService.requestTimesInRange(
                firstDay,
                lastDay,
                timeselectListFilters
            )).subscribe(dates => setSlots(dates.data));
            return () => fromRangeSub.unsubscribe();
        }
    }, [dataService, timeselectListFilters, populatingFilters]);

    useEffect(() => {
        const hammastarkastus = flattenServices(allServices ? allServices.data : [])
            .find(service => service.uuid === "e98fec45-97f8-11e9-bd29-02004c4f4f50");
        const hammaskiven_poisto = flattenServices(allServices ? allServices.data : [])
            .find(service => service.uuid === "41b6d7b9-2ac2-11e9-9d67-0050569b2d10");
        const selfPaying = customerType === customerTypes["selfPaying"];
        const setFilters = async (filters?: string[]) => {
            if (dataService && (filters?.length || queryParams.base) && allServices) {
                const nonCampaignFilters = filters?.filter(uuid =>
                    !servicesJSON.some(service => service.id === uuid)
                ) || [];


                const populated = await allSettled(nonCampaignFilters.map(filter => dataService
                    .populateFilters(filter, flattenServices(allServices.data))));

                const premises = populated
                    .filter(promise =>
                        promise.status === "fulfilled" &&
                        promise.value?.model === Models.toimipaikka
                    )
                    .map(promise => promise.value) as EntityResponse<Premise>[];
                const experts = populated
                    .filter(promise =>
                        promise.status === "fulfilled" &&
                        promise.value?.model === Models.työntekijä
                    )
                    .map(promise => promise.value) as EntityResponse<Expert>[];

                const languages = populated
                    .filter(promise =>
                        promise.status === "fulfilled" &&
                        promise.value?.model === Models.kieli
                    )
                    .map(promise => promise.value) as EntityResponse<LanguageEntity>[];

                const specialities = populated
                    .filter(promise =>
                        promise.status === "fulfilled" &&
                        promise.value?.model === Models.erityisosaaminen
                    )
                    .map(promise => promise.value) as EntityResponse<Speciality>[];
                const selectedServices = populated
                    .filter(promise =>
                        promise.status === "fulfilled" &&
                        promise.value?.maksutapa !== undefined
                    )
                    .map(promise => promise.value);


                const dentistServices = servicesJSON.find(serv => serv.name === "Hammasklinikka");

                const isDentistService = (queryParams.base?.every(service =>
                    dentistServices?.subServices
                        .find(serv => serv.id === service
                            && customerType === customerTypes["selfPaying"]
                        ))
                    || Object.values(dentistServices?.path || {})?.some(path =>
                        window.location.pathname.match(path)))
                    && selfPaying
                    && hammastarkastus && experts.length === 0;

                const isDentist = experts.length > 0 && experts.every(expert =>
                    expert.data.palvelu.find(service => service.uuid === hammastarkastus?.uuid)
                )
                    && selfPaying;
                const isHygienist = experts.length > 0 && experts.every(expert =>
                    expert.data.palvelu.find(service => service.uuid === hammaskiven_poisto?.uuid)
                    && !expert.data.palvelu.find(service => service.uuid === hammastarkastus?.uuid)
                )
                    && selfPaying;

                const preSelectedService = isDentistService || isDentist
                    ? hammastarkastus
                    : isHygienist
                        ? hammaskiven_poisto
                        : null;

                setSelectedFilters(prevState => ({
                    ...prevState,
                    selectedExperts: experts,
                    selectedPremises: premises,
                    selectedServices: selectedServices.length === 0 && preSelectedService
                        ? [preSelectedService]
                        : selectedServices,
                    selectedLanguages: languages,
                    selectedSpecialities: specialities,
                    mapLocationUuids: [],
                    locationUuids: [],
                    specialityServices: [],
                }));
                setPopulatingFilters(false);
            } else if (!queryParams.filters && !queryParams.base && allServices) {
                const isDentistService =
                    campaign?.id === "beae4d25-0af8-4dd5-b082-d522e803819b"
                    && selfPaying;
                isDentistService && hammastarkastus && setSelectedFilters((prevState) => ({
                    ...prevState,
                    selectedServices: [hammastarkastus]
                }));
                setPopulatingFilters(false);
            }
        };

        const setDate = (date?: string) => {
            date && setSelectedDate(new Date(date));
        };

        const setInvoicingType = (invoicing_type?: string) => {
            const customerType = Object.values(customerTypes).find(type => type === invoicing_type);
            customerType && setSelectedFilters(prevState => ({
                ...prevState,
                customerType: customerType
            }));
        };

        const setGeolocation = (use_geolocation?: QueryParams["use_geolocation"]) => {
            if (use_geolocation && Boolean(parseInt(use_geolocation))) {
                setSelectedSorter("distance");
                enableLocation();
                setLoadingInitialState(false);
            }
        };

        const setTimeOfDay = (timeOfDay?: string) => {
            timeOfDay && setSelectedTimeframe(timeframes[timeOfDay]);
        };

        setGeolocation(queryParams.use_geolocation);
        setDate(queryParams.date);
        setInvoicingType(queryParams.invoicing_type);
        setTimeOfDay(queryParams.time_of_day);
        setFilters(queryParams.filters);
    }, [dataService, queryParams, allServices]);

    useLayoutEffect(() => {
        if (dataService && serviceFilterList.length !== 0) {
            const fetchSpecialityServices = async () => {
                const filteredServices = await dataService.requestServices(serviceFilterList)
                    .then(servicesList =>
                        servicesList.data.palvelu.map(getUuid)
                    ) as string[];
                setSpecialityServices(filteredServices);
            };
            fetchSpecialityServices();
        } else {
            setSpecialityServices([]);
        }
    }, [dataService, serviceFilterList]);

    const locationFilters = useMemo(() =>
        filtersFromService
            .concat(filtersFromExperts)
            .concat(filtersFromSpecialities)
        ,
        [
            filtersFromService,
            filtersFromExperts,
            selectedSpecialities
        ]
    );

    const expertFilters =
        useMemo(() => !mapLocationUuids && locationEnabled
            ? []
            : filtersFromPremise.length !== 0 ||
                filtersFromService.length !== 0 ?
                [
                    ...filtersFromService,
                    ...filtersFromPremise,
                    ...filtersFromLanguages,
                    ...filtersFromSpecialities
                ] :
                [
                    ...filtersFromService,
                    ...filtersFromLanguages,
                    ...filtersFromSpecialities
                ],
            [
                filtersFromService,
                filtersFromPremise,
                filtersFromLanguages,
                filtersFromSpecialities,
                mapLocationUuids,
                locationEnabled
            ]);

    const languageFilters = useMemo(() =>
        !mapLocationUuids && locationEnabled
            ? []
            : filtersFromService
                .concat(filtersFromExperts)
                .concat(filtersFromPremise)
                .concat(filtersFromSpecialities),
        [
            filtersFromExperts,
            filtersFromPremise,
            filtersFromService,
            filtersFromSpecialities,
            mapLocationUuids,
            locationEnabled
        ]);

    const specialityFilters = useMemo(() =>
        !mapLocationUuids && locationEnabled
            ? []
            : filtersFromService
                .concat(filtersFromExperts)
                .concat(filtersFromPremise)
                .concat(filtersFromLanguages),
        [
            filtersFromExperts,
            filtersFromService,
            filtersFromPremise,
            filtersFromLanguages,
            mapLocationUuids,
            locationEnabled
        ]);

    const handleMonthChange = (dateRendered: MaterialUiPickersDate): Promise<void> =>
        new Promise(async (resolve) => {
            if (dateRendered && dataService && timeselectListFilters.length !== 0) {
                const year = dateRendered.getFullYear();
                const month = dateRendered.getMonth();
                const isCurrentMonth = new Date().getMonth() === month &&
                    new Date().getFullYear() === year;
                const firstDay = isCurrentMonth ?
                    new Date() :
                    new Date(year, month, 1);
                const lastDay = isCurrentMonth ?
                    new Date(year, month, maxCalendarDays) :
                    new Date(year, month + 1, 0);
                const dates = await dataService.requestTimesInRange(
                    firstDay,
                    lastDay,
                    timeselectListFilters
                );
                setCurrentCalendarDate(firstDay);
                setSlots(dates.data);
                resolve();
            }
            resolve();
        });

    const enableLocation = () => {
        setLocationEnabled(true);
        setSelectedFilters(prevState => ({
            ...prevState,
            locationUuids: [],
            mapLocationUuids: null
        }));
    };

    const disableLocation = () => {
        setSelectedFilters((prevState) => ({
            ...prevState,
            mapLocationUuids: null
        }));
        setAccessToken(null);
        setLocationEnabled(false);
    };

    const serviceFilters = serviceFilterList.length !== 0 && specialityServices ?
        specialityServices :
        [];

    const filterMenuProps = {
        date,
        onDateChange,
        premises: selectedPremises,
        setSelectedFilters,
        position,
        selectedExperts,
        selectedServices,
        timeselectListFilters,
        locationFilters,
        expertFilters,
        services,
        locationUuids: locationEnabled ? mapLocationUuids : locationUuids,
        languageFilters,
        specialityFilters,
        selectedSpecialities,
        selectedLanguages,
        serviceFilters,
        utils,
        localization: calendarLocalization,
        onCalendarModalOpen: openCalendarModal,
        onCalendarModalClose: closeCalendarModal,
        calendarModalOpen,
        slots,
        setSlots,
        enableLocation,
        disableLocation,
        locationEnabled,
        handleMonthChange,
        customerType,
        campaign,
        loadingInitialState,
        availableCustomerTypes,
        setPopulatingFilters,
        accessToken,
        setAccessToken,
        urlBase: queryParams.base,
    };

    useEffect(() => {
        if (allServices) {
            const baseServices = queryParams?.base?.map(base => recursiveFind(
                allServices?.data || [],
                "uuid",
                base,
                "children"
            )) || [];

            const findSubCategory = (service: string, baseServices: CampaignService[]): boolean => {
                return !!baseServices.find(base => base.uuid === service);
            };

            const service = servicesJSON
                .find(service =>
                    service.subServices.some(serv => findSubCategory(serv.id, baseServices))
                );

            const services = baseServices && baseServices.length > 0
                ? flattenServices(baseServices)
                : [];
            const serviceCustomerTypesSet = new Set(services.map(service => service.maksutapa));
            const serviceCustomerTypes = campaign
                ? campaign.customerTypes
                : Array.from(serviceCustomerTypesSet);
            setAvailableCustomerTypes(service?.customerTypes || serviceCustomerTypes);
            service?.id && setSelectedCampaignId(service?.id);
        }
    }, [queryParams.base, allServices]);

    const filterMenu = locationEnabled === false ? (
        <FilterMenu
            {...filterMenuProps}
        />
    ) : (
        <LocationFilterMenu
            {...filterMenuProps}
            userLocation={userLocation}
        />
    );

    return (
        <div
            className={classes.container}
            id="container"
        >
            <Grid
                container
                spacing={!mobile ? 6 : 4}
                className={!mobile ? classes.root : classes.rootMobile}

            >
                {BUILD_ENV !== "production" && <Grid
                    item
                    xs={12}
                    role={"search"}
                    aria-label={"Select service category"} //section should be removed and functionality moved to CMS before release
                >
                    <ServiceMenu
                        selectedUuid={selectedCampaignId}
                    />
                </Grid>}
                {filterMenu}
            </Grid>
            <Grid
                container
                className={classes.root}
                direction="row"
            >
                {!mobile &&
                    <Grid
                        container
                        item
                        md={4}
                        sm={12}
                        className={classes.calendarContainer}
                        role={"search"}
                    >
                        <CalendarFilter
                            date={date}
                            utils={utils}
                            localization={calendarLocalization}
                            onDateChange={onDateChange}
                            filters={timeselectListFilters}
                            setSlots={setSlots}
                            slots={slots}
                            handleMonthChange={handleMonthChange}
                        />
                        {uniquePhoneNr !== null &&
                            <Grid item xs={12} style={{ marginTop: theme.spacing(3) }}>
                                <PreferCalling
                                    phoneNumber={uniquePhoneNr}
                                    premise={
                                        selectedPremises.length === 1 ?
                                            selectedPremises[0].data :
                                            null
                                    }
                                />
                            </Grid>}
                    </Grid>}

                <Grid
                    item
                    md={8}
                    sm={12}
                    role={"main"}
                >
                    <TimeSelectList
                        position={position}
                        selectedDate={date as Date}
                        setSelectedDate={onDateChange}
                        filters={timeselectListFilters}
                        locationUuids={locationUuids}
                        selectedExperts={selectedExperts}
                        selectedPremises={selectedPremises}
                        services={services}
                        selectedServices={selectedServices}
                        handleMonthChange={handleMonthChange}
                        locationEnabled={locationEnabled}
                        customerType={customerType}
                        setLoadingInitialState={setLoadingInitialState}
                        allServices={allServices ? allServices.data : []}
                        selectedSorter={selectedSorter}
                        setSelectedSorter={setSelectedSorter}
                        selectedTimeframe={selectedTimeframe}
                        setSelectedTimeframe={setSelectedTimeframe}
                        slots={slots}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    />
                </Grid>
                {mobile && uniquePhoneNr !== null &&
                    <Grid
                        item
                        xs={12}
                        role={"comment"}
                    >
                        <PreferCalling
                            phoneNumber={uniquePhoneNr}
                            premise={
                                selectedPremises.length === 1 ?
                                    selectedPremises[0].data :
                                    null
                            }
                        />
                    </Grid>
                }
            </Grid>
            {mobile &&
                <ScrollToTop>
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                        <ArrowUpwardRoundedIcon />
                    </Fab>
                </ScrollToTop>
            }
        </div>
    );
};

export const Header: React.FC<{ text: string; icon?: React.ReactElement }> = (props) => {
    const classes = useStyles()();
    return (
        <div className={classes.header}>
            {props.icon}
            <Typography variant="h5">{props.text.toUpperCase()}</Typography>
        </div>
    );
};



export default Layout;
