import { CardMedia } from "@material-ui/core";
import React from "react";
import { pictureURL, PictureSizes } from "../settings";


interface IExpertPicture {
    uuid: string,
    className?: string,
    title?: string
}

export const ExpertPicture: React.FC<IExpertPicture> = ({
    uuid, className, title
}) =>
    <CardMedia className={className}
        image={`${pictureURL}/${uuid}.${PictureSizes["250x250"]}`}
        title={title}
    />;