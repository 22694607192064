import React, { useContext, useState, useMemo, useEffect } from "react";
import {
    Grid, Container, Typography, makeStyles, Theme,
    createStyles, useTheme, useMediaQuery, InputAdornment,
    Tooltip, IconButton, Avatar, Link
} from "@material-ui/core";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { LocalizationContext } from "./LanguageContext/LocalizationContext";
import { ServiceContext } from "./ServiceContext";
import Validator from "validator";
import { CancelReservationSuccessResponse, CancelReservationFailureResponse } from "../types";
import { Header } from "../Layout";
import { DefaultButton, SuccessButton } from "./CustomStyleComponents/Buttons";
import { LabelOutSideTextField } from "./CustomStyleComponents/TextFields";
import { FieldStatusIcon } from "./UtilComponents/FieldStatusIcon";
import HelpOutlineIcon from '@material-ui/icons/HelpOutlineRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { gaEventCancelledReservation } from "../gaEvents/events";
import LoadingIndicator from "./UtilComponents/LoadingIndicator";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        desktopButton: {
            minWidth: "360px"
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        buttonWrapperMargin: {
            marginTop: theme.typography.pxToRem(90),
            marginBottom: theme.typography.pxToRem(40)
        },
        buttonWrapperMobile: {
            display: "flex",
            justifyContent: "center",
        },
        avatar: {
            margin: theme.spacing(1),
            height: theme.typography.pxToRem(48),
            width: theme.typography.pxToRem(48),
        },
        avatarSuccess: {
            backgroundColor: theme.palette.success.main,
        },
        avatarFailure: {
            backgroundColor: theme.palette.error.main,
        },
        paper: {
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        blue: {
            color: theme.palette.text.secondary
        },
        button: {
            height: theme.typography.pxToRem(55)
        },
        paddingTop: {
            paddingTop: theme.spacing(8),
        },
        container: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0)
        },
        content: {
            paddingTop: theme.spacing(8),
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8)
        },
        contentMobile: {
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        disclaimerSpacing: {
            marginTop: theme.spacing(7)
        },
        lineHeightOverride: {
            lineHeight: "22px"
        },
        labelText: {
            color: "#595959",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(16),
            letterSpacing: theme.typography.pxToRem(0.4),
            backgroundColor: "#FFFFFF",
            paddingRight: theme.typography.pxToRem(4),
            "&::placeholder": {
                fontFamily: "Gotham Narrow Book",
                fontSize: theme.typography.pxToRem(16),
                letterSpacing: theme.typography.pxToRem(0.13),
                lineHeight: theme.typography.pxToRem(24),
                color: "rgba(33,33,33)",
                opacity: 0.7
            }
        },
        infoIconContainer: {
            display: "flex"
        },
        link: {
            textDecoration: "none",
            color: theme.palette.common.white,
            width: "100%",
            "&:hover": {
                textDecoration: "none"
            }
        },
        loading: {
            width: "3em",
            height: "3em",
            paddingRight: "1em"
        }
    })
);

type responseType = CancelReservationSuccessResponse
    | CancelReservationFailureResponse
    | "catch"
    | null


interface ISuccessErrorView {
    type: "success" | "error",
    headerText: string,
    paragraphText?: string,
    setResponse?: React.Dispatch<React.SetStateAction<responseType>>
}

const SuccessErrorView: React.FC<ISuccessErrorView> = ({
    type, headerText, paragraphText, setResponse
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    const successErrorButton = useMemo(() =>
        <Grid
            item
            xs={12}
            className={
                `${windowIsMdSize ?
                    classes.buttonWrapper :
                    classes.buttonWrapperMobile}` +
                ` ${!!setResponse && windowIsMdSize ? classes.disclaimerSpacing : ""}`
            }
        >
            <SuccessButton
                fullWidth={!windowIsMdSize}
                // onClick is set to undefined when button has link element inside.
                onClick={setResponse ? () => setResponse(null) : undefined}
                className={
                    classes.button +
                    ` ${windowIsMdSize ? classes.desktopButton : ""}`
                }
            >
                {!!!setResponse ?
                    <Link
                        href="/"
                        className={classes.link}
                    >
                        {translation.cancelSuccessButton}
                    </Link> :
                    translation.cancelGenericFailButton}
            </SuccessButton>
        </Grid>
        , [translation, setResponse, windowIsMdSize]);

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <div className={classes.paper}>
                    <Avatar
                        className={
                            classes.avatar +
                            ` ${type === "success" ?
                                classes.avatarSuccess :
                                classes.avatarFailure}`
                        }
                    >
                        {
                            type === "success" ?
                                <CheckIcon
                                    fontSize={"large"}
                                /> :
                                <CloseIcon
                                    fontSize={"large"}
                                />
                        }
                    </Avatar>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant={"h6"}
                    className={classes.blue}
                    align={"center"}
                >
                    {headerText}
                </Typography>
            </Grid>
            {!!setResponse ? successErrorButton : ''}
            {
                paragraphText &&
                <Grid item xs={12}>
                    <Typography
                    >
                        {`${paragraphText}.`}
                    </Typography>
                </Grid>
            }
            {!!!setResponse ? successErrorButton : ''}
        </React.Fragment>
    );
};

interface ICancelReservation {
    uuid: string
}

const CancelReservaton: React.FC<ICancelReservation> = (props) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);
    const { dataService } = useContext(ServiceContext);

    const reservationUuid = props.uuid;
    const [pin, setPin] = useState<string>("");

    const [waitingResp, setWaitingResp] = useState<boolean>(false);

    const [response, setResponse] = useState<responseType>(null);

    const isValidUuid = useMemo(
        () => Validator.isUUID(reservationUuid), [reservationUuid]);

    const createSendRequest = (uuid: string, pin: string) => () => {
        if (dataService) {
            setWaitingResp(true);
            dataService.requestReservationCancellation(uuid, pin)
                .then((resp) => {
                    setResponse(resp);
                    setPin("");
                    setWaitingResp(false);
                }).catch((_err) => {
                    setResponse("catch");
                    setWaitingResp(false);
                });
        }
    };

    useEffect(() => {
        if (response) {
            response !== "catch" ?
                gaEventCancelledReservation(response.success) :
                gaEventCancelledReservation(false);
        }
    }, [response]);

    const isValidPin = (pin: string) => /^[0-9A-Za-z]{4}$/.test(pin);
    const pinIsValid = useMemo(() => isValidPin(pin), [pin]);

    const loading = useMemo(() =>
        <>
            <LoadingIndicator className={classes.loading}></LoadingIndicator>
            <span>{translation.sendingCancellationRequest}</span>
        </>
        , [LoadingIndicator, translation]);

    const CancellationFormView = useMemo(() =>
        <React.Fragment>
            <Grid item xs={12}
                className={
                    classes.paddingTop
                }
            >
                <Typography
                    variant={"h6"}
                    className={`${classes.blue} ${classes.lineHeightOverride}`}
                >
                    {translation.cancelReservationCancelWithPinHeader}
                </Typography>
            </Grid>
            <Grid
                item
                xs={10}
                md={5}
            >
                <form>
                    <LabelOutSideTextField
                        value={pin}
                        onChange={(e: any) => setPin(e.target.value.trim())}
                        variant="outlined"
                        required
                        fullWidth
                        id={"pin"}
                        label={translation.pinCode}
                        name={"pin"}
                        error={pin !== "" && !pinIsValid}
                        helperText={
                            pin !== "" && !pinIsValid ?
                                translation.cancelReservationPinDisclaimer :
                                ""
                        }
                        disabled={waitingResp || !isValidUuid}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {
                                        <FieldStatusIcon
                                            value={pin}
                                            isValid={isValidPin}
                                        />
                                    }
                                </InputAdornment>
                            ),
                            classes: { input: classes.labelText }
                        }}
                    />
                </form>
            </Grid>
            {
                <Grid
                    item
                    xs={2}
                    md={1}
                    className={classes.infoIconContainer}
                >
                    <Tooltip
                        title={translation.cancelReservationPinDisclaimer}
                        arrow={true}
                        interactive
                    >
                        <IconButton
                            size={"medium"}
                            disableRipple={true}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <HelpOutlineIcon
                                fontSize={"large"}
                                className={classes.blue}
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            <Grid
                item
                xs={12}
                md={5}
            >
                <DefaultButton
                    size={"large"}
                    fullWidth
                    onClick={createSendRequest(reservationUuid, pin)}
                    disabled={waitingResp || !pinIsValid || !isValidUuid}
                    className={classes.button}
                >
                    {
                        waitingResp ?
                            loading :
                            translation.cancelReservationButton
                    }
                </DefaultButton>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <Typography
                    variant={"h6"}
                    className={`
                                    ${classes.blue} ` +
                        `${windowIsMdSize ? classes.disclaimerSpacing : ""}`
                    }
                    paragraph={true}
                >
                    {translation.cancelReservationCancellingReservedTimeHeader}
                </Typography>
                <Typography
                    paragraph={true}
                >
                    {translation.cancelReservationDisclaimerParagraph1}
                </Typography>
                <Typography
                    paragraph={true}
                >
                    {translation.cancelReservationDisclaimerParagraph2}
                </Typography>
            </Grid>
        </React.Fragment>
        , [
            translation, windowIsMdSize, reservationUuid, pin,
            waitingResp, pinIsValid, isValidUuid, setPin
        ]);

    const cancellationSuccessView = useMemo(() =>
        <SuccessErrorView
            type="success"
            headerText={translation.cancellationSuccess}
        />
        , [translation]);

    const invalidUuidView = useMemo(() =>
        < SuccessErrorView
            type="error"
            headerText={translation.invalidUUIDError}
        />
        , [translation]);

    const genericErrorView = useMemo(() =>
        < SuccessErrorView
            type="error"
            headerText={translation.cancellationErrorDefault}
            paragraphText={translation.cancellationGenericError}
            setResponse={setResponse}
        />
        , [translation]);

    const reservationTooCloseErrorView = useMemo(() =>
        < SuccessErrorView
            type="error"
            headerText={translation.cancellationError}
            paragraphText={translation.reservationTooCloseError}
        />
        , [translation]);

    return (
        <Container
            maxWidth="md"
            className={classes.container}
        >
            <ScopedCssBaseline />
            <Grid
                container
            >
                <Grid item xs={12}>
                    <Header text={translation.cancelReservationHeader} />
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    className={
                        windowIsMdSize ?
                            classes.content :
                            classes.contentMobile
                    }
                >
                    {
                        !isValidUuid ?
                            invalidUuidView :
                            response === null ?
                                CancellationFormView :
                                response === "catch" || (!response.success &&
                                    response.data.error !== "Cancellation time has expired") ?
                                    genericErrorView :
                                    !response.success ?
                                        reservationTooCloseErrorView :
                                        cancellationSuccessView
                    }
                </Grid>
            </Grid>
        </Container>
    );
};

export default CancelReservaton;
