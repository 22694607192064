import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { makeStyles, Typography, Theme, createStyles, FormControlLabel, FormControl, RadioGroup, Radio, FormLabel, useMediaQuery, useTheme, Button } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { customerTypes } from "../../settings";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoDialog, { ContentProps } from "./CustomerTypeInfoDialog/InfoDialog";
import servicesJson from "../../services.json";
import InfoDialogContentDentist from "./CustomerTypeInfoDialog/InfoDialogContentDentist";
import InfoDialogContentSleep from "./CustomerTypeInfoDialog/InfoDialogContentSleep";
import InfoDialogContentEndoscopy from "./CustomerTypeInfoDialog/InfoDialogContentEndoscopy";
import { useLocation } from "react-router-dom";
import { Campaign, SelectedFilters } from "../../types";

const formBase = (theme: Theme): CSSProperties => ({
    fontFamily: "Gotham Narrow Bold",
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    color: "rgba(0,0,0,0.87)",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(4)
});

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexDirection: "row",
            paddingBottom: theme.spacing(8),
            alignItems: "center",
            width: "100%"
        },
        rootMobile: {
            flexDirection: "column",
            paddingBottom: theme.spacing(8),
            alignItems: "flex-start",
            width: "100%"

        },
        formGroup: {
            flexDirection: "row",
            flexWrap: "nowrap",
        },
        button: {
            paddingRight: "32px",
        },
        formLabel: {
            ...formBase(theme)
        },
        formGroupMobile: {
            display: "flex",
            flexDirection: "column",
            ...formBase(theme),
            alignItems: "flex-start"
        },
        infoButton: {
            ...(mobile ? { width: "100%" } : {}),
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "flex-start",
            textTransform: "none",
            textDecoration: "none"
        },
        buttonText: {
            paddingRight: theme.spacing(1),
            paddingLeft: mobile ? theme.spacing(2.7) : theme.spacing(0),
            color: theme.palette.primary.main,
            textAlign: "left"
        }
    }));


interface SelectCustomerTypeProps {
    customerType: string,
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    availableCustomerTypes: string[],
    campaign?: Campaign,
    setPopulatingFilters: Dispatch<SetStateAction<boolean>>
}

const SelectCustomerType: React.FC<SelectCustomerTypeProps> = props => {
    const {
        customerType: selectedCustomerType,
        setSelectedFilters,
        availableCustomerTypes,
        campaign,
        setPopulatingFilters
    } = props;
    const { translation, getLanguage } = useContext(LocalizationContext);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const language = getLanguage();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { pathname } = useLocation();

    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setPopulatingFilters(true);
        setSelectedFilters(prevState => ({
            ...prevState,
            customerType: value,
            selectedExperts: [],
            selectedLanguages: [],
            selectedPremises: [],
            selectedServices: [],
            selectedSpecialities: [],
            locationUuids: [],
            mapLocationUuids: null,
        }));
    };

    const getTranslation = (customerType: string) => {
        const key = getKey(customerType);
        return key ? translation[key] : null;
    };


    const getKey = (customerType: string) => {
        const [key] = Object.entries(customerTypes)
            .find(([_key, value]) => value === customerType) || [null, null];
        return key;
    };

    const radioButtons = availableCustomerTypes.map((customerType, index) =>
        <FormControlLabel
            key={`customerType-${index}`}
            id={`customerType-${index}`}
            value={customerType}
            control={
                <Radio
                    color="primary"
                    checked={selectedCustomerType === customerType}
                />}
            label={getTranslation(customerType)}
            className={classes.button}
        />);

    const getContent = (pathname: string) => {
        const id = servicesJson.find(service =>
            (service.path && Object.values(service.path)
                .some(path => new RegExp(path).test(pathname)))
            || campaign?.id === service.id
        )?.name;
        const content: { [index: string]: React.FC<ContentProps> | null } = {
            Fysioterapia: null,
            Hieronta: null,
            Psykoterapia: null,
            Psykologipalvelut: null,
            "Neuropsykologi-palvelut": null,
            "Puheterapia-palvelut": null,
            "Toimintaterapia-palvelut": null,
            Hammasklinikka: InfoDialogContentDentist,
            Uniklinikka: InfoDialogContentSleep,
            Tähystysklinikka: InfoDialogContentEndoscopy,
            Jalkojenhoito: null
        };
        return id ? content[id] : null;
    };

    const DialogTextContent = getContent(pathname);

    return (
        <>
            <FormControl
                component="div"
                className={mobile ? classes.rootMobile : classes.root}
            >
                <FormLabel
                    className={classes.formLabel}
                >
                    <Typography
                        variant="h6"
                        component="span"
                        color="textSecondary"
                    >
                        {translation.paymentMethod + ":"}
                    </Typography>
                </FormLabel>
                <RadioGroup
                    aria-label={translation.ariaLabelSelectCustomerTypeRadio}
                    name="paymentMethod"
                    value={selectedCustomerType}
                    onChange={handleChange}
                    className={mobile ? classes.formGroupMobile : classes.formGroup}
                >
                    {radioButtons}
                </RadioGroup>
                {language === "finnish" && DialogTextContent &&
                    <Button
                        id="info-dialog-button"
                        size={"medium"}
                        disableRipple={true}
                        className={classes.infoButton}
                        color="primary"
                        onClick={() => setDialogOpen(true)}
                    >
                        <Typography variant="body2" className={classes.buttonText}>
                            {translation.howToChooseCustomerType}
                        </Typography>
                        <InfoOutlinedIcon
                        />
                    </Button>
                }
            </FormControl>
            {DialogTextContent && <InfoDialog
                id="info-dialog"
                open={dialogOpen}
                translation={translation}
                closeDialog={() => setDialogOpen(false)}
            >
                <DialogTextContent translation={translation} />
            </InfoDialog>}
        </>
    );
};

export default SelectCustomerType;
