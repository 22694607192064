import React, { useContext, useState, useMemo } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ShortCancellationDisclaimer } from './Footers';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { SuccessButton, DefaultButton } from '../CustomStyleComponents/Buttons';
import { PrivacyPolicyLink } from '../Links';
import LoadingIndicator from '../UtilComponents/LoadingIndicator';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        "& >.MuiGrid-item:not(:last-child)": {
            paddingBottom: theme.spacing(2)
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    blue: {
        color: theme.palette.text.secondary
    },
    mobileButtons: {
        paddingBottom: theme.spacing(2)
    },
    buttonContainer: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        "& >div:last-child": {
            paddingBottom: 0
        }
    },
    headerPadding: {
        paddingBottom: theme.typography.pxToRem(17)
    },
    textPadding: {
        paddingBottom: theme.typography.pxToRem(13)
    },
    desktopCheckBoxes: {
        display: "flex",
        flexDirection: "row"
    },
    loading: {
        width: "1.5em",
        height: "1.5em",
        paddingRight: "1em"
    },
    desktopButtons: {
        "& >.MuiGrid-item:first-child": {
            paddingRight: theme.spacing(4)
        }
    },
}));

interface ReminderFormProps {
    handleConfirm: () => void,
    handleBack: () => void,
    marketingConsent: boolean,
    setMarketingConsent: (val: boolean) => void,
    remindHoursBefore: number | false,
    setRemindHoursBefore: (item: number | false) => void,
    remindDayBefore: boolean,
    setRemindDayBefore: (item: boolean) => void,
    isDentistCategoryTimeslot: boolean
}

const ReminderForm: React.FC<ReminderFormProps> = ({
    handleConfirm,
    handleBack,
    marketingConsent,
    setMarketingConsent,
    remindHoursBefore,
    setRemindHoursBefore,
    remindDayBefore,
    setRemindDayBefore,
    isDentistCategoryTimeslot
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);
    const [requestSent, setRequestSent] = useState<boolean>(false);

    const loading = useMemo(() =>
        <div style={{ display: "flex" }}>
            <LoadingIndicator className={classes.loading}></LoadingIndicator>
            <span>{translation.reservingTimeslot}</span>
        </div>
        , [LoadingIndicator, translation]);

    const confirmationButton = useMemo(() =>
        <SuccessButton
            variant="contained"
            color="primary"
            onClick={() => {
                setRequestSent(true);
                handleConfirm();
            }}
            id={"reserveTimeButton"}
            fullWidth
            disabled={requestSent}
        >
            {
                !requestSent ?
                    translation.buttonConfirm :
                    loading
            }
        </SuccessButton>
        , [handleConfirm, requestSent, translation]);

    const backButton = useMemo(() =>
        <DefaultButton
            onClick={handleBack}
            fullWidth
            disabled={requestSent}
        >
            {translation.buttonGoBack}
        </DefaultButton>
        , [requestSent, translation]);

    const mobileButtons = useMemo(() =>
        <Grid item className={classes.buttonContainer}>
            <div style={{ flex: 1 }} />
            <div className={classes.mobileButtons}>
                {confirmationButton}
            </div>
            <div className={classes.mobileButtons}>
                {backButton}
            </div>
        </Grid>
        , [confirmationButton, backButton, translation]);

    const desktopButtons = useMemo(() =>
        <Grid item container className={classes.desktopButtons}>
            <Grid item xs={12} md={6}>
                {backButton}
            </Grid>
            <Grid item xs={12} md={6}>
                {confirmationButton}
            </Grid>
        </Grid>
        , [confirmationButton, backButton, translation]);

    return (
        <Grid
            component="form"
            noValidate
            container
            item
            className={classes.form}
        >
            {
                isDentistCategoryTimeslot &&
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography
                            variant={"h6"}
                            className={`${classes.blue} ${classes.headerPadding}`}
                        >
                            {translation.iWantAReminderWithTextMessage}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        className={
                            windowIsMdSize ?
                                classes.desktopCheckBoxes :
                                undefined
                        }
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={remindDayBefore}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setRemindDayBefore(event.target.checked)
                                    }
                                    style={{ pointerEvents: "auto" }}
                                    color="primary"
                                    disabled={requestSent}
                                />}
                            label={
                                <Typography>
                                    {translation.oneDayBefore}
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={remindHoursBefore !== false}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setRemindHoursBefore(
                                            event.target.checked ?
                                                2 :
                                                false
                                        )
                                    }
                                    style={{ pointerEvents: "auto" }}
                                    color="primary"
                                    disabled={requestSent}
                                />}
                            label={
                                <Typography>
                                    {translation.twoHoursBefore}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
            }
            <Grid container item>
                <Grid item xs={12}>
                    <Typography
                        variant={"h6"}
                        className={`${classes.blue} ${classes.headerPadding}`}
                    >
                        {translation.dataProtectionAndMarketing}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={marketingConsent}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setMarketingConsent(event.target.checked)
                                }
                                style={{ pointerEvents: "auto" }}
                                color="primary"
                                disabled={requestSent}
                            />}
                        label={
                            <Typography>
                                {translation.checkboxMarketingPermission}
                                <PrivacyPolicyLink />
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography
                    variant={"h6"}
                    className={`${classes.blue} ${classes.headerPadding}`}
                >
                    {translation.summaryOfForm}
                </Typography>
                <Typography
                    className={classes.textPadding}
                >
                    {translation.sendSummaryOfReservation}
                </Typography>
                <ShortCancellationDisclaimer />
            </Grid>
            {windowIsMdSize ? desktopButtons : mobileButtons}
        </Grid>
    );
};

export default ReminderForm;
