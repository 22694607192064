import React, { useRef, useImperativeHandle, forwardRef, Ref } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme, createStyles, IconButton, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentMd: {
            paddingLeft: "51px",
            paddingRight: "51px",
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(4)
        },
        contentXs: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2)
        },
        header: {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.text.secondary,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.background.paper,
        }
    }));

interface CustomDialogProps {
    children: React.ReactChild,
    header: string,
    open: boolean,
    handleClose: () => void,
    fullScreen?: boolean,
    maxWidth?: DialogProps["maxWidth"]
}

export interface DialogRef {
    scrollToDialogTop: () => void
}

const CustomDialog = forwardRef(({
    children, header, open, handleClose, maxWidth, fullScreen
}: CustomDialogProps, ref: Ref<DialogRef>) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));
    const dialogRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollToDialogTop: () => {
            if (dialogRef && dialogRef.current) {
                const paperView = dialogRef.current.querySelector(".MuiDialog-paperScrollBody");
                paperView?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
            }
        }
    }));

    const classes = useStyles();

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : "xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="dialogTitle"
            fullScreen={fullScreen}
            scroll={"body"}
            ref={dialogRef}
            disableBackdropClick
            disableEnforceFocus
        >
            <DialogTitle
                id={"dialogTitle"}
                className={classes.header}
                disableTypography={true}
            >
                <Typography
                    variant={"h5"}
                    align={"center"}
                >
                    {header}
                </Typography>
                <IconButton
                    id={"closeModal"}
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={
                    windowIsMdSize ?
                        classes.contentMd :
                        classes.contentXs
                }
            >
                {children}
            </DialogContent>
        </Dialog>
    );
});

export default CustomDialog;
