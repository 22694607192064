import React from 'react';
import Heart from "../../icons/Heart";
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes heartbeat": {
            "0%": {
                transform: "scale(1)"
            },
            "15%": {
                transform: "scale(1.1)"
            },
            "30%": {
                transform: "scale(1)"
            },
            "45%": {
                transform: "scale(1.15)"
            },
            "100%": {
                transform: "scale(1)"
            },
        },
        icon: {
            color: theme.palette.primary.light,
            height: "100%",
            width: "100%",
            animation: "$heartbeat 1s linear infinite",
        },

    })
);


const LoadingIndicator: React.FC<{ className?: string }> = props => {
    const classes = useStyles();
    return (
        <div {...props}>
            <Heart className={classes.icon} />
        </div>
    );
};

export default LoadingIndicator;
