import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useAuthContext } from '../AuthContext';

const useStyles = makeStyles(() => ({
    logout: {
        float: "right"
    }
}));

const Logout = () => {
    const { logout } = useAuthContext();
    const classes = useStyles();

    const logoutFromApp = () => {
        if (logout) {
            logout();
        }
    };

    return (
        <div className={classes.logout}>
            <Button
                onClick={() => logoutFromApp()}
                color="primary"
            >
                Kirjaudu Ulos
                        </Button>
        </div>
    );
};

export default Logout;
