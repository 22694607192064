import { createMuiTheme, ThemeOptions } from "@material-ui/core";


const textColorPrimary = "#1A2226";
const textColorSecondary = "#003754";
const htmlFontSize = 18;
const main = "#005596";

export const colors = {
    greyText: "#595959",
    lightGrey: "#979797",
    boxBackground: "#DAEDF7",
    discountBackground: "#FBCF56",
    borderColor: "#CCCCCC"
};

const themeBase = {
    palette: {
        primary: {
            light: "#009CEB",
            main,
            secondary: "#B3B3B3"
        },
        success: {
            main: "#008A05"
        },
        error: {
            main: "#A91E00"
        },
        text: {
            primary: textColorPrimary,
            secondary: textColorSecondary,

        },
        background: {
            default: "#FFFFFF",
            paper: "#FFFFFF"
        }
    },
    typography: {
        htmlFontSize,
        size: "16px",
        fontFamily: [
            "Gotham Narrow Book",
            "Gotham Narrow Bold",
            "Gotham Narrow Medium",
            "Gotham Medium",
            "Gotham Book",
            "acumin-pro-extra-condensed",
            "sans-serif"
        ].join(","),

        h1: {
            fontSize: "64px",
            letterSpacing: 0,
            lineHeight: "96px",
            fontWeight: 800,
            color: "#009ceb",
            textTransform: "uppercase",
            fontFamily: [
                "acumin-pro-extra-condensed",
            ].join(","),
        },
        h2: {
            fontSize: "54px",
            lineHeight: "81px",
            letterSpacing: 0,
            fontFamily: [
                "Gotham Narrow Bold",
            ].join(","),
        },
        h3: {
            fontSize: "42px",
            letterSpacing: 0,
            lineHeight: "63px",
            fontFamily: [
                "Gotham Narrow Bold",
            ].join(","),
        },
        h4: {
            fontSize: "30px",
            letterSpacing: 0,
            lineHeight: "45px",
            fontFamily: [
                "Gotham Narrow Bold",
            ].join(","),
        },
        h5: {
            fontSize: "22px",
            letterSpacing: 0,
            lineHeight: "33px",
            fontFamily: [
                "Gotham Narrow Bold",
            ].join(","),
        },
        h6: {
            fontSize: "18px",
            letterSpacing: 0,
            lineHeight: "27px",
            fontFamily: [
                "Gotham Narrow Bold",
            ].join(","),
        },
        body1: {
            fontSize: "16px",
            letterSpacing: 0,
            lineHeight: "24px"
        },
        body2: {
            fontSize: "14px",
            color: textColorPrimary,
            lineHeight: "21px"
        },
        subtitle1: {
            fontFamily: "Gotham Narrow Medium",
            fontSize: "16px",
            letterSpacing: 0,
            lineHeight: "21px"
        },
        subtitle2: {
            fontFamily: "Gotham Narrow Book",
            fontSize: "14px",
            letterSpacing: 0,
            lineHeight: "21px"
        },
        button: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: "16px",
            letterSpacing: "0.5px",
            lineHeight: "24px"
        },
        caption: {
            fontFamily: "Gotham Narrow Book",
            fontSize: "12px",
            letterSpacing: 0,
            lineHeight: "18px"
        },
        overline: {
            fontFamily: "Gotham Narrow Book",
            fontSize: "12px",
            letterSpacing: "2px",
            lineHeight: "18px"
        }
    },
    overrides: {
        MuiPickersCalendarHeader: {
            daysHeader: {
                color: "#000000"
            },
            dayLabel: {
                color: "#000000",
                fontFamily: "Gotham Narrow Book",
                width: 40
            },
            switchHeader: {
                color: textColorPrimary,
                textTransform: "capitalize",
                "& .MuiTypography-body1": {
                    fontSize: 18,
                    fontFamily: "Gotham Narrow Bold",
                }
            }
        },
        MuiPickersDay: {
            dayDisabled: {
                margin: 0,
                width: 44,
                height: 44,
                borderRadius: 0,
                color: "#DAEDF7",
                "& .MuiIconButton-label": {
                    background: "#DAEDF7"
                }
            },
            hidden: {
                margin: 0,
                width: 44,
                height: 44
            }
        },
        MuiAutocomplete: {
            option: {
                fontFamily: "Gotham Narrow Book",
                fontSize: "16px",
                height: "auto",
                paddingRight: 0,
                paddingLeft: 0,
                paddingBottom: "1px",
                paddingTop: 0,
                "&:hover": {
                    backgroundColor: main,
                    color: "#FFFFFF",
                    "& .MuiSvgIcon-root": {
                        color: "#FFF"
                    },
                    "& .MuiItem-listItemText": {
                        color: "#FFF",
                        fontFamily: "Gotham Narrow Bold",
                    },
                    "& .MuiItem-popular": {
                        color: textColorSecondary
                    },
                },
                justifyContent: "space-between !important",
                "& .MuiSvgIcon-root": {
                    color: main
                }
            }
        },
        MuiButton: {
            root: {
                padding: "6px 16px",
            },
            outlined: {
                padding: "5px 16px",
            }
        }
    }
};

export const theme = createMuiTheme(themeBase as ThemeOptions);

export const darkInfoScreenTheme = createMuiTheme({
    ...themeBase as ThemeOptions,
    palette: {
        ...themeBase.palette,
        background: {
            default: "#075997",
        },
    }
});

export const lightInfoScreenTheme = createMuiTheme({
    ...themeBase as ThemeOptions,
    palette: {
        ...themeBase.palette,
        background: {
            default: "#FFFFFF",
        },
    }
});

export const containerShadow =
    "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)";
