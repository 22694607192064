import { websocketURL } from "../settings";
import {
    Building, Language, Expert, Premise, ExpertAndLocation,
    Service, TimeslotResponse, Address, Models, Operations,
    DataResponse, EntityResponse, WaitingResponses, ServiceAndLocation,
    RequestReservationResponseFailure, RequestReservationResponseSuccess, DataStore,
    Contexes,
    Locations,
    Experts,
    CancelReservationFailureResponse,
    CancelReservationSuccessResponse,
    LocationCoords,
    Languages,
    Specialities,
    Speciality,
    Services,
    CampaignService,
    MapBoxTokenResponse,
} from "../types";
import {
    initializeSocket, createCache, send, createOnmessage,
    createRequestSessionKey,
    utoa,
    arraysAreEqual,
    tryJSONParse,
    hasData
} from "./socketHelpers";
import moment from "moment";
import {
    expertStore,
    premiseStore,
    timeslotStore,
    buildingStore,
    serviceStore,
    addressStore,
    languageStore,
    specialityStore
} from "../store/stores";
import { waitingResponses as waitingResps } from "./AuthService";

export const waitingResponses: WaitingResponses<any> = process.env.REACT_APP_BUILD_ASPA === "ASPA" ? waitingResps : {};

const requestData = <T>(
    socket: WebSocket,
    filter: string[] = [],
    position?: GeolocationPosition | null,
    ctx?: Contexes
): Promise<DataResponse<T>> => {
    const location = position && {
        lon: position.coords.longitude,
        lat: position.coords.latitude
    };
    // API demands request to be strings with strict ordering
    const msgStr = `{"op":"${Operations.data}",${ctx ? `"ctx":"${ctx}",` : ""}"filter":${JSON.stringify(filter)}${location ? `,"location":{"lon":${location.lon},"lat":${location.lat}}` : ""}}`;
    const msg = JSON.parse(msgStr);

    const matchResponseToCallback = (resp: DataResponse<T>) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op &&
            respObj.filter &&
            arraysAreEqual(msg.filter, respObj.filter) &&
            respObj.ctx === ctx;
    };

    return new Promise((resolve, reject) => {
        const callback = (data: DataResponse<T>) => resolve(data);
        send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

const entityCache = createCache();
const requestEntity = <T>(
    socket: WebSocket,
    uuid: string,
): Promise<EntityResponse<T>> => {
    // API demands request to be strings with strict ordering
    const msgStr = `{"op":"${Operations.data}","uuid":"${uuid}"}`;
    const msg = JSON.parse(msgStr);

    const matchResponseToCallback = (resp: EntityResponse<T>) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op && msg.uuid === respObj.uuid;
    };

    return new Promise((resolve, reject) => {
        const callback = (data: EntityResponse<T>) => {
            entityCache.add(uuid, data);
            resolve(data);
        };
        const cached = entityCache.get(uuid);

        cached ?
            callback(cached.response as EntityResponse<T>) :
            send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

const requestDataStoreEntity = <T>(
    socket: WebSocket,
    uuid: string,
    store: DataStore<T>,
    location?: LocationCoords | null
): Promise<EntityResponse<T>> => {
    // API demands request to be strings with strict ordering
    const msgStr = `{"op":"${Operations.data}","uuid":"${uuid}"${location ? `,"location":{"lon":${location.lon},"lat":${location.lat}}` : ""}}`;
    const msg = JSON.parse(msgStr);

    const matchResponseToCallback = (resp: EntityResponse<T>) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op && msg.uuid === respObj.uuid;
    };

    return new Promise(async (resolve, reject) => {
        const callback = async (data: EntityResponse<T>) => {
            try {
                await hasData(data.data);
                await store.updateState(store.subject, data);
                resolve(data);
            } catch (err) {
                await store.updateState(store.subject, data);
                reject("Response has no data.");
            }
        };
        const cached = await store.getValueByUuid(uuid);
        cached ?
            resolve(cached) :
            send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

//Move this where it belongs
export interface TimeSpan {
    from: String,
    to: String
}
const createRequestTimesInRange = (socket: WebSocket) =>
    (
        dateFrom: Date,
        dateTo: Date,
        filter: string[] = [],

    ): Promise<DataResponse<any>> => {
        const from = moment.utc(dateFrom).local().format("YYYY-MM-DD");
        const to = moment.utc(dateTo).local().format("YYYY-MM-DD");

        // API demands request to be strings with strict ordering
        const msgStr = `{"op":"${Operations.appointment}","range":{"from":"${from}","to":"${to}"},"filter":${JSON.stringify(filter)}}`;
        const msg = JSON.parse(msgStr);
        const matchResponseToCallback = (resp: DataResponse<TimeslotResponse[]>) => {
            const respObj = tryJSONParse(resp as unknown as string);
            return respObj.op === msg.op &&
                respObj.range &&
                respObj.range.from === msg.range.from &&
                respObj.range.to === msg.range.to &&
                arraysAreEqual(msg.filter, respObj.filter);
        };

        return new Promise((resolve, reject) => {
            const callback = (data: any) => resolve(data);
            send(
                socket,
                callback,
                matchResponseToCallback,
                msg,
                waitingResponses,
                reject,
                msgStr
            );
        });
    };


const createDataStoreRequestTimesInHourRange = (
    socket: WebSocket
) => (
    date: Date,
    timeSpan: TimeSpan,
    filter: string[] = [],
    from: Number = 0,
    selectedExpertsUuids: string[],
    selectedPremisesUuids: string[],
    selectedServicesUuids: string[],
    limit: number = 30
): Promise<DataResponse<TimeslotResponse[]>> => {
        const formattedDate = moment.utc(date).local().format("YYYY-MM-DD");
        const range = timeSpan.from === "00:00" && timeSpan.to === "23:59" ? "" : `"range":{"from":"${timeSpan.from}Z","to":"${timeSpan.to}Z"},`;
        // API demands request to be strings with strict ordering
        const msgStr = `{"op":"${Operations.appointment}","date":"${formattedDate}",${range}"filter":${JSON.stringify(filter)},"from":${from},"limit":${limit}}`;
        const msg = JSON.parse(msgStr);

        const matchResponseToCallback = (resp: DataResponse<TimeslotResponse[]>) => {
            const respObj = tryJSONParse(resp as unknown as string);
            return respObj.op === msg.op &&
                respObj.date === msg.date &&
                ((respObj.range && respObj.range.from === msg.range.from &&
                    respObj.range.to === msg.range.to) || !respObj.range) &&
                arraysAreEqual(respObj.filter, msg.filter) &&
                msg.from === respObj.from;
        };

        return new Promise((resolve, reject) => {
            const callback = async (data: DataResponse<TimeslotResponse[]>) => {
                await timeslotStore.updateState(
                    timeslotStore.subject,
                    data,
                    selectedExpertsUuids,
                    selectedPremisesUuids,
                    selectedServicesUuids,
                    date
                );
                resolve(data);
            };
            send(
                socket,
                callback,
                matchResponseToCallback,
                msg,
                waitingResponses,
                reject,
                msgStr
            );
        });
    };


type ReservationResponses =
    RequestReservationResponseSuccess | RequestReservationResponseFailure;

const createRequestReservation = (
    socket: WebSocket
) => <T>(
    uuid: string,
    ssn: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailAddress: string,
    streetAddress: string,
    postalCode: string,
    localityName: string,
    dataConsent: boolean,
    marketingConsent: boolean,
    moreInformation: string,
    sendEmailConfirmation: boolean,
    sendSmsConfirmation: number | false,
    serviceVoucherCode: string | null,
    discountCode: string | null
): Promise<ReservationResponses> => {
        // API demands request to be strings with strict ordering
        const consent = `{"personalData":${dataConsent},"marketingData":${marketingConsent}}`;
        const email = emailAddress !== "" ? `"emailAddress":"${emailAddress.replace(/\s/g, "")}",` : "";
        const data = `{"ssn":"${ssn}","firstName":"${utoa(firstName)}","lastName":"${utoa(lastName)}","phoneNumber":"${utoa(phoneNumber)}",${email}"streetAddress":"${utoa(streetAddress)}","postalCode":"${utoa(postalCode)}","localityName":"${utoa(localityName)}","consent":${consent},"custom1":"${utoa(`${serviceVoucherCode ? `palvelusetelin numero: ${serviceVoucherCode}, ` : ""}${discountCode ? `alennuskoodi: ${discountCode}, ` : ""}${moreInformation}`)}","SendEmailConfirmation":${sendEmailConfirmation},"SendSmsConfirmation":${sendSmsConfirmation}}`;
        const msgStr = `{"op":"${Operations.appointment}","uuid":"${uuid}","data":${data}}`;
        const msg = JSON.parse(msgStr);
        const matchRespnseToCallback = (resp: ReservationResponses) => {
            const respObj = tryJSONParse(resp as unknown as string);
            return respObj.op === msg.op &&
                respObj.uuid === msg.uuid &&
                typeof respObj.success === "boolean";
        };

        return new Promise((resolve, reject) => {
            const callback = (data: ReservationResponses) => resolve(data);
            send(socket, callback, matchRespnseToCallback, msg, waitingResponses, reject, msgStr);
        });
    };

const createRequestReservationCancellation = (socket: WebSocket) => (
    reservationUuid: string,
    pin: string
): Promise<CancelReservationSuccessResponse | CancelReservationFailureResponse> => {
    // API demands request to be strings with strict ordering
    const msgStr = `{"op":"${Operations.appointment}","uuid":"${reservationUuid}","data":{"cancellation pin":"${pin}"}}`;
    const msg = JSON.parse(msgStr);

    const matchResponseToCallback = (
        resp: CancelReservationSuccessResponse | CancelReservationFailureResponse
    ) => {
        const respObj = tryJSONParse(resp as unknown as string);

        return respObj.op === msg.op &&
            respObj.uuid === reservationUuid &&
            typeof respObj.success === "boolean";
    };

    return new Promise((resolve, reject) => {
        const callback = (
            data: CancelReservationSuccessResponse | CancelReservationFailureResponse
        ) => resolve(data);

        send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

const createRequestServices = (
    socket: WebSocket,
) => (filters: string[]): Promise<DataResponse<Services>> =>
        requestData(socket, filters, null, Contexes.palvelu);

const createRequestAllServices = (
    socket: WebSocket,
) => (): Promise<DataResponse<CampaignService[]>> =>
        requestData(socket, [], null, Contexes.palvelu);

const createRequestExpertsAndLocations = (socket: WebSocket) => (
    filters: string[],
    position?: GeolocationPosition | null
): Promise<DataResponse<ExpertAndLocation>> =>
    requestData(socket, filters, position);

const createRequestLocation = (socket: WebSocket) => (
    filters: string[],
    position?: GeolocationPosition | null
): Promise<DataResponse<Locations>> =>
    requestData(socket, filters, position, Contexes.toimipaikka);

const createRequestExperts = (socket: WebSocket) => (
    filters: string[]
): Promise<DataResponse<Experts>> =>
    requestData(socket, filters, undefined, Contexes.työntekijä);

const createRequestServicesAndLocations = (socket: WebSocket) => (
    filters: string[],
    position?: GeolocationPosition
): Promise<DataResponse<ServiceAndLocation>> =>
    requestData(socket, filters, position);

type ICreatePopulateUuids =
    EntityResponse<Expert | Premise | Language | Speciality> | CampaignService | null

const createPopulateFilters = (socket: WebSocket) =>
    async (uuid: string, services: CampaignService[]): Promise<ICreatePopulateUuids> => {

        const service = services.find(service => service.uuid === uuid);
        if (service)
            return service;
        const populated = await requestEntity(socket, uuid);
        const expert = populated.model === Models.työntekijä ?
            populated as EntityResponse<Expert> :
            null;
        const premise = populated.model === Models.toimipaikka ?
            populated as EntityResponse<Premise> :
            null;
        const language = populated.model === Models.kieli ?
            populated as EntityResponse<Language> :
            null;
        const speciality = populated.model === Models.erityisosaaminen ?
            populated as EntityResponse<Speciality> :
            null;

        return expert || premise || language || speciality || null;
    };

const createRequestExpert = (socket: WebSocket) =>
    (expertUuid: string): Promise<EntityResponse<Expert>> =>
        requestDataStoreEntity(socket, expertUuid, expertStore);

const createRequestPremise = (socket: WebSocket) =>
    (premiseUuid: string): Promise<EntityResponse<Premise>> =>
        requestDataStoreEntity(socket, premiseUuid, premiseStore);

const createRequestBuilding = (socket: WebSocket) =>
    (buildingUuid: string, location?: LocationCoords | null): Promise<EntityResponse<Building>> =>
        requestDataStoreEntity(socket, buildingUuid, buildingStore, location);

const createRequestAddress = (socket: WebSocket) =>
    (addressUuid: string): Promise<EntityResponse<Address[]>> => {
        // API demands request to be strings with strict ordering
        const msgStr = `{"op":"${Operations.suomiOsoitteet}","ctx":"2019-1--14","uuid":"${addressUuid}"}`;
        const msg = JSON.parse(msgStr);

        const matcher = (resp: EntityResponse<Address[]>) => {
            const respObj = tryJSONParse(resp as unknown as string);
            return respObj.op === msg.op &&
                msg.ctx === respObj.ctx &&
                msg.uuid === respObj.uuid;
        };

        return new Promise(async (resolve, reject) => {
            const callback = async (data: EntityResponse<Address[]>) => {
                await addressStore.updateState(addressStore.subject, data);
                resolve(data);
            };

            const cached = await addressStore.getValueByUuid(addressUuid);
            return cached ?
                callback(cached) :
                send(socket, callback, matcher, msg, waitingResponses, reject, msgStr);
        });
    };

const createRequestService = (socket: WebSocket) =>
    (serviceUuid: string): Promise<EntityResponse<Service>> =>
        requestDataStoreEntity(socket, serviceUuid, serviceStore);

const createRequestLanguage = (socket: WebSocket) =>
    (languageUuid: string): Promise<EntityResponse<Language>> =>
        requestDataStoreEntity(socket, languageUuid, languageStore);

const createRequestLanguages = (socket: WebSocket) =>
    (filters: string[]): Promise<DataResponse<Languages>> =>
        requestData(socket, filters, null, Contexes.kieli);

const createRequestSpecialities = (socket: WebSocket) =>
    (filters: string[]): Promise<DataResponse<Specialities>> =>
        requestData(socket, filters, null, Contexes.erityisosaaminen);

const createRequestSpeciality = (socket: WebSocket) =>
    (specialityUuid: string): Promise<EntityResponse<Speciality>> =>
        requestDataStoreEntity(socket, specialityUuid, specialityStore);

const createRequestMapBoxToken = (socket: WebSocket) => (): Promise<MapBoxTokenResponse> => {
    const msgStr = `{"op":"${Operations.mapbox}","ctx":"${Contexes.temporaryToken}"}`;
    const msg = JSON.parse(msgStr);

    const matcher = (resp: MapBoxTokenResponse) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op && respObj.ctx === msg.ctx;
    };

    return new Promise((resolve, reject) => {
        const callback = async (data: MapBoxTokenResponse) =>
            resolve(data);

        return send(socket, callback, matcher, msg, waitingResponses, reject, msgStr);
    });


};

export interface IDataService {
    requestServices: (filters: string[]) => Promise<DataResponse<Services>>,
    requestAllServices: () => Promise<DataResponse<CampaignService[]>>,
    requestExpertsAndLocations: (
        filters: string[],
        position?: GeolocationPosition | null
    ) =>
        Promise<DataResponse<ExpertAndLocation>>,
    requestLocations: (
        filters: string[],
        position?: GeolocationPosition | null
    ) => Promise<DataResponse<Locations>>,
    requestExperts: (
        filters: string[]
    ) => Promise<DataResponse<Experts>>,
    requestExpert: (uuid: string) => Promise<EntityResponse<Expert>>,
    requestPremise: (uuid: string) => Promise<EntityResponse<Premise>>,
    requestTimesInRange: (dateFrom: Date, dateTo: Date, filter: string[]) =>
        Promise<DataResponse<string[]>>,
    requestTimesInHourRange: (
        date: Date,
        timeSpan: TimeSpan,
        filter: string[],
        from: Number,
        selectedExpertsUuids: string[],
        selectedPremisesUuids: string[],
        selectedServicesUuids: string[]
    ) =>
        Promise<DataResponse<TimeslotResponse[]>>,
    requestBuilding: (
        uuid: string,
        location?: LocationCoords | null
    ) => Promise<EntityResponse<Building>>,
    requestService: (uuid: string) => Promise<EntityResponse<Service>>,
    requestLanguage: (uuid: string) => Promise<EntityResponse<Language>>,
    requestAddress: (uuid: string) => Promise<EntityResponse<Address[]>>,
    populateFilters: (uuid: string, services: CampaignService[]) => Promise<ICreatePopulateUuids>,
    requestServicesAndLocations: (uuids: string[], position?: GeolocationPosition) =>
        Promise<DataResponse<ServiceAndLocation>>,
    requestReservation: (
        uuid: string,
        ssn: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        emailAddress: string,
        streetAddress: string,
        postalCode: string,
        localityName: string,
        dataConsent: boolean,
        marketingConsent: boolean,
        moreInformation: string,
        sendEmailConfirmation: boolean,
        sendSmsConfirmation: number | false,
        serviceVoucherCode: string | null,
        discountCode: string | null
    ) => Promise<ReservationResponses>,
    requestReservationCancellation: (
        reservationUuid: string,
        pin: string
    ) => Promise<CancelReservationSuccessResponse | CancelReservationFailureResponse>,
    requestLanguages: (
        filters: string[]
    ) => Promise<DataResponse<Languages>>,
    requestSpeciality: (
        uuid: string
    ) => Promise<EntityResponse<Speciality>>,
    requestSpecialities: (
        filters: string[]
    ) => Promise<DataResponse<Specialities>>,
    requestMapboxToken: () => Promise<MapBoxTokenResponse>,
    socket: WebSocket
}

export interface IInitializeDataService {
    service: (sessionKey: string) => IDataService,
    sessionKey: string
}

export const initializeDataService = (
    oldSessionKey?: string | null,
    onClose?: () => void
): Promise<IInitializeDataService> => {
    return new Promise((resolve, reject) => {
        const callback = async (socket: WebSocket) => {
            const fetchError = Error("Could not fetch session-key");

            return createRequestSessionKey(socket, waitingResponses, oldSessionKey)()
                .then((resp) => {
                    resp.success ?
                        resolve({
                            service: () => ({
                                requestServices: createRequestServices(socket),
                                requestAllServices: createRequestAllServices(socket),
                                requestExpertsAndLocations:
                                    createRequestExpertsAndLocations(socket),
                                requestLocations: createRequestLocation(socket),
                                requestExperts: createRequestExperts(socket),
                                requestServicesAndLocations:
                                    createRequestServicesAndLocations(socket),
                                requestExpert: createRequestExpert(socket),
                                requestPremise: createRequestPremise(socket),
                                requestTimesInRange: createRequestTimesInRange(socket),
                                requestTimesInHourRange:
                                    createDataStoreRequestTimesInHourRange(socket),
                                requestBuilding: createRequestBuilding(socket),
                                requestService: createRequestService(socket),
                                requestLanguage: createRequestLanguage(socket),
                                requestAddress: createRequestAddress(socket),
                                populateFilters: createPopulateFilters(socket),
                                requestReservation: createRequestReservation(socket),
                                requestReservationCancellation:
                                    createRequestReservationCancellation(socket),
                                requestLanguages: createRequestLanguages(socket),
                                requestSpeciality: createRequestSpeciality(socket),
                                requestSpecialities: createRequestSpecialities(socket),
                                requestMapboxToken: createRequestMapBoxToken(socket),
                                socket
                            }),
                            sessionKey: resp["session-key"]
                        }) : reject(fetchError);
                }).catch((_err) => {
                    reject(fetchError);
                });
        };

        initializeSocket(
            callback,
            createOnmessage(waitingResponses),
            websocketURL,
            reject,
            onClose
        );
    });
};

export const createDataService =
    (socket: WebSocket, oldSessionKey: string): Promise<IInitializeDataService> =>
        Promise.resolve({
            service: () => ({
                requestServices: createRequestServices(socket),
                requestAllServices: createRequestAllServices(socket),
                requestExpertsAndLocations:
                    createRequestExpertsAndLocations(socket),
                requestLocations: createRequestLocation(socket),
                requestExperts: createRequestExperts(socket),
                requestServicesAndLocations:
                    createRequestServicesAndLocations(socket),
                requestExpert: createRequestExpert(socket),
                requestPremise: createRequestPremise(socket),
                requestTimesInRange: createRequestTimesInRange(socket),
                requestTimesInHourRange:
                    createDataStoreRequestTimesInHourRange(socket),
                requestBuilding: createRequestBuilding(socket),
                requestService: createRequestService(socket),
                requestLanguage: createRequestLanguage(socket),
                requestAddress: createRequestAddress(socket),
                populateFilters: createPopulateFilters(socket),
                requestReservation: createRequestReservation(socket),
                requestReservationCancellation:
                    createRequestReservationCancellation(socket),
                requestLanguages: createRequestLanguages(socket),
                requestSpeciality: createRequestSpeciality(socket),
                requestSpecialities: createRequestSpecialities(socket),
                requestMapboxToken: createRequestMapBoxToken(socket),
                socket
            }),
            sessionKey: oldSessionKey
        });
