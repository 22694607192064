import React, { useState, useEffect, useContext, useMemo, Suspense, Dispatch, SetStateAction } from 'react';
import MultiFilter, { StyledChip } from "../MultiFilter";
import { Premise, EntityResponse, PremiseLocation, SelectedFilters } from "../../types";
import { ServiceContext } from "../ServiceContext";
import { AutocompleteGetTagProps, createFilterOptions, FilterOptionsState } from "@material-ui/lab";
import { sortStr, getMapRadius, isEmptyOrNull, isBetweenDates, allSettled, getKunta, getAddress } from '../utils';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { Typography, useMediaQuery, useTheme, makeStyles, createStyles, Theme } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOnRounded';
import DevicesIcon from '@material-ui/icons/DevicesRounded';
import LoadingIndicator from '../UtilComponents/LoadingIndicator';
import StyledTooltip from '../CustomStyleComponents/Tooltip';
import moment from 'moment';
import { colors } from "../../theme";
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const MapBox = React.lazy(() => import("../MapBox"));
const sleepClinicRemoteReception = "c69044a8-d356-11eb-9ff9-02004c4f4f50";
const isCoronariaFI = window.location.hostname.includes("coronaria.fi");

const group = (theme: Theme, mobile: boolean): CSSProperties => ({
    paddingLeft: mobile ? theme.spacing(0.5) : theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    height: "100%",
});

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        groupLocation: {
            ...group(theme, mobile),
        },
        location: {
            ...group(theme, mobile),
            justifyContent: "flex-start",
            borderBottom: `1px solid ${colors.boxBackground}`,
            "&:hover": {
                "& .MuiTypography-root": {
                    color: theme.palette.common.white
                }
            },
            "& .MuiTypography-root": {
                color: theme.palette.text.primary,
            }
        },
        remote: {
            ...group(theme, mobile),
            justifyContent: "flex-start",
            borderBottom: `1px solid ${colors.boxBackground}`,
            "&:hover": {
                "& .MuiTypography-root": {
                    color: theme.palette.common.white
                }
            },
            "& .MuiTypography-root": {
                color: theme.palette.text.primary,
            }
        },
        loading: {
            height: "1.5em",
            width: "1.5em"
        },
        loadingMap: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "4em",
            width: "4em"
        },
        listItem: {
            paddingLeft: theme.spacing(2)
        },
        listItemText: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    }), { name: "MuiItem" }
);

interface SearchPremiseProps {
    locationFilters: string[],
    locationUuids: PremiseLocation[] | null,
    premises: EntityResponse<Premise>[] | null,
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    position?: GeolocationPosition | null,
    enableLocation: () => void,
    locationEnabled?: boolean,
    setPosition?: (position: GeolocationPosition | null) => void,
    loadingInitialState?: boolean,
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}


const SearchPremise: React.FC<SearchPremiseProps> = (props) => {
    const {
        premises,
        setSelectedFilters,
        locationFilters,
        position,
        locationUuids,
        enableLocation,
        locationEnabled,
        loadingInitialState,
        accessToken,
        setAccessToken
    } = props;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [inputStr, setInputStr] = useState<string>("");
    const { dataService } = useContext(ServiceContext);
    const { translation } = useContext(LocalizationContext);
    const [selection, setSelection] = useState<PremiseLocation[] | null>(null);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const others = translation.others;

    const locationPremise = {
        uuid: "1234",
        toimitila: {
            uuid: "1234",
            Kunta: "AAAAA"
        },
        Nimi: {
            liiketoiminta: "",
            yritys: "",
            toimipaikka: "AAAAA",
            toimitila: ""
        },
    };

    const radius = useMemo(() =>
        position ? getMapRadius(position?.coords.latitude) : null,
        [position]
    );

    useEffect(() => {
        if (dataService && selection) {
            const fetchSelected = async () => {
                const premisePromises = await allSettled<EntityResponse<Premise>>(
                    selection.map(value =>
                        dataService.requestPremise(value.uuid)
                    )
                );
                const allResolved = premisePromises.reduce((acc, cur) =>
                    cur.status === "fulfilled" ?
                        [...acc, cur.value] :
                        acc,
                    [] as EntityResponse<Premise>[]
                );
                setSelectedFilters((prevState: SelectedFilters) => ({
                    ...prevState,
                    selectedPremises: allResolved
                }));
            };
            fetchSelected();
        }

    }, [selection, dataService]);

    useEffect(() => {
        if (premises?.length === 0) {
            setSelection(null);
        }
    }, [premises]);

    useEffect(() => {
        if (locationFilters.length !== 0 &&
            dataService &&
            !locationEnabled &&
            !loadingInitialState
        ) {
            const fetchLocations = async () => {
                try {
                    const currentDate = moment(new Date()).format("YYYY-MM-DD");
                    const resp = await dataService.requestLocations(locationFilters);
                    const filteredByStartDateAndEndDate = resp.data.toimipaikka
                        .filter(premise =>
                            isBetweenDates(
                                currentDate,
                                premise.alkamispäivä,
                                premise.päättymispäivä
                            ));
                    const filterRemoteReception = filteredByStartDateAndEndDate.filter((premise) =>
                        !(premise.uuid === sleepClinicRemoteReception && isCoronariaFI)
                    );

                    setSelectedFilters((prevState) => ({
                        ...prevState,
                        locationUuids: [locationPremise, ...filterRemoteReception]
                    }));
                    setLoading(false);
                } catch (_err) {

                }
            };
            fetchLocations();
        } else if (locationFilters.length === 0 && !loading) {
            setSelectedFilters((prevState) => ({
                ...prevState,
                locationUuids: null
            }));
        }
    }, [dataService, locationFilters, locationEnabled, loadingInitialState]);

    useEffect(() => setLoading(true), [locationFilters]);

    useEffect(() => {
        if (locationFilters.length !== 0 &&
            dataService &&
            locationEnabled &&
            position &&
            radius &&
            !loadingInitialState
        ) {
            const fetchLocations = async () => {
                const resp = await dataService.requestLocations(locationFilters, position);
                const filter = locationEnabled ? resp.data.toimipaikka
                    .filter(toimipaikka =>
                        toimipaikka.toimitila.dist && toimipaikka.toimitila.dist <= radius
                    ) :
                    resp.data.toimipaikka;
                setSelectedFilters((prevState) => ({
                    ...prevState,
                    mapLocationUuids: filter,
                    locationUuids: filter
                }));
            };
            fetchLocations();
        }
    }, [dataService, locationFilters, position, locationEnabled, radius, loadingInitialState]);

    const sort = (a: Filter, b: Filter) => {
        const aKunta = a.Kunta ?
            a.Kunta : "";
        const bKunta = b.Kunta ?
            b.Kunta : "";

        const kuntaSort =
            aKunta === bKunta ?
                0 :
                aKunta === "" ?
                    1 :
                    bKunta === "" ?
                        -1 :
                        aKunta < bKunta ?
                            -1 : 1;

        return kuntaSort !== 0 ?
            kuntaSort :
            a.toimitila && b.toimitila && a.toimitila.katuosoite && b.toimitila.katuosoite ?
                sortStr(a.toimitila.katuosoite, b.toimitila.katuosoite) :
                0;
    };

    const getLabel = (premise: Filter) => {
        if (premise.premises) {
            return premise.Kunta === "AAAAA" ?
                translation.locate : premise.Kunta;
        }
        const katuosoite = getAddress(premise.toimitila && premise.toimitila.katuosoite ?
            premise.toimitila.katuosoite :
            ""
        ).trim();
        const toimitila = premise.Nimi && premise.Nimi.toimitila ?
            premise.Nimi.toimitila.trim() :
            "";

        return (!isEmptyOrNull(katuosoite) && !isEmptyOrNull(toimitila) ?
            `${toimitila}, ${katuosoite}` :
            !isEmptyOrNull(katuosoite) ?
                katuosoite :
                !isEmptyOrNull(toimitila) ?
                    toimitila :
                    premise.toimitila?.katuosoite ?
                        getAddress(premise.toimitila.katuosoite) :
                        "");
    };

    const isNearMeOption = (option: Filter) =>
        option.Kunta === "AAAAA";

    const filterForInputs = (
        options: Filter[],
        state: FilterOptionsState<Filter>
    ) => createFilterOptions({
        matchFrom: "any",
        stringify: (option: Filter) => {
            const kunta = option.Kunta;

            const strToCheckAgainstUsersSearchStr: string =
                getLabel(option) +
                " | " +
                (kunta !== "" ? kunta : others);

            return strToCheckAgainstUsersSearchStr;
        },
        ignoreCase: true
    })(options, state);

    const filterOptions = (
        options: Filter[],
        state: FilterOptionsState<Filter>
    ) => {
        const option = options.find(isNearMeOption);
        const newOptions = options.reduce((acc, cur) => {
            return cur.Kunta === "AAAAA" ? acc : [...acc, cur];
        }, [] as Filter[]);
        return option ?
            [option, ...filterForInputs(newOptions, state)] :
            filterForInputs(newOptions, state);
    };

    const renderTags = (value: Filter[], getTagProps: AutocompleteGetTagProps) => {
        return value.map((option: Filter, index: number) => {
            return (<StyledTooltip
                key={`chip-${index}`}
                title={getLabel(option)}
            >
                <StyledChip
                    id={`chip-${option.uuid}`}
                    label={getLabel(option)} {...getTagProps({ index })}
                />
            </StyledTooltip>
            );
        });
    };

    const onChange = (_e: any, value: Filter[]) => {
        if (value.length > 0 && value.some(val => val.Kunta === "AAAAA")) {
            enableLocation();
            return;
        }

        const isNewRemoteSelection = value[value.length - 1]?.Kunta.includes("Etävastaanotto");
        const filteredValue = value.filter((val) => {
            const isRemote = val.Kunta.includes("Etävastaanotto");
            return isNewRemoteSelection ? isRemote : !isRemote;
        });

        const multipleValues = filteredValue.filter(val => val.premises !== undefined);
        const vals = multipleValues.reduce((acc, cur) =>
            cur.premises ? [...acc, ...cur.premises] : acc,
            [] as PremiseLocation[]
        );
        const singleValue = filteredValue.filter(val => val.premises === undefined);
        const values = [...vals, ...singleValue] as PremiseLocation[];
        setSelection(values);
        setInputStr("");
    };

    const kunnat = useMemo(() =>
        locationUuids?.reduce((acc, cur) => {
            const isAdded =
                acc.find(kunta =>
                    kunta === getKunta(cur.toimitila.katuosoite ?? "")
                );

            let kunta;
            if (cur.Nimi.toimipaikka === "AAAAA") {
                kunta = cur.Nimi.toimipaikka;
            } else if (cur.uuid === sleepClinicRemoteReception) {
                kunta = "Etävastaanotto Uniklinikka";
            } else {
                kunta = getKunta(cur.toimitila.katuosoite ?? "");
            }
            return !isAdded && kunta ?
                [kunta, ...acc] :
                acc;
        }, [] as string[]),
        [locationUuids]
    );

    const premisesInKunta = useMemo(() => kunnat?.reduce((acc, cur) => {
        const curPremises = locationUuids?.filter((premise) => {
            if (premise.uuid === sleepClinicRemoteReception && cur.includes("Etävastaanotto")) {
                return true;
            } else {
                return (
                    (
                        getKunta(premise.toimitila.katuosoite ? premise.toimitila.katuosoite : "") === cur
                    ) &&
                    premise.toimitila.uuid !== "1234"
                );
            }
        });

        const premisesWithKunta = curPremises
            ? curPremises?.map(premise => ({ ...premise, Kunta: cur }))
            : [];

        const isRemote = cur.includes("Etävastaanotto");
        const result = [
            ...acc,
            {
                Kunta: cur,
                premises: curPremises,
            },
        ];
        const returnValue = isRemote ? result : [...result, ...premisesWithKunta];

        return curPremises ? returnValue : acc;
    }, [] as Filter[]),
        [locationUuids, kunnat]
    );

    const renderOption = (option: Filter) => {
        if (option.Kunta === "AAAAA") {
            return <div
                id={"locationButton"}
                key={"header" + option.Kunta}
                className={classes.location}
            >
                <div style={{ paddingRight: "10px" }}>
                    <LocationOnIcon color="primary" />
                </div>
                <Typography className={classes.listItemText} variant="subtitle1">
                    {getLabel(option)}
                </Typography>
            </div>;
        } else if ((option.Kunta.toLowerCase()).includes("etävastaanotto")) {
            return (
                <div
                    id={"remoteButton"}
                    key={"header" + option.Kunta}
                    className={classes.remote}
                >
                    <div style={{ paddingRight: "10px" }}>
                        <DevicesIcon />
                    </div>
                    <Typography className={classes.listItemText} variant="subtitle1">
                        {getLabel(option)}
                    </Typography>
                </div>
            );
        } else {
            return <div
                id={
                    option.premises !== undefined ?
                        `header-${option.Kunta}` :
                        undefined
                }
                className={
                    option.premises !== undefined ?
                        classes.groupLocation :
                        classes.listItem
                }
            >
                <Typography className={classes.listItemText} variant="body1" component="span">
                    {getLabel(option)}
                </Typography>
            </div>;
        }
    };

    const loadingEl = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {translation.loadingPremises}
            <LoadingIndicator className={classes.loading} />
        </div>
    );

    const onOpen = (_event: any) => {
        setOpen(true);
    };

    const onClose = (_event: any, _reason: string) => {
        setOpen(false);
    };


    type Filter = Partial<PremiseLocation> &
    {
        Kunta: string,
        premises?: PremiseLocation[]
    }

    const sortedPremises = useMemo(() => premisesInKunta ?
        premisesInKunta.sort(sort) :
        [],
        [premisesInKunta]
    );

    const value = useMemo(() => {
        const selectedKunnat =
            sortedPremises.filter(location =>
                location.premises && location.premises.length > 1 &&
                location.premises?.every(premise =>
                    premises?.some(pre => pre.uuid === premise.uuid) ||
                    selection?.some(pre => pre.uuid === premise.uuid)
                )
            );

        const selectedPremises = sortedPremises.filter(prem =>
            premises?.some(premise => prem.uuid === premise.uuid) ||
            selection?.some(premise => prem.uuid === premise.uuid)
        );

        const filteredPremises = selectedPremises.filter(premise =>
            !selectedKunnat.find(kunta => premise.Kunta === kunta.Kunta)
        );

        const isRemoteReception = selection?.some((item) => (
            item.uuid === sleepClinicRemoteReception)
        );
        const remoteReception = sortedPremises.filter((premises) => (
            isRemoteReception && premises.premises?.find((premise) => (
                premise.uuid === sleepClinicRemoteReception)
            )
        ));

        return [...selectedKunnat, ...filteredPremises, ...remoteReception];

    },
        [sortedPremises, premises, selection]
    );

    const getOptionSelected = (option: Filter, newValue: Filter) => {
        if (option.premises !== undefined) {
            return newValue.Kunta === option.Kunta;
        }
        return option.uuid === newValue.uuid
            || !!newValue.premises?.find(premise => premise.uuid === option.uuid);
    };

    const onInputChange = (e: any) => {
        e.preventDefault();
        setInputStr(e.target.value);
    };

    return !locationEnabled ? (
        <MultiFilter<Filter>
            id={"searchPremise"}
            key="search-premise-filter"
            options={loading ? [] : sortedPremises}
            label={translation.premiseOrMunicipality}
            placeholder={premises && premises.length === 0 ? translation.searchForPremiseOrMunicipality : ""}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getLabel}
            filterOptions={filterOptions}
            renderTags={renderTags}
            onChange={onChange}
            loading={loading}
            loadingText={loadingEl}
            open={open}
            value={value}
            onOpen={onOpen}
            onClose={onClose}
            renderOption={renderOption}
            onInputChange={onInputChange}
            inputStr={inputStr}
        />) :
        (
            <Suspense
                fallback={
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "282px"
                    }}>
                        <LoadingIndicator className={classes.loadingMap} />
                    </div>
                }
            >
                <div style={{
                    width: "100%",
                    height: "282px",
                    minWidth: "280px",
                    display: "block",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <MapBox
                        position={position}
                        locationUuids={locationUuids}
                        setSelectedFilters={setSelectedFilters}
                        containerId="premise-search"
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    />
                </div>
            </Suspense>
        );
};

export default SearchPremise;
