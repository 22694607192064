import React, { useContext, useMemo, Suspense, Dispatch, SetStateAction } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { PopulatedTimeslot, Building, EntityResponse, Premise, CampaignService } from "../../types";
import { makeStyles, createStyles, Theme, Card, useTheme, useMediaQuery } from '@material-ui/core';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { getPrettyDate, getKunta, getLengthOfAppointment, getReservationTimeButtonText, getAddress, getPostalCode, parsePrice, getTime } from "../utils";
import moment from 'moment';
import { ExpertPictureNameAndTitle } from '../ExpertProfile/ExpertPictureNameAndTitle';
import { DefaultButton, SuccessButton } from '../CustomStyleComponents/Buttons';
import LoadingIndicator from '../UtilComponents/LoadingIndicator';

const MapBox = React.lazy(() => import("../MapBox"));

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        map: {
            display: "block",
            position: "relative",
            height: 172,
            minHeight: 172,
            width: "100%",
            marginBottom: theme.spacing(2)
        },
        paper: {
            justifyContent: "center",
            height: "100%",
            "& >.MuiGrid-item:not(:last-child)": {
                paddingBottom: theme.spacing(2),
            },
            "& >.MuiGrid-item:first-child": {
                paddingRight: mobile ? 0 : theme.spacing(2)
            }
        },
        blue: {
            color: theme.palette.text.secondary
        },
        serviceName: {
            color: theme.palette.text.secondary,
            paddingTop: theme.spacing(2),
        },
        serviceDescription: {
            paddingBottom: theme.spacing(2)
        },
        mobileButtons: {
            paddingBottom: theme.spacing(2),
            "&:last-child": {
                paddingBottom: 0
            }
        },
        desktopButtons: {
            "& >.MuiGrid-item:first-child": {
                paddingRight: theme.spacing(4)
            }
        },
        buttonContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
        },
        loading: {
            width: "4em",
            height: "4em"
        }
    }));

interface InitialInformationProps {
    timeslot: PopulatedTimeslot,
    serviceName: string,
    building: EntityResponse<Building> | null,
    premise: EntityResponse<Premise> | null,
    service: CampaignService | null,
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}

export const InitialInformation: React.FC<InitialInformationProps> = ({
    timeslot,
    serviceName,
    building,
    premise,
    service,
    accessToken,
    setAccessToken
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles(!windowIsMdSize)();
    const { translation } = useContext(LocalizationContext);
    const location = useMemo(() => building && building.data.location ?
        building.data.location :
        null, [building]);

    const liiketoiminta = premise && premise.data.Nimi.liiketoiminta ?
        premise.data.Nimi.liiketoiminta + " " :
        "";

    const yritys = premise && premise.data.Nimi.yritys ?
        premise.data.Nimi.yritys + " " : "";

    const toimipaikka = premise && premise.data.Nimi.toimipaikka ?
        premise.data.Nimi.toimipaikka + " " : "";

    const toimitila = premise && premise.data.Nimi.toimitila ?
        premise.data.Nimi.toimitila : "";

    const nimi = `${liiketoiminta}${yritys}${toimipaikka}${toimitila}`;

    return (
        <>
            <Grid
                container
                item xs={12}
                sm={6}
            >
                <Grid item xs={12}>
                    <Typography
                        variant={"subtitle1"}
                        className={classes.blue}
                    >
                        {
                            `${getPrettyDate(moment.utc(timeslot.start).local(), translation)}` +
                            ` ${translation.clock} ${getTime(timeslot.start)}`
                        }
                    </Typography>
                    <Typography
                        variant={"subtitle1"}
                        className={classes.serviceName}
                    >
                        {`${serviceName} ${getLengthOfAppointment(timeslot)} min`}
                    </Typography>
                    {service &&
                        <Typography
                            className={classes.serviceDescription}
                            variant="subtitle2"
                        >
                            {service.Kuvaus}
                        </Typography>
                    }
                    <ExpertPictureNameAndTitle
                        expert={timeslot.työntekijä}
                        size={"small"}
                    />
                </Grid>

                {timeslot.hinta &&
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.blue}>
                            {translation.price} {parsePrice(timeslot.hinta)}€
                        </Typography>
                    </Grid>
                }

            </Grid>
            <Grid item xs={12} md={6}>
                {location && <Card className={classes.map}>
                    <Suspense fallback={<LoadingIndicator className={classes.loading} />}>
                        <div style={{
                            display: "block",
                            position: "relative",
                            width: "100%",
                            height: "100%"
                        }}>
                            <MapBox
                                location={location}
                                containerId={timeslot.uuid}
                                accessToken={accessToken}
                                setAccessToken={setAccessToken}
                            />
                        </div>
                    </Suspense>
                </Card>}
                {
                    building && premise &&
                    <React.Fragment>
                        {
                            <Typography
                                variant={"subtitle1"}
                                className={classes.blue}
                            >
                                {`${nimi}`}
                            </Typography>
                        }
                        <Typography>
                            {
                                !building.data.katuosoite ? "" :
                                    getAddress(building.data.katuosoite)
                            }
                        </Typography>
                        <Typography>
                            {`${getPostalCode(building.data.katuosoite ? building.data.katuosoite : "")} ${building.data.katuosoite ?
                                getKunta(building.data.katuosoite) :
                                getKunta(building.data.Markkinointinimi)
                                }`}
                        </Typography>
                    </React.Fragment>
                }
            </Grid>
        </>
    );
};

interface InitialInformationViewProps extends InitialInformationProps {
    handleNext: () => void,
    handleClose: () => void
}

export const InitialInformationView: React.FC<InitialInformationViewProps> = ({
    timeslot,
    serviceName,
    building,
    premise,
    service,
    handleClose,
    handleNext,
    accessToken,
    setAccessToken
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles(!windowIsMdSize)();
    const { translation } = useContext(LocalizationContext);

    const successButton = useMemo(() =>
        <SuccessButton
            color="primary"
            onClick={handleNext}
            fullWidth
            id="nextStep"
        >
            {getReservationTimeButtonText(timeslot?.start, translation)}
        </SuccessButton>
        , [translation, handleNext]);

    const abortButton = useMemo(() =>
        <DefaultButton
            onClick={handleClose}
            fullWidth
            id={"closeModal"}
        >
            {translation.buttonAbort}
        </DefaultButton>
        , [translation, handleClose]);

    const mobileButtons = useMemo(() =>
        <Grid item className={classes.buttonContainer}>
            <div style={{ flex: 1 }} />
            <div className={classes.mobileButtons}>
                {
                    windowIsMdSize ?
                        abortButton :
                        successButton
                }
            </div>
            <div className={classes.mobileButtons}>
                {
                    windowIsMdSize ?
                        successButton :
                        abortButton
                }
            </div>
        </Grid>
        , [classes, windowIsMdSize]);

    const desktopButtons = useMemo(() =>
        <Grid item container xs={12} className={classes.desktopButtons}>
            <Grid item md={6} xs={12}>
                {
                    abortButton
                }
            </Grid>
            <Grid item md={6} xs={12}>
                {
                    successButton
                }
            </Grid>
        </Grid>
        , [classes]);

    return (
        <Grid
            item
            container
            xs={12}
            className={classes.paper}
        >
            <InitialInformation
                timeslot={timeslot}
                serviceName={serviceName}
                building={building}
                premise={premise}
                service={service}
                accessToken={accessToken}
                setAccessToken={setAccessToken}
            />
            {windowIsMdSize ? desktopButtons : mobileButtons}
        </Grid>
    );
};

export default InitialInformationView;
