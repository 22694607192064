import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import { Grid, makeStyles, createStyles, Theme, useMediaQuery, Collapse, Button, TextField } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SelectCustomerType from './Filters/SelectCustomerType';
import { Premise, EntityResponse, Expert, PremiseLocation, Speciality, Campaign, SelectedFilters, CampaignService, Language } from '../types';
import SearchPremise from "./Filters/SearchPremise";
import SearchService from "./Filters/SearchService";
import SearchExpert from "./Filters/SearchExpert";
import SearchExpertPhysio from "./Filters/SearchExpertPhysio";
import ExtraFilters from './Filters/ExtraFilters/ExtraFilters';
import { theme } from '../theme';
import { LocalizationContext } from './LanguageContext/LocalizationContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import CalendarFilter from './CalendarFilter';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizedUtils } from './LocalizedUtils';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayRounded';
import FullWidthDialog from './FullWidthDialog';
import Logout from './Logout';
import { FilterServiceMenu } from './FilterServiceMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "#DAEDF7",
        },
        itemMobile: {
            paddingBottom: theme.spacing(4),
            "&:last-child": {
                paddingBottom: 0
            }
        },
        itemDesktop: {
            paddingRight: theme.spacing(2),
            "&:last-child": {
                paddingBottom: 0,
                paddingRight: 0
            }
        },
        collapse: {
            width: "100%",
        },
        button: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.main,
            paddingLeft: 0,
        },
        buttonGrid: {
            paddingTop: theme.spacing(4)
        },
        dateInput: {
            cursor: "none",
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            borderRadius: "4px",
            "& label": {
                color: "#595959",
                fontFamily: "Gotham Narrow Medium",
                "&::placeholder": {
                    opacity: 0.7
                }
            },
            "& .MuiInputLabel-outlined": {
                transform: "translate(6px, -16px) scale(0.75)"
            },
            "& legend": {
                maxWidth: 0
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                color: "#595959",
                '& fieldset': {
                    borderWidth: "1px",
                    borderColor: "#979797",
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "1px",
                    borderColor: theme.palette.primary.main,
                },
            },
            "& .MuiOutlinedInput-input": {
                cursor: "none",
                color: "#595959"
            }
        },
        logout: {
            float: "right"
        },
        serviceMenu: {
            paddingBottom: theme.spacing(4)
        }
    })
);

interface FilterMenuProps {
    date: MaterialUiPickersDate,
    onDateChange: (date: MaterialUiPickersDate) => void,
    premises: EntityResponse<Premise>[],
    selectedServices: CampaignService[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    position?: GeolocationPosition | null,
    selectedExperts: EntityResponse<Expert>[],
    timeselectListFilters: string[] | null,
    locationFilters: string[],
    expertFilters: string[],
    services: CampaignService[],
    locationUuids: PremiseLocation[] | null,
    languageFilters: string[],
    specialityFilters: string[],
    selectedSpecialities: EntityResponse<Speciality>[],
    selectedLanguages: EntityResponse<Language>[],
    serviceFilters: string[],
    localization: Locale,
    utils: typeof DateFnsUtils | typeof LocalizedUtils,
    onCalendarModalOpen: () => void,
    onCalendarModalClose: () => void,
    calendarModalOpen: boolean,
    slots: string[],
    setSlots: (slots: string[]) => void,
    handleMonthChange: (renderedDay: MaterialUiPickersDate) => Promise<void>,
    enableLocation: () => void,
    disableLocation: () => void,
    customerType: string,
    campaign?: Campaign,
    loadingInitialState: boolean,
    availableCustomerTypes: string[],
    setPopulatingFilters: Dispatch<SetStateAction<boolean>>,
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>,
    urlBase?: string[]
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
    premises,
    selectedExperts,
    selectedServices,
    setSelectedFilters,
    locationFilters,
    expertFilters,
    services,
    locationUuids,
    position,
    languageFilters,
    specialityFilters,
    serviceFilters,
    timeselectListFilters,
    date,
    onDateChange,
    utils,
    localization,
    onCalendarModalClose,
    onCalendarModalOpen,
    calendarModalOpen,
    slots,
    setSlots,
    handleMonthChange,
    enableLocation,
    customerType,
    campaign,
    loadingInitialState,
    selectedSpecialities,
    selectedLanguages,
    availableCustomerTypes,
    setPopulatingFilters,
    accessToken,
    setAccessToken,
    urlBase,
}) => {
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [checked, setChecked] = useState<boolean>(false);
    const { translation } = useContext(LocalizationContext);

    const handleChecked = (isOpen: boolean) => (_e: any) => {
        setChecked(isOpen);
    };

    useEffect(() => {
        if (selectedLanguages.length || selectedSpecialities.length) {
            setChecked(true);
        }
    }, [selectedLanguages, selectedSpecialities]);

    useEffect(() => {
        setChecked(!mobile);
    }, [mobile]);

    const endIcon = !checked ? <ExpandMoreIcon /> : <ExpandLessIcon />;

    const getExpertFilter = (campaignName?: string) => {
        const filter: { [index: string]: JSX.Element } = {

            Fysioterapia: <SearchExpertPhysio
                expertFilters={expertFilters}
                setSelectedFilters={setSelectedFilters}
                loadingInitialState={loadingInitialState}
                selectedExperts={selectedExperts}
            />,
            default: <SearchExpert
                expertFilters={expertFilters}
                setSelectedFilters={setSelectedFilters}
                loadingInitialState={loadingInitialState}
                selectedExperts={selectedExperts}
                services={services}
            />
        };
        return campaignName && filter[campaignName] ? filter[campaignName] : filter.default;
    };

    return (
        <Grid
            container
            item
            spacing={0}
            className={classes.root}
            role={"search"}
            aria-label={translation.ariaLabelFilterMenu}
        >
            <Grid container justify="space-between" alignItems="center" item xs={12} className={classes.serviceMenu}>
                <Grid item xs={3}>
                    <FilterServiceMenu
                        urlBase={urlBase}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Logout />
                </Grid>
            </Grid>

            <Grid item xs={10}>
                <SelectCustomerType
                    customerType={customerType}
                    setSelectedFilters={setSelectedFilters}
                    availableCustomerTypes={availableCustomerTypes}
                    campaign={campaign}
                    setPopulatingFilters={setPopulatingFilters}
                />
            </Grid>

            <Grid container item xs={12}>
                <Grid
                    item
                    md={4}
                    xs={12}
                    className={mobile ? classes.itemMobile : classes.itemDesktop}
                >
                    <SearchPremise
                        locationFilters={locationFilters}
                        premises={premises}
                        position={position}
                        setSelectedFilters={setSelectedFilters}
                        locationUuids={locationUuids}
                        enableLocation={enableLocation}
                        loadingInitialState={loadingInitialState}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    className={mobile ? classes.itemMobile : classes.itemDesktop}
                >
                    <SearchService
                        services={services}
                        selectedExperts={selectedExperts}
                        selectedPremises={premises}
                        setSelectedFilters={setSelectedFilters}
                        selectedServices={selectedServices}
                        serviceFilters={serviceFilters}
                    />
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    className={mobile ? classes.itemMobile : classes.itemDesktop}
                >
                    {getExpertFilter(campaign?.name)}
                </Grid>
                {mobile &&
                    <Grid item xs={12}>
                        <TextField
                            className={classes.dateInput}
                            placeholder={translation.selectDay}
                            fullWidth
                            variant="outlined"
                            label={translation.day.toUpperCase()}
                            value={!date ?
                                null :
                                date}
                            onClick={onCalendarModalOpen}
                            InputProps={{
                                endAdornment: (<CalendarTodayIcon />),
                                readOnly: true,
                                value: date ? moment(date).format("YYYY-MM-DD") : null
                            }}
                        />

                        <FullWidthDialog
                            header={translation.selectDay}
                            open={calendarModalOpen}
                            handleClose={onCalendarModalClose}
                        >
                            <CalendarFilter
                                date={date}
                                filters={timeselectListFilters}
                                onDateChange={onDateChange}
                                utils={utils}
                                localization={localization}
                                slots={slots}
                                setSlots={setSlots}
                                handleMonthChange={handleMonthChange}
                            />
                        </FullWidthDialog>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
                <Button
                    id={"showExtraFilters"}
                    variant="text"
                    onClick={handleChecked(!checked)}
                    endIcon={endIcon}
                    className={classes.button}
                >
                    {!checked ? translation.showExtraFilters : translation.hideExtraFilters}
                </Button>
            </Grid>
            <Collapse
                className={classes.collapse}
                in={checked}
                timeout="auto"
            >
                <ExtraFilters
                    languageFilters={languageFilters}
                    specialityFilters={specialityFilters}
                    selectedSpecialities={selectedSpecialities}
                    selectedLanguages={selectedLanguages}
                    setSelectedFilters={setSelectedFilters}
                    loadingInitialState={loadingInitialState}
                />
            </Collapse>
        </Grid>
    );
};
