import React, { useContext, useMemo } from 'react';
import { ListItem, makeStyles, createStyles, Theme, ListItemAvatar, Divider, useMediaQuery, useTheme, Link } from "@material-ui/core";
import { CampaignService, PopulatedTimeslot, PremiseLocation } from '../../types';
import { ExpertPicture } from '../ExpertPicture';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import ListItemMobile from './ListItemMobile';
import ListItemDesktop from './ListItemDesktop';
import { getTime } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        time: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: theme.spacing(2),
        },
        avatar: {
            padding: 0,
            height: "100%"
        },
        expert: {
            alignItems: "center",
            padding: theme.spacing(2)
        },
        expertTitle: {
            fontFamily: "Gotham Narrow Book",
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(17),
            letterSpacing: theme.typography.pxToRem(0.42)
        },
        timeOfDay: {
            fontFamily: "Gotham Narrow Book",
            fontSize: theme.typography.pxToRem(21),
            lineHeight: theme.typography.pxToRem(25)
        },
        lengthOfAppointment: {
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(22),
            color: "#085B9B"
        },
        locationText: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(17),
            letterSpacing: theme.typography.pxToRem(0.49),
            color: "#000000"
        },
        cardMedia: {
            width: "60px",
            minHeight: "60px",
        },
        cardMediaDesktop: {
            width: "80px",
            minHeight: "80px",
        },
        centered: {
            width: "100%",
            height: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center"
        },

        listItem: {
            padding: theme.spacing(2)
        },
        linkButton: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: "1em",
            background: "none!important",
            border: "none",
            padding: "0!important",
            color: theme.palette.primary.main,
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "none"
        },


    }));

interface TimeSelectListItemProps {
    timeslot: PopulatedTimeslot,
    position?: GeolocationPosition | null,
    openReservationModal: (timeslot: PopulatedTimeslot) => void,
    openExpertModal: (timeslot: PopulatedTimeslot) => void,
    locationUuids: PremiseLocation[] | null,
    id: string,
    services?: CampaignService[]
}

const TimeSelectListItem: React.FC<TimeSelectListItemProps> = ({
    timeslot,
    openReservationModal,
    openExpertModal,
    id,
    services
}) => {
    const classes = useStyles();
    const { getLanguage, translation } = useContext(LocalizationContext);
    const language = getLanguage();

    const uiTime = getTime(timeslot.start);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const numberOfTitles = mobile ?
        1 : 5;

    const expertPicture = useMemo(() =>
        <Link
            onClick={timeslot.työntekijä.data.Nimi ?
                () => openExpertModal(timeslot) :
                undefined}
            style={{
                cursor: "pointer"
            }}
        >
            <ListItemAvatar
                className={classes.avatar}>
                <ExpertPicture
                    uuid={timeslot.työntekijä.uuid}
                    className={mobile ? classes.cardMedia : classes.cardMediaDesktop}
                />
            </ListItemAvatar>
        </Link>
        , [timeslot, mobile]);

    return (
        <>
            <ListItem
                component="div"
                alignItems="flex-start"
                className={classes.listItem}
                id={id}
            >
                {mobile ?
                    <ListItemMobile
                        expertPicture={expertPicture}
                        translation={translation}
                        populatedSlot={timeslot}
                        numberOfTitles={numberOfTitles}
                        openExpertModal={openExpertModal}
                        openReservationModal={openReservationModal}
                        startTime={uiTime}
                        language={language}
                        id={id}
                        services={services}
                    /> :
                    <ListItemDesktop
                        expertPicture={expertPicture}
                        translation={translation}
                        populatedSlot={timeslot}
                        numberOfTitles={numberOfTitles}
                        openExpertModal={openExpertModal}
                        openReservationModal={openReservationModal}
                        startTime={uiTime}
                        language={language}
                        id={id}
                        services={services}
                    />}


            </ListItem>
            <Divider />
        </>
    );
};

export default TimeSelectListItem;
