import { websocketURL } from "../settings";
import { AuthResponse, Operations, SSNResponse, WaitingResponses } from "../types";
import { createOnmessage, send, tryJSONParse, initializeSocket, utoa } from "./socketHelpers";

export const waitingResponses: WaitingResponses<any> = {};

export interface IAuthService {
    login: (username: string, password: string) => Promise<AuthResponse>,
    fetchBySSN: (ssn: string) => Promise<SSNResponse>,
    reAuth: (sessionKey: string) => Promise<AuthResponse>,
    socket: WebSocket
}

const createLogin = (socket: WebSocket) => (
    username: string,
    password: string
): Promise<AuthResponse> => {
    const msgStr = `{"op":"${Operations.auth}","data":{"api-key":"${process.env.REACT_APP_ASPA_API_KEY || "INVALID API KEY"}","username":"${username}","password":"${utoa(password)}"}}`;
    const msg = JSON.parse(msgStr);

    const matcher = (resp: AuthResponse) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return (
            (
                respObj.op === msg.op &&
                Object.keys(respObj).includes("dn" && "cn" && "memberOf")
            ) ||
            (
                respObj.op === msg.op &&
                respObj.success === false
            ));
    };

    return new Promise((resolve, reject) => {
        const callback = async (data: AuthResponse) => resolve(data);
        return send(socket, callback, matcher, msg, waitingResponses, reject, msgStr);
    });
};

const createFetchBySSN = (socket: WebSocket) => (
    ssn: string,
): Promise<SSNResponse> => {
    const msgStr = `{"op":"${Operations.customer}","data":{"socialSecurityNumber":"${ssn}"}}`;
    const msg = JSON.parse(msgStr);

    const matcher = (resp: SSNResponse) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return (
            respObj.op === msg.op &&
            (respObj.data.socialSecurityNumber || respObj.data.customer)
        );
    };

    return new Promise((resolve, reject) => {
        const callback = async (data: SSNResponse) => resolve(data);
        return send(socket, callback, matcher, msg, waitingResponses, reject, msgStr);
    });
};

const createReAuth = (socket: WebSocket) => (sessionKey: string): Promise<AuthResponse> => {
    const msgStr = `{"op":"${Operations.auth}","data":{"api-key":"${process.env.REACT_APP_ASPA_API_KEY || "INVALID API KEY"}","session-key":"${sessionKey}"}}`;
    const msg = JSON.parse(msgStr);
    const matchResponseToCallback = (resp: AuthResponse) => {
        const respObj = tryJSONParse(resp as unknown as string);
        return respObj.op === msg.op;
    };

    return new Promise((resolve, reject) => {
        const callback = (data: AuthResponse) => resolve(data);
        send(socket, callback, matchResponseToCallback, msg, waitingResponses, reject, msgStr);
    });
};

export const initializeAuthService = async (
    onClose?: () => void,
): Promise<IAuthService> => {
    const callback = () => null;
    const initSocket = () => new Promise<WebSocket>((resolve, reject) => {
        initializeSocket(
            callback,
            createOnmessage(waitingResponses),
            websocketURL,
            reject,
            onClose,
            resolve
        );
    });
    return initSocket().then(socket => {
        return ({
            login: createLogin(socket),
            fetchBySSN: createFetchBySSN(socket),
            reAuth: createReAuth(socket),
            socket
        });
    });
};
