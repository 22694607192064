import React from 'react';
import { Zoom, useScrollTrigger, makeStyles, createStyles, Theme } from '@material-ui/core';

interface BackToTopProps {
    window?: () => Window,
    children: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "fixed",
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    })
);

const ScrollToTop: React.FC<BackToTopProps> = props => {
    const { children, window } = props;
    const classes = useStyles();
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument ||
            document
        ).querySelector("#container");

        if (anchor) {
            anchor.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
};

export default ScrollToTop;
