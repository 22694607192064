export enum Language {
    en = "english",
    fi = "finnish",
    se = "swedish",
}

export enum TranslationKeys {
    selectLanguage = "selectLanguage",
    selectVisitSubject = "selectVisitSubject",
    selectCustomerType = "selectCustomerType",
    selectService = "selectService",
    selectSubService = "selectSubService",
    selectDistrict = "selectDistrict",
    selectPremise = "selectPremise",
    selectExpert = "selectExpert",
    summary = "summary",
    selectTime = "selectTime",
    service = "service",
    time = "time",
    duration = "duration",
    city = "city",
    address = "address",
    confirm = "confirm",
    selfPaying = "selfPaying",
    serviceVoucher = "serviceVoucher",
    purchaseService = "purchaseService",
    monday = "monday",
    tuesday = "tuesday",
    wednesday = "wednesday",
    thursday = "thursday",
    friday = "friday",
    saturday = "saturday",
    sunday = "sunday",
    stepperReserveTimeslot = "stepperReserveTimeslot",
    stepperInformationForm = "stepperInformationForm",
    stepperConfirm = "stepperConfirm",
    clock = "clock",
    price = "price",
    loginToMyCoronaria = "loginToMyCoronaria",
    personalIdentificationNumber = "personalIdentificationNumber",
    firstName = "firstName",
    lastName = "lastName",
    postalCode = "postalCode",
    district = "district",
    phoneNumber = "phoneNumber",
    email = "email",
    iDontHaveEmail = "iDontHaveEmail",
    moreInformation = "moreInformation",
    buttonGoBack = "buttonGoBack",
    buttonNext = "buttonNext",
    buttonAbort = "buttonAbort",
    buttonConfirm = "buttonConfirm",
    howToRemind = "howToRemind",
    withEmail = "withEmail",
    withTextMessage = "withTextMessage",
    chooseReminderTime = "chooseReminderTime",
    oneDayBefore = "oneDayBefore",
    twoHoursBefore = "twoHoursBefore",
    dataProtectionAndMarketing = "dataProtectionAndMarketing",
    checkboxDataProtectionAgree = "checkboxDataProtectionAgree",
    checkboxMarketingPermission = "checkboxMarketingPermission",
    appointmentSuccessfull = "appointmentSuccesfull",
    buttonSaveToCalendar = "buttonSaveToCalendar",
    buttonPrint = "buttonPrint",
    buttonScheduleNewAppointment = "buttonScheduleNewAppointment",
    buttonClose = "buttonClose",
    confirmationFormLongFooterHeader = "confirmationFormLongFooterHeader",
    confirmationFormLongFooterText1 = "confirmationFormLongFooterText1",
    confirmationFormLongFooterText2 = "confirmationFormLongFooterText2",
    confirmationFormShortFooterText = "confirmationFormShortFooterText",
    confirmationFormShortFooterText2 = "confirmationFormShortFooterText2",
    specialty = "specialty",
    education = "education",
    languageSkills = "languageSkills",
    expertsPremises = "expertsPremises",
    priceList = "priceList",
    reserveTimeslot = "reserveTimeslot",
    closeOrMoveBack = "closeOrMoveBack",
    reservationService = "reservationService",
    location = "location",
    writeYourLocation = "writeYourLocation",
    locate = "locate",
    gettingLocation = "gettingLocation",
    preferCalling = "preferCalling",
    youCanCallUs = "youCanCallUs",
    morning = "morning",
    dayTime = "dayTime",
    afternoon = "afternoon",
    evening = "evening",
    nextFreeTimeslots = "nextFreeTimeslots",
    noTimesAvailable = "noTimesAvailable",
    searchExpert = "searchExpert",
    searchService = "searchService",
    timeslotConfirmationFailureHeader = "timeslotConfirmationFailureHeader",
    timeslotConfirmationFailureMiddle = "timeslotConfirmationFailureMiddle",
    timeslotConfirmationFailureText = "timeslotConfirmationFailureText",
    closeAndSelectNewTimeslot = "closeAndSelectNewTimeslot",
    ssnError = "ssnError",
    ssnTooltip = "ssnTooltip",
    emailError = "emailError",
    phoneError = "phoneError",
    postalError = "postalError",
    continueToNextPhase = "continueToNextPhase",
    errorNotFoundHeader = "errorNotFoundHeader",
    errorNotFoundText = "errorNotFoundText",
    errorMaintenanceHeader = "errorMaintenanceHeader",
    errorAlternateHeader = "errorAlternateHeader",
    errorMaintenanceText = "errorMaintenanceText",
    errorSomethingWentWrongHeader = "errorSomethingWentWrongHeader",
    errorSomethingWentWrongFirstParagraph = "errorSomethingWentWrongFirstParagraph",
    errorSomethingWentWrongSecondParagraph = "errorSomethingWentWrongSecondParagraph",
    loading = "loading",
    addressSpecifier = "addressSpecifier",
    selectStreet = "selectStreet",
    searchForStreetBy = "searchForStreetBy",
    selectPostalRegion = "selectPostalRegion",
    selectSpecificAddress = "selectSpecificAddress",
    ariaLabelSelectCustomerTypeRadio = "ariaLabelSelectCustomerTypeRadio",
    thisDay = "thisDay",
    noFreeTimes = "noFreeTimes",
    chosenDate = "chosenDate",
    timesFree = "timesFree",
    showExtraFilters = "showExtraFilters",
    hideExtraFilters = "hideExtraFilters",
    searchSpeciality = "searchSpeciality",
    speciality = "speciality",
    expertLanguage = "expertLanguage",
    chooose = "choose",
    expertCustomers = "expertCustomers",
    customers = "customers",
    reservingTimeslot = "reservingTimeslot",
    stairAndOrUnitInformation = "stairAndOrUnitInformation",
    errorTryAgain = "errorTryAgain",
    cancelReservationHeader = "cancelReservationHeader",
    cancelReservationCancelWithPinHeader = "cancelReservationCancelWithPinHeader",
    cancelReservationCancellingReservedTimeHeader = "cancelReservationCancellingReservedTimeHeader",
    cancelReservationDisclaimerParagraph1 = "cancelReservationDisclaimerParagraph1",
    cancelReservationDisclaimerParagraph2 = "cancelReservationDisclaimerParagraph2",
    cancelSuccessButton = "cancelSuccessButton",
    cancelGenericFailButton = "cancelGenericFailButton",
    pinCode = "pinCode",
    cancelReservationButton = "cancelReservationButton",
    sendingCancellationRequest = "sendingCancellationRequest",
    cancellationErrorDefault = "cancellationErrorDefault",
    cancellationError = "cancellationError",
    cancellationSuccess = "cancellationSuccess",
    cancelReservationPinDisclaimer = "cancelReservationPinDisclaimer",
    services = "services",
    others = "others",
    premiseOrMunicipality = "premiseOrMunicipality",
    searchForPremiseOrMunicipality = "searchForPremiseOrMunicipality",
    invalidUUIDError = "invalidUUIDError",
    reservationTooCloseError = "reservationTooCloseError",
    cancellationGenericError = "cancellationGenericError",
    showMore = "showMore",
    distance = "distance",
    fromLocation = "fromLocation",
    toAddress = "toAddress",
    oclock = "oclock",
    expertProfile = "expertProfile",
    paymentMethod = "paymentMethod",
    selectDay = "selectDay",
    day = "day",
    all = "all",
    reserveTimeByPhone = "reserveTimeByPhone",
    openTimes = "openTimes",
    callPrice = "callPrice",
    informationWillOnlyBeAvailableTo = "informationWillOnlyBeAvailableTo",
    agreeToDataPrivacyHeader = "agreeToDataPrivacyHeader",
    verifySelection = "verifySelection",
    arrival = "arrival",
    showMoreArrivalTips = "showMoreArrivalTips",
    showLessArrivalTips = "showLessArrivalTips",
    summaryOfReservation = "summaryOfReservation",
    arrivalWithOwnCar = "arrivalWithOwnCar",
    accessibility = "accessibility",
    parking = "parking",
    showNextMonth = "showNextMonth",
    showPreviousMonth = "showPreviousMonth",
    showNextDay = "showNextDay",
    showPreviousDay = "showPreviousDay",
    ariaLabelFilterMenu = "ariaLabelFilterMenu",
    loadingPremises = "loadingPremises",
    loadingExperts = "loadingExperts",
    searchingLocationHelper = "searchingLocationHelper",
    premisesNearYou = "premisesNearYou",
    cancelLocationSearch = "cancelLocationSearch",
    orderByTime = "orderByTime",
    orderByDistance = "orderByDistance",
    streetAddress = "streetAddress",
    readOurPrivacyPolicyHere = "readOurPrivacyPolicyHere",
    sendSummaryOfReservation = "sendSummaryOfReservation",
    summaryOfForm = "summaryOfForm",
    iWantAReminderWithTextMessage = "iWantAReminderWithTextMessage",
    listOfPrices = "listOfPrices",
    listOfPricesDisclaimer = "listOfPricesDisclaimer",
    listOfPricesLink = "listOfPricesLink",
    popular = "popular",
    serviceVoucherNumber = "serviceVoucherNumber",
    discountCodeNumber = "discountCodeNumber",
    discountCodeNumberInfo = "discountCodeNumberInfo",
    paymentVoucher = "paymentVoucher",
    errorContactLinkText = "errorContactLinkText",
    reservationProblem = "reservationProblem",
    reservationConfirmationTimeout = "reservationConfirmationTimeout",
    reservationConfirmationTimeoutText = "reservationConfirmationTimeoutText",
    reservationConfirmationTimeoutPointOne = "reservationConfirmationTimeoutPointOne",
    reservationConfirmationTimeoutPointTwo = "reservationConfirmationTimeoutPointTwo",
    reservationConfirmationTimeoutEnd = "reservationConfirmationTimeoutEnd",
    infoAndPrice = "infoAndPrice",
    nextDay = "nextDay",
    previousDay = "previousDay",
    chooseCustomerType = "chooseCustomerType",
    ifPayingYourself = "ifPayingYourself",
    chooseAsCustomerType = "chooseAsCustomerType",
    weHaveKelasPayment = "weHaveKelasPayment",
    serviceVoucherCustomers = "serviceVoucherCustomers",
    purchaseServiceCustomer = "purchaseServiceCustomer",
    cityOfHelsinki = "cityOfHelsinki",
    cityOfKerava = "cityOfKerava",
    cityOfTampere = "cityOfTampere",
    karviainens = "karviainens",
    cityOfEspoo = "cityOfEspoo",
    infoReminder = "infoReminder",
    ythsCustomers = "ythsCustomers",
    ythsInfo = "ythsInfo",
    tampereInfo = "tampereInfo",
    byPhone = "byPhone",
    howToChooseCustomerType = "howToChooseCustomerType",
    serviceVoucherUsedFor = "serviceVoucherUsedFor",
    helsinkiAndUusimaa = "helsinkiAndUusimaa",
    tampereArea = "tampereArea",
    paidByInsurance = "paidByInsurance",
    hus = "hus",
    ifUsingServiceVoucher = "ifUsingServiceVoucher",
    emptyTimeselectItem = "emptyTimeselectItem",
    endoscopyCustomerTypeTooltip = "endoscopyCustomerTypeTooltip",
    remoteReception = "remoteReception",
    koho = "koho",
    kohoClarification = "kohoClarification",
    reservationOnly = "reservationOnly",
}

export type Translation = {
    [index: string]: string,
    [TranslationKeys.selectLanguage]: string,
    [TranslationKeys.selectVisitSubject]: string,
    [TranslationKeys.selectCustomerType]: string,
    [TranslationKeys.selectService]: string,
    [TranslationKeys.selectSubService]: string,
    [TranslationKeys.selectDistrict]: string,
    [TranslationKeys.selectPremise]: string,
    [TranslationKeys.selectExpert]: string,
    [TranslationKeys.summary]: string,
    [TranslationKeys.selectTime]: string,
    [TranslationKeys.service]: string,
    [TranslationKeys.time]: string,
    [TranslationKeys.duration]: string,
    [TranslationKeys.city]: string,
    [TranslationKeys.address]: string,
    [TranslationKeys.confirm]: string,
    [TranslationKeys.selfPaying]: string,
    [TranslationKeys.serviceVoucher]: string,
    [TranslationKeys.purchaseService]: string,
    [TranslationKeys.monday]: string,
    [TranslationKeys.tuesday]: string,
    [TranslationKeys.wednesday]: string,
    [TranslationKeys.thursday]: string,
    [TranslationKeys.friday]: string,
    [TranslationKeys.saturday]: string,
    [TranslationKeys.sunday]: string,
    [TranslationKeys.stepperReserveTimeslot]: string,
    [TranslationKeys.stepperInformationForm]: string,
    [TranslationKeys.stepperConfirm]: string,
    [TranslationKeys.clock]: string,
    [TranslationKeys.price]: string,
    [TranslationKeys.loginToMyCoronaria]: string,
    [TranslationKeys.personalIdentificationNumber]: string,
    [TranslationKeys.firstName]: string,
    [TranslationKeys.lastName]: string,
    [TranslationKeys.postalCode]: string,
    [TranslationKeys.district]: string,
    [TranslationKeys.phoneNumber]: string,
    [TranslationKeys.email]: string,
    [TranslationKeys.iDontHaveEmail]: string,
    [TranslationKeys.moreInformation]: string,
    [TranslationKeys.buttonGoBack]: string,
    [TranslationKeys.buttonNext]: string,
    [TranslationKeys.buttonAbort]: string,
    [TranslationKeys.buttonConfirm]: string,
    [TranslationKeys.howToRemind]: string,
    [TranslationKeys.withEmail]: string,
    [TranslationKeys.withTextMessage]: string,
    [TranslationKeys.chooseReminderTime]: string,
    [TranslationKeys.oneDayBefore]: string,
    [TranslationKeys.twoHoursBefore]: string,
    [TranslationKeys.dataProtectionAndMarketing]: string,
    [TranslationKeys.checkboxDataProtectionAgree]: string,
    [TranslationKeys.checkboxMarketingPermission]: string,
    [TranslationKeys.appointmentSuccessfull]: string,
    [TranslationKeys.buttonSaveToCalendar]: string,
    [TranslationKeys.buttonPrint]: string,
    [TranslationKeys.buttonScheduleNewAppointment]: string,
    [TranslationKeys.buttonClose]: string,
    [TranslationKeys.confirmationFormLongFooterHeader]: string,
    [TranslationKeys.confirmationFormLongFooterText1]: string,
    [TranslationKeys.confirmationFormLongFooterText2]: string,
    [TranslationKeys.confirmationFormShortFooterText]: string,
    [TranslationKeys.confirmationFormShortFooterText2]: string,
    [TranslationKeys.specialty]: string,
    [TranslationKeys.education]: string,
    [TranslationKeys.languageSkills]: string,
    [TranslationKeys.expertsPremises]: string,
    [TranslationKeys.priceList]: string,
    [TranslationKeys.reserveTimeslot]: string,
    [TranslationKeys.closeOrMoveBack]: string,
    [TranslationKeys.reservationService]: string,
    [TranslationKeys.location]: string,
    [TranslationKeys.writeYourLocation]: string,
    [TranslationKeys.locate]: string,
    [TranslationKeys.gettingLocation]: string,
    [TranslationKeys.preferCalling]: string,
    [TranslationKeys.youCanCallUs]: string,
    [TranslationKeys.morning]: string,
    [TranslationKeys.dayTime]: string,
    [TranslationKeys.afternoon]: string,
    [TranslationKeys.evening]: string,
    [TranslationKeys.nextFreeTimeslots]: string,
    [TranslationKeys.noTimesAvailable]: string,
    [TranslationKeys.searchExpert]: string,
    [TranslationKeys.searchService]: string,
    [TranslationKeys.timeslotConfirmationFailureHeader]: string,
    [TranslationKeys.timeslotConfirmationFailureMiddle]: string,
    [TranslationKeys.timeslotConfirmationFailureText]: string,
    [TranslationKeys.closeAndSelectNewTimeslot]: string,
    [TranslationKeys.ssnError]: string,
    [TranslationKeys.ssnTooltip]: string,
    [TranslationKeys.emailError]: string,
    [TranslationKeys.phoneError]: string,
    [TranslationKeys.continueToNextPhase]: string,
    [TranslationKeys.errorNotFoundHeader]: string,
    [TranslationKeys.errorNotFoundText]: string,
    [TranslationKeys.errorMaintenanceHeader]: string,
    [TranslationKeys.errorMaintenanceText]: string,
    [TranslationKeys.errorSomethingWentWrongHeader]: string,
    [TranslationKeys.errorSomethingWentWrongFirstParagraph]: string,
    [TranslationKeys.errorSomethingWentWrongSecondParagraph]: string,
    [TranslationKeys.stairAndOrUnitInformation]: string,
    [TranslationKeys.errorTryAgain]: string,
    [TranslationKeys.loading]: string,
    [TranslationKeys.addressSpecifier]: string,
    [TranslationKeys.selectStreet]: string,
    [TranslationKeys.searchForStreetBy]: string,
    [TranslationKeys.selectPostalRegion]: string,
    [TranslationKeys.selectSpecificAddress]: string,
    [TranslationKeys.ariaLabelSelectCustomerTypeRadio]: string,
    [TranslationKeys.thisDay]: string,
    [TranslationKeys.noFreeTimes]: string,
    [TranslationKeys.chosenDate]: string,
    [TranslationKeys.timesFree]: string,
    [TranslationKeys.showExtraFilters]: string,
    [TranslationKeys.hideExtraFilters]: string,
    [TranslationKeys.speciality]: string,
    [TranslationKeys.searchSpeciality]: string,
    [TranslationKeys.expertLanguage]: string,
    [TranslationKeys.chooose]: string,
    [TranslationKeys.expertCustomers]: string,
    [TranslationKeys.customers]: string,
    [TranslationKeys.reservingTimeslot]: string,
    [TranslationKeys.reservingTimeslot]: string,
    [TranslationKeys.stairAndOrUnitInformation]: string,
    [TranslationKeys.cancelReservationHeader]: string,
    [TranslationKeys.pinCode]: string,
    [TranslationKeys.cancelReservationButton]: string,
    [TranslationKeys.sendingCancellationRequest]: string,
    [TranslationKeys.cancellationError]: string,
    [TranslationKeys.cancellationSuccess]: string,
    [TranslationKeys.services]: string,
    [TranslationKeys.others]: string,
    [TranslationKeys.premiseOrMunicipality]: string,
    [TranslationKeys.searchForPremiseOrMunicipality]: string,
    [TranslationKeys.invalidUUIDError]: string,
    [TranslationKeys.reservationTooCloseError]: string,
    [TranslationKeys.showMore]: string,
    [TranslationKeys.distance]: string,
    [TranslationKeys.fromLocation]: string,
    [TranslationKeys.toAddress]: string,
    [TranslationKeys.oclock]: string,
    [TranslationKeys.expertProfile]: string,
    [TranslationKeys.paymentMethod]: string,
    [TranslationKeys.selectDay]: string,
    [TranslationKeys.day]: string,
    [TranslationKeys.all]: string,
    [TranslationKeys.reserveTimeByPhone]: string,
    [TranslationKeys.openTimes]: string,
    [TranslationKeys.callPrice]: string,
    [TranslationKeys.informationWillOnlyBeAvailableTo]: string,
    [TranslationKeys.agreeToDataPrivacyHeader]: string,
    [TranslationKeys.verifySelection]: string,
    [TranslationKeys.arrival]: string,
    [TranslationKeys.showMoreArrivalTips]: string,
    [TranslationKeys.showLessArrivalTips]: string,
    [TranslationKeys.summaryOfReservation]: string,
    [TranslationKeys.arrivalWithOwnCar]: string,
    [TranslationKeys.accessibility]: string,
    [TranslationKeys.parking]: string,
    [TranslationKeys.cancelReservationCancelWithPinHeader]: string,
    [TranslationKeys.cancelReservationCancellingReservedTimeHeader]: string,
    [TranslationKeys.cancelReservationDisclaimerParagraph1]: string,
    [TranslationKeys.cancelReservationDisclaimerParagraph2]: string,
    [TranslationKeys.cancelReservationPinDisclaimer]: string,
    [TranslationKeys.cancelSuccessButton]: string,
    [TranslationKeys.cancellationGenericError]: string,
    [TranslationKeys.showNextMonth]: string,
    [TranslationKeys.showPreviousMonth]: string,
    [TranslationKeys.showNextDay]: string,
    [TranslationKeys.showPreviousDay]: string,
    [TranslationKeys.ariaLabelFilterMenu]: string,
    [TranslationKeys.loadingPremises]: string,
    [TranslationKeys.loadingExperts]: string,
    [TranslationKeys.searchingLocationHelper]: string,
    [TranslationKeys.premisesNearYou]: string,
    [TranslationKeys.cancelLocationSearch]: string,
    [TranslationKeys.orderByTime]: string,
    [TranslationKeys.orderByDistance]: string,
    [TranslationKeys.streetAddress]: string,
    [TranslationKeys.postalError]: string,
    [TranslationKeys.readOurPrivacyPolicyHere]: string,
    [TranslationKeys.sendSummaryOfReservation]: string,
    [TranslationKeys.summaryOfForm]: string,
    [TranslationKeys.iWantAReminderWithTextMessage]: string,
    [TranslationKeys.listOfPrices]: string,
    [TranslationKeys.listOfPricesDisclaimer]: string,
    [TranslationKeys.listOfPricesLink]: string,
    [TranslationKeys.popular]: string,
    [TranslationKeys.serviceVoucherNumber]: string,
    [TranslationKeys.discountCodeNumber]: string,
    [TranslationKeys.discountCodeNumberInfo]: string,
    [TranslationKeys.paymentVoucher]: string,
    [TranslationKeys.errorContactLinkText]: string,
    [TranslationKeys.errorAlternateHeader]: string,
    [TranslationKeys.reservationProblem]: string,
    [TranslationKeys.reservationConfirmationTimeout]: string,
    [TranslationKeys.reservationConfirmationTimeoutText]: string,
    [TranslationKeys.reservationConfirmationTimeoutPointOne]: string,
    [TranslationKeys.reservationConfirmationTimeoutPointTwo]: string,
    [TranslationKeys.reservationConfirmationTimeoutEnd]: string,
    [TranslationKeys.infoAndPrice]: string,
    [TranslationKeys.nextDay]: string,
    [TranslationKeys.previousDay]: string,
    [TranslationKeys.chooseCustomerType]: string,
    [TranslationKeys.ifPayingYourself]: string,
    [TranslationKeys.chooseAsCustomerType]: string,
    [TranslationKeys.weHaveKelasPayment]: string,
    [TranslationKeys.serviceVoucherCustomers]: string,
    [TranslationKeys.purchaseServiceCustomer]: string,
    [TranslationKeys.cityOfHelsinki]: string,
    [TranslationKeys.cityOfKerava]: string,
    [TranslationKeys.cityOfTampere]: string,
    [TranslationKeys.karviainens]: string,
    [TranslationKeys.cityOfEspoo]: string,
    [TranslationKeys.infoReminder]: string,
    [TranslationKeys.ythsCustomers]: string,
    [TranslationKeys.ythsInfo]: string,
    [TranslationKeys.tampereInfo]: string,
    [TranslationKeys.byPhone]: string,
    [TranslationKeys.howToChooseCustomerType]: string,
    [TranslationKeys.serviceVoucherUsedFor]: string,
    [TranslationKeys.helsinkiAndUusimaa]: string,
    [TranslationKeys.tampereArea]: string,
    [TranslationKeys.paidByInsurance]: string,
    [TranslationKeys.hus]: string,
    [TranslationKeys.ifUsingServiceVoucher]: string,
    [TranslationKeys.emptyTimeselectItem]: string,
    [TranslationKeys.remoteReception]: string,
    [TranslationKeys.koho]: string,
    [TranslationKeys.kohoClarification]: string,
    [TranslationKeys.reservationOnly]: string,
    [TranslationKeys.endoscopyCustomerTypeTooltip]: string,
    [TranslationKeys.remoteReception]: string
};

export interface ITranslationLanguages {
    [Language.en]: Translation,
    [Language.fi]: Translation,
    [Language.se]: Translation
}
