import React from "react";
import { makeStyles, Theme, createStyles, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import HeartIcon from "../icons/Heart";

export const useStyles = (mobile?: boolean) =>
    makeStyles((theme: Theme) =>
        createStyles({
            container: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5)
            },
            component: {
                minHeight: "100%",
                padding: "10px"
            },
            header: {
                color: theme.palette.primary.main,
                fontSize: theme.typography.pxToRem(24),
                lineHeight: theme.typography.pxToRem(29),
                letterSpacing: theme.typography.pxToRem(0.3),
                fontWeight: "bold",
            },
            text: {
                fontSize: mobile ? "16px" : "18px",
            },
            icon: {
                color: theme.palette.primary.light,
                minHeight: "100%",
                width: "auto",
                minWidth: "150px",
                maxHeight: "150px"
            },
            leftItem: {
                minHeight: "250px",
                width: "auto",
                minWidth: "150px",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center"
            }
        }));

interface IInformationViewProps {
    header: string | JSX.Element,
    firstParagraph?: string | JSX.Element,
    secondParagraph?: string | JSX.Element,
    icon?: JSX.Element
}

const InformationView: React.FC<IInformationViewProps> = ({
    header,
    firstParagraph,
    secondParagraph,
    icon
}) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();

    return (
            <Grid container className={classes.container} >
                <Grid
                    container
                    item
                    xs={12}
                    md={3}
                    className={classes.leftItem}
                >
                    <Grid item xs={12} >
                        {
                            icon ?
                                icon :
                                <HeartIcon className={classes.icon} />
                        }
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    className={classes.component}
                    style={{textAlign: mobile ? "center" : "left"}}
                    xs={12}
                    md={3}
                >
                    <Grid item xs={12}>
                        {typeof header === "string" ? <Typography
                            variant="h1"
                            component="h2"
                        >
                            {header.toUpperCase()}
                        </Typography> : header}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.text}
                            variant="body1"
                            paragraph
                        >
                            {firstParagraph}
                        </Typography>
                        {
                            typeof secondParagraph === "string" ?
                                <Typography
                                    className={classes.text}
                                    variant="body1"
                                    paragraph
                                >
                                    {secondParagraph}
                                </Typography> :
                                secondParagraph
                        }
                    </Grid>
                </Grid >
            </Grid>
    );
};

export default InformationView;
