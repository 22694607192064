import React, { useContext } from 'react';
import { Grid, Typography, createStyles, Theme, makeStyles } from '@material-ui/core';
import { LocalizationContext } from './LanguageContext/LocalizationContext';
import { colors } from '../theme';

const itemIcon = (theme: Theme) => ({
    width: 33,
    height: 33,
    marginRight: theme.spacing(2)
});

const itemText = (_theme: Theme) => ({
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoItem: {
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            paddingLeft: theme.spacing(4),
            paddingBottom: theme.spacing(1)
        },
        itemText: { ...itemText(theme) },
        itemTextFree: {
            ...itemText(theme),
            color: theme.palette.success.main,
            "& .MuiTypography-root": {
                textTransform: "none"
            }
        },
        itemTextNoFree: {
            ...itemText(theme),
            textTransform: "none",
            color: colors.lightGrey,
            "& .MuiTypography-root": {
                textTransform: "none"
            }
        },
        today: {
            ...itemIcon(theme),
            border: "1px solid #006600",
            borderRadius: "50%"
        },
        chosen: {
            display: "flex",
            alignItems: "center",
            ...itemIcon(theme),
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            textAlign: "center",
        },
    })
);

const CalendarInfo = () => {
    const {
        infoItem,
        itemText,
        itemTextFree,
        itemTextNoFree,
        today,
        chosen
    } = useStyles();
    const { translation } = useContext(LocalizationContext);
    return (

        <Grid container item xs={12}>
            <Grid item xs={6} className={infoItem}>
                <div className={today} />
                <div className={itemText}>
                    <Typography>{translation.thisDay}</Typography>
                </div>
            </Grid>
            <Grid item xs={6} className={infoItem}>
                <div className={chosen} />
                <div className={itemText}>
                    <Typography>{translation.chosenDate}</Typography>
                </div>
            </Grid>
            <Grid item xs={6} className={infoItem}>
                <div className={itemTextFree}>
                    <Typography
                        variant="button"
                        component="p"
                    >
                        {translation.timesFree}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6} className={infoItem}>
                <div className={itemTextNoFree}>
                    <Typography
                        variant="button"
                        component="p"
                    >
                        {translation.noFreeTimes}
                    </Typography>
                </div>
            </Grid>

        </Grid>
    );
};

export default CalendarInfo;
