interface Translation {
    username: string,
    password: string,
    customerNotFound: string,
    login: string,
    loginError: string
}

interface Translations {
    [key: string]: Translation,
    finnish: Translation,
    english: Translation,
    swedish: Translation
}

export const translations: Translations = {
    finnish: {
        username: "Käyttäjätunnus",
        password: "Salasana",
        customerNotFound: "Asiakasta ei löydy",
        login: "Kirjaudu",
        loginError: "Virheellinen käyttäjätunnus tai salasana"
    },
    english: {
        username: "User account",
        password: "Password",
        customerNotFound: "Customer not found",
        login: "Login",
        loginError: "Wrong useraccount or password"
    },
    swedish: {
        username: "Användarnamn",
        password: "Lösenord",
        customerNotFound: "Kunden kan inte hittas",
        login: "Logga in",
        loginError: "Ogiltigt användarnamn eller losenord"
    }
};
