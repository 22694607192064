import React, { useState, useMemo, useEffect } from "react";
import { Language, Translation } from "./types";
import { translations } from "./translations";
import { defaultLanguage, LocalStorageKeys } from "../../settings";

export interface ILocalizationAPI {
    translation: Translation,
    getLanguage: () => Language,
    setLanguage: (language: Language) => void
}

const getSavedOrDefaultLanguage = (): Language => {
    const localStorageLanguage = localStorage.getItem(LocalStorageKeys.languageSelection);
    const preselectedLanguage: Language =
        Object.values(Language).includes(localStorageLanguage as Language) ?
            localStorageLanguage as Language :
            defaultLanguage.languageKey;

    return preselectedLanguage;
};

const getLocalizationAPI = (
    language: Language,
    setLanguage: (lang: Language) => void
) => {
    return {
        translation: translations[language],
        getLanguage: () => language,
        setLanguage
    };
};

const savedOrDefault: Language = getSavedOrDefaultLanguage();
export const LocalizationContext = React.createContext<ILocalizationAPI>(
    getLocalizationAPI(savedOrDefault, () => { })
);

const saveLanguageToLocalStorage = (language: Language): void => {
    localStorage.setItem(LocalStorageKeys.languageSelection, language);
};

export const LocalizationProvider: React.FC = (props) => {
    const savedOrDefault = getSavedOrDefaultLanguage;
    const [language, setLanguage] = useState<Language>(savedOrDefault);

    const setAndSaveLanguage = (language: Language) => {
        setLanguage(language);
        saveLanguageToLocalStorage(language);
    };

    const updateLanguage = (e: StorageEvent) => {
        const oldVal = e.oldValue;
        const newVal = e.newValue;

        if (
            newVal !== oldVal &&
            newVal !== null &&
            Object.values(Language).includes(newVal as Language)
        ) { // Dont rerender if value is the same
            setAndSaveLanguage(newVal as Language);
        }
    };

    useEffect(() => {
        window.addEventListener("storage", updateLanguage);
        return () => window.removeEventListener("storage", updateLanguage);
    }, []);

    const localizationAPI = useMemo(() => getLocalizationAPI(
        language,
        setAndSaveLanguage
    ), [language]);

    return (
        <React.Fragment>
            <LocalizationContext.Provider value={localizationAPI}>
                {props.children}
            </LocalizationContext.Provider>
        </React.Fragment>
    );
};
