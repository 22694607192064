import React, { useContext } from "react";
import { Grid, IconButton, Typography, makeStyles, Theme, createStyles } from "@material-ui/core";
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import { getNextDay, getPrettyDate, getPrevDay, isFutureDate } from "./utils";
import { LocalizationContext } from "./LanguageContext/LocalizationContext";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isFirstDayOfMonth, isLastDayOfMonth } from "date-fns";
import moment from "moment";

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            verticalAlign: "center",
            textTransform: "none",
            textDecoration: "none"
        },
        verticalCenter: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        floatRight: {
            display: "flex",
            flexDirection: "row-reverse",
        },
        arrowButton: {
            fontSize: 70,
            width: 70,
            height: 70,
        },
        arrowIcon: {
            fontSize: 70,
            width: 70
        }
    }));

interface IDateChangerProps {
    date: Date | null,
    setDate: (date: Date | null) => void,
    handleMonthChange: (dayRendere: MaterialUiPickersDate) => Promise<void>
}


const DateChanger: React.FC<IDateChangerProps> = (props) => {
    const { date, setDate, handleMonthChange } = props;
    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    const handlePreviousDayChange = (date: Date) => {
        const newDate = getPrevDay(date);
        setDate(newDate);
        if (isLastDayOfMonth(newDate)) {
            handleMonthChange(newDate);
        }
    };

    const handleNextDayChange = (date: Date) => {
        const newDate = getNextDay(date);
        setDate(newDate);
        if (isFirstDayOfMonth(newDate)) {
            handleMonthChange(newDate);
        }
    };

    return date && (
        <Grid container item id="date-changer">
            <Grid item xs={1}>
                <IconButton
                    color="primary"
                    disabled={!isFutureDate(date)}
                    aria-label={translation.showPreviousDay}
                    onClick={() => handlePreviousDayChange(date)}
                    className={classes.arrowButton}
                >
                    <ArrowLeftRoundedIcon className={classes.arrowIcon} />
                </IconButton>
            </Grid>
            <Grid item xs={10} className={classes.verticalCenter}>
                <Typography className={classes.root} variant="button" component="span">
                    {getPrettyDate(moment(date), translation)}
                </Typography>
            </Grid>
            <Grid item xs={1} className={classes.floatRight}>
                <IconButton
                    color="primary"
                    aria-label={translation.showNextDay}
                    onClick={() => handleNextDayChange(date)}
                    className={classes.arrowButton}
                >
                    <ArrowRightRoundedIcon className={classes.arrowIcon} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default DateChanger;
