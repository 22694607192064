import React from "react";
import { createStyles, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ContentProps } from "./InfoDialog";

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        header: {
            color: theme.palette.primary.main
        },
        bold: {
            fontWeight: "bold"
        },
        title: {
            padding: 0
        },
        content: {
            padding: mobile ? theme.spacing(2) : theme.spacing(4),
            "& .MuiTypography": {
                fontFamily: "Gotham Narrow"
            }
        }
    })
);

const Content: React.FC<ContentProps> = props => {
    const { translation } = props;
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(fullscreen)();
    return (
        <>
            <Typography
                paragraph
                className={classes.bold}
            >
                {translation.chooseCustomerType}:
            </Typography>
            <Typography
                paragraph
            >
                <Typography
                    component="span"
                    display="inline"
                    className={classes.bold}
                >
                    {`${translation.ifPayingYourself}`}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                >
                    {translation.chooseAsCustomerType}
                </Typography>
                <Typography
                    component="span"
                    display="inline"
                    className={classes.bold}
                >
                    {`${translation.selfPaying}. `}
                </Typography>
                <Typography
                    component="span"
                >
                    {translation.weHaveKelasPayment}
                </Typography>
            </Typography>
            <Typography
                paragraph
                className={classes.bold}
            >
                {translation.serviceVoucher}:
            </Typography>
            <Typography
                paragraph
            >
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.koho}`}
                    <Typography
                        component="span"
                    >
                        {` (${translation.kohoClarification}) ${translation.reservationOnly} `}
                    </Typography>
                    {translation.byPhone}
                </Typography>
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.cityOfHelsinki}`}
                    <Typography
                        component="span"
                    >
                        {translation.serviceVoucherCustomers}
                    </Typography>
                </Typography>
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.cityOfKerava}`}
                    <Typography
                        component="span"
                    >
                        {translation.serviceVoucherCustomers}
                    </Typography>
                </Typography>
                <Typography
                    component="span"
                    display="block"
                    className={classes.bold}
                >
                    {`- ${translation.karviainens}`}
                    <Typography
                        component="span"
                    >
                        {translation.serviceVoucherCustomers}
                    </Typography>
                </Typography>
            </Typography>
            <Typography
                paragraph
                className={classes.bold}
            >
                {translation.purchaseService}:
            </Typography>
            <Typography
                component="span"
                display="block"
                className={classes.bold}
            >
                - {`${translation.cityOfEspoo} ${translation.purchaseServiceCustomer}`}
            </Typography>
            <Typography
                paragraph
                className={classes.bold}
            >
                {`- ${translation.ythsCustomers}`}
                <Typography
                    display="inline"
                    component="span"
                >
                    {`(${translation.ythsInfo})`}
                </Typography>
            </Typography>
            <Typography
                paragraph
            >
                <Typography
                    component="span"
                    display="inline"
                    className={classes.bold}
                >
                    {`- ${translation.cityOfTampere}`}
                </Typography>
                {translation.tampereInfo}
                <Typography
                    display="inline"
                    component="span"
                    className={classes.bold}
                >
                    {translation.byPhone}
                </Typography>
            </Typography>
            <Typography
                display="block"
                className={classes.bold}
            >
                {translation.infoReminder}
            </Typography>
        </>
    );
};

export default Content;
