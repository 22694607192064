import React from 'react';
import { Typography, makeStyles, createStyles, ButtonBase, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Link } from 'react-router-dom';

const root = (theme: Theme): CSSProperties => ({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    flex: "0 0 auto",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(1.5),
    margin: 0,
    maxWidth: "min-content",
    minWidth: 120,
    height: 72,
    overflow: "visible",
    color: theme.palette.action.active,
    transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.shortest
    })
});
const text = (theme: Theme): CSSProperties => ({
    maxWidth: "100%",
    fontSize: theme.typography.pxToRem(13.8),
    fontFamily: "Gotham Narrow Bold",
    lineHeight: "16px",
    paddingTop: "2px",
    letterSpacing: theme.typography.pxToRem(1.25)

});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            ...root(theme),
            borderBottom: "2px solid #FFFFFF",
            borderColor: theme.palette.background.default,
            "&:hover": {
                borderBottom: "2px solid #075997",
            },
        },
        active: {
            ...root(theme),
            borderBottom: "2px solid #075997"
        },
        text: {
            ...text(theme),
            color: "rgba(0,0,0,0.6)"
        },
        activeText: {
            ...text(theme),
            color: "#075997",
        },
        center: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    })
);

interface MenuIconProps {
    icon: JSX.Element,
    label: string,
    active: boolean,
    uuid: string
}

const ServiceMenuIcon: React.FC<MenuIconProps> = props => {
    const { icon, label, active, uuid } = props;
    const classes = useStyles();
    return (
        <div className={classes.center}>
            <Link to={{ search: `?base=${uuid}` }}>
                <ButtonBase
                    className={active ? classes.active : classes.root}
                >
                    {icon}
                    <Typography
                        className={
                            active ?
                                classes.activeText :
                                classes.text
                        } color="primary"
                    >
                        {label}
                    </Typography>
                </ButtonBase>
            </Link>
        </div>
    );
};

export default ServiceMenuIcon;
