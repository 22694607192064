import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './AuthContext';
import { LocalizationProvider } from './components/LanguageContext/LocalizationContext';
import { ServiceProvider } from './components/ServiceContext';
import { LocalStorageKeys } from './settings';
import { theme } from './theme';
import ServicesJSON from "./services.json";
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import { QueryProps } from './App';
import CancelReservaton from './components/CancelReservation';
import Layout from './Layout';
import Validator from "validator";
import Login from './Login';
import { QueryKeys, QueryParams } from './types';

interface ContentProps {
    campaignUuid: string | null,
    socket?: WebSocket
}

const useQuery = () => new URLSearchParams(useLocation().search);

export const QueryComponent: React.FC<QueryProps> = props => {
    const { campaignUuid } = props;
    const query = useQuery();
    const cancelReservation = query.get("cancelReservation");
    const getQueryParams = (query: URLSearchParams): Partial<QueryParams> =>
        Object.keys(QueryKeys).reduce((acc, cur) => {
            const filters = (key: string) => {
                const filterParams = query.get(key);
                return filterParams ? filterParams
                    .split(";")
                    .filter(str => Validator.isUUID(str)) :
                    null;
            };
            const value = cur === "filters" || cur === "base" ? filters(cur) : query.get(cur);
            return value ? { ...acc, [cur]: value } : acc;
        }, {});

    const usedQueryParams = getQueryParams(query);


    return (
        cancelReservation ?
            <CancelReservaton uuid={cancelReservation} /> :
            <Layout
                campaignUuid={campaignUuid}
                queryParams={usedQueryParams}
            />
    );
};

const Content: React.FC<ContentProps> = props => {
    const { campaignUuid } = props;
    const { socket, authenticated } = useAuthContext();
    return (
        socket ? <ServiceProvider socket={socket}>
            {authenticated ? (
                <QueryComponent campaignUuid={campaignUuid} />
            ) : <Login />}
        </ServiceProvider> : null);
};

const AspaApp: React.FC = () => {
    const selectedLanguage = localStorage.getItem(LocalStorageKeys.languageSelection) || "finnish";
    const getCampaignUuid = () => {
        const uuid = ServicesJSON.find(service => {
            const path = service.path as { [key: string]: string };
            return path && new RegExp(path[selectedLanguage]).test(window.location.pathname);
        })?.id || null;
        if (!uuid && window.location.pathname === "/" && !window.location.search) {
            return ServicesJSON[0].id;
        }
        return uuid;
    };

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme} >
                <ScopedCssBaseline />
                <LocalizationProvider>
                    <AuthProvider>
                        <Content campaignUuid={getCampaignUuid()} />
                    </AuthProvider>
                </LocalizationProvider>
            </ThemeProvider >
        </BrowserRouter>
    );
};

export default AspaApp;
