import React, { useState } from "react";
import { initializeDataService, IDataService, createDataService } from "../services/DataService";
import { initializeSearch, ISearchService } from "../services/SearchService";
import { sessionStorageSessionKeyName, retryConnectionTimes, retryTimeoutMs } from "../settings";
import { NotFound, Maintenance, SomethingWentWrong } from "./Errors";
import Loading from "./Loading";
import moment from "moment";

export enum ErrorStates {
    notFound,
    somethingWentWrong,
    maintenance,
    noError
}

export interface IServiceContext {
    dataService: IDataService | null,
    getSearchService: (() => Promise<ISearchService>) | null,
    setErrorState: ((err: ErrorStates) => void) | null,
    getMapBoxToken: (() => Promise<{ expires: string; token: string } | null>) | null,
    createDataService: null | ((socket: WebSocket, sessionKey: string) => Promise<void>),
    sessionKey: string | null
}

export const ServiceContext = React.createContext<IServiceContext>({
    dataService: null,
    getSearchService: null,
    setErrorState: null,
    getMapBoxToken: null,
    createDataService: null,
    sessionKey: null
});

interface ServiceProviderProps {
    socket?: WebSocket | null
}

export const ServiceProvider: React.FC<ServiceProviderProps> = (props) => {
    const { socket } = props;
    const [dataService, setDataService] = useState<IDataService | null>(null);
    const [searchService, setSearchService] = useState<ISearchService | null>(null);
    const [errorState, setErrorState] = useState<ErrorStates>(ErrorStates.noError);
    const [firstConnection, setFirstConnection] = useState(true);
    const [dataServiceState, setDataServiceState] = useState(WebSocket.CONNECTING);
    const [mapBoxToken, setMapBoxToken] = useState<null | { expires: string; token: string }>(null);

    const [sessionKey, setSessionKey] = useState<string | null>(null);
    const [sessionKeyBackup, setSessionKeyBackup] = useState<string | null>(null);

    const [retries, setRetries] = useState<number>(0);
    const oldSessionKey: string | null =
        window.sessionStorage.getItem(sessionStorageSessionKeyName);

    const getSearchService = async () => {
        if (sessionKey === null) {
            return Promise.reject("SessionKey could be found");
        }

        const socketIsClosed = (searchService: ISearchService | null) => searchService &&
            (searchService.socket.readyState === WebSocket.CLOSED ||
                searchService.socket.readyState === WebSocket.CLOSING);

        return searchService === null || socketIsClosed(searchService) ?
            initializeSearch(sessionKey)
                .then((search) => {
                    setSearchService(search);
                    return search;
                }) :
            searchService;
    };

    const initMapBoxToken = async () => {
        if (dataService === null) {
            return Promise.resolve(null);
        }
        return dataService.requestMapboxToken()
            .then(token => {
                if (token.success) {
                    setMapBoxToken(token.data);
                    return token.data;
                } else {
                    return Promise.reject("Couldn't get token");
                }
            });
    };


    const getMapBoxToken = async () => {
        if (dataService === null) {
            return null;
        }

        return mapBoxToken !== null && moment(mapBoxToken.expires) > moment(new Date()) ?
            mapBoxToken :
            initMapBoxToken();
    };

    const setServiceStatus = (dataService: IDataService, sessionKey: string) => {
        setSessionKey(sessionKey);
        setDataService(dataService);
        window.sessionStorage.setItem(
            sessionStorageSessionKeyName,
            sessionKey
        );
        setSessionKeyBackup(sessionKey);
        setDataServiceState(WebSocket.OPEN);
        return true;
    };

    const initServices = () => initializeDataService(
        oldSessionKey ? oldSessionKey : sessionKeyBackup,
        onDataServiceClose
    ).then(({ service, sessionKey }) =>
        setServiceStatus(service(sessionKey), sessionKey)
    ).catch((_err: Error) => {
        window.sessionStorage.removeItem(sessionStorageSessionKeyName);
        setSessionKeyBackup(null);
        return false;
    });

    const tryInitServices = async (retryTimes: number) => {
        setDataServiceState(WebSocket.CONNECTING);
        if (retries < retryTimes) {
            const handleFailure = () => {
                setRetries((retries) => retries + 1);
            };

            const handleSuccess = () => {
                setFirstConnection(false);
                setRetries(0);
                setDataServiceState(WebSocket.OPEN);
                setErrorState(ErrorStates.noError);
            };

            const success = await initServices();

            !success ?
                handleFailure() :
                handleSuccess();

        } else if (retries === retryTimes) {
            setErrorState(ErrorStates.somethingWentWrong);
        }
    };

    const onDataServiceClose = () => {
        setDataServiceState(WebSocket.CLOSED);
    };

    React.useEffect(() => {
        if (!firstConnection &&
            (dataServiceState === WebSocket.CLOSED || !socket) &&
            retries <= retryConnectionTimes
        ) {
            setTimeout(() => tryInitServices(retryConnectionTimes), retryTimeoutMs);
        }
    }, [firstConnection, retries, dataServiceState, socket]);

    React.useEffect(() => {
        if (retries === 0 && firstConnection && !socket) {
            tryInitServices(retryConnectionTimes);
        } else if (retries <= retryConnectionTimes && firstConnection && !socket) {
            setTimeout(() => tryInitServices(retryConnectionTimes), retryTimeoutMs);
        }
    }, [retries, firstConnection, socket]);

    const errors = errorState === ErrorStates.maintenance ?
        <Maintenance /> :
        errorState === ErrorStates.notFound ?
            <NotFound /> :
            errorState === ErrorStates.somethingWentWrong ?
                <SomethingWentWrong /> :
                null;

    const components =
        !socket ?
            <Loading /> :
            props.children;

    const initService = (socket: WebSocket, sessionKey: string) =>
        createDataService(socket, sessionKey)
            .then(({ service, sessionKey }) => {
                setServiceStatus(service(sessionKey), sessionKey);
            });

    return (
        <ServiceContext.Provider value={{
            dataService,
            getSearchService,
            setErrorState,
            getMapBoxToken,
            createDataService: initService,
            sessionKey
        }}>
            {
                errorState === ErrorStates.noError ?
                    components :
                    errors
            }
        </ServiceContext.Provider>
    );
};
