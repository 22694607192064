import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isFirstDayOfMonth, isLastDayOfMonth } from 'date-fns';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { colors } from '../../theme';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { getNextDay, getPrevDay, isFutureDate } from '../utils';
interface ChangeDayButtonsProps {
    date: Date | null,
    setDate: (date: Date | null) => void,
    handleMonthChange: (dayRendere: MaterialUiPickersDate) => Promise<void>,
    setDateChanged: Dispatch<SetStateAction<boolean>>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "row",
            height: theme.typography.pxToRem(56),
            borderTop: `1px solid ${colors.borderColor}`,
            "& .MuiButton-root:last-child": {
                borderLeft: `1px solid ${colors.borderColor}`
            }
        },
        buttons: {
            flex: "1 0 0",
            color: theme.palette.primary.main,
            textTransform: "none",
            textDecoration: "none",
            borderRadius: 0,
        }
    })
);

const ChangeDayButtons: React.FC<ChangeDayButtonsProps> = props => {
    const classes = useStyles();
    const { date, setDate, handleMonthChange, setDateChanged } = props;
    const { translation } = useContext(LocalizationContext);
    const handlePreviousDayChange = (date: Date) => {
        const newDate = getPrevDay(date);
        setDate(newDate);
        if (isLastDayOfMonth(newDate)) {
            handleMonthChange(newDate);
        }
        setDateChanged(true);
    };

    const handleNextDayChange = (date: Date) => {
        const newDate = getNextDay(date);
        setDate(newDate);
        if (isFirstDayOfMonth(newDate)) {
            handleMonthChange(newDate);
        }
        setDateChanged(true);
    };
    return date && (
        <div className={classes.container}>
            <Button
                className={classes.buttons}
                disabled={!isFutureDate(date)}
                onClick={() => handlePreviousDayChange(date)}
            >
                {translation.previousDay}
            </Button>
            <Button
                className={classes.buttons}
                onClick={() => handleNextDayChange(date)}
            >
                {translation.nextDay}
            </Button>
        </div>
    );
};

export default ChangeDayButtons;
