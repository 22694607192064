import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from './AuthContext';
import { LabelOutSideTextField } from './components/CustomStyleComponents/TextFields';
import { ServiceContext } from './components/ServiceContext';
import { translations } from "./components/LanguageContext/aspaTranslations";
import LoadingIndicator from './components/UtilComponents/LoadingIndicator';


const useStyles = makeStyles((theme: Theme) => ({
    labelText: {
        "&::placeholder": {
            fontFamily: "Gotham Narrow Book",
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: theme.typography.pxToRem(0.13),
            lineHeight: theme.typography.pxToRem(24),
            color: "rgba(33,33,33)",
            opacity: 0.7
        }
    },
    textField: {
        margin: theme.spacing(0),
    },
    button: {
        float: "right"
    },
    loading: {
        height: "2em",
        width: "auto"
    }
}));

const Login: React.FC<{}> = () => {
    const { authenticated, authenticate, socket, authService, setAuthStatus } = useAuthContext();
    const { dataService, createDataService } = useContext(ServiceContext);
    const usedTranslation = localStorage.getItem("languageSelection");
    const translation = usedTranslation ? translations[usedTranslation] : translations.finnish;
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const login = async () => {
        if (authenticate && createDataService) {
            setLoading(true);
            return authenticate(username, password)
                .then(resp => {
                    if (resp.success && resp["session-key"] && socket) {
                        !dataService && createDataService(socket, resp["session-key"]);
                    } else {
                        setLoading(false);
                        setErrorText(translation.loginError);
                    }
                })
                .catch(err => console.log(err));
        } else return Promise.resolve(false);
    };

    const reAuth = useMemo(() => (status: boolean, sessionKey: string) => {
        if (setAuthStatus && status && !dataService && createDataService && socket) {
            setAuthStatus(status, sessionKey);
            createDataService(socket, sessionKey);
        } else if (setAuthStatus) {
            setAuthStatus(false, "");
        }
    }, [setAuthStatus, createDataService, socket, dataService]);

    useEffect(() => {
        const sessionKey = localStorage.getItem("sessionKey");
        if (sessionKey && authService && socket) {
            authService.reAuth(sessionKey).then(resp => {
                if (resp.success && resp["session-key"] === sessionKey) {
                    reAuth(resp.success, resp["session-key"]);
                } else {
                    reAuth(false, "");
                }
            });
        } else {
            reAuth(false, "");
        }
    }, [authService, socket]);

    const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    return (
        <Grid container justify="center" alignItems="center" direction="column" spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h1" component="h1">
                    Asiakaspalvelun Ajanvaraus
                    </Typography>
            </Grid>
            <Grid
                container
                item
                xs={12}
                justify="center"
                alignContent="center"
                alignItems="center"
                spacing={3}
                component="form"
                direction="column"
            >
                {authenticated === null ? <LoadingIndicator /> : <>
                    <Grid container item xs={2} direction="row">
                        <LabelOutSideTextField
                            variant="outlined"
                            error={!!errorText}
                            className={classes.textField}
                            label={translation.username}
                            name="username"
                            id="username"
                            fullWidth
                            InputProps={{
                                classes: { input: classes.labelText }
                            }}
                            onChange={onUsernameChange}
                        />
                    </Grid>
                    <Grid container item xs={2} direction="row">
                        <LabelOutSideTextField
                            variant="outlined"
                            error={!!errorText}
                            className={classes.textField}
                            label={translation.password}
                            name="password"
                            id="password"
                            fullWidth
                            InputProps={{
                                classes: { input: classes.labelText }
                            }}
                            type="password"
                            onChange={onPasswordChange}
                        />
                    </Grid>
                    <Grid item container xs={2} direction="row" alignContent="flex-start">
                        <Grid item xs={6}>
                            {loading ?
                                <LoadingIndicator className={classes.loading} /> :
                                !loading && !!errorText ?
                                    <Typography component="p" color="error">{errorText}</Typography> :
                                    null
                            }
                        </Grid>
                        <Grid item xs={6} >
                            <Button
                                className={classes.button}
                                type="submit"
                                variant="outlined"
                                color="primary"
                                id="login"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    login();
                                }}
                            >
                                {translation.login}
                            </Button>
                        </Grid>
                    </Grid>
                </>}
            </Grid>
        </Grid>
    );
};

export default Login;
