import React, { useContext } from "react";
import { Typography, Grid, Link, useMediaQuery, useTheme, makeStyles, Theme, createStyles } from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/PhoneRounded';
import { LocalizationContext } from "./LanguageContext/LocalizationContext";
import { isValidPhoneNr, isEmptyOrNull } from "./utils";
import { Header } from "../Layout";
import { containerShadow } from "../theme";
import { Premise } from "../types";

interface IPreferCallingProps {
    phoneNumber: string,
    premise: Premise | null
}

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        container: {
            boxShadow: containerShadow
        },
        icon: {
            color: theme.palette.primary.main,
        },
        headerIcon: {
            display: "flex",
            width: "36px",
            height: "36px",
            alignItems: "center",
            justifyContent: "center",
            marginRight: theme.spacing(2),
            borderRadius: "50%",
            border: "2px solid",
            borderColor: theme.palette.common.white
        },
        firstParagraph: {
            paddingLeft: mobile ? "16px" : "36px",
            paddingRight: mobile ? "16px" : "36px",
            paddingTop: "16px",
            paddingBottom: "16px",
            justifyContent: "center",
            alignItems: "center"
        },
        secondParagraph: {
            paddingLeft: mobile ? "16px" : "36px",
            paddingRight: mobile ? "16px" : "36px",
            paddingTop: 0,
            paddingBottom: "16px",
            justifyContent: "center",
            alignItems: "center"
        },
        textPadding: {
            paddingTop: theme.spacing(4),
        }
    })
);

const PreferCalling: React.FC<IPreferCallingProps> = (props) => {
    const { phoneNumber, premise } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const { translation } = useContext(LocalizationContext);
    const description = premise?.Ajanvaraus_Kuvaus ? premise.Ajanvaraus_Kuvaus?.split(/\n/) : [];
    const texts = description
        .filter(desc => desc !== "")
        .slice(0, 10)
        .map((text, index) =>
            <Typography
                key={`description-${index}`}
                className={classes.secondParagraph}
            >{text}</Typography>
        );
    const phone = (
        <Link
            href={`tel:${phoneNumber}`}
        >
            {phoneNumber}
        </Link>
    );

    return (
        !isValidPhoneNr(phoneNumber) ? null :
            <Grid container item xs={12} className={classes.container} spacing={0}>
                <Header text={translation.reserveTimeByPhone} icon={
                    <div className={classes.headerIcon}>
                        <PhoneIcon fontSize="small" />
                    </div>
                } />
                <Grid item xs={12} className={classes.firstParagraph}>
                    {
                        premise !== null &&
                        !isEmptyOrNull(premise?.Nimi?.liiketoiminta) &&
                        !isEmptyOrNull(premise?.Nimi?.toimitila) &&
                        <Typography
                            color="textSecondary"
                            variant="subtitle1"
                            style={{ textDecoration: "none" }}
                        >
                            {`${premise.Nimi.liiketoiminta} ${premise.Nimi.toimitila}`}
                        </Typography>

                    }
                    <Typography color="primary" display="inline">
                        {phone}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {description ? texts :
                        <Typography className={classes.firstParagraph}>
                            {translation.youCanCallUs}
                        </Typography>}
                </Grid>
            </Grid>
    );
};

export default PreferCalling;
