export enum Operations {
    auth = "auth",
    data = "data",
    search = "search",
    operations = "appointment"
}

export enum Contexes {
    topaasi = "topaasi",
    toimitila = "toimitila",
    palvelu = "palvelu",
    osoitteet = "suomi osoitteet",
    topaasiAsiantuntija = "topaasi::asiantuntija"
}
