import React, { useContext, useMemo } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import Validator from "validator";
import { IconButton, InputAdornment, useMediaQuery, Typography } from '@material-ui/core';
import Tooltip from "../CustomStyleComponents/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutlineRounded';
import { isValidPhoneNr } from '../utils';
import { DefaultButton, SuccessButton } from '../CustomStyleComponents/Buttons';
import { LabelOutSideTextField, StyledTextField } from '../CustomStyleComponents/TextFields';
import { FieldStatusIcon } from '../UtilComponents/FieldStatusIcon';
import { PrivacyPolicyLink } from '../Links';
import { defaultCustomer } from '../../settings';
import { translations } from "../LanguageContext/aspaTranslations";

const useStyles = (mobile: boolean) => makeStyles(theme => ({
    form: {
        "& .MuiGrid-item": {
            height: "100%"
        },
        ...(mobile ? {
            "& >.MuiGrid-item:last-child": {
                paddingTop: 0
            }
        } : {})
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    helpIcon: {
        color: "#00497F"
    },
    infoIconContainer: {
        display: "flex",
        paddingLeft: theme.spacing(0) + "!important",
        paddingTop: theme.spacing(2) + "!important",
        paddingRight: (mobile ? theme.spacing(0) : theme.spacing(2)) + "!important",
        paddingBottom: theme.spacing(2) + "!important",
        justifyContent: mobile ? "flex-end" : "flex-start",
    },
    labelText: {
        color: "#595959",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(16),
        letterSpacing: theme.typography.pxToRem(0.4),
        backgroundColor: "#FFFFFF",
        paddingRight: theme.typography.pxToRem(4),
        "&::placeholder": {
            fontFamily: "Gotham Narrow Book",
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: theme.typography.pxToRem(0.13),
            lineHeight: theme.typography.pxToRem(24),
            color: "rgba(33,33,33)",
            opacity: 0.7
        }
    },
    textarea: {
        minHeight: "48px"
    },
    blue: {
        color: theme.palette.text.secondary
    },
    noBottomPadding: {
        paddingBottom: "0px"
    }
}));

const checkSumMatches = (hetu: string) => {
    const mark = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D",
        "E", "F", "H", "J", "K", "L", "M", "N", "P", "R",
        "S", "T", "U", "V", "W", "X", "Y"];

    const calculateCheckCharId = (str: string) =>
        parseInt(str) % 31;

    const calculatesToValidCheckChar = (hetuWithoutCheckChar: string) =>
        Validator.isNumeric(hetuWithoutCheckChar) &&
        calculateCheckCharId(hetuWithoutCheckchar) < mark.length;

    const hetuWithoutCheckchar = hetu.slice(0, 6) + hetu.slice(7, 10);
    const checkChar = hetu.slice(10, 11);

    return calculatesToValidCheckChar(hetuWithoutCheckchar) &&
        mark[parseInt(hetuWithoutCheckchar) % 31] === checkChar;
};

const isValidHetu = (hetu: string) => {
    const hetuRegex =
        /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[0-2]\dA)\d{3}[\dA-Z]$/;
    return hetuRegex.test(hetu) && checkSumMatches(hetu);
};

const isAtleastOneCharLong = (str: string) => str.length > 0;
const isValidEmail = (email: string) => RegExp(/\S+@\S+\.\S+/).test(email);
const isValidName = isAtleastOneCharLong;
const isValidPostalNumber = (postalNr: string) => RegExp(/([0-9]){5}/g).test(postalNr);
const isServiceVoucherFilled = (serviceVoucherCode: string, customerType?: string) =>
    (customerType === "palveluseteli" && isAtleastOneCharLong(serviceVoucherCode)) ||
    customerType !== "palveluseteli";

const allValid = (
    hetu: string,
    phoneNr: string,
    email: string,
    firstName: string,
    lastName: string,
    streetAddress: string,
    postalCode: string,
    localityName: string,
    dataConsent: boolean,
    serviceVoucherCode: string,
    customerType?: string
) =>
    isValidHetu(hetu) &&
    isValidPhoneNr(phoneNr) &&
    (isValidEmail(email) || email === "") &&
    isValidName(firstName) &&
    isValidName(lastName) &&
    isAtleastOneCharLong(streetAddress) &&
    isValidPostalNumber(postalCode) &&
    isAtleastOneCharLong(localityName) &&
    dataConsent &&
    isServiceVoucherFilled(serviceVoucherCode, customerType);

interface InformationFormProps {
    handleNext: () => void,
    handleBack: () => void,
    hetu: string,
    setHetu: (hetu: string) => void,
    phoneNr: string,
    setPhoneNr: (phoneNr: string) => void,
    email: string,
    setEmail: (email: string) => void,
    firstName: string,
    setFirstName: (firstname: string) => void,
    lastName: string,
    setLastName: (lastname: string) => void,

    streetAddress: string,
    setStreetAddress: (str: string) => void,

    postalCode: string,
    setPostalCode: (str: string) => void,

    localityName: string,
    setLocalityName: (str: string) => void,

    dataConsent: boolean,
    setDataConsent: (val: boolean) => void,
    moreInformation: string,
    setMoreInformation: (str: string) => void,
    customerType?: string,
    serviceVoucherCode: string,
    setServiceVoucherCode: (code: string) => void,
    discountCode: string,
    setDiscountCode: (code: string) => void,
    customerNotFoundError: boolean,
    setCustomerNotFoundError: (status: boolean) => void,
    setValidatedHetu: (status: boolean | null) => void,
    validatedHetu: boolean | null
}

const InformationForm: React.FC<InformationFormProps> = ({
    handleNext,
    handleBack,
    hetu,
    setHetu,
    email,
    setEmail,
    phoneNr,
    setPhoneNr,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dataConsent,
    setDataConsent,
    moreInformation,
    setMoreInformation,

    streetAddress,
    setStreetAddress,
    postalCode,
    setPostalCode,
    localityName,
    setLocalityName,
    customerType,
    serviceVoucherCode,
    setServiceVoucherCode,
    discountCode,
    setDiscountCode,
    customerNotFoundError,
    setCustomerNotFoundError,
    setValidatedHetu,
    validatedHetu,
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles(!windowIsMdSize)();
    const { translation } = useContext(LocalizationContext);
    const usedTranslation = localStorage.getItem("languageSelection");
    const aspaTranslation = usedTranslation ? translations[usedTranslation] : translations.finnish;

    const nextButton = useMemo(() =>
        <Grid item xs={12} md={6}>
            <SuccessButton
                onClick={handleNext}
                fullWidth
                id={"nextButtonConfirmation"}
                disabled={!allValid(
                    hetu,
                    phoneNr,
                    email,
                    firstName,
                    lastName,
                    streetAddress,
                    postalCode,
                    localityName,
                    dataConsent,
                    serviceVoucherCode,
                    customerType
                )}
            >
                {translation.continueToNextPhase}
            </SuccessButton>
        </Grid>
        , [
            hetu, phoneNr, email, firstName, lastName,
            streetAddress, postalCode, localityName, dataConsent, serviceVoucherCode
        ]);

    const closeButton = useMemo(() =>
        <Grid item xs={12} md={6}>
            <DefaultButton
                onClick={handleBack}
                fullWidth
            >
                {translation.buttonGoBack}
            </DefaultButton>
        </Grid>
        , []);

    const spacing = 4;

    const validateHetu = (hetu: string) =>
        hetu !== "" && isValidHetu(hetu) ?
            setValidatedHetu(true) :
            hetu === "" ?
                setValidatedHetu(null) :
                setValidatedHetu(false);



    const onHetuBlur = () => {
        validateHetu(hetu);
    };

    const onHetuFocus = () => {
        setValidatedHetu(null);
        setCustomerNotFoundError(false);
    };

    const ssnHelperText = useMemo(() => {
        if (hetu !== "" && validatedHetu === false) {
            return translation.ssnError;
        } else if (customerNotFoundError) {
            return aspaTranslation.customerNotFound;
        } else {
            return "";
        }
    }, [hetu, validatedHetu, customerNotFoundError, translation, aspaTranslation]);

    return (
        <Grid component="form" noValidate container item className={classes.form} spacing={spacing}>
            <Grid
                item
                xs={12}
                style={{ paddingBottom: "0px" }}
            >
                <Typography
                    variant={"h6"}
                    className={classes.blue}
                >
                    {translation.verifySelection}
                </Typography>
            </Grid>
            <Grid item md={6} xs={10}>
                <LabelOutSideTextField
                    value={hetu}
                    onChange={(e: any) => setHetu(e.target.value)}
                    onFocus={onHetuFocus}
                    onBlur={onHetuBlur}
                    variant="outlined"
                    required
                    fullWidth
                    id="hetu"
                    label={translation.personalIdentificationNumber}
                    name="hetu"
                    error={validatedHetu === false}
                    helperText={ssnHelperText}
                    autoComplete={"off"}
                    InputProps={{
                        endAdornment: (
                            validatedHetu !== null && <InputAdornment position="end">
                                {
                                    <FieldStatusIcon
                                        value={hetu}
                                        isValid={() =>
                                            validatedHetu === true
                                        }
                                    />
                                }
                            </InputAdornment>
                        ),
                        classes: { input: classes.labelText }
                    }}
                />
            </Grid>
            {
                <Grid item xs={2} md={6} className={classes.infoIconContainer}>
                    <Tooltip
                        title={translation.ssnTooltip}
                        arrow={true}
                        interactive
                    >
                        <IconButton
                            size={"medium"}
                            disableRipple={true}
                            style={{ backgroundColor: 'transparent' }}
                        >
                            <HelpOutlineIcon
                                fontSize={"large"}
                                className={classes.helpIcon}
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            {customerType === "palveluseteli" && <Grid item xs={12} md={6}>
                <LabelOutSideTextField
                    value={serviceVoucherCode}
                    onChange={(e: any) => {
                        setServiceVoucherCode(e.target.value);
                    }}
                    variant="outlined"
                    label={translation.serviceVoucherNumber}
                    required
                    fullWidth
                    id="palse"
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {
                                    <FieldStatusIcon
                                        value={serviceVoucherCode}
                                        isValid={isAtleastOneCharLong}
                                    />
                                }
                            </InputAdornment>
                        ),
                        classes: { input: classes.labelText }
                    }}
                />

            </Grid>}
            <Grid item xs={12}>
                <Grid container spacing={spacing}>
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={firstName}
                            onChange={(e: any) => setFirstName(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="firstname"
                            label={translation.firstName}
                            id="firstname"
                            error={firstName !== "" && !isValidName(firstName)}
                            helperText={
                                firstName !== "" && !isValidName(firstName) ?
                                    "error" :
                                    ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={firstName}
                                                isValid={isValidName}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={lastName}
                            onChange={(e: any) => setLastName(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="lastname"
                            label={translation.lastName}
                            id="lastname"
                            error={lastName !== "" && !isValidName(lastName)}
                            helperText={
                                lastName !== "" && !isValidName(lastName) ?
                                    "error" :
                                    ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={lastName}
                                                isValid={isValidName}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid container spacing={spacing}>
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={streetAddress}
                            onChange={(e: any) => setStreetAddress(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="streetAddress"
                            label={translation.streetAddress}
                            id="streetAddress"
                            autoComplete={"shipping street-address"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={streetAddress}
                                                isValid={isAtleastOneCharLong}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={spacing} justify="flex-end">
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={postalCode}
                            onChange={(e: any) => setPostalCode(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="postalCode"
                            label={translation.postalCode}
                            id="postalCode"
                            autoComplete={"shipping postal-code"}
                            error={postalCode !== "" && !isValidPostalNumber(postalCode)}
                            helperText={
                                postalCode !== "" && !isValidPostalNumber(postalCode) ?
                                    translation.postalError :
                                    ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={postalCode}
                                                isValid={isValidPostalNumber}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={localityName}
                            onChange={(e: any) => setLocalityName(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="locality"
                            label={translation.district}
                            id="locality"
                            autoComplete={"shipping locality"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={localityName}
                                                isValid={isAtleastOneCharLong}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={spacing} justify="flex-end">
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={phoneNr}
                            onChange={(e: any) => setPhoneNr(e.target.value)}
                            variant="outlined"
                            required
                            fullWidth
                            name="phoneNumber"
                            label={translation.phoneNumber}
                            id="phoneNumber"
                            autoComplete={"tel"}
                            error={phoneNr !== "" && !isValidPhoneNr(phoneNr)}
                            helperText={
                                phoneNr !== "" && !isValidPhoneNr(phoneNr) ?
                                    translation.phoneError :
                                    ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={phoneNr}
                                                isValid={isValidPhoneNr}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LabelOutSideTextField
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            name="email"
                            label={translation.email}
                            id="email"
                            autoComplete={"email"}
                            error={email !== "" && !isValidEmail(email)}
                            helperText={
                                email !== "" && !isValidEmail(email) ?
                                    translation.emailError :
                                    ""
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            <FieldStatusIcon
                                                value={email}
                                                isValid={isValidEmail}
                                            />
                                        }
                                    </InputAdornment>
                                ),
                                classes: { input: classes.labelText }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {customerType === defaultCustomer && <Grid item xs={12}>
                <LabelOutSideTextField
                    value={discountCode}
                    onChange={(e: any) => {
                        setDiscountCode(e.target.value);
                    }}
                    variant="outlined"
                    label={`${translation.discountCodeNumber} (${translation.discountCodeNumberInfo})`}
                    fullWidth
                    id="discount"
                    autoComplete="off"
                />

            </Grid>}
            <Grid item xs={12}>
                <StyledTextField
                    variant="outlined"
                    value={moreInformation}
                    onChange={(e: any) => setMoreInformation(e.target.value)}
                    fullWidth
                    multiline
                    name="information"
                    label={translation.moreInformation.toUpperCase()}
                    placeholder={translation.informationWillOnlyBeAvailableTo}
                    id="information"
                    InputProps={{
                        classes: { input: `${classes.labelText} ${classes.textarea}` }
                    }}
                    InputLabelProps={{
                        disableAnimation: true,
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                style={{ paddingBottom: "0px" }}
            >
                <Typography
                    variant={"h6"}
                    className={classes.blue}
                >
                    {translation.agreeToDataPrivacyHeader}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={dataConsent}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setDataConsent(event.target.checked)
                            }
                            style={{ pointerEvents: "auto" }}
                            color="primary"
                            required
                        />}
                    label={
                        <Typography>
                            {translation.checkboxDataProtectionAgree}
                            <PrivacyPolicyLink />
                        </Typography>
                    }
                />
            </Grid>
            {
                windowIsMdSize ?
                    closeButton :
                    nextButton
            }
            {
                windowIsMdSize ?
                    nextButton :
                    closeButton
            }
        </Grid>
    );
};

export default InformationForm;
