import { makeStyles, Theme, createStyles, Stepper, Step, StepLabel, StepConnector, withStyles, StepIconProps } from '@material-ui/core';
import { useState, useContext, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import React from 'react';
import InitialInformation from "./InitialInformation";
import InformationForm from "./InformationForm";
import ReminderForm from "./ReminderForm";
import ConfirmSuccess from "./CorfirmationSuccess";
import ConfirmFailure from "./ConfirmationFailure";
import ConfirmTimeout from "./ConfirmationTimeout";
import { PopulatedTimeslot, Building, EntityResponse, RequestReservationResponseFailure, RequestReservationResponseSuccess, Operations, Premise, CampaignService, RequestReservationResponseTimeout, CustomerFound, CustomerNotFound } from "../../types";
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { ServiceContext } from '../ServiceContext';
import { timeslotStore } from "../../store/stores";
import clsx from 'clsx';
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import { theme, colors } from '../../theme';
import { dentistUuid } from '../../settings';
import { btou } from '../../services/socketHelpers';
import { useAuthContext } from '../../AuthContext';
import servicesJson from "../../services.json";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            width: '100%',
            height: "100%"
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        alwaysEnableClearIcon: {
            "&.MuiAutocomplete-clearIndicator": {
                visibility: "visible"
            }
        },
        muiStepIconActiveOverride: {
            "& .MuiStepIcon-root": {
                fontSize: "1.6666rem",
            },
            "& .MuiStepLabel-iconContainer": {
                position: "relative",
                top: "-3px"
            }
        },
        stepper: {
            "& .MuiSvgIcon-root": {
                width: "27px" // fix clipping step-icon on safari
            }
        }
    }),
);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: colors.lightGrey,
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: theme.palette.primary.main,
    },
    completed: {
        zIndex: 1,
        color: theme.palette.success.main,
    }
});

const CustomStepIcon = (props: StepIconProps) => {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            <CheckCircleIcon
                fontSize={"large"}
                className={completed ? classes.completed : ""}
            />
        </div>
    );
};

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.success.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.success.main,
        },
    },
    line: {
        borderColor: colors.lightGrey,
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

interface ConfirmReservationProps {
    timeslot: PopulatedTimeslot,
    serviceName: string,
    buildingUuid: string,
    setHeader: (str: string) => void,
    closeDialog: () => void,
    customerType: string,
    services: CampaignService[],
    scrollToDialogTop: () => void,
    allServices: CampaignService[],
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}

enum StepperStates {
    "initilaInformation",
    "informationForm",
    "contactPreferences"
}

const ConfirmReservation: React.FC<ConfirmReservationProps> = ({
    timeslot,
    serviceName,
    buildingUuid,
    setHeader,
    closeDialog,
    customerType,
    services,
    scrollToDialogTop,
    allServices,
    accessToken,
    setAccessToken
}) => {
    const classes = useStyles();
    const { dataService } = useContext(ServiceContext);
    const { translation } = useContext(LocalizationContext);

    const [activeStep, setActiveStep] = useState<StepperStates>(StepperStates.initilaInformation);
    const [reservationSuccess, setReservationSuccess] =
        useState<
            RequestReservationResponseSuccess |
            RequestReservationResponseFailure |
            RequestReservationResponseTimeout |
            null
        >(null);

    const [hetu, setHetu] = useState<string>("");
    const [phoneNr, setPhoneNr] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [firstName, setFirstname] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");

    const [streetAddress, setStreetAddress] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [localityName, setLocalityName] = useState<string>("");

    const [remindTwoHoursBefore, setRemindTwoHoursBefore] = useState<number | false>(false);
    const [remindDayBefore, setRemindDayBefore] = useState<boolean>(false);

    const [dataConsent, setDataConsent] = useState<boolean>(false);
    const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
    const [moreInformation, setMoreInformation] = useState<string>("");

    const [isStolenTime, setIsStolenTime] = useState<boolean>(false);
    const [showStolenTimeError, setShowStolenTimeError] = useState<boolean>(false);

    const [serviceVoucherCode, setServiceVoucherCode] = useState<string>("");
    const [discountCode, setDiscountCode] = useState<string>("");

    const [building, setBuilding] = useState<EntityResponse<Building> | null>(null);
    const [premise, setPremise] = useState<EntityResponse<Premise> | null>(null);
    const [service, setService] = useState<CampaignService | null>(null);
    const [customerNotFoundError, setCustomerNotFoundError] = useState(false);
    const [validatedHetu, setValidatedHetu] = useState<boolean | null>(null);
    const { authService, sessionKey } = useAuthContext();

    useEffect(() => {
        const subscription = timeslotStore.subject.subscribe(resp => {
            const changedSlot = resp.data.find((slot) => slot.uuid === timeslot.uuid);
            if (changedSlot?.available !== undefined && !changedSlot?.available) {
                setIsStolenTime(true);
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (dataService) {
            dataService.requestBuilding(buildingUuid).then(setBuilding).catch();
            dataService.requestPremise(timeslot.toimipaikkaUuid).then(setPremise).catch();
            const service = services.find(service => service.uuid === timeslot.palvelu);
            setService(service ? service : null);
        }
    }, [dataService, buildingUuid]);


    const category = useMemo(() => {
        const findSubCategory = (service: CampaignService): CampaignService | null =>
            timeslot.palvelu === service.uuid ? service :
                service?.children?.find(findSubCategory) || null;

        const categories = allServices.reduce((acc, cur) =>
            cur.children ? [...acc, ...cur.children] : acc, [] as CampaignService[]
        ).find(findSubCategory);

        const categoryUuid = categories?.uuid;
        const service = servicesJson
            .find(service =>
                service.subServices.find(serv => serv.id === categoryUuid)
            );
        return service;
    }, []);

    const isDentistCategoryTimeslot = useMemo(() => {
        return category?.id === dentistUuid && !!services
            .some(service => service.uuid === timeslot.palvelu);
    }, [timeslot, category]);

    const handleNext = () => {
        scrollToDialogTop();
        return isStolenTime ?
            setShowStolenTimeError(true) :
            setActiveStep(prevActiveStep => {
                if (prevActiveStep === StepperStates.initilaInformation)
                    return StepperStates.informationForm;
                else if (prevActiveStep === StepperStates.informationForm)
                    return StepperStates.contactPreferences;
                else
                    return prevActiveStep;
            });
    };

    const handleBack = () =>
        setActiveStep(prevActiveStep => {
            if (prevActiveStep === StepperStates.contactPreferences)
                return StepperStates.informationForm;
            else if (prevActiveStep === StepperStates.informationForm)
                return StepperStates.initilaInformation;
            else
                return prevActiveStep;
        });
    const isCustomerNotFound = (data: CustomerFound | CustomerNotFound): data is CustomerNotFound =>
        (data as CustomerNotFound).customer !== undefined;

    const showCustomerNotFound = () => {
        setCustomerNotFoundError(true);
    };

    const fillFormData = (data: CustomerFound) => {
        setFirstname(btou(data.firstName));
        setLastName(btou(data.lastName));
        setEmail(data.emailAddress);
        setStreetAddress(btou(data.streetAddress));
        setPhoneNr(btou(data.phoneNumber));
        setPostalCode(btou(data.postalCode));
        setLocalityName(btou(data.localityName));
    };

    useEffect(() => {
        if (hetu !== "" && validatedHetu && sessionKey) {
            const fetchCustomer = async () =>
                authService?.fetchBySSN(hetu)
                    .then(resp =>
                        isCustomerNotFound(resp.data) ?
                            showCustomerNotFound() :
                            fillFormData(resp.data)
                    ).catch(_err => { });
            fetchCustomer();
        }
    }, [validatedHetu, sessionKey, authService, hetu]);

    const handleConfirm = () => {
        dataService?.requestReservation(
            timeslot.uuid,
            hetu,
            firstName,
            lastName,
            phoneNr,
            email,
            streetAddress,
            postalCode,
            localityName,
            dataConsent,
            marketingConsent,
            moreInformation,
            remindDayBefore,
            remindTwoHoursBefore ? 0 : false, // 0 Means 2h before reserved time.
            serviceVoucherCode,
            discountCode
        ).then((resp) => {
            setHeader(
                resp.success ?
                    translation.appointmentSuccesfull :
                    translation.timeslotConfirmationFailureHeader
            );
            setReservationSuccess(resp);
        }).catch((_err) => {
            setHeader(translation.reservationProblem);
            setReservationSuccess({
                op: Operations.appointment,
                success: false,
                uuid: timeslot.uuid,
                timeout: true,
                data: {}
            });
        });
    };

    const initialInformation = useMemo(() =>
        <InitialInformation
            timeslot={timeslot}
            serviceName={serviceName}
            building={building}
            premise={premise}
            service={service}
            handleClose={closeDialog}
            handleNext={handleNext}
            accessToken={accessToken}
            setAccessToken={setAccessToken}
        />
        , [timeslot, serviceName, building, premise, service, accessToken]);

    const steps = (
        activeStep === 0 ?
            initialInformation
            : activeStep === 1 ?
                (<InformationForm
                    handleNext={handleNext}
                    handleBack={handleBack}
                    hetu={hetu}
                    setHetu={setHetu}
                    email={email}
                    setEmail={setEmail}
                    phoneNr={phoneNr}
                    setPhoneNr={setPhoneNr}
                    firstName={firstName}
                    setFirstName={setFirstname}
                    lastName={lastName}
                    setLastName={setLastName}
                    dataConsent={dataConsent}
                    setDataConsent={setDataConsent}
                    moreInformation={moreInformation}
                    setMoreInformation={setMoreInformation}

                    streetAddress={streetAddress}
                    setStreetAddress={setStreetAddress}

                    postalCode={postalCode}
                    setPostalCode={setPostalCode}

                    localityName={localityName}
                    setLocalityName={setLocalityName}
                    customerType={customerType}
                    serviceVoucherCode={serviceVoucherCode}
                    setServiceVoucherCode={setServiceVoucherCode}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    customerNotFoundError={customerNotFoundError}
                    setCustomerNotFoundError={setCustomerNotFoundError}
                    setValidatedHetu={setValidatedHetu}
                    validatedHetu={validatedHetu}
                />) :
                activeStep === 2 ?
                    (<ReminderForm
                        handleConfirm={handleConfirm}
                        handleBack={handleBack}
                        marketingConsent={marketingConsent}
                        setMarketingConsent={setMarketingConsent}
                        remindHoursBefore={remindTwoHoursBefore}
                        setRemindHoursBefore={setRemindTwoHoursBefore}
                        remindDayBefore={remindDayBefore}
                        setRemindDayBefore={setRemindDayBefore}
                        isDentistCategoryTimeslot={isDentistCategoryTimeslot}
                    />) : null);

    const successAndFailure = (
        reservationSuccess === null ?
            null :
            reservationSuccess.success ?
                (<ConfirmSuccess
                    timeslot={timeslot}
                    serviceName={serviceName}
                    building={building}
                    premise={premise}
                    service={service}
                    handleClose={closeDialog}
                    reservationObj={reservationSuccess}
                    discountCode={discountCode}
                    category={category ? category.name : ""}
                    accessToken={accessToken}
                    setAccessToken={setAccessToken}
                />) : reservationSuccess.timeout ?
                    (<ConfirmTimeout
                        handleClose={closeDialog}
                    />) :
                    (<ConfirmFailure
                        handleClose={closeDialog}
                    />));

    const isInitial = useMemo(() => activeStep === StepperStates.initilaInformation, [activeStep]);
    const isInformationForm = useMemo(() =>
        activeStep === StepperStates.informationForm,
        [activeStep]);
    const isContactPreferences = useMemo(() => activeStep ===
        StepperStates.contactPreferences,
        [activeStep]);

    return (
        <>
            {
                !showStolenTimeError &&
                reservationSuccess === null && // Only show stepper when reservation has not been initiated
                <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    alternativeLabel
                    connector={<QontoConnector />}
                >
                    <Step
                        id="step-1"
                    >
                        <StepLabel
                            className={
                                isInitial ?
                                    classes.muiStepIconActiveOverride :
                                    ""
                            }
                            StepIconComponent={
                                isInformationForm || isContactPreferences ?
                                    CustomStepIcon :
                                    undefined
                            }
                        >
                            {

                                isInitial ?
                                    translation.stepperReserveTimeslot :
                                    ""
                            }
                        </StepLabel>
                    </Step>
                    <Step
                        id="step-2"
                    >
                        <StepLabel
                            className={
                                isInitial || isInformationForm ?
                                    classes.muiStepIconActiveOverride :
                                    ""
                            }
                            StepIconComponent={
                                isContactPreferences ?
                                    CustomStepIcon :
                                    undefined
                            }
                        >
                            {
                                activeStep === StepperStates.informationForm ?
                                    translation.stepperInformationForm :
                                    ""
                            }
                        </StepLabel>
                    </Step>
                    <Step
                        id="step-3"
                    >
                        <StepLabel
                            className={classes.muiStepIconActiveOverride}
                        >
                            {
                                activeStep === StepperStates.contactPreferences ?
                                    translation.stepperConfirm :
                                    ""
                            }
                        </StepLabel>
                    </Step>
                </Stepper>
            }

            {
                showStolenTimeError ?
                    <ConfirmFailure
                        handleClose={closeDialog}
                    /> :
                    reservationSuccess === null ?
                        steps :
                        successAndFailure
            }
        </>
    );
};

export default ConfirmReservation;
