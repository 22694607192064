import React, { useState, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { Grid, makeStyles, createStyles, Theme, useMediaQuery, Collapse, Button, TextField, Typography } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SelectCustomerType from './Filters/SelectCustomerType';
import { Premise, EntityResponse, Expert, PremiseLocation, Speciality, UserLocation, Building, Campaign, SelectedFilters, CampaignService, Language } from '../types';
import SearchService from "./Filters/SearchService";
import SearchExpert from "./Filters/SearchExpert";
import ExtraFilters from './Filters/ExtraFilters/ExtraFilters';
import { theme } from '../theme';
import { LocalizationContext } from './LanguageContext/LocalizationContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import CalendarFilter from './CalendarFilter';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizedUtils } from './LocalizedUtils';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarTodayRounded';
import FullWidthDialog from './FullWidthDialog';
import SearchPremise from './Filters/SearchPremise';
import UserLocationSearch from './Filters/UserLocationSearch';
import { ServiceContext } from './ServiceContext';
import { getAddress } from './utils';
import SearchExpertPhysio from './Filters/SearchExpertPhysio';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "#DAEDF7",
        },
        itemMobile: {
            paddingBottom: theme.spacing(4),
            "&:last-child": {
                paddingBottom: 0
            }
        },
        itemDesktop: {
            paddingRight: theme.spacing(2),
            "&:last-child": {
                paddingBottom: 0,
                paddingRight: 0
            }
        },
        collapse: {
            width: "100%",
        },
        button: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: theme.typography.pxToRem(16),
            color: theme.palette.primary.main,
            paddingLeft: 0,
            maxHeight: "36px",
        },
        buttonGrid: {
            paddingTop: theme.spacing(4)
        },
        disableButton: {
            maxHeight: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        disableButtonMobile: {
            paddingBottom: theme.spacing(4)
        },
        dateInput: {
            cursor: "none",
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            borderRadius: "4px",
            "& label": {
                color: "#595959",
                fontFamily: "Gotham Narrow Medium",
                "&::placeholder": {
                    opacity: 0.7
                }
            },
            "& .MuiInputLabel-outlined": {
                transform: "translate(6px, -16px) scale(0.75)"
            },
            "& legend": {
                maxWidth: 0
            },
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                color: "#595959",
                '& fieldset': {
                    borderWidth: "1px",
                    borderColor: "#979797",
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "1px",
                    borderColor: theme.palette.primary.main,
                },
            },
            "& .MuiOutlinedInput-input": {
                cursor: "none",
                color: "#595959"
            }
        }
    })
);

interface FilterMenuProps {
    date: MaterialUiPickersDate,
    onDateChange: (date: MaterialUiPickersDate) => void,
    premises: EntityResponse<Premise>[],
    selectedServices: CampaignService[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    position?: GeolocationPosition | null,
    selectedExperts: EntityResponse<Expert>[],
    timeselectListFilters: string[] | null,
    locationFilters: string[],
    expertFilters: string[],
    services: CampaignService[],
    locationUuids: PremiseLocation[] | null,
    languageFilters: string[],
    specialityFilters: string[],
    selectedSpecialities: EntityResponse<Speciality>[],
    selectedLanguages: EntityResponse<Language>[],
    serviceFilters: string[],
    localization: Locale,
    utils: typeof DateFnsUtils | typeof LocalizedUtils,
    onCalendarModalOpen: () => void,
    onCalendarModalClose: () => void,
    calendarModalOpen: boolean,
    slots: string[],
    setSlots: (slots: string[]) => void,
    enableLocation: () => void,
    disableLocation: () => void,
    locationEnabled: boolean,
    handleMonthChange: (renderedDay: MaterialUiPickersDate) => Promise<void>,
    customerType: string,
    campaign?: Campaign,
    userLocation: UserLocation | null,
    loadingInitialState: boolean,
    availableCustomerTypes: string[],
    setPopulatingFilters: Dispatch<SetStateAction<boolean>>,
    accessToken: string | null,
    setAccessToken: Dispatch<SetStateAction<string | null>>
}

export const LocationFilterMenu: React.FC<FilterMenuProps> = ({
    premises,
    selectedExperts,
    selectedServices,
    setSelectedFilters,
    locationFilters,
    expertFilters,
    services,
    locationUuids,
    position,
    languageFilters,
    specialityFilters,
    serviceFilters,
    timeselectListFilters,
    date,
    onDateChange,
    utils,
    localization,
    onCalendarModalClose,
    onCalendarModalOpen,
    calendarModalOpen,
    slots,
    setSlots,
    disableLocation,
    enableLocation,
    locationEnabled,
    handleMonthChange,
    customerType,
    userLocation,
    loadingInitialState,
    selectedLanguages,
    selectedSpecialities,
    availableCustomerTypes,
    campaign,
    setPopulatingFilters,
    accessToken,
    setAccessToken
}) => {
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [checked, setChecked] = useState<boolean>(false);
    const [location, setLocation] = useState<EntityResponse<Building> | null>(null);
    const { translation } = useContext(LocalizationContext);

    const handleChecked = (isOpen: boolean) => (_e: any) => {
        setChecked(isOpen);
    };
    const { dataService } = useContext(ServiceContext);
    const endIcon = !checked ? <ExpandMoreIcon /> : <ExpandLessIcon />;
    const premiseName = premises.length ? premises[0].data.Nimi : null;

    useEffect(() => {
        if (premises.length > 0)
            dataService?.requestBuilding(premises[0].data.toimitila)
                .then(location => setLocation(location))
                .catch((_err) => { });
    }, [dataService, premises]);

    useEffect(() => {
        if (selectedLanguages.length || selectedSpecialities.length) {
            setChecked(true);
        }
    }, [selectedLanguages, selectedSpecialities]);

    const premise = (
        premiseName && location &&
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(2) }}>
            <Typography
                color="textSecondary"
                variant="subtitle1"
                style={{ textDecoration: "none" }}
            >
                {`Valittu toimipaikka: ${getAddress(location.data.katuosoite ? location.data.katuosoite : "")}, ${premiseName.liiketoiminta} ${premiseName.toimitila}, ${premiseName.yritys}`}
            </Typography>
        </Grid>
    );

    const getExpertFilter = (campaignName?: string) => {
        const filter: { [index: string]: JSX.Element } = {

            Fysioterapia: <SearchExpertPhysio
                expertFilters={expertFilters}
                setSelectedFilters={setSelectedFilters}
                loadingInitialState={loadingInitialState}
                selectedExperts={selectedExperts}
            />,
            default: <SearchExpert
                expertFilters={expertFilters}
                setSelectedFilters={setSelectedFilters}
                loadingInitialState={loadingInitialState}
                selectedExperts={selectedExperts}
                services={services}
            />
        };
        return campaignName && filter[campaignName] ? filter[campaignName] : filter.default;
    };

    return (
        <Grid container item spacing={0} className={classes.root}>
            <Grid item xs={12}>
                <SelectCustomerType
                    customerType={customerType}
                    setSelectedFilters={setSelectedFilters}
                    availableCustomerTypes={availableCustomerTypes}
                    setPopulatingFilters={setPopulatingFilters}
                    campaign={campaign}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="button" component="h2" style={{
                    marginBottom: mobile ? theme.spacing(4) : 0
                }}>
                    {translation.premisesNearYou.toUpperCase()}
                </Typography>
            </Grid>
            {mobile &&
                <Grid item xs={12}>
                    <UserLocationSearch
                        position={position}
                        setSelectedFilters={setSelectedFilters}
                        value={userLocation}
                        accessToken={accessToken}
                    />
                </Grid>
            }
            <Grid container item xs={12}
                style={{ paddingTop: "8px" }}
            >
                <Grid
                    item
                    md={4}
                    xs={12}
                    className={mobile ? classes.itemMobile : classes.itemDesktop}
                >
                    <SearchPremise
                        locationFilters={locationFilters}
                        premises={premises}
                        position={position}
                        setSelectedFilters={setSelectedFilters}
                        locationUuids={locationUuids}
                        enableLocation={enableLocation}
                        locationEnabled={locationEnabled}
                        loadingInitialState={loadingInitialState}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                    />
                </Grid>
                <Grid
                    container
                    item
                    md={8}
                    xs={12}
                    className={mobile ? classes.itemMobile : classes.itemDesktop}
                >
                    <Grid container item xs={12}>
                        {!mobile &&
                            <Grid
                                item xs={mobile ? 12 : 6}
                                className={mobile ? classes.itemMobile : classes.itemDesktop}
                            >
                                <UserLocationSearch
                                    position={position}
                                    setSelectedFilters={setSelectedFilters}
                                    value={userLocation}
                                    accessToken={accessToken}
                                />
                            </Grid>}
                        {mobile &&
                            premise
                        }
                        <Grid
                            item xs={mobile ? 12 : 6}
                            className={
                                mobile ?
                                    classes.disableButtonMobile :
                                    classes.disableButton
                            }
                        >
                            <Button
                                variant="contained"
                                onClick={disableLocation}
                                color="primary"
                                fullWidth={mobile}
                            >
                                {translation.cancelLocationSearch.toUpperCase()}
                            </Button>
                        </Grid>
                    </Grid>
                    {!mobile && premise}
                    <Grid container item xs={12}>
                        <Grid
                            item
                            xs={mobile ? 12 : 6}
                            className={mobile ? classes.itemMobile : classes.itemDesktop}
                        >
                            <SearchService
                                services={services}
                                selectedExperts={selectedExperts}
                                selectedPremises={premises}
                                setSelectedFilters={setSelectedFilters}
                                selectedServices={selectedServices}
                                serviceFilters={serviceFilters}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={mobile ? 12 : 6}
                            className={mobile ? classes.itemMobile : classes.itemDesktop}
                        >
                            {getExpertFilter(campaign?.name)}
                        </Grid>
                    </Grid>
                    {mobile &&
                        <Grid item xs={12} style={{
                            paddingTop: theme.spacing(4),
                            paddingBottom: theme.spacing(4)
                        }}
                        >
                            <TextField
                                className={classes.dateInput}
                                placeholder={translation.selectDay}
                                fullWidth
                                variant="outlined"
                                label={translation.day.toUpperCase()}
                                value={!date ?
                                    null :
                                    date}
                                onClick={onCalendarModalOpen}
                                InputProps={{
                                    endAdornment: (<CalendarTodayIcon />),
                                    readOnly: true,
                                    type: "date",
                                    value: date ? moment(date).format("YYYY-MM-DD") : null
                                }}
                            />

                            <FullWidthDialog
                                header={translation.selectDay}
                                open={calendarModalOpen}
                                handleClose={onCalendarModalClose}
                            >
                                <CalendarFilter
                                    date={date}
                                    filters={timeselectListFilters}
                                    onDateChange={onDateChange}
                                    utils={utils}
                                    localization={localization}
                                    slots={slots}
                                    setSlots={setSlots}
                                    handleMonthChange={handleMonthChange}
                                />
                            </FullWidthDialog>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button
                            variant="text"
                            onClick={handleChecked(!checked)}
                            endIcon={endIcon}
                            className={classes.button}
                        >
                            {!checked ? translation.showExtraFilters : translation.hideExtraFilters}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse className={classes.collapse} in={checked} timeout="auto">
                <Grid container item xs={12}>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={8} xs={12}>
                        <ExtraFilters
                            languageFilters={languageFilters}
                            setSelectedFilters={setSelectedFilters}
                            selectedLanguages={selectedLanguages}
                            selectedSpecialities={selectedSpecialities}
                            specialityFilters={specialityFilters}
                            locationEnabled={locationEnabled}
                            loadingInitialState={loadingInitialState}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        </Grid>
    );
};
