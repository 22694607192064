import { Tooltip, withStyles, Theme } from "@material-ui/core";
import { colors } from "../../theme";

const StyledTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: colors.greyText,
        color: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(14),
        fontFamily: "Gotham Book",
        whiteSpace: "pre-wrap"
    }
}))(Tooltip);

export default StyledTooltip;
