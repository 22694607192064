import React, { useContext, useState, useEffect, useMemo, Dispatch, SetStateAction, ReactNode } from 'react';
import MultiFilter, { StyledChip } from "../MultiFilter";
import { EntityResponse, Premise, Expert, SelectedFilters, CampaignService } from "../../types";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { IconButton, Typography, makeStyles, createStyles, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import StyledTooltip from '../CustomStyleComponents/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AutocompleteGetTagProps, AutocompleteRenderGroupParams } from '@material-ui/lab';
import moment from 'moment';
import { sortByDateOrderAndName } from '../utils';
import { colors } from '../../theme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { mobileServiceTooltipCloseTimeoutMs } from '../../settings';
import toothclinicListOrder from '../../toothclinicListOrder';

interface SearchServiceProps {
    services: CampaignService[],
    selectedPremises: EntityResponse<Premise>[],
    selectedExperts: EntityResponse<Expert>[],
    selectedServices: CampaignService[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    serviceFilters: string[]
}

const listItem = (theme: Theme): CSSProperties => ({
    paddingLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
});



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            ...listItem(theme),
        },
        listItemText: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        listItemDiscount: {
            ...listItem(theme),
            backgroundColor: colors.discountBackground,
            "&:hover": {
                backgroundColor: theme.palette.primary.main
            }
        },
        popular: {
            backgroundColor: colors.boxBackground,
            borderRadius: "4px",
            padding: "0 4px",
            color: theme.palette.text.secondary,
            minWidth: "60px",
            textAlign: "center"
        },
        tags: {
            display: "flex",
            minWidth: "105px",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "flex-start",
        },
        infoIcon: {
            minWidth: "45px"
        },
        optionGroup: {
            borderBottom: "1px solid #BEBEBE"
        }
    }), { name: "MuiItem" });

const SearchService: React.FC<SearchServiceProps> = props => {
    const {
        selectedExperts,
        selectedPremises,
        services,
        selectedServices,
        setSelectedFilters,
    } = props;
    const { translation } = useContext(LocalizationContext);
    const classes = useStyles();
    const onValueChange = async (_e: any, value: CampaignService[]) => {

        const selected = services.filter(service => value.find(val => val.uuid === service.uuid));

        setSelectedFilters((prevState: SelectedFilters) => ({
            ...prevState,
            selectedServices: selected
        }));
    };
    const [inputStr, setInputStr] = useState("");

    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const filterServices = (service: CampaignService) => {
        const expertHasService = selectedExperts.length === 0 ||
            selectedExperts
                .some(expert =>
                    expert?.data?.palvelu?.find(palvelu => palvelu.uuid === service.uuid));

        const premiseHasService = selectedPremises.length === 0 ||
            selectedPremises
                .some(premise =>
                    premise.data.palvelu.find(palvelu => palvelu.uuid === service.uuid));

        const serviceHasName = service.name !== undefined;

        const currentDate = moment(new Date()).format("YYYY-MM-DD");
        const isBetweenDates = (currentDate: string, from?: string, to?: string) =>
            (!from || moment(currentDate).isSameOrAfter(from)) &&
            (!to || moment(currentDate).isSameOrBefore(to));

        return expertHasService &&
            premiseHasService &&
            isBetweenDates(currentDate, service.alkamispäivä, service.päättymispäivä) &&
            serviceHasName;
    };

    const TooltipButton: React.FC<{ title: string }> = (props) => {
        const { title } = props;
        const [open, setOpen] = useState<boolean>(false);

        useEffect(() => {
            if (open) {
                const timeout = setTimeout(
                    () => setOpen(false),
                    mobileServiceTooltipCloseTimeoutMs
                );

                return () => window.clearTimeout(timeout);
            }
        }, [open]);

        return (
            <StyledTooltip
                open={open}
                title={title}
                disableFocusListener={true}
                disableHoverListener={true}
                disableTouchListener={true}
            >
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        setOpen(val => !val);
                    }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </StyledTooltip>
        );
    };

    const renderOption = (option: CampaignService) =>
        <div
            className={option.päättymispäivä ?
                classes.listItemDiscount :
                classes.listItem}
        >
            <Typography variant="body1" className={classes.listItemText} component="span">
                {option.name}
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <div
                    className={classes.tags}
                >

                    <div className={classes.popular}>
                        {option.popular && <Typography
                            variant="subtitle2"
                            component="span"
                        >
                            {translation.popular}
                        </Typography>
                        }
                    </div>
                    <div className={classes.infoIcon}>
                        {
                            option.Kuvaus &&
                            option.Kuvaus !== "" &&
                            (windowIsMdSize ?
                                <StyledTooltip
                                    title={option.Kuvaus || ""}
                                >
                                    <IconButton >
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </StyledTooltip> :
                                <TooltipButton
                                    title={option.Kuvaus || ""}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>;

    const getOptionLabel = (option: CampaignService) => option.name as string;

    const renderTags = (options: CampaignService[], getTagProps: AutocompleteGetTagProps) =>
        options.map((option, index) => <StyledTooltip
            key={`chip-tooltip-${index}`}
            title={option.name || ""}>
            <StyledChip
                id={`chip-${option.uuid}`}
                label={option.name}
                {...getTagProps({ index })}
            />
        </StyledTooltip>);

    const value = useMemo(() =>
        services.filter(service =>
            selectedServices.find(({ uuid }) => uuid === service.uuid)),
        [selectedServices, services]);

    const onInputChange = (e: any) => {
        e.preventDefault();
        const input = e.target.value;
        setInputStr(input);
    };

    const renderPopularGroup = (option: AutocompleteRenderGroupParams) => {
        const options = option.children as Array<ReactNode>;
        return options.length > 0
            ? <div
                key={option.key}
                className={option.group === "true" ? classes.optionGroup : undefined}
            >
                {option.children}
            </div>
            : "";
    };

    const groupBy = (option: CampaignService) =>
        String(
            !!option.popular
            || toothclinicListOrder.find(item => item.uuid === option.uuid)
        );
    return (
        <MultiFilter<CampaignService>
            id={"searchService"}
            options={services.filter(filterServices).sort(sortByDateOrderAndName)}
            label={translation.service.toUpperCase()}
            getOptionLabel={getOptionLabel}
            placeholder={translation.searchService}
            onChange={onValueChange}
            value={value}
            renderOption={renderOption}
            renderTags={renderTags}
            onInputChange={onInputChange}
            inputStr={inputStr}
            groupBy={groupBy}
            renderGroup={renderPopularGroup}
        />
    );
};

export default SearchService;
