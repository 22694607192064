import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiDialog-paper": {
                margin: 0,
                width: "100%",
                borderRadius: "1px"
            },
        },
        content: {
            padding: 0,
            paddingBottom: theme.spacing(2),
            alignItems: "center"
        },
        header: {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.text.secondary,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.background.paper,
        }
    }));

interface CustomDialogProps {
    children: React.ReactChild,
    header: string,
    open: boolean,
    handleClose: () => void,
    fullScreen?: boolean,
    maxWidth?: DialogProps["maxWidth"]
}

const FullWidthDialog: React.FC<CustomDialogProps> = ({
    children, open, handleClose, maxWidth, fullScreen
}) => {
    const classes = useStyles();

    return (
        <Dialog
            className={classes.dialog}
            fullWidth={true}
            maxWidth={maxWidth ? maxWidth : "xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
            fullScreen={fullScreen}
            disableEnforceFocus
        >

            {children}
        </Dialog>
    );
};

export default FullWidthDialog;
