import { withStyles, Theme, createStyles, TextField } from "@material-ui/core";

export const LabelOutSideTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            borderRadius: "4px",
            "& label": {
                color: "#595959",
                fontFamily: "Gotham Narrow Book",
                size: theme.typography.pxToRem(16)
            },
            "& legend": {
                maxWidth: 0
            },
            fontSize: theme.typography.pxToRem(16),
            fontFamily: "Gotham Narrow Bold",
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
                transform: "translate(6px, -16px) scale(0.75)"
            },
            "& .MuiInputLabel-shrink": {
                color: theme.palette.primary.main,
                transform: "translate(6px, -16px) scale(0.75)"
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderWidth: "1px",
                    borderColor: "#979797",
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "1px",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
    })
)(TextField);

export const StyledTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            borderRadius: "4px",
            "& label": {
                fontSize: theme.typography.pxToRem(12),
                color: "#595959",
                fontFamily: "Gotham Narrow Medium",
                letterSpacing: "2px"
            },
            "& .MuiInputLabel-outlined": {
                transform: "translate(6px, -16px) scale(0.75)"
            },
            "& legend": {
                maxWidth: 0
            },
            fontSize: theme.typography.pxToRem(16),
            fontFamily: "Gotham Narrow Bold",
            color: "#595959",
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                color: "#595959",
                '& fieldset': {
                    borderWidth: "1px",
                    borderColor: "#979797",
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "1px",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
    })
)(TextField);
