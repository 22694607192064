import React, { useEffect, useContext, useState, useMemo, Dispatch, SetStateAction, useLayoutEffect } from 'react';
import MultiFilter, { StyledChip } from "../MultiFilter";
import { Expert, EntityResponse, SelectedFilters, Experts } from "../../types";
import { ServiceContext } from "../ServiceContext";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { AutocompleteGetTagProps } from "@material-ui/lab";
import { BehaviorSubject } from "rxjs";
import { filter, debounceTime, distinctUntilChanged, concatMap } from "rxjs/operators";
import { uiSearchTimeoutMs } from "../../settings";
import LoadingIndicator from '../UtilComponents/LoadingIndicator';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { allSettled } from '../utils';
import { expertStore } from '../../store/stores';

interface SearchExpertProps {
    selectedExperts: EntityResponse<Expert>[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    expertFilters: string[],
    loadingInitialState: boolean
}

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        loading: {
            width: "1.5em",
            height: "1.5em",
        }
    })
);

const searchSubject = new BehaviorSubject<string>("");

const SearchExpertPhysio: React.FC<SearchExpertProps> = props => {
    const { expertFilters, setSelectedFilters, selectedExperts, loadingInitialState } = props;
    const { translation } = useContext(LocalizationContext);
    const { dataService, getSearchService } = useContext(ServiceContext);
    const [experts, setExperts] = useState<{
        uuid: string,
        Nimi: string
    }[]>([]);
    const [searchStr, setSearchStr] = useState<string>("");
    const [expertUuids, setExpertUuids] = useState<Experts["työntekijä"] | null>(null);
    const [selection, setSelection] = useState<{ uuid: string; Nimi: string }[] | null>(null);
    const [searchExperts, setSearchExperts] = useState<{ uuid: string; Nimi: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const classes = useStyles();

    useEffect(() => {
        const expertSub = expertStore.subject.subscribe((experts) => {
            const expertList = experts.map(expert => ({
                uuid: expert.uuid,
                Nimi: expert.data.Nimi
            }));
            setExperts(expertList);
            expertList.length !== 0 && setLoading(false);
        });
        return () => expertSub.unsubscribe();
    }, []);

    useEffect(() => {
        if (expertFilters.length !== 0 && dataService && !loadingInitialState) {
            const fetchExperts = async () => {
                const resp = await dataService.requestExperts(expertFilters);
                setExpertUuids(resp.data.työntekijä);
                setLoading(false);
            };
            fetchExperts();
        }
        if (expertFilters.length === 0) {
            setExpertUuids(null);
        }
    }, [dataService, expertFilters, loadingInitialState]);

    useEffect(() => setLoading(true), [expertFilters]);

    useEffect(() => {
        if (dataService && selection) {
            const fetchSelectedExperts = async () => {
                const selectedExperts = await allSettled(
                    selection.map(({ uuid }) => dataService.requestExpert(uuid))
                );
                const resolvedExperts = selectedExperts.reduce((acc, cur) => {
                    return cur.status === "fulfilled" ? [...acc, cur.value] : acc;
                }, [] as EntityResponse<Expert>[]);
                setSelectedFilters((prevState: SelectedFilters) => ({
                    ...prevState,
                    selectedExperts: resolvedExperts
                }));
            };
            fetchSelectedExperts();
        }
    }, [dataService, selection]);

    useLayoutEffect(() => {
        if (getSearchService) {
            const searchResultsObs = searchSubject.pipe(
                filter(str => str.length > 1),
                debounceTime(uiSearchTimeoutMs),
                distinctUntilChanged(),
                concatMap(searchString =>
                    getSearchService().then(async search => {
                        const experts = await search.searchExpert(searchString);
                        return experts;
                    })
                )
            );
            const subscription = searchResultsObs?.subscribe(results => {
                const experts = results.data.map((result: any) =>
                    ({ uuid: result.uuid, Nimi: result.name, probability: result.probability })
                );
                setSearchExperts(experts);
            }, () => true, () => getSearchService().then(search => search.socket.close()));

            return () => {
                subscription?.unsubscribe();
            };
        }
    }, [getSearchService]);

    const expertIsInFilterList = (expert: { uuid: string; Nimi: string }) =>
        expert && expertUuids?.map(expert => expert.uuid)?.includes(expert.uuid) && expert.Nimi;

    const nameMatchesSearchString = (expert: { uuid: string; Nimi: string }) =>
        expert && expert.Nimi &&
        expert.Nimi.toLowerCase().includes(searchStr.toLowerCase());

    const filteredExperts = experts
        .filter(expert =>
            expertUuids && expertUuids.length > 0 ?
                expertIsInFilterList(expert) :
                nameMatchesSearchString(expert)
        );

    const onSelectionChange = (e: any, value: { uuid: string; Nimi: string }[]) => {
        setSelection(value);
        setSearchStr("");
    };

    const renderTags = (
        value: { uuid: string; Nimi: string }[],
        getTagProps: AutocompleteGetTagProps
    ) =>
        value.map((option, index) => <StyledChip
            label={option.Nimi} {...getTagProps({ index })}
        />
        );

    const filterOptions = (entities: { uuid: string; Nimi: string; probability?: number }[]) =>
        entities.filter(entity =>
            entity.Nimi.toLowerCase().includes(searchStr.toLowerCase())
            || (entity.probability && entity.probability > 0.6)
        );

    const onInputChange = (e: any) => {
        e.preventDefault();
        const newValue = e.target.value;
        searchSubject.next(newValue);
        setSearchStr(newValue);
    };

    const filteredSearchExperts = useMemo(() => searchExperts.filter(({ uuid }) =>
        expertUuids?.map(expert => expert.uuid)?.includes(uuid)
    ), [searchExperts]);

    const selectedValues = useMemo(() => selectedExperts.map(expert => ({
        uuid: expert.uuid,
        Nimi: expert.data.Nimi
    })), [selectedExperts]);

    const loadingEl = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {translation.loadingExperts}
            <LoadingIndicator className={classes.loading} />
        </div>
    );

    return (
        <MultiFilter<{ uuid: string; Nimi: string; probability?: number }>
            id={"searchExpert"}
            options={loading ? [] : searchStr.length > 0 ? filteredSearchExperts : filteredExperts}
            label={translation.selectExpert}
            getOptionLabel={option => option.Nimi}
            renderTags={renderTags}
            placeholder={translation.searchExpert}
            onChange={onSelectionChange}
            value={selectedValues}
            filterOptions={filterOptions}
            onInputChange={onInputChange}
            loading={loading}
            loadingText={loadingEl}
            inputStr={searchStr}
        />
    );
};

export default SearchExpertPhysio;
