import React, { ReactNode, ChangeEvent } from 'react';
import { Autocomplete, AutocompleteGetTagProps, AutocompleteRenderGroupParams } from "@material-ui/lab";
import {
    Chip, createStyles, makeStyles, Theme,
    TextField, withStyles, Paper, Typography
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/SearchRounded';
import { theme } from "../theme";
import StyledTooltip from './CustomStyleComponents/Tooltip';

interface MultiFilterProps<T> {
    options: T[] | null,
    label: string,
    placeholder: string,
    value?: T[],
    getOptionLabel?: (option: T) => string,
    renderOption?: (option: T) => ReactNode,
    groupBy?: (option: T) => string,
    renderTags?: (value: any, getTagProps: AutocompleteGetTagProps) => ReactNode,
    filterOptions?: any,
    onChange: (e: any, value: T[]) => void,
    onInputChange?: (e: any) => void,
    disabled?: boolean,
    renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode,
    loading?: boolean,
    loadingText?: ReactNode,
    id?: string,
    open?: boolean,
    onOpen?: (event: ChangeEvent<{}>) => void,
    onClose?: (event: any, reason: string) => void,
    getOptionSelected?: (option: T, value: T) => boolean,
    inputStr?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textInput: {
            "& .MuiAutocomplete-popupIndicatorOpen": {
                transform: "rotate(0)",
            },
        },
        listItem: {
            paddingLeft: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%"
        },
        listItemText: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        label: {
            fontFamily: "Gotham Narrow Medium",
            textTransform: "uppercase",
            paddingLeft: theme.spacing(0.5),
            color: "#595959",
            fontSize: theme.typography.pxToRem(14),
            "& .Mui-focused": {
                color: "red"
            }
        },
        labelText: {
            color: "#595959",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(16),
            letterSpacing: theme.typography.pxToRem(0.4),
            backgroundColor: "#FFFFFF",
            paddingRight: theme.typography.pxToRem(4),
            "&::placeholder": {
                fontFamily: "Gotham Narrow Book",
                fontSize: theme.typography.pxToRem(16),
                letterSpacing: theme.typography.pxToRem(0.13),
                lineHeight: theme.typography.pxToRem(24),
                color: "rgba(33,33,33)",
                opacity: 0.7
            },
        },
        popper: {
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            width: "100%",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: "24px"
        }
    }), { name: "MuiItem" }
);

const StyledTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.white,
            borderColor: "#979797",
            borderRadius: "4px",
            "& label": {
                color: "#595959",
                fontFamily: "Gotham Narrow Medium"
            },
            "& .MuiInputLabel-outlined": {
                transform: "translate(6px, -16px) scale(0.75)"
            },
            "& legend": {
                maxWidth: 0
            },
            fontSize: theme.typography.pxToRem(16),
            fontFamily: "Gotham Narrow Bold",
            color: "#595959",
            '& label.Mui-focused': {
                color: theme.palette.primary.main,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main,
            },
            '& .MuiOutlinedInput-root': {
                color: "#595959",
                paddingRight: "9px !important",
                '& fieldset': {
                    borderWidth: "1px",
                    borderColor: "#979797",
                },
                '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "1px",
                    borderColor: theme.palette.primary.main,
                },
            },
        },
    })
)(TextField);


export const StyledChip = withStyles({
    root: {
        borderWidth: "1px",
        borderColor: theme.palette.primary.main,
        borderStyle: "solid",
        color: theme.palette.primary.main,
        fontFamily: "Gotham Narrow Medium",
        borderRadius: "4px",
        backgroundColor: theme.palette.common.white,
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: theme.typography.pxToRem(0.18),
        lineHeight: theme.typography.pxToRem(20),
        textTransform: "uppercase",
    },
    deleteIcon: {
        color: theme.palette.primary.main,
        "&:hover": {
            color: "rgb(0,59,105)"
        }
    }
})(Chip);


const MultiFilter: <T>(props: MultiFilterProps<T>) =>
    React.ReactElement<MultiFilterProps<T>> = (props) => {
        const classes = useStyles();
        const {
            id,
            options,
            label,
            placeholder,
            value,
            getOptionLabel = (option: any) => option.name,
            renderOption = (option: any) =>
            (<div className={classes.listItem} id={option.uuid}>
                <Typography className={classes.listItemText} variant="body1" component="span">
                    {getOptionLabel(option)}
                </Typography>
            </div>),
            groupBy,
            renderTags = (value: any, getTagProps: AutocompleteGetTagProps) =>
                value.map((option: any, index: number) => (
                    <StyledTooltip title={option.name}>
                        <StyledChip
                            color="primary"
                            variant="outlined"
                            label={option.name}
                            {...getTagProps({ index })}
                        />
                    </StyledTooltip>
                )),
            filterOptions,
            onChange,
            onInputChange,
            disabled,
            renderGroup,
            loading,
            loadingText,
            open,
            onOpen,
            onClose,
            getOptionSelected,
            inputStr
        } = props;
        return (
            <Autocomplete
                multiple
                open={open}
                onClose={onClose}
                onOpen={onOpen}
                id={id}
                className={classes.textInput}
                options={options ? options : []}
                placeholder={value && value.length > 0 ? undefined : placeholder}
                value={value}
                getOptionLabel={getOptionLabel}
                popupIcon={<SearchIcon color="primary" />}
                renderOption={renderOption}
                groupBy={groupBy}
                renderTags={renderTags}
                filterOptions={filterOptions}
                onChange={onChange}
                disabled={disabled}
                loading={loading}
                loadingText={loadingText}
                renderGroup={renderGroup}
                getOptionSelected={getOptionSelected}
                blurOnSelect="touch"
                autoComplete={false}
                inputValue={inputStr}
                PaperComponent={(params) =>
                    <Paper className={classes.popper}>{params.children}</Paper>
                }
                noOptionsText={""}
                renderInput={params => (
                    <StyledTextField
                        {...params}
                        label={label.toUpperCase()}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            disableAnimation: true,
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        placeholder={(value && value.length > 0) || placeholder === "" ?
                            undefined :
                            placeholder
                        }
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password"
                        }}
                        InputProps={{
                            ...params.InputProps,
                            classes: { input: classes.labelText },
                            endAdornment: null,
                            name: id,
                        }}
                        onChange={onInputChange}
                    />
                )}
            />
        );
    };

export default MultiFilter;
