import { createStyles, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import CloseIcon from "@material-ui/icons/CloseRounded";
import { Translation } from '../../LanguageContext/types';

interface InfoDialogProps {
    id: string,
    open: boolean,
    translation: Translation,
    closeDialog: () => void
}
export interface ContentProps {
    translation: Translation
}

const useStyles = (mobile: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.primary.main,
        },
        header: {
            color: theme.palette.primary.main
        },
        bold: {
            fontWeight: "bold"
        },
        title: {
            padding: 0
        },
        content: {
            padding: mobile ? theme.spacing(2) : theme.spacing(4),
            "& .MuiTypography": {
                fontFamily: "Gotham Narrow"
            }
        }
    })
);

const InfoDialog: React.FC<InfoDialogProps> = props => {
    const { id, open, closeDialog, children } = props;
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(fullscreen)();

    return (
        <Dialog
            id={id}
            fullWidth
            open={open}
            onClose={closeDialog}
            maxWidth={'md'}
            scroll={"body"}
            disableEnforceFocus
        >
            <DialogTitle
                className={classes.title}
            />
            <DialogContent
                className={classes.content}
            >
                <IconButton
                    id={"closeInfoModal"}
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={closeDialog}
                >
                    <CloseIcon />
                </IconButton>
                {children}
            </DialogContent>
        </Dialog >
    );
};

export default InfoDialog;
