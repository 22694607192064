import { withStyles, Theme, Button } from "@material-ui/core";
import { colors } from "../../theme";

export const DefaultButton = withStyles((theme: Theme) => ({
    root: {
        fontFamily: "Gotham narrow bold",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
        border: "solid",
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
        borderRadius: "4px",
        padding: "5px 16px",
        boxShadow: "0px 0px",
        '&:disabled': {
            border: "0px"
        }
    },
}))(Button);

export const SuccessButton = withStyles((theme: Theme) => ({
    root: {
        fontFamily: "Gotham narrow bold",
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.success.main,
        padding: "6px 16px",
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
        '&:disabled': {
            backgroundColor: '#FFFFFF',
            borderColor: 'rgba(0,0,0,0.38)',
        }
    },
}))(Button);

export const DisclaimerButton = withStyles((theme: Theme) => ({
    root: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        textTransform: "none",
        fontFamily: "Gotham narrow book",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        border: "0px",
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: colors.boxBackground
        },
        '&:contained': {
            color: theme.palette.primary.main,
            backgroundColor: colors.boxBackground
        },
        '&:disabled': {
            backgroundColor: '#FFFFFF',
            borderColor: 'rgba(0,0,0,0.38)',
        }
    },
}))(Button);
