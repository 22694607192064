import { ITranslationLanguages, Language } from "./types";
import finnish from "./TranslationFiles/finnish";
import english from "./TranslationFiles/english";
import swedish from "./TranslationFiles/swedish";

export const translations: ITranslationLanguages = {
    [Language.fi]: finnish,
    [Language.en]: english,
    [Language.se]: swedish
};
