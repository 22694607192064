import { BehaviorSubject } from "rxjs";
import { EntityResponse, DataStore, Expert, Premise, DataResponse, TimeslotResponse, Building, TimeslotStore, Service, Address, TimeslotStoreItems, TimeslotRange, Language, Speciality } from "../types";
import moment from "moment";

const createUpdateState = async <T>(
    subject: BehaviorSubject<EntityResponse<T>[]>,
    entity: EntityResponse<T>
) => {
    const currentState = subject.getValue();
    const entityAlreadyInStore =
        (entityToSave: EntityResponse<T>) =>
            (storedEntity: EntityResponse<T>) =>
                storedEntity && storedEntity.uuid === entityToSave.uuid;

    const newState = entity && currentState
        .find(entityAlreadyInStore(entity)) ?
        currentState :
        [...currentState, entity];
    subject.next(newState);
};

const createUpdateTimeslotState = async (
    subject: BehaviorSubject<TimeslotStoreItems>,
    entity: DataResponse<TimeslotResponse[]>,
    selectedExpertsUuids: string[],
    selectedPremisesUuids: string[],
    selectedServicesUuids: string[]
) => {
    const newFilters = entity.filter;
    const oldFilters = subject.getValue().filter;
    const oldRange = subject.getValue().range;
    const rangeHasChanged =
        entity.range?.from !== oldRange?.from || entity.range?.to !== oldRange?.to;
    const filtersHaveChanged = newFilters.length !== oldFilters.length ||
        !newFilters.every(uuid => oldFilters.includes(uuid)) ||
        entity.date !== subject.getValue().date ||
        rangeHasChanged;

    const newItems = entity.data.filter(item =>
        !subject.getValue().data
            .find(storedItem => storedItem.uuid === item.uuid)
    );

    const dataToStore = {
        date: entity.date ? entity.date : null,
        from: entity.from ? entity.from : 0,
        limit: entity.limit ? entity.limit : 0,
        total: entity.total ? entity.total : 0,
        filter: entity.filter,
        range: entity.range ? {
            from: entity.range.from,
            to: entity.range.to
        } :
            null,
        data: !filtersHaveChanged ? [
            ...subject.getValue().data,
            ...newItems
        ] : entity.data,
        selectedExpertsUuids,
        selectedPremisesUuids,
        selectedServicesUuids
    };
    const newState = dataToStore.data.length === 0 ?
        {
            date: entity.date ? entity.date : null,
            from: 0,
            limit: 0,
            total: 0,
            filter: entity.filter,
            data: [],
            range: entity.range ? entity.range : null,
            selectedExpertsUuids,
            selectedPremisesUuids,
            selectedServicesUuids
        } :
        dataToStore;
    subject.next(newState);
};

const createStore = <T>(initialState: EntityResponse<T>[] = []): DataStore<T> => {
    const subject = new BehaviorSubject<EntityResponse<T>[]>(initialState);
    return ({
        subject,
        updateState: async (
            subject: BehaviorSubject<EntityResponse<T>[]>,
            entity: EntityResponse<T>
        ) => createUpdateState(subject, entity),
        getValueByUuid: async (uuid: string) =>
            subject.getValue()
                .find(value => value && value.uuid === uuid),
    });
};

const createAddressStore = (): DataStore<Address[]> => {
    const subject = new BehaviorSubject<EntityResponse<Address[]>[]>([]);

    return ({
        subject,
        updateState: async (
            subject: BehaviorSubject<EntityResponse<Address[]>[]>,
            entity: EntityResponse<Address[]>
        ) => createUpdateState(subject, entity),
        getValueByUuid: async (uuid: string) =>
            subject.getValue()
                .find(value => value && value.uuid === uuid),
    });
};

const isSameDate = (dateA: string, dateB: string) =>
    moment(dateA).isSame(dateB, "day") &&
    moment(dateA).isSame(dateB, "month") &&
    moment(dateA).isSame(dateB, "year");

const isWithinRange = (start: string, range: TimeslotRange) => {
    try {
        const fromArr = range.from.split(":");
        const from = moment(start)
            .hours(parseInt(fromArr[0]))
            .minutes(parseInt(fromArr[1]));

        const toArr = range.to.split(":");
        const to = moment(start)
            .hours(parseInt(toArr[0]))
            .minutes(parseInt(toArr[1]));

        const inclusive = "[]";
        const time = moment(start);
        return time.isBetween(from, to, undefined, inclusive);
    } catch {
        return false;
    }
};

const isEmptyOrIncudes = (list: string[], shouldBeIncluded: string) =>
    list.length === 0 ||
    list.includes(shouldBeIncluded);

const createTimeslotStore = (): TimeslotStore => {
    const subject = new BehaviorSubject<TimeslotStoreItems>({
        from: 0,
        limit: 0,
        total: 0,
        filter: [],
        data: [],
        date: null,
        range: null,
        selectedExpertsUuids: [],
        selectedPremisesUuids: [],
        selectedServicesUuids: []
    });

    return ({
        subject,
        updateState: async (
            subject: BehaviorSubject<TimeslotStoreItems>,
            entity: DataResponse<TimeslotResponse[]>,
            selectedExpertUuids: string[],
            selectedPremisesUuids: string[],
            selectedServicesUuids: string[]
        ) => createUpdateTimeslotState(
            subject,
            entity,
            selectedExpertUuids,
            selectedPremisesUuids,
            selectedServicesUuids
        ),
        unsubscribe: () => subject.unsubscribe(),
        getValueByUuid: async (uuid: string) =>
            subject.getValue().data
                .find(value => value.uuid === uuid),
        updateTimeslotValues: (store: TimeslotStore, available: boolean, uuid: string) => {
            const currentState = Object.assign({}, store.subject.getValue());
            const index = currentState.data
                .findIndex((slot: TimeslotResponse) =>
                    slot.uuid === uuid
                );

            const updateTimeslot = (currentState: TimeslotStoreItems) => {
                currentState.data[index].available = available;
                return currentState;
            };
            index !== -1 &&
                subject.next(updateTimeslot(currentState));
        },
        addTimeslot: (store: TimeslotStore, available: boolean, item: TimeslotResponse) => {
            const currentState = Object.assign({}, store.subject.getValue());
            const index = currentState.data
                .findIndex((slot: TimeslotResponse) =>
                    slot.uuid === item.uuid
                );

            const updateTimeslot = (currentState: TimeslotStoreItems, index: number) => {
                currentState.data[index].available = available;
                subject.next(currentState);
            };


            const addTimeslotIfMatch = async (
                currentState: TimeslotStoreItems,
                item: TimeslotResponse
            ) => {
                const {
                    date,
                    selectedExpertsUuids,
                    selectedPremisesUuids,
                    selectedServicesUuids,
                    range
                } = currentState;

                const match =
                    (date === null || isSameDate(date, item.start)) &&
                    (range === null || isWithinRange(item.start, range)) &&
                    isEmptyOrIncudes(
                        selectedExpertsUuids, item.työntekijä ?
                        item.työntekijä :
                        item.tyontekija
                    ) &&
                    isEmptyOrIncudes(selectedPremisesUuids, item.toimipaikka) &&
                    isEmptyOrIncudes(selectedServicesUuids, item.palvelu);

                match && // If not a match, dont update store to avoid rerenders.
                    subject.next(
                        { ...currentState, data: currentState.data.concat(item) }
                    );
            };

            index !== -1 ?
                updateTimeslot(currentState, index) :
                addTimeslotIfMatch(currentState, item);
        }
    });
};

export const buildingStore = createStore<Building>();
export const expertStore = createStore<Expert>();
export const premiseStore = createStore<Premise>();
export const timeslotStore = createTimeslotStore();
export const serviceStore = createStore<Service>();
export const addressStore = createAddressStore();
export const languageStore = createStore<Language>();
export const specialityStore = createStore<Speciality>();

export const forTests = {
    isSameDate,
    isWithinRange,
    isEmptyOrIncudes
};
