import React, { useContext } from "react";
import { sheduleAppointmentPhoneNumbersURL } from "../settings";
import { LocalizationContext } from "./LanguageContext/LocalizationContext";
import InformationView from "./InformationView";
import { Link, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./InformationView";

const link = (fromHere: string) =>
    <Link
        href={sheduleAppointmentPhoneNumbersURL}
        style={{
            color: "#000000",
            textDecoration: "underline"
        }}
    >
        {fromHere}
    </Link>;

export const NotFound: React.FC = (_props) => {
    const { translation } = useContext(LocalizationContext);
    return <InformationView
        header={translation.errorNotFoundHeader}
        firstParagraph={translation.errorNotFoundText}
    />;
};

export const SomethingWentWrong: React.FC = (_props) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const { translation, getLanguage } = useContext(LocalizationContext);
    const language = getLanguage();
    const secondParagraph =
        <Typography
            className={classes.text}
        >
            {`${translation.errorSomethingWentWrongSecondParagraph} `}
            {link(translation.errorContactLinkText)}
            {` ${translation.errorTryAgain}`}
        </Typography>;

    const nonFinnishHeader = <>
        <Typography component="h2" variant="h1">
            {translation.errorSomethingWentWrongHeader.toUpperCase()}
        </Typography>
        <Typography variant="body1" component="h3" className={classes.text}>
            {translation.errorAlternateHeader}
        </Typography>
    </>;



    return <InformationView
        header={language === "finnish" ? translation.errorSomethingWentWrongHeader : nonFinnishHeader}
        firstParagraph={translation.errorSomethingWentWrongFirstParagraph}
        secondParagraph={secondParagraph}
    />;
};

export const Maintenance: React.FC = (_props) => {
    const { translation } = useContext(LocalizationContext);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(mobile)();
    const firstParagraph =
        <Typography
            className={classes.text}
        >
            {`${translation.errorMaintenanceText} `}
            {link(translation.errorFoundFromOurContactSite)}
            {`.`}
        </Typography>;

    return <InformationView
        header={translation.errorMaintenanceHeader}
        firstParagraph={firstParagraph}
    />;
};
