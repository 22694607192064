import React from "react";
import { Translation, Language } from "../LanguageContext/types";
import { CampaignService, PopulatedTimeslot } from "../../types";
import { makeStyles, createStyles, Grid, Theme, Typography, Button } from "@material-ui/core";
import { getLengthOfAppointment, getExpertTitles, roundToOneDecimal, getKunta, getAddress, parsePrice } from "../utils";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { colors } from "../../theme";
import { DoubleArrowRounded } from "@material-ui/icons";

interface ItemProps {
    translation: Translation,
    populatedSlot: PopulatedTimeslot,
    expertPicture: JSX.Element | null,
    numberOfTitles: number,
    openReservationModal: (timeslot: PopulatedTimeslot) => void,
    openExpertModal: (timeslot: PopulatedTimeslot, services?: CampaignService[]) => void,
    startTime: string,
    language: Language,
    id: string,
    services?: CampaignService[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            "& .MuiListItemAvatar-root": {
                padding: 0,
                margin: 0,
                paddingLeft: theme.spacing(2)
            }
        },
        button: {
            backgroundColor: theme.palette.success.main,
            "&:hover": {
                backgroundColor: theme.palette.success.dark
            },
            color: theme.palette.common.white,
            maxWidth: "140px",
        },
        iconContainer: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(0.5),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        },
        distance: {
            color: theme.palette.primary.main,
            textTransform: "none"
        },
        iconDist: {
            color: theme.palette.primary.main
        },
        icon: {
            color: "#B3B3B3"
        },
        linkButton: {
            fontFamily: "Gotham Narrow Bold",
            fontSize: "1em",
            background: "none!important",
            border: "none",
            padding: "0!important",
            color: theme.palette.primary.main,
            textDecoration: "none",
            cursor: "pointer",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
                fontSize: "0.9em",
                marginTop: theme.typography.pxToRem(3)
            }
        },
        span: {
            height: "max-content",
            borderRadius: "4px",
            maxWidth: "230px",
            display: "inline",
            alignItems: "center",
            justifyContent: "center",
        },
        duration: {
            paddingTop: theme.spacing(2),
        },
        buttonGrid: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end"
        },
        name: {
            paddingTop: theme.typography.pxToRem(2),
            textDecoration: "none",
            textTransform: "none"
        },
        price: {
            maxWidth: "140px",
            display: "block",
            width: "100%",
            paddingBottom: theme.spacing(2),
            textAlign: "center"
        }
    })
);

const ListItemDesktop: React.FC<ItemProps> = props => {
    const classes = useStyles();
    const {
        populatedSlot,
        translation,
        expertPicture,
        numberOfTitles,
        openExpertModal,
        openReservationModal,
        startTime,
        id,
        services
    } = props;
    return (
        <Grid container item xs={12}>
            <Grid item container xs={6} className={classes.grid}>
                <Grid item xs={2}>
                    <Typography
                        variant="h5"
                        align="center"
                        component="h3"
                        color="primary"
                    >
                        {startTime}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        align="center"
                        className={classes.duration}
                    >
                        {getLengthOfAppointment(populatedSlot)} min
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <div style={{ display: "flex", flexFlow: "row nowrap", height: "100%" }}>
                        {expertPicture}
                        <div
                            style={{
                                paddingLeft: "10px",
                                wordWrap: "break-word",
                                maxWidth: "230px"
                            }}
                        >
                            {populatedSlot.työntekijä.data.Nimi &&
                                <button
                                    className={classes.linkButton}
                                    onClick={() => openExpertModal(populatedSlot, services)}
                                >
                                    <Typography
                                        variant="button"
                                        component="div"
                                        color="primary"
                                        className={classes.name}
                                    >
                                        {populatedSlot.työntekijä.data.Nimi}
                                    </Typography>
                                    <DoubleArrowRounded />
                                </button>
                            }
                            <Typography variant="body1">
                                {getExpertTitles(
                                    populatedSlot.työntekijä.data,
                                    numberOfTitles
                                )}
                            </Typography>
                            <div className={classes.span}>
                                <Typography
                                    display="inline"
                                    variant="body1"
                                    component="span"
                                    style={{ backgroundColor: colors.boxBackground }}
                                >
                                    {populatedSlot.palvelunNimi}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item container xs={6} direction="row" className={classes.grid}>
                <Grid item xs={1}>
                    <div className={classes.iconContainer}>
                        <LocationOnIcon
                            className={populatedSlot.dist ? classes.iconDist : classes.icon}
                        />
                    </div>
                </Grid>
                <Grid item xs={5} lg={6}>
                    {populatedSlot.dist &&
                        <Typography className={classes.distance} variant="button" component="p">
                            {`${translation.distance} ${roundToOneDecimal(populatedSlot.dist)} km ${translation.fromLocation}`}
                        </Typography>}
                    <Typography variant="body1">
                        {
                            getAddress(
                                populatedSlot.toimitila.katuosoite ?
                                    populatedSlot.toimitila.katuosoite :
                                    ""
                            )
                        }
                    </Typography>
                    <Typography variant="body1">
                        {
                            getKunta(populatedSlot.toimitila.katuosoite ?
                                populatedSlot.toimitila.katuosoite :
                                ""
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={5} className={classes.buttonGrid}>
                    {populatedSlot.hinta &&
                        <div className={classes.price}>
                            <Typography
                                variant="h5"
                                component="span"
                                color="primary"
                            >
                                {parsePrice(populatedSlot.hinta)}€
                            </Typography>
                        </div>}
                    <Button
                        id={`button-${id}`}
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        onClick={() => openReservationModal(populatedSlot)}
                    >
                        {translation.reserveTimeslot}
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListItemDesktop;
