import React, { useContext, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { LocalizationContext } from '../LanguageContext/LocalizationContext';
import { SuccessButton } from '../CustomStyleComponents/Buttons';
import { LinkOff } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            margin: theme.spacing(1),
            fontSize: theme.typography.h1.fontSize
            //backgroundColor: "#B00020"
        },
        paper: {
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        desktopButton: {
            minWdith: "360px"
        },
        buttonWrapper: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.typography.pxToRem(90),
            marginBottom: theme.typography.pxToRem(40)
        },
        blue: {
            color: theme.palette.text.secondary
        },
        mobileButtons: {
            paddingBottom: theme.spacing(2)
        },
        buttonContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        },
        textContainer: {
            display: "flex",
            flex: 1,
            flexDirection: "column"
        }
    }));

interface IFailureProps {
    handleClose: () => void
}

const Timeout: React.FC<IFailureProps> = ({
    handleClose
}) => {
    const theme = useTheme();
    const windowIsMdSize = useMediaQuery(theme.breakpoints.up('md'));

    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);

    const successButton = useMemo(() =>
        <SuccessButton
            fullWidth={!windowIsMdSize}
            onClick={handleClose}
            className={windowIsMdSize ? classes.desktopButton : undefined}
        >
            {translation.closeAndSelectNewTimeslot}
        </SuccessButton>
        , [translation, windowIsMdSize]);

    const desktopButton = useMemo(() =>
        <Grid
            item
            xs={12}
            md={12}
            className={classes.buttonWrapper}
        >
            {successButton}
        </Grid>
        , [successButton]);

    const mobileButton = useMemo(() =>
        <div className={classes.buttonContainer}>
            <div style={{ flex: 1 }} />
            <div className={classes.mobileButtons}>
                {successButton}
            </div>
        </div>
        , [translation]);

    const textArea = useMemo(() =>
        <div className={classes.buttonContainer}>
            <Typography
                variant={"subtitle1"}
                className={classes.blue}
                align={"left"}
                >
                {`${translation.reservationConfirmationTimeoutText}.`}
            </Typography>
            <ul
                style={{paddingInlineStart: "18px"}}
            >
                <li>
                    <Typography
                        variant={"subtitle1"}
                        className={classes.blue}
                        align={"left"}
                        >
                        {`${translation.reservationConfirmationTimeoutPointOne}.`}
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant={"subtitle1"}
                        className={classes.blue}
                        align={"left"}
                        >
                        {`${translation.reservationConfirmationTimeoutPointTwo}.`}
                    </Typography>
                </li>
            </ul>
            <Typography
                variant={"subtitle1"}
                className={classes.blue}
                style={ windowIsMdSize ? {} : { paddingBottom: "24px" }}
                align={"left"}
                >
                {`${translation.reservationConfirmationTimeoutEnd}.`}
            </Typography>
        </div>
        , [translation, windowIsMdSize]);

    return (
        <React.Fragment>
            <Grid item container>
                <Grid
                    item
                    xs={12}
                    className={classes.paper}
                >
                    <LinkOff className={`${classes.icon} ${classes.blue}`}/>

                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classes.paper}
                >
                    <Typography
                        variant={"h6"}
                        className={classes.blue}
                        style={{ paddingBottom: "24px" }}
                        align={"center"}
                    >
                        {`${translation.reservationConfirmationTimeout}.`}
                    </Typography>
                    {textArea}
                </Grid>

            </Grid>
            {windowIsMdSize ? desktopButton : mobileButton}
        </React.Fragment>
    );
};

export default Timeout;
