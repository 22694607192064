
import { makeStyles, Theme, createStyles, Tabs, Tab, useTheme } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { ITimeOfDayProps } from "./TimeOfDay";
import { colors } from '../../theme';
import { createTimeframesMobile } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        centered: {
            textAlign: "center",
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(2),
        },
        line: {
            width: "100%",
            height: "46px",
            borderBottom: "1px solid " + colors.lightGrey,
        },
        selected: {
            color: theme.palette.primary.main
        }
    }));

const TimeOfDayMobile: React.FC<ITimeOfDayProps> = ({
    date,
    selectedTimeframe,
    setSelectedTimeframe
}) => {
    const classes = useStyles();
    const { translation } = useContext(LocalizationContext);
    const timeframes = useMemo(() => createTimeframesMobile(date), [date]);
    const theme = useTheme();
    const toggle = (_event: any, val: number) =>
        setSelectedTimeframe(timeframes[val]);


    return (
        <div className={classes.centered}>
            <div className={classes.line}>
                <Tabs
                    variant="fullWidth"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.primary.main,
                            height: "3px",
                        }
                    }}
                    value={selectedTimeframe.id}
                    onChange={toggle}
                    aria-label="select time of day"
                >
                    <Tab
                        value={timeframes.findIndex((timeframe) => timeframe.id === 0)}
                        label={translation.all}
                        className={selectedTimeframe.id === 0 ? classes.selected : ""}
                    />
                    <Tab
                        value={timeframes.findIndex((timeframe) => timeframe.id === 1)}
                        label={translation.morning}
                        className={selectedTimeframe.id === 1 ? classes.selected : ""}
                    />
                    <Tab
                        value={timeframes.findIndex((timeframe) => timeframe.id === 2)}
                        label={translation.dayTime}
                        className={selectedTimeframe.id === 2 ? classes.selected : ""}
                    />
                    <Tab
                        value={timeframes.findIndex((timeframe) => timeframe.id === 3)}
                        label={translation.evening}
                        className={selectedTimeframe.id === 3 ? classes.selected : ""}
                    />
                </Tabs>
            </div>
        </div>
    );
};

export default TimeOfDayMobile;
