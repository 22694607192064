import React, { createContext, useContext, useEffect, useState } from "react";
import { IAuthService, initializeAuthService } from "./services/AuthService";
import { Operations } from "./services/constants";
import { AuthResponse } from "./types";

interface IAuthContext {
    authenticated: null | boolean,
    error: null | string,
    authService: IAuthService | null,
    authenticate?: (username: string, password: string) => Promise<Partial<AuthResponse>>,
    socket?: WebSocket,
    sessionKey: string | null,
    setAuthStatus: null | ((status: boolean | null, sessionKey?: string) => void),
    logout: null | (() => void)
}

export const AuthContext = createContext<IAuthContext>({
    authenticated: null,
    error: null,
    sessionKey: null,
    authService: null,
    setAuthStatus: null,
    logout: null
});

export const AuthProvider: React.FC = props => {
    const [authenticated, setAuthenticated] = useState<null | boolean>(null);
    const [error, setError] = useState<null | string>(null);
    const [authService, setAuthService] = useState<null | IAuthService>(null);
    const [sessionKey, setSessionKey] = useState<string | null>(null);

    const setAuthStatus = (status: boolean | null, sessionKey?: string) => {
        setAuthenticated(status);
        status && sessionKey ?
            setSessionKey(sessionKey) :
            setSessionKey(null);

        status && sessionKey ?
            localStorage.setItem("sessionKey", sessionKey) :
            localStorage.removeItem("sessionKey");
    };

    const authenticate = async (
        username: string,
        password: string
    ): Promise<Partial<AuthResponse>> => {
        if (authService) {
            return authService.login(username, password).then((resp) => {
                resp.success ?
                    setAuthStatus(resp.success, resp["session-key"]) :
                    setAuthenticated(false);
                if (!resp.success) {
                    throw Error("Authentication failed.");
                } else
                    return resp;
            }).catch(err => {
                setError(err);
                return {
                    op: Operations.auth,
                    success: false
                } as unknown as AuthResponse;
            });
        } else return Promise.resolve({
            op: Operations.auth,
            success: false
        } as unknown as AuthResponse);
    };

    const logout = () => {
        setSessionKey(null);
        setAuthenticated(false);
        if (localStorage.getItem("sessionKey")) {
            localStorage.removeItem("sessionKey");
        }
    };

    useEffect(() => {
        initializeAuthService()
            .then(setAuthService)
            .catch(err => console.error(err));
    }, []);

    return (<AuthContext.Provider value={{
        authenticated,
        error,
        authenticate,
        logout,
        authService,
        socket: authService?.socket,
        sessionKey,
        setAuthStatus
    }}>{props.children}</AuthContext.Provider>);
};

export const useAuthContext = () => useContext(AuthContext);
