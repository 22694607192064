import React, { useContext, useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import MultiFilter, { StyledChip } from '../../MultiFilter';
import { LocalizationContext } from '../../LanguageContext/LocalizationContext';
import { EntityResponse, Speciality, SelectedFilters } from '../../../types';
import { BehaviorSubject, from } from 'rxjs';
import { ServiceContext } from '../../ServiceContext';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { distinctUntilChanged, concatMap, map } from 'rxjs/operators';
import { specialityStore } from '../../../store/stores';

interface SpecialityFilterProps {
    specialityFilters: string[],
    selectedSpecialities: EntityResponse<Speciality>[],
    setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>,
    loadingInitialState: boolean
}

const filterSubject = new BehaviorSubject<string[]>([]);

const SpecialityFilter: React.FC<SpecialityFilterProps> = props => {
    const {
        specialityFilters,
        selectedSpecialities,
        setSelectedFilters,
        loadingInitialState
    } = props;
    const [specialityUuids, setSpecialityUuids] = useState<string[]>([]);
    const [specialities, setSpecialities] = useState<EntityResponse<Speciality>[]>([]);
    const { translation } = useContext(LocalizationContext);
    const { dataService } = useContext(ServiceContext);

    useEffect(() => {
        if (dataService && specialityFilters.length !== 0 && !loadingInitialState) {
            filterSubject.next(specialityFilters);
            const sub = filterSubject.pipe(
                distinctUntilChanged(),
                concatMap(filters => dataService.requestSpecialities(filters)),
                map(resp => resp.data.erityisosaaminen ? resp.data.erityisosaaminen : [])
            ).subscribe(setSpecialityUuids);
            return () => sub.unsubscribe();
        }
    }, [dataService, specialityFilters, loadingInitialState]);

    useEffect(() => {
        if (dataService) {
            const sub = from(specialityUuids).pipe(
                distinctUntilChanged(),
                concatMap(uuid => dataService.requestSpeciality(uuid)
                    .catch(_err => { })
                )
            ).subscribe();
            return () => sub.unsubscribe();
        }
    }, [dataService, specialityUuids]);

    useEffect(() => {
        const sub = specialityStore.subject.subscribe(setSpecialities);
        return () => sub.unsubscribe();
    }, []);

    const onSelect = (_e: any, value: EntityResponse<Speciality>[]) => {
        setSelectedFilters((prevState: SelectedFilters) => ({
            ...prevState,
            selectedSpecialities: value
        }));
    };

    const renderTags = (
        value: EntityResponse<Speciality>[],
        getTagProps: AutocompleteGetTagProps
    ) =>
        value.map((option: EntityResponse<Speciality>, index: number) => (
            <StyledChip
                label={option.data.erityisosaaminen} {...getTagProps({ index })}
            />
        ));

    const filteredSpecialities = useMemo(() =>
        specialities.filter(speciality => specialityUuids.includes(speciality.uuid)),
        [specialities, specialityUuids]
    );

    return (
        <MultiFilter
            id={"specialtyFilter"}
            options={filteredSpecialities}
            label={translation.speciality}
            getOptionLabel={speciality => speciality.data.erityisosaaminen}
            getOptionSelected={(option, value) => option.uuid === value.uuid}
            placeholder={translation.searchSpeciality}
            onChange={onSelect}
            renderTags={renderTags}
            value={selectedSpecialities}
        />
    );
};

export default SpecialityFilter;
