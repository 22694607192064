import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { EntityResponse, Premise, Building } from "../../types";
import { LocalizationContext } from "../LanguageContext/LocalizationContext";
import { StyledLink } from "../Links";
import { ServiceContext } from "../ServiceContext";
import { getKunta, getStrOrEmpty, getAddress } from "../utils";

interface PremiseViewProps {
    premise: EntityResponse<Premise>,
    renderUrl: boolean
}

export const PremiseView: React.FC<PremiseViewProps> = props => {
    const { premise, renderUrl } = props;
    const [building, setBuilding] = useState<EntityResponse<Building> | null>(null);
    const { dataService } = useContext(ServiceContext);
    const { translation } = useContext(LocalizationContext);
    useEffect(() => {
        if (premise)
            dataService?.requestBuilding(premise.data.toimitila)
                .then(setBuilding)
                .catch(_err => { });
    }, [dataService, premise]);

    const Kunta =
        building?.data.katuosoite ?
            getKunta(building.data.katuosoite) : "";

    const liiketoiminta = premise && premise.data.Nimi.liiketoiminta ?
        premise.data.Nimi.liiketoiminta + " " :
        "";

    const yritys = premise && premise.data.Nimi.yritys ?
        premise.data.Nimi.yritys + " " : "";

    const toimipaikka = premise && premise.data.Nimi.toimipaikka ?
        premise.data.Nimi.toimipaikka + " " : "";

    const toimitila = premise && premise.data.Nimi.toimitila ?
        premise.data.Nimi.toimitila : "";

    const nimi = `${liiketoiminta}${yritys}${toimipaikka}${toimitila}`;

    const url = premise && premise.data.Verkkosivu ?
        premise.data.Verkkosivu : null;

    return premise && building ?
        <React.Fragment>
            {
                <Typography>
                    {nimi}
                </Typography>
            }
            <Typography>
                {getAddress(building.data.katuosoite ? building.data.katuosoite : "")}
            </Typography>
            <Typography>
                {`${getStrOrEmpty(building.data.Postinumero)} ${Kunta}`}
            </Typography>
            {renderUrl && url && <Typography>
                <StyledLink
                    text={translation.infoAndPrice}
                    link={url.startsWith("https") ? url : "https://" + url}
                />
            </Typography>}
        </React.Fragment> :
        null;
};
