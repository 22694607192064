import React from 'react';
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const Rehab = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 1024 1024">
            <g>
	            <path d="M32.7,214.6C32.7,214.6,32.7,214.6,32.7,214.6L69.1,251c0,0,0,0,0,0c37.2,37.4,87.3,57.7,139.3,57.7
		            c13.2,0,26.4-1.3,39.7-4c27.3-5.5,54.6-8,81.9-7.8l69.4,87.3c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.3,0.4
		            c16.2,18.5,38.7,30.8,63.4,34.7c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c3.1,0.5,6.1,0.8,9.2,0.8
		            c8.2,0,16.3-1.8,23.8-5.2c13.3,9.5,29,15.9,45.6,18.5c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
		            c3.3,0.5,6.5,0.8,9.7,0.8c9.3,0,18.3-2.2,26.4-6.3c13.4,10.8,30.1,16.6,47,16.6c10.9,0,22-2.4,32.3-7.4c0.2-0.1,0.3-0.1,0.5-0.2
		            c0.2-0.1,0.5-0.2,0.7-0.3c0,0,0.1,0,0.1-0.1c0.5-0.3,1-0.5,1.5-0.8c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		            c17.7-11,30-29.1,33.9-49.8c3.7-19.9-1.4-40.3-14.4-57.6c0,0,0-0.1-0.1-0.1l-181.9-252c0,0,0,0,0,0c-26.5-36.7-70.5-56.7-115-52.3
		            c0,0,0,0,0,0c0,0,0,0,0,0l-135,13.6c0,0,0,0,0,0c-13.6,1.3-27.2-3.1-37.4-12.1L200.4,15c-0.1-0.1-0.2-0.1-0.2-0.2
		            c-0.1-0.1-0.1-0.2-0.2-0.2C187.9,3.8,172.6-1.3,157,0.3c-15.8,1.6-31,10-40.5,22.5l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l-89,117.5
		            c0,0,0,0,0,0C10.4,163.1,12.5,194.3,32.7,214.6C32.7,214.6,32.7,214.6,32.7,214.6z M476,297.3l72.1,94c-14.1-2.6-26.9-9.5-36-19.7
		            l-49.7-66.5C467.1,303,471.7,300.4,476,297.3z M409.2,305.8l51.9,69.4c-11-3.4-21.1-9.8-28.6-18.2L388.9,302
		            C395.7,303.1,402.5,304.4,409.2,305.8z M161.2,43c3.6-0.4,7,0.8,10.1,3.5l11.3,10.7c0.1,0.1,0.2,0.1,0.2,0.2
		            c0.1,0.1,0.1,0.1,0.2,0.2C200,72.7,221.9,81,244.4,81c3,0,6-0.1,9-0.4c0,0,0,0,0,0c0,0,0,0,0,0l135-13.6c0,0,0,0,0,0
		            c29.3-2.9,58.4,10.4,76,34.7c0,0,0,0,0,0l181.9,252c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c4.1,5.4,8.6,14,6.7,24.2
		            c-1.6,8.4-6.7,16.1-13.7,20.8c-13.3,6.2-29.3,2.6-38.3-8.9L501.3,259.7c7.6-29.7-5.8-60.3-34.1-76.1c0,0,0,0-0.1,0
		            c-51.7-30.4-112.9-42.6-172.3-34.1c-11.7,1.7-19.9,12.5-18.2,24.3c1.7,11.7,12.5,19.9,24.3,18.2c49.8-7.1,101.1,3.1,144.5,28.6
		            c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1c12,6.6,17.3,18.9,13.1,30.6c-4.1,11.6-16.5,18.2-28.7,15.2c0,0,0,0,0,0
		            c-62.8-15.4-126.9-16.7-190.6-4c-51.4,10.3-103.8-5.4-140.1-42c0,0,0,0,0,0c0,0,0,0,0,0l-36.3-36.3c-4.9-5-5.5-12.5-1.4-18
		            c0,0,0,0,0,0l89-117.5c0,0,0,0,0,0c0,0,0,0,0,0C153.1,45.7,157.3,43.4,161.2,43z"/>
	            <path d="M1003.2,787.1C1003.2,787.1,1003.2,787.1,1003.2,787.1l-115-116.3c0,0,0,0,0,0c0,0,0,0,0,0
		            c-65.5-65.9-158.7-94.3-249.3-76.2c-40.5,8.1-82,11.8-123.4,11L415.1,479.8c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.4
		            c-22.6-26-53.1-43-85.8-48.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0
		            c-16-2.6-32.3-0.1-46.5,7.1c-18.4-14.2-40.8-23.9-65.3-27.7c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
		            c-18.7-3-37.4,0.8-53,10.1c-29.9-26.1-73.3-32.6-109.7-14.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
		            c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
		            c-23.8,14.8-40.4,39-45.5,66.6c-5.2,27.7,1.6,56.4,18.4,78.8c0,0,0.1,0.1,0.1,0.1l259.8,359.8c0,0,0,0,0,0
		            c33,45.6,86.2,72.5,142.1,72.5c5.7,0,11.4-0.3,17.1-0.8c0,0,0,0,0,0c0,0,0,0,0,0l192.9-19.5c20.8-2,42,5,58.1,19.3c0,0,0,0,0,0
		            c0,0,0,0,0,0l27.9,24.7c0,0,0,0,0.1,0.1c11.3,10,25.5,15.2,39.7,15.2c10.8,0,21.6-3,31.2-9c0.5-0.3,1.1-0.6,1.6-1l200.7-135.7
		            c0,0,0,0,0,0c15-10.2,25-26.6,26.7-43.9C1022.6,817.3,1016.1,800.1,1003.2,787.1z M309.3,609.1L194.7,458.9
		            c4.2-1.2,8.8-1.6,13.2-0.8c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c23.2,3.6,43.2,14.7,56.5,31.1l76,101.9C329,594.9,318.4,601,309.3,609.1z
		            M401.9,592l-84.6-113.6c0.9,0.1,1.8,0.2,2.7,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c21.5,3.2,41.6,14.5,56.8,31.8l71.6,89.8
		            C433.3,598.3,417.5,595.5,401.9,592z M322.3,884.1L62.5,524.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
		            c-8.7-11.4-12.4-26.8-9.7-41c2.6-13.9,10.9-26.2,22.8-33.8c21.4-10.1,47.3-3.9,62.1,15l144.7,189.7c-10.2,39.1,8.7,82.2,46.1,103.1
		            c0,0,0,0,0.1,0c57.7,34,123.7,51.7,190.2,51.7c17.4,0,34.8-1.2,52.1-3.7c13.3-1.9,22.6-14.2,20.7-27.5
		            c-1.9-13.3-14.2-22.6-27.5-20.7c-72.7,10.3-147.6-4.5-210.8-41.8c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
		            c-16.4-9-29.8-30.1-22.2-51.5c7.1-20,28-31.3,48.5-26.2l0,0c1.2,0.3,2.4,0.6,3.5,0.8c0.2,0.1,0.4,0.1,0.6,0.1
		            c37.8,9,76.5,14.4,115.5,16.1c1.9,0.5,3.9,0.7,5.9,0.7c1.4,0,2.8-0.1,4.2-0.4c6.1,0.2,12.3,0.3,18.4,0.3c41,0,81.9-4,122-12
		            c74.5-15,151.2,8.5,205.2,62.8c0,0,0,0,0,0l114.9,116.2c0,0,0,0,0,0l0,0c1.6,1.6,4.2,4.8,3.9,8.3c-0.3,3-2.5,6.3-5.5,8.3
		            c0,0,0,0,0,0L766.3,973.7c0,0,0,0,0,0c-4,2.7-9.1,2.2-13.1-1.3c0,0,0,0,0,0c0,0,0,0,0,0l-27.9-24.7c0,0,0,0,0,0
		            c-26.2-23.3-60.9-34.7-95.1-31.3l0,0c0,0,0,0,0,0l-192.9,19.5C392.5,940.2,348.5,920.4,322.3,884.1z"/>
            </g>
        </SvgIcon>
    );
};

export default Rehab;
